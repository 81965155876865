import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, OnChanges, HostListener } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Chapter } from '../../../../model/chapter.model';
import { Resource } from '../../../../model/resource.model';
import { Section } from '../../../../model/section.model';
import { Communication } from '../../../../model/communication.model';
import { CreateGroup } from '../../../../model/group.model';
import { User, UserSuccessData } from '../../../../model/user.model';
import { AuthService } from '../../../../services/auth.service';
import { CommunicationService } from '../../../../services/communication.service';
import { SharedService } from '../../../../services/shared.service';
import { UserService } from '../../../../services/user.service';
import { Constants } from '../../../../utilities/constants';
import { MessageAlert } from '../../../../utilities/message.alert';
import { Utility } from '../../../../utilities/utility';

@Component({
  selector: 'app-create-chat-group',
  templateUrl: './create-chat-group.component.html',
  styleUrls: ['./create-chat-group.component.scss']
})
export class CreateChatGroupComponent implements OnInit, OnChanges {
  // @Input() allMembers: User[] = [];
  @Input() newGroupCheckedIds: any = {};
  @Input() groupMessage = new Communication();
  @Input() groupModel = new CreateGroup();
  @Input() resource = new Resource();
  @Input() chapter = new Chapter();
  @Input() section = new Section();
  @Input() viewName = '';
  @Input() btnDisabled = false;
  @Output() onCreateGroup: EventEmitter<any> = new EventEmitter();
  @Output() onUpdateGroup: EventEmitter<any> = new EventEmitter();
  authUser = new User();
  allMembers: User[] = [];
  keywords = new FormControl('');
  page = 1;
  limit = 20;
  userTypes = Constants.userTypes;
  constructor(
    private _authService: AuthService,
    private alert: MessageAlert,
    private _communicationService: CommunicationService,
    private _userService: UserService,
    private _sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.authUser = this._authService.authUser();
    this.getUsers();
    this.keywords.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(data => {
      this.memberSearch();
    });
  }
  memberSearch() {
    this.page = 1;
    this.allMembers = [];
    this.getUsers();
  }
  ngOnChanges() {
    this.groupModel._id = this.groupMessage._id;
    this.groupModel.groupName = this.groupMessage.groupName;
    this.groupModel.createdBy = this.groupMessage.createdBy;
    setTimeout(() => {
      this.newGroupCheckedIds[this.authUser._id] = true;
      this.groupModel.members = Object.keys(this.newGroupCheckedIds);
    }, 1000);
  }
  onSubmitGroup(): void {
    this.groupModel.messageType = Constants.messageType.GROUP;
    this.groupModel.members = Object.keys(this.newGroupCheckedIds);
    if (!this.groupModel.groupName.trim()) {
      return this.alert.warning('Group name should not be empty');
    }
    if (this.groupModel.members.length < 2) { // one will be the current user
      return this.alert.warning('Please select at least one member');
    }
    this.groupModel.sectionId = this.section._id;
    this.groupModel.chapterId = this.chapter._id;
    this.groupModel.resourceId = this.resource._id;
    if (this.groupModel._id) {
      this.updateGroup(this.groupModel);
    } else {
      this.createGroup(this.groupModel);
    }
    this.groupModel.groupName = '';
    this.newGroupCheckedIds = {};
  }
  createGroup(groupModel: CreateGroup): void {
    this.btnDisabled = true;
    this._communicationService.createGroup(groupModel).subscribe((res) => {
      this.alert.success(res.message);
      this.btnDisabled = false;
      this._sharedService.setGroupWriteEvent(true);
      document.getElementById('btnDismissModal')?.click();
    }, (err) => {
      this.alert.error(Utility.bindErrors(err));
      this.btnDisabled = false;
    });
  }
  updateGroup(groupModel: CreateGroup) {
    groupModel.messageType = Constants.messageType.GROUP;
    this._communicationService.updateGroup(groupModel._id, groupModel).subscribe((res) => {
      this.alert.success(res.message);
      this._sharedService.setGroupWriteEvent(true);
      document.getElementById('btnDismissModal')?.click();
    }, (err) => {
      this.alert.error(Utility.bindErrors(err));
    });
  }
  onCheckedMember(checked: boolean, id: string) {
    if (!checked) {
      delete this.newGroupCheckedIds[id];
    }
    this.groupModel.members = Object.keys(this.newGroupCheckedIds);
  }
  getUsers() {
    this._userService.filterList(
      this.keywords.value,
      this.userTypes.STUDENT,
      this.resource._id,
      true,
      this.page,
      this.limit,
      '-invitationData.sortOrder'
    ).subscribe((res: HttpResponse<UserSuccessData>) => {
      const membersList = res.body?.data || [];
      if (this.allMembers.length === 0) {
        this.allMembers = membersList.slice();
      } else {
        this.allMembers = [...this.allMembers, ...membersList];
      }
    });
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    // visible height + pixel scrolled >= total height
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.page++;
      this.getUsers();
    }
  }
}
