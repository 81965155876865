<section class="banner_sec" [ngClass]="{'individual_': page === 'individual', 'course_provider': page === 'university', 'corporate_trainer': page === 'corporate' }">
    <div class="position-relative">
      <ng-container *ngIf="page === 'individual' ">
        <img src="assets/img/individual_bg.jpg" class="img-fluid main_bg">
      </ng-container>
      <ng-container *ngIf="page === 'university' ">
        <img src="assets/img/course-provider-bg.jpg" class="img-fluid main_bg">
      </ng-container>
      <ng-container *ngIf="page === 'corporate' ">
        <img src="assets/img/corporate-training-bg.jpg" class="img-fluid main_bg">
      </ng-container>
    <div class="text_overlay">
        <ng-container *ngIf="page === 'individual' ">
            <div>
                <h1 class="mb-3 main_head">Our finance library contains both theoretical topics at MBA,CFA and Masters Level but also more practical industry related topics within
                  the  finance sector. Browse our library and obtain an edge.
            </h1>
                <div class="sub_txt_sec">
                <p class="sub_head">Access a stream to provide interactive problems, some with calculators Q&A  and multiple choice questions.</p>
               </div>
               <a href="javascript:void(0)" class="common_btn" (click)="getStart(resourcesRef)">Get started now</a>
            </div>
        </ng-container>
        <ng-container *ngIf="page === 'university' ">
            <div>
                <h1 class="mb-3 main_head">Use our existing streams or create your own streams via Finstream.</h1>
                <div class="sub_txt_sec">
                <p class="sub_head">Powerful Admin capability to allow self-contained private content  build, publishing and tutor outsourcing.</p>
                <p class="sub_head">Enhance your current course offering.</p>
               </div>
            </div>
        </ng-container>
        <ng-container *ngIf="page === 'corporate' ">
            <div>
                <h1 class="mb-3 main_head">Target your clients with  specific inhouse streams that provide specific skill sets.</h1>
                <div class="sub_txt_sec">
                  <p class="sub_head">Powerful Admin capability to allow self-contained private content  build, publishing and tutor outsourcing. </p>
                  <p class="sub_head">Use our Streams or create your own .</p>
                </div>
            </div>
        </ng-container>
    </div>
  </div>
</section>
<div class="scroll_parallax">
    <div class="container-fluid pt-5">
        <div class="row">
            <ng-container *ngIf="page === 'individual' ">
                <div class="col-md-4 col-lg-4">
                    <div class="white_wrp">
                    <img src="assets/img/education.svg">
                    <h4>Existing Course</h4>
                    <p>Losing touch with the course material due to time pressures? </p>
                    <p>Access our online primary learning Streams  such as FX, Derivatives, Portfolio Theory, Interest Rates to use as an additional continual resource to help with your degree</p>
                    <a href="about.html" class="secondary_btn">View Experts</a>
                </div>
                </div>
                <div class="col-md-4 col-lg-4">
                    <div class="white_wrp">
                    <img src="assets/img/learn.svg">
                    <h4>Advanced Topics</h4>
                    <p>Are you doing a specific course and feel you would like more advanced material. You can choose several streams which are more mathematical and  can can prepare the user for more industry related skill set</p>
                    <p>Targeting specific sectors of the Finance Industy e.g. Funds management, FX Risk Management, Structured Products Risk, Interest Rates.</p>
                    <a href="resources-efx.html" class="secondary_btn">Find out more</a>
                </div>
                </div>
                <div class="col-md-4 col-lg-4">
                    <div class="white_wrp">
                        <img src="assets/img/document.svg">
                        <h4>Finance Professional</h4>
                        <p>For finance professionals who can quickly and continually access key foundational concepts for accelerated learning  and help to transition within the industry.</p>
                         <p>Targeting specific sectors of the Finance Industy e.g. Portfolio Theory concepts, FX Risk Management, Structured Products Risk, Value at Risk.</p>
                        <a href="resources-efx.html" class="secondary_btn">View New Content</a>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="page === 'university' ">
                <div class="col-md-4 col-lg-4">
                    <div class="white_wrp">
                    <img src="assets/img/education.svg">
                    <h4>Content</h4>
                    <p>The theory and content include standard Masters of Finance material however there is a substantial level of transitional material for those who wish to obtain material to help transition to the workforce that is designed by our industry experts </p>
                    <p>Currently our primary learning Streams are FX, Portfolio Theory,  Derivatives, Interest rates and Structured Products.</p>
                    <p>Interactive Problems aligned with the theory.</p>
                    <a href="about.html" class="secondary_btn">View Experts</a>
                   </div>
                </div>
                <div class="col-md-4 col-lg-4">
                    <div class="white_wrp">
                        <img src="assets/img/learn.svg">
                        <h4>Admin</h4>
                        <p>Choose specific topics, both theory and interactive elements problems and create a 'stream' or course With admin rights provided by Finstream additional content by the course provider can be built and added to the library with strict access available only to the content owner.</p>
                         <p>Students and tutors associated with a course can have acccess by a simple drop and drag procedure from Admin </p>
                          <p>Students can form internal discussion group, take notes and ask their tutor questions and context of the material can be added by the Course Coordinator.</p>
                        <a href="resources-efx.html" class="secondary_btn">Find out more</a>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4">
                    <div class="white_wrp">
                        <img src="assets/img/document.svg">
                        <h4>Bespoke Material</h4>
                        <p>Targeting specific sectors of the Finance Industy e.g. Funds management, FX risk management,  Structured Products Risk, Interest Rates.</p>
                         <p>Choose FinStream topics or create via your admin selected topics to facilate focus.</p>
                        <a href="resources-efx.html" class="secondary_btn">View New Content</a>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="page === 'corporate' ">
                <div class="col-md-4 col-lg-4">
                    <div class="white_wrp">
                    <img src="assets/img/education.svg">
                    <h4>Content</h4>
                    <p>The theory and content include standard Masters of Finance material however there is a substantial level of transitional material for those who wish to obtain material to help transition to the workforce that is designed by our industry experts </p>
                    <p>Currently our primary learning Streams are FX, Portfolio Theory,  Derivatives, Interest rates and Structured Products.</p>
                    <p>Interactive Problems aligned with the theory.</p>
                    <a href="about.html" class="secondary_btn">View Experts</a>
                   </div>
                </div>
                <div class="col-md-4 col-lg-4">
                    <div class="white_wrp">
                        <img src="assets/img/learn.svg">
                        <h4>Admin</h4>
                        <p>Choose specific topics, both theory and interactive elements problems and create a 'stream' or course With admin rights provided by Finstream additional content by the course provider can be built and added to the library with strict access available only to the content owner.</p>
                         <p>Students and tutors associated with a course can have acccess by a simple drop and drag procedure from Admin </p>
                          <p>Students can form internal discussion group, take notes and ask their tutor questions and context of the material can be added by the Course Coordinator.</p>
                        <a href="resources-efx.html" class="secondary_btn">Find out more</a>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4">
                    <div class="white_wrp">
                        <img src="assets/img/document.svg">
                        <h4>Bespoke Material</h4>
                        <p>Targeting specific sectors of the Finance Industy e.g. Funds management, FX risk management,  Structured Products Risk, Interest Rates.</p>
                         <p>Choose FinStream topics or create via your admin selected topics to facilate focus.</p>
                        <a href="resources-efx.html" class="secondary_btn">View New Content</a>
                    </div>
                </div>
            </ng-container>
        </div>
      </div>
    <section class="resource_home" #resourcesRef>
        <!-- <div class="container-fluid pt-5">
            <div class="row">
                <div class="col-lg-12">
                    <h2>Popular Streams</h2>
                    <nav class="pt-5">
                        <div class="nav nav-tabs justify-content-center border-0 scroll_menu" id="nav-tab" role="tablist">
                            <a class="nav-item nav-link" [ngClass]="{'active':resource._id===item._id}"
                                id="nav-home-tab" data-toggle="tab" href="#resource1" role="tab"
                                aria-controls="nav-home" aria-selected="true" *ngFor="let item of resourcesList"
                                (click)="resource = item; getChapters(item._id)">{{item.title}}
                                <mat-spinner [diameter]="20" *ngIf="resource._id===item._id && isLoadingChapters">
                                </mat-spinner>
                            </a>
                        </div>
                    </nav>
                    <div class="tab-content pt-4" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="resource1" role="tabpanel"
                            aria-labelledby="nav-home-tab">
                            <div class="owl-carousel resource_slider wrpr_height" id="resource_slider1">
                                <div class="card col" *ngFor="let item of chaptersList">
                                    <img class="card-img-top" [src]="item.image?.thumbPath || 'assets/img/chapter-default.png'" [alt]="item.image?.originalName">
                                    <div class="card-body">
                                        <h5 class="card-title">{{item.title}}</h5>
                                        <ul>
                                            <li class="card-text" *ngFor="let s of item.sectionsList | slice:0:4">
                                                <span *ngIf="s.status==='Active'">{{s.title}}</span>
                                            </li>
                                        </ul>
                                        <a routerLink="/resources" *ngIf="item.sectionsList.length>4">View all sections ></a>
                                    </div>
                                </div>
                            </div>
                            <div class="slider_nav text-center" *ngIf="resourcesList.length>0">
                                <button class="am-next"> <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                        fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                    </svg> </button>
                                <a routerLink="/resources">View all <svg xmlns="http://www.w3.org/2000/svg" width="25"
                                        height="25" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                    </svg></a>
                                <button class="am-prev"> <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                        fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg> </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </section>
    <button id="btnTrigger" style="display: none;" data-toggle="modal" data-target="#createAccount" ></button>
    <!-- <section class="reach_us">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-6 offset-lg-6 col-md-7 offset-md-5">
              <h2>Shine out from the<br> crowd</h2>
              <p>Access high quality resources from the<br> world's leading experts today!</p>
              <div class="d-flex justify-content-left">
              <a class="cursor-pointer" (click)="getStartLogin()">Get started now</a>
              <button id="btnTrigger" style="display: none;" data-toggle="modal" data-target="#createAccount" ></button>
            </div>
            </div>
          </div>
        </div>
    </section> -->
    <!-- <section class="testimonial_sec bg_blue">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div id="testimonialCarousel" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner" role="listbox">
                            <div class="carousel-item active text-center">
                                <div class="carousel-content">
                                    <h2 class="col-md-6 offset-md-3">Lorem ipsum dolor sit amet, consectetur adipiscing
                                        elit. Sed efficitur tortor nulla, vel pellentesque urna pulvinar sed.</h2>
                                    <h3>— Joe Bloggs, Deloitte </h3>
                                    <div class="client-img"><img src="assets/img/prof1.jpg" alt="Testimonial Slider">
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item text-center">
                                <div class="carousel-content">
                                    <h2 class="col-md-6 offset-md-3">Lorem ipsum dolor sit amet, consectetur adipiscing
                                        elit. Sed efficitur tortor nulla, vel pellentesque urna pulvinar sed.</h2>
                                    <h3>— Joe Bloggs, Deloitte </h3>
                                    <div class="client-img"><img src="assets/img/prof1.jpg" alt="Testimonial Slider">
                                    </div>
                                </div>
                            </div>

                            <a class="carousel-control-prev text-white" href="#testimonialCarousel" role="button"
                                data-slide="prev">
                                <i class="fas fa-angle-left"></i>
                            </a>
                            <a class="carousel-control-next text-white" href="#testimonialCarousel" role="button"
                                data-slide="next">
                                <i class="fas fa-angle-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <!-- <section class="partners">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2>Partners</h2>
                    <div class="owl-carousel owl-theme" id="partners">
                        <div class="slide_item"><img src="assets/img/University_of_Sydney1.png"></div>
                        <div class="slide_item"><img src="assets/img/2000px-Deloitte.svg.png"></div>
                        <div class="slide_item"><img src="assets/img/ernst-young-logo.png"></div>
                        <div class="slide_item"><img
                                src="assets/img/faculty-of-education-university-of-cambridge-clare-hall-cambridge-university-of-edinburgh-school-university-logo-1f36fc36a2621e52e7e3a8e9348e2421.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <app-footer></app-footer>
</div>
<!-- Modal -->
<app-signup-modal></app-signup-modal>
