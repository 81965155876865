<div class="topsec h-auto">
    <div class="sub-banner top-banner banner3">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-7 col-xl-5">
                    <h2>Our vision</h2>
                    <h6>FinStream’s founders wanted to improve the quality of financial services education with
                        an online platform dedicated to the finance sector. With a significant gap between
                        theory and practice, students, even those with a Masters in Finance, require additional
                        skills to find ongoing success in the industry. This has created an ongoing problem for
                        both recent graduates and finance employers.</h6>
                    <h6 class="pt-4">Their vision was to help bridge the gap between higher education and
                        professional banking for individuals and companies.</h6>
                </div>
            </div>
        </div>
    </div>
</div>
<section>
    <div class="container py-4 py-lg-5">
        <div class="row justify-content-center px-4 px-lg-0">
            <div class="col-lg-10 py-2 py-lg-4">
                <p>
                    To achieve this vision, our founders sought to create a more innovative approach to
                    learning, offering more interactive aspects and a focus on the most important finance
                    aspects. They created FinStream.
                </p>
                <p class="blue-txt">
                    What makes us different? FinStream seeks to contextualise learning to focus on the more
                    complex parts of finance. It is practice-based and focuses on how individual proficiency
                    boosts learning.
                </p>
                <p>
                    At FinStream, we go beyond simply explaining the rules of finance. Our team is engaged in
                    genuine partnerships with our users. We actively listen, formulate ideas and develop
                    solutions to provide the best learning experience and outcomes possible.
                </p>
                <p style="padding: 2rem 0;">
                    If you’re already working in the finance industry, our educational streams will let you
                    quickly and continually expand your understanding of key foundational concepts, enabling faster learning
                    to help you excel in this industry.
                </p>
            </div>
        </div>
    </div>
</section>
<section class="team">
    <div class="container">
        <div class="row justify-content-center px-4 px-lg-0">
            <div class="col-lg-10 pe-10">
                <h2 class="under-line">Our team</h2>
                <p class="mt-4">We have an accessible team of financial professionals who are committed to
                    enhancing the learning experience. They have the knowledge and a can-do attitude to find
                    what you need now and throughout your educational journey. </p>
                <p class="mt-4">Users can request access to instructors, all of whom are either working
                    practitioners or consultants in their specialised fields of study.</p>
                <p class="mt-4">Together, our team offers:</p>
                <div class="row pt-4">
                    <div class="col-lg-5 pb-4 pb-lg-0">
                        <h4>20+ years</h4>
                        <h5>at major investment</h5>
                    </div>
                    <div class="col-lg-6">
                        <h4>10+ years</h4>
                        <h5>teaching masters finance</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="btm-wrp pb-4 pb-lg-5">
    <div class="container">
        <!-- <div class="row p-4 p-sm-5 justify-content-center">
            <div class="col-lg-11 mt-3">
                <h2 class="under-line">Our Clients</h2>
                <p>FinStream’s user base ranges in educational backgrounds, professional history and location.
                    From postgraduate candidates to early financial industry professionals, FinStream can be
                    tailored to user or company needs. </p>
                <p class="mt-4">In addition to our large individual user base, FinStream also works with several
                    clients worldwide in staff training.</p>
            </div>
        </div> -->
        <div class="row p-4 p-sm-5 justify-content-center">
            <div class="col-lg-11 mt-3">
                <h2 class="under-line">Finstream Founders</h2>
                
                <div class="row mt-6">
                    <div class="col-lg-3">
                        <div class="prof-img"><img src="assets/img/steve.png" alt=""></div>
                        <h4 class="name">Steve MacIntosh</h4>
                    </div>
                    <div class="col-lg-9">
                        <div class="prof-description">
                            <p>
                                Steve's key areas of expertise are identifying new markets that are under serviced by
                                current industry offerings, optimising management structures and implementing change
                                programs. Apart from interest in providing innovative solutions within the corporate
                                training sector Steve is a highly experienced property and facilities management
                                industry leader with a significant global track record of achievement in large scale
                                outsourcing solutions. He is currently Chairman of NPM and Deputy Chairman of Ikon
                                Services. In 2010 he led an MBO of international property services firm Five D and was
                                Chairman and CEO until the business was acquired by JLL in 2015.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row mt-6">
                    <div class="col-lg-3">
                        <div class="prof-img"><img src="assets/img/james.png" alt=""></div>
                        <h4 class="name">James Dunning</h4>
                    </div>
                    <div class="col-lg-9">
                        <div class="prof-description">
                            <p>
                                James’s career included working with two professional services organisations and a major
                                London stockbroker, he was a partner with PricewaterhouseCoopers for 21 years in their financial services practice.
                                Experienced in listing entities on the ASX, equity and debt raisings, mergers and acquisitions and providing
                                assurance
                                services. Clients included ASX 200 entities and major local and international fund managers. He developed PwC’s
                                internal
                                training material for their market leading real estate assurance team. The education program provided industry
                                knowledge
                                and skills which facilitated career progression both within PwC and with leading real estate companies.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row mt-6">
                    <div class="col-lg-3">
                        <div class="prof-img"><img src="assets/img/steve2.png" alt=""></div>
                        <h4 class="name">Steve Bannigan</h4>
                    </div>
                    <div class="col-lg-9">
                        <div class="prof-description">
                            <p>
                                Steve is an investor with interests in fintech, edutech, resources and hospitality via
                                his investment house, Liquidity Partners. In 2013 Steve co-founded the ASX-listed
                                diversified consumer direct lending platform, MoneyMe, which has been highly successful
                                in providing innovative finance solutions designed specifically for the digital world.
                                Steve strengths are in identifying sectors and opportunities where technology has not
                                yet been optimally deployed and where business models and customer experience can be
                                favourably changed through the deployment of thoughtful operational changes, underpinned
                                by innovative technology.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row mt-6">
                    <div class="col-lg-3">
                        <div class="prof-img"><img src="assets/img/glenn.png" alt=""></div>
                        <h4 class="name">Dr Glenn Kentwell</h4>
                    </div>
                    <div class="col-lg-9">
                        <div class="prof-description">
                            <p>
                                Glenn has worked for several global Investment banks for over 25 years as a lead quant. These include including
                                Bankers Trust, Macquarie Bank, CitiGroup, Merrill Lynch, ANZ at a Executive Vice President and Manager Director
                                levels. The main areas include derivative pricing, structuring, corporate risk management and regulatory risk. More recently he has been involved as a part-time lecturer at the University of Sydney, School of
                                Business Masters of Finance program e.g. Derivative Securities, International Finance (FX) and Portfolio Theory.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="getin-touch">
    <div class="container-fluid">
        <div class="row p-4">
            <div class="col-lg-5">
                <h5 class="mb-2">Partner with us and our FinStream community to explore new opportunities
                    and enhance your lifelong learning experience. Contact us today.
                </h5>
                <a class="secondary-btn" routerLink="/contact">Contact Us</a>
            </div>
        </div>
    </div>
</div>
<!-- <section class="about_sec">
    <div class="container-fluid w_80">
        <div class="row">
            <div class="col-lg-12">
                <h3>About Us</h3>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container-fluid w_80">
        <div class="row">
            <div class="col-lg-12">
                <div class="wrapper_white">
                    <h1>We offer lots of online<br class="breake_lg">
                        courses from the best</h1>
                    <p>it officia commodo ex quis ex fugiat ullamco in enim labore. Cupidatat elit nisi adipisicing eu
                        reprehenderit adipisicing excepteur. Do aliqua velit ipsum nostrud ad consectetur amet dolore
                        exercitation aute nisi consectetur consequat </p>
                    <video width="100%" height="100%" controls poster="img/video_thumb.png">
                        <source src="assets/img/corporate.mp4" type="video/mp4">
                    </video>
                    <div class="row pt-4">
                        <div class="col-lg-12 text-center">
                            <h2>Team Members</h2>
                        </div>
                        <div class="col-lg-4 col-md-6 text-center pt-5">
                            <div class="people">
                                <img src="assets/img/member1.png">
                            </div>
                            <h5>Joseph S. Tom</h5>
                            <h6>Autor courses</h6>
                        </div>
                        <div class="col-lg-4 col-md-6 text-center pt-5">
                            <div class="people">
                                <img src="assets/img/member2.png">
                            </div>
                            <h5>Joseph S. Tom</h5>
                            <h6>Autor courses</h6>
                        </div>
                        <div class="col-lg-4 col-md-6 text-center pt-5">
                            <div class="people">
                                <img src="assets/img/member3.png">
                            </div>
                            <h5>Joseph S. Tom</h5>
                            <h6>Autor courses</h6>
                        </div>
                        <div class="col-lg-4 col-md-6 text-center pt-5">
                            <div class="people">
                                <img src="assets/img/member4.png">
                            </div>
                            <h5>Joseph S. Tom</h5>
                            <h6>Autor courses</h6>
                        </div>
                        <div class="col-lg-4 col-md-6 text-center pt-5">
                            <div class="people">
                                <img src="assets/img/member1.png">
                            </div>
                            <h5>Joseph S. Tom</h5>
                            <h6>Autor courses</h6>
                        </div>
                        <div class="col-lg-4 col-md-6 text-center pt-5">
                            <div class="people">
                                <img src="assets/img/member2.png">
                            </div>
                            <h5>Joseph S. Tom</h5>
                            <h6>Autor courses</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="partners">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2>Partners</h2>
                <div class="owl-carousel owl-theme" id="partners">
                    <div class="slide_item"><img src="assets/img/University_of_Sydney1.png"></div>
                    <div class="slide_item"><img src="assets/img/2000px-Deloitte.svg.png"></div>
                    <div class="slide_item"><img src="assets/img/ernst-young-logo.png"></div>
                    <div class="slide_item"><img
                            src="assets/img/faculty-of-education-university-of-cambridge-clare-hall-cambridge-university-of-edinburgh-school-university-logo-1f36fc36a2621e52e7e3a8e9348e2421.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->