import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SuccessDialogComponent } from 'src/app/common/success-dialog/success-dialog.component';
import { CertificateModel, CertificateSuccessData } from 'src/app/model/certificate.model';
import { DialogModel } from 'src/app/model/dialog.model';
import { CertificateService } from 'src/app/services/certificate.service';

@Component({
  selector: 'app-my-certificates',
  templateUrl: './my-certificates.component.html',
  styleUrls: ['./my-certificates.component.scss']
})
export class MyCertificatesComponent implements OnInit {
  listItems: CertificateModel[] = [];
  keywords = new FormControl('');
  page = 1;
  limit = 20;
  totalCount = 0;
  // status = 'true';
  orderBy = '-createdAt';
  errorMessage = '';
  today = moment().format('YYYY-MM-DD');
  isLoading = false;
  noResult = false;
  btnDisabled = false;
  httpError: HttpErrorResponse | undefined;
  constructor(
    private dialog: MatDialog,
    private certificateService: CertificateService
  ) { }

  ngOnInit(): void {
    this.filterList();
    this.keywords.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(data => {
      this.page = 1;
      this.listItems = [];
      this.filterList();
    });
  }
  filterList() {
    this.isLoading = true;
    this.noResult = false;
    this.listItems = [];
    this.httpError = undefined;
    this.certificateService.filterList(
      this.keywords.value,
      this.orderBy,
      this.page,
      this.limit
    ).subscribe((res: HttpResponse<CertificateSuccessData>) => {
      this.listItems = res.body?.data || [];
      this.totalCount = Number(res.headers.get('x-total-count'));
      if (this.listItems.length === 0) {
        this.noResult = true;
      }
      this.isLoading = false;
    }, (err: HttpErrorResponse) => {
      this.listItems = [];
      this.noResult = true;
      this.isLoading = false;
      this.httpError = err;
    });
  }
  pageChanged(pno: number) {
    this.page = pno;
    this.filterList();
  }
  showMessage(title: string, message: string): void {
    const dialogData = new DialogModel(title, message);
    this.dialog.open(SuccessDialogComponent, {
      maxWidth: '600px',
      data: dialogData
    });
  }
  onSortBy(field: string) {
    if (this.orderBy.includes('-')) {
      this.orderBy = field;
    } else {
      this.orderBy = '-' + field;
    }
    this.filterList();
  }
}
