<div class="col not-found-page">
    <div class="noResultsCont">
        <div *ngIf="!isLoading && isVerificationError">
            <img src="assets/img/error-500.svg">
            <div class="noresultTxt">
                <label>Invalid request!</label>
                <p class="mt-3">{{message}}</p>
            </div>
        </div>
        <div *ngIf="!isLoading && !isVerificationError" class="content-height">
            <img src="assets/img/successTick.svg">
            <h4>{{message}}</h4>
        </div>
        <div *ngIf="isLoading" class="content-height" style="margin-top: 10%;">
            <mat-spinner class="btnLoading" [diameter]="75">
            </mat-spinner>
        </div>
    </div>

</div>
<app-footer></app-footer>