export class Section {
    _id = '';
    chapterId = '';
    content = '';
    createdAt = '';
    createdBy = '';
    publishedAt = '';
    resourceId = '';
    slug = '';
    status = '';
    title = '';
    isFree = false;
    hasSectionAccess = false;
    updatedAt = '';
    updatedBy = '';
}
export class SectionList {
    _id = '';
    title = '';
    slug = '';
    status = '';
    isFree = false;
    hasSectionAccess = false;
    chapters: string[] = [];
    contentInteractive = '';
    contentRelatedCourse = '';
    contentTheory = '';
    contentTheoryUseAdvancedEditor = false;
    contentTheoryAdvanced = '';
    createdAt = '';
    createdBy = '';
    organization = '';
    publishedAt = '';
    resources: string[] = [];
    updatedAt = '';
    updatedBy = '';
    showTheory = false;
    showInteractive = true;
    showRelatedCourse = true;
}
export interface SectionSuccessData {
    message: string;
    status: string;
    data: [Section];
}
export interface SectionErrorData {
    message: string;
    status: string;
    data: any;
}
export class InteractiveExamination {
    id = '';
    duration = 0;
    questions: any = [];
    totalQuestions = 0;
    totalAttended = 0;
    totalMark = 0;
    examRetry = 0;
    answers = {} as any;
    marks = {} as any;
    activeQuestion = {
        id: '',
        index: 0
    };
    isStarted = false;
    isCompleted = false;
    resource = '';
    chapter = '';
    section = '';
};
