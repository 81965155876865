<div class="col not-found-page">
    <div class="noResultsCont">
        <!-- <img src="assets/img/error-401.svg"> -->
        <div class="container pb-4">
            <div class="row justify-content-center pb-5">    
                <div class="">
                    <a class="primaryButton cursor-pointer" (click)="exploreApp()">Explore our library - and register for free!</a>
                </div>
            </div>
        </div>
        <div class="noresultTxt">
            <label>Register to see content!</label>
            <p class="mt-3">You are not authorized for this content. Please Register to see content</p>
        </div>
    </div>
</div>