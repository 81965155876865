import { Component, HostListener, Inject, Input, OnInit, OnChanges, PLATFORM_ID, Output, EventEmitter } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MessageAlert } from '../../../utilities/message.alert';
import { Animation } from '../../../utilities/animation';
import { Constants } from '../../../utilities/constants';
import { CommunicationService } from '../../../services/communication.service';
import { AuthService } from '../../../services/auth.service';
import { SharedService } from '../../../services/shared.service';
import { UserService } from '../../../services/user.service';
import { CreateGroup } from '../../../model/group.model';
import { User, UserSuccessData, UserDetails, AuthUser } from '../../../model/user.model';
import { Communication, CommunicationDetailsSuccessData, CommunicationSuccessData, Members } from '../../../model/communication.model';
import { Section } from '../../../model/section.model';
import { Resource } from '../../../model/resource.model';
import { Chapter } from '../../../model/chapter.model';
import { OrganizationDetails } from '../../../model/organization.model';
import { MessageNotification } from '../../../model/dialog.model';
@Component({
  selector: 'app-def-communication',
  templateUrl: './def-communication.component.html',
  styleUrls: ['./def-communication.component.scss'],
  animations: [Animation.inOutAnimation()]
})
export class DefCommunicationComponent implements OnInit, OnChanges {
  @Input() resource = new Resource();
  @Input() chapter = new Chapter();
  @Input() sections: any = [];
  @Input() tutor = new UserDetails();
  @Input() organization = new OrganizationDetails();
  @Input() activeSection = '';
  @Output() emitSlug: EventEmitter<any> = new EventEmitter();
  section = new Section();
  activeChildTab = 'Notes';
  activeThread = '';
  userTypes = Constants.userTypes;
  messageTypes = Constants.messageType;
  // group
  messageType = '';
  chatId = '';
  receiverId = '';
  groupMessage = new Communication();
  groupModel = new CreateGroup();
  updateGroupCheckedIds: any = {};
  authUser = new AuthUser();
  inboxMembersId: string[] = [];
  tempMessageList: Communication[] = [];
  messageList: Communication[] = [];
  membersList: User[] = [];
  tutorsList: User[] = [];
  selectedTutor = '';
  keywords = new FormControl('');
  page = 1;
  limit = 20;
  isLoading = true;
  refreshInbox = false;
  isLoadingMessage = false;
  isSelectedChatBlocked = false;
  btnDisabled = false;
  watchClick: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private alert: MessageAlert,
    private _sharedService: SharedService,
    private _authService: AuthService,
    private _userService: UserService,
    private _communicationService: CommunicationService,
    @Inject(PLATFORM_ID) private platform: Record<string, unknown>
  ) {
    route.queryParams.subscribe(params => {
      if (params.section) {
        this.activeSection = params.section;
      }
    });
  }

  ngOnInit(): void {
    if (this.authUser.userType === this.userTypes.STUDENT || this.authUser.userType === this.userTypes.TUTOR) {
      this.getMessages();
    }
    this.keywords.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(data => {
      this.page = 1;
      this.membersList = [];
      this.getUsers();
      // eslint-disable-next-line arrow-body-style
      const result = this.tempMessageList.filter(x => {
        return x.members.some(item => item.firstName.toLowerCase().includes(data.toLowerCase()) || item.lastName.toLowerCase().includes(data.toLowerCase())) || (x.groupName ? x.groupName.includes(data) : null);
      });
      this.messageList = result;
    });

    this._sharedService.groupWriteEvent.subscribe((data: any) => {
      if (data === true) {
        this.membersList = [];
        this.getMessages();
        document.getElementById('lnkGroupBack')?.click();
      }
    });
    setTimeout(() => {
      const socket = this._sharedService.webSocketGetInstance();
      socket.on(`event.message.created.${this.authUser._id}`, (message: MessageNotification) => {
        this.refreshInbox = true;
        this.getMessages();
      });
    }, 2000);
    this.activeThread = this.route.snapshot.queryParams.thread;
  }
  ngOnChanges(): void {
    this.authUser = this._authService.authUser();
    if (this.sections.length > 0) {
      if (this.activeSection) {
        this.section = this.sections.find((x: any) => x.slug === this.activeSection) || this.sections[0];
      } else {
        this.section = this.sections[0];
      }
      this.activeSection = this.section.slug;
    }
    if (this.route.snapshot.queryParams.subtab) {
      this.activeChildTab = this.route.snapshot.queryParams.subtab;
    }
    if (this.activeChildTab === 'Groups' || this.activeChildTab === 'Tutor') {
      setTimeout(() => {
        this.onCLiCKFirstMessage();
      }, 20);
    }

    // handling some unnecessary calls
    if (this.watchClick) {
      clearTimeout(this.watchClick);
    }
    this.watchClick = setTimeout(() => {
      this.onClickTab();
    }, 1000);
  }
  memberSearch() {
    this.page = 1;
    this.membersList = [];
    this.getMessages();
  }
  async getUsers() {
    if (this.authUser.userType === Constants.userTypes.INDIVIDUAL) {
      return;
    }
    const membersList: User[] = await this.getUsersList(this.keywords.value, this.userTypes.STUDENT, this.page, this.limit);
    for (const item of membersList) {
      const index = this.inboxMembersId.findIndex(x => x === item._id);
      if (index === -1) {
        this.membersList.push(item);
      }
    }
    const authUserIndex = this.membersList.findIndex(x => x._id === this.authUser._id);
    if (authUserIndex > -1) {
      this.membersList.splice(authUserIndex, 1);
    }
    if (!this.refreshInbox) {
      this.onCLiCKFirstMessage();
    }
    this.refreshInbox = false;
    this.isLoading = false;
  }
  getUsersList(keywords: string, userType: string, page: number, limit: number): any {
    return new Promise((resolve, reject) => {
      this._userService.filterList(
        keywords,
        userType,
        this.resource._id,
        true,
        page,
        limit,
        '-invitationData.sortOrder'
      ).subscribe((res: HttpResponse<UserSuccessData>) => {
        const data = res.body?.data || [];
        resolve(data);
      }, (err) => {
        this.alert.error(err.error.message);
        reject(err);
      });
    });
  }
  getMessages() {
    if (this.authUser.userType === Constants.userTypes.INDIVIDUAL) {
      return;
    }
    if (!this.section._id || !this.chapter._id || !this.resource._id) {
      return;
    }
    let messageType = '';
    if (this.authUser.userType === Constants.userTypes.STUDENT) {
      messageType = [Constants.messageType.GROUP, Constants.messageType.STUDENT].toString();
    } else if (this.authUser.userType === Constants.userTypes.TUTOR) {
      messageType = [Constants.messageType.GROUP, Constants.messageType.TUTOR].toString();
    }
    this.isLoading = true;
    this._communicationService.messages(
      messageType,
      this.section._id,
      this.chapter._id,
      this.resource._id,
      1,
      10000,
      '-updatedAt'
    ).subscribe((res: HttpResponse<CommunicationSuccessData>) => {
      this.messageList = res.body?.data || [];
      this.tempMessageList = this.messageList.slice();
      const directMessages = this.messageList.filter(x => x.messageType === Constants.messageType.STUDENT || x.messageType === Constants.messageType.TUTOR);
      let temp: string[] = [];
      for (const item of directMessages) {
        const ids = item.members.map((x) => x._id);
        temp = [...temp, ...ids];
      }
      this.inboxMembersId = temp;
      if (this.authUser.userType !== this.userTypes.INDIVIDUAL) {
        this.getUsers();
      }
    });
  }
  getMyTutorMessages() {
    this._communicationService.messages(
      Constants.messageType.TUTOR,
      this.section._id,
      this.chapter._id,
      this.resource._id,
      1,
      1000,
      '-updatedAt'
    ).subscribe((res: HttpResponse<CommunicationSuccessData>) => {
      const messageList = res.body?.data || [];
      if (messageList.length > 0) {
        this.chatId = messageList[0]?._id || '';
      }
    });
  }
  getTutors() {
    this.getUsersList('', this.userTypes.TUTOR, 1, 50).then((usersList: any) => {
      this.tutorsList = usersList;
      if (this.tutorsList.length > 0) {
        this.selectedTutor = this.tutorsList[0]._id;
        this.getThreadByUser(this.selectedTutor);
      }
    });
  }
  getThreadByUser(receiverId: string) {
    this.isLoadingMessage = true;
    this._communicationService.messageThreads(
      this.authUser._id,
      receiverId,
      this.section._id,
      this.chapter._id,
      this.resource._id).subscribe((res: HttpResponse<CommunicationDetailsSuccessData>) => {
        this.groupMessage = new Communication();
        this.groupModel = new CreateGroup();
        this.groupMessage = res.body?.data || Object.assign({});
        this.chatId = this.groupMessage._id || '';
        this.receiverId = this.selectedTutor;
        this.isLoadingMessage = false;
      });
  }
  getThreadDetails(item: Communication) {
    this.chatId = item._id;
    this.messageType = item.messageType;
    this.receiverId = item.members.find(x => x._id !== this.authUser._id)?._id || '';
    this.isSelectedChatBlocked = this.getBlockedStatus(item.members);
    this.updateReadCount(this.chatId);
  }
  getBlockedStatus(members: Members[]): boolean {
    const toUser = members.find((x) => x._id !== this.authUser._id);
    if (toUser && toUser.blockedData && toUser.blockedData._id) {
      return true;
    } else {
      return false;
    }
  }
  startNewConversation(data: any) {
    this.chatId = data.chatId;
    this.receiverId = data.receiverId;
    this.messageType = data.messageType;
  }
  updateReadCount(id: string) {
    try {
      document.getElementById('count_' + id)?.remove();
    } catch (error) {
      //
    }
  }
  onRefreshMessage(data: any): void {
    if (data) {
      this.inboxMembersId = [];
      this.membersList = [];
      this.page = 1;
      if (data.messageType !== 'Tutor') {
        if (data.messageType === 'Group') {
          this.chatId = data.chatId;
        } else {
          this.receiverId = data.receiverId;
        }
        this.getMessages();
      } else if ((this.authUser.userType === this.userTypes.STUDENT || this.authUser.userType === this.userTypes.INDIVIDUAL) && data.messageType === 'Tutor') {
        this.receiverId = data.receiverId;
        this.getThreadByUser(this.receiverId);
      } else {
        this.chatId = data.chatId;
        this.getMessages();
      }
    }
  }
  setActiveChildTab(name: string) {
    this.activeChildTab = name;
    this.inboxMembersId = [];
    this.membersList = [];
    this.messageList = [];
    this.tempMessageList = [];
    this.groupMessage = new Communication();
    this.groupModel = new CreateGroup();
    this.chatId = '';
    this.receiverId = '';
    if (name === 'Groups') {
      this.getMessages();
    } else if (name === 'Tutor') {
      this.getTutors();
    }
    this.handleQueryParams();
  }
  onCLiCKFirstMessage() {
    const getFirstMessage = () => {
      this.chatId = this.messageList[0]._id;
      this.receiverId = this.messageList[0].members.find(x => x._id !== this.authUser._id)?._id || '';
      this.messageType = this.messageList[0].messageType;
      this.isSelectedChatBlocked = this.getBlockedStatus(this.messageList[0].members);
    };
    if (this.messageList.length > 0) {
      if (this.activeThread) {
        const data = this.messageList.find((x) => x._id === this.activeThread);
        if (data) {
          this.chatId = data._id;
          this.receiverId = data.members.find(x => x._id !== this.authUser._id)?._id || '';
          this.messageType = data.messageType;
          this.activeThread = '';
        } else {
          getFirstMessage();
        }
      } else {
        getFirstMessage();
      }
    } else if (this.membersList.length > 0) {
      if (this.getBlockedStatus([this.membersList[0]])) { // check whether the first user is accepted your request
        this.chatId = '';
        this.receiverId = this.membersList[0]._id;
        if (this.authUser.userType === this.userTypes.STUDENT) {
          this.messageType = this.messageTypes.STUDENT;
        } else if (this.authUser.userType === this.userTypes.TUTOR) {
          this.messageType = this.messageTypes.TUTOR;
        }
      }
    }
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    // visible height + pixel scrolled >= total height
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      if (!this.isLoading) {
        this.page++;
        this.getUsers();
      }
    }
  }
  onClickTab() {
    if (isPlatformBrowser(this.platform)) {
      setTimeout(() => {
        document.getElementById(this.activeChildTab + '0')?.click();
      }, 10);
    }
  }
  handleQueryParams() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        subtab: this.activeChildTab
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      skipLocationChange: false
      // do not trigger navigation
    });
  }
  // section and notes
  selectSection(section: Section) {
    if (section.hasSectionAccess) {
      this.section = section;
      this.activeSection = this.section.slug;
      this.handleSection();
    }
  }
  handleSection() {
    this.emitSlug.emit(this.activeSection);
  }
}
