<div class="topsec h-auto">
  <div class="banner__sec">
      <div class="owl-carousel owl-theme h-100" id="mainSlider">
          <div class="item">
              <div class="banner__sec--image-wrap img1 w-100">
                  <div class="banner__sec--image-wrap--title-sec fadeOut">
                      <h1>Welcome to FinStream.</h1>
                      <p>Our mission is simple — to provide you with the advanced financial education tools
                          necessary to enhance your industry knowledge and skill set.</p>
                  </div>
              </div>
          </div>
          <div class="item">
              <div class="banner__sec--image-wrap img1 w-100">
                  <div class="banner__sec--image-wrap--title-sec fadeOut">
                      <h1> About theory and practice.</h1>
                      <p>FinStream delivers comprehensive online financial lessons to single users, including
                          post-graduate candidates and early financial industry professionals.</p>
                  </div>
              </div>
          </div>
          <div class="item">
              <div class="banner__sec--image-wrap img1 w-100">
                  <div class="banner__sec--image-wrap--title-sec fadeOut">
                      <h1>We can help your career</h1>
                      <p>The knowledge gained from FinStream’s finance libraries could help you secure employment
                          in the finance industry. </p>
                  </div>
              </div>
          </div>
      </div>
  </div>
  </div>
  <div class="intro">
      <div class="container">
          <div class="row justify-content-center">
              <div class="col-lg-10">
                  <div class="grey-box p-7">
                      <h2>FinStream — bridging theory and practice</h2>
                      <p>FinStream delivers comprehensive online financial lessons to single users, including
                          post-graduate candidates and early financial industry professionals, as well as employers.
                          Our goal is to clarify important concepts through concise theory. Via targeted, interactive
                          delivery models, you’ll gain a deeper understanding of a number of critical financial
                          learning streams including:</p>
                      <div class="row mt-4 mt-md-5">
                          <div class="col-md-4 col-lg-4 text-center mb-2 mb-md-0">
                             <a routerLink="/streams/foreign-exchange-international-finance">
                              <img src="assets/img/icon1.svg">
                              <h6>Foreign exchange and<br> international finance</h6>
                             </a>
                          </div>
                          <div class="col-md-4 col-lg-4 text-center mb-2 mb-md-0">
                            <a routerLink="/streams/financial-derivatives">
                              <img src="assets/img/icon2.svg">
                              <h6>Financial<br> derivatives</h6>
                            </a>
                          </div>
                          <div class="col-md-4 col-lg-4 text-center">
                             <a routerLink="/streams/portfolio-management">
                              <img src="assets/img/icon3.svg">
                              <h6>Portfolio<br> management</h6>
                             </a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="row p-4 p-sm-5 justify-content-center reveal">
              <div class="col-lg-11">
                  <h2>Take your finance skills to<br> the next level with FinStream</h2>
                  <p>Whether you’re working in the banking industry already, are a graduate school student or if you
                      just want to enhance your financial literacy, FinStream offers a mix of accessible interactive
                      problems to increase learning and help you take the next step in your career. The accelerated
                      learning tracks within our existing streams are geared toward those looking to transition to or
                      within the financial industry.</p>
                  <a class="primaryButton" routerLink="/what-is-finstream">Learn how FinStream works.</a>
              </div>
          </div>
      </div>
  </div>
  <div class="midwrp mt-4">
      <div class="container">
          <div class="row p-4 p-sm-5 justify-content-center">
              <div class="col-lg-11">
                  <div class="row">
                      <div class="col-lg-7 pe-lg-4 reveal fade-left">
                          <h2 class="under-line">Are you a post-graduate?</h2>
                          <p>The knowledge gained from FinStream’s finance libraries could help you secure employment
                              in
                              the
                              finance industry. Here are some employers that use FinStream already.</p>
                          <a class="primaryButton" routerLink="/for-individuals">Learn how FinStream can help your career</a>
                      </div>
                      <div class="col-lg-5 pt-5 pt-lg-0 reveal fade-right d-flex align-items-center">
                          <!-- <div class="mb-3"><img src="assets/img/PwcLogo.png" class="w-140"></div> -->
                          <div><img src="assets/img/FexLogo.png" class="w-300"></div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="btm-wrp">
      <div class="container">
          <div class="row p-4 p-sm-5 justify-content-center reveal">
              <div class="col-lg-11">
                  <h2>Advance your team’s finance skills and<br> careers with FinStream</h2>
                  <p>If you’re looking to train or improve your staff’s finance skills, our flexible, online learning
                      methodology incorporates easy-to-use interactive models to illustrate finance’s more complex
                      areas. The FinStream platform lets you:</p>
              </div>
          </div>
          <div class="row reveal">
              <div class="col-lg-12">
                  <div class="grey-box">
                      <div class="row mt-4 mt-md-5">
                          <div class="col-md-4 col-lg-4 text-center mb-2 mb-md-0">
                              <img src="assets/img/icon4.svg">
                              <h6>Create bespoke content.</h6>
                          </div>
                          <div class="col-md-4 col-lg-4 text-center mb-2 mb-md-0">
                              <img src="assets/img/icon5.svg">
                              <h6>Assign streams to teams.</h6>
                          </div>
                          <div class="col-md-4 col-lg-4 text-center">
                              <img src="assets/img/icon6.svg">
                              <h6>Analyse streams usage.</h6>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-12 text-center mt-3">
                  <a class="primaryButton" routerLink="/for-employers">Learn more about FinStream for your company</a>
              </div>
          </div>
      </div>
  </div>
  <div class="getin-touch reveal">
    <div class="container">
        <div class="row p-4 p-md-5 justify-content-center">
            <div class="col-lg-11">
                <h5>Contact us today to<br> discuss your requirements.</h5>
                <a class="secondary-btn" routerLink="/contact">Contact Us</a>
            </div>
        </div>
    </div>
</div>
<!-- <section class="banner_sec bg_md">
    <div class="position-relative">
    <img src="assets/img/home_bg1.jpg" class="img-fluid main_bg hide_md">
    <div class="text_overlay custom_wrp">
      <div>
        <div class="row justify-content-center">
         <div class="col-lg-10">
          <h1 class="mb-3 main_head">Deep understanding of important financial subjects through concise content and targeted interactive delivery models.</h1>
          <div class="sub_txt_sec">
            <p class="sub_head mb-0">Foreign Exchange | Derivatives | Portfolio Management | Structured Products</p>
          </div>
         </div>
         </div>
        <div class="row pt-1 justify-content-center">
          <div class="col-lg-5 col-md-12">
            <div class="wrpr_">
            <a routerLink="/individuals">
              <img src="assets/img/0001.jpg">
              <h5 (click)="analyticsEvent('Individuals')">Individuals</h5>
              <p>Accelerated learning within an existing course or looking to transition to or within the industry.</p>
              <p>Finstream provides a mix of accessible  theory and interactive problems to enhance learning to help take the next step. </p>
            </a>
           </div>
          </div>
          <div class="col-lg-5 col-md-12">
            <div class="wrpr_">
            <a routerLink="/corporate-learning">
              <img src="assets/img/0002.jpg">
              <h5 (click)="analyticsEvent('Corporate Learning')">Corporate Learning</h5>
              <p>Finstream’s unique flexible online learning methodology incorporates easy to use interactive models to illustrate complex areas of finance.</p>
              <p>The platform allows the user to create, tailor and administer their learning streams. </p>
          </a>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </section> -->
