import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-no-records-found',
  templateUrl: './no-records-found.component.html',
  styleUrls: ['./no-records-found.component.scss']
})
export class NoRecordsFoundComponent implements OnInit, OnChanges {
  @Input() httpError: any;
  @Input() noResult = false;
  constructor() { }

  ngOnInit(): void {
  }
  ngOnChanges(): void {
  }
}
