<div class="container-fluid w_80 mb-5">
    <div class="row">
        <div class="col-lg-12">
            <div class="payment-processing">
                <div class="loading-container" *ngIf="isLoading">
                    <div class="loader-3 center"><span></span></div>
                    <div class="text">
                       <label>Processing your payment...</label>
                    </div>
                </div>
                <div class="success-container" *ngIf="isPaymentCompleted">
                    <div class="payment-success">
                        <div class="check-animated">
                            <div class="success-checkmark">
                                <div class="check-icon">
                                  <span class="icon-line line-tip"></span>
                                  <span class="icon-line line-long"></span>
                                  <div class="icon-circle"></div>
                                  <div class="icon-fix"></div>
                                </div>
                              </div>
                        </div>
                        <label>Payment success</label>
                        <button type="button" class="common_btn buy-now" routerLink="/account/profile" [queryParams]="{tab:'my-orders'}">View
                            Subscription</button>
                    </div>
                </div>
                <app-no-records-found *ngIf="httpError" [httpError]="httpError"></app-no-records-found> 
            </div>
        </div>
    </div>
</div>