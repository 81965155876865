import { User, UserChatPreference } from './user.model';

export class Attachment {
    _id = '';
    filename = '';
    folder = '';
    originalName = '';
    path = '';
    thumbFilename = '';
    thumbFolder = '';
    thumbPath = '';
    contentType = '';
}
export class Message {
    _id = '';
    isRead = false;
    attachments: Attachment[] = [];
    content = '';
    creator = '';
    createdAt = '';
};
export class CreateMessage {
    _id = '';
    isRead = false;
    attachments: Attachment[] = [];
    content = '';
    creator = '';
    messageType = '';
    groupId = '';
    receiverId = '';
    sectionId = '';
    chapterId = '';
    resourceId = '';
    // for socket io
    resourceSlug = '';
    chapterSlug = '';
    sectionSlug = '';
    //
    deletedAttachmentNames = '';
};
export class Members {
    _id = '';
    firstName = '';
    lastName = '';
    email = '';
    userType = '';
    profilePicture = {
        thumbPath: ''
    };
    blockedData = new UserChatPreference();
};
export class Communication {
    _id = '';
    messageType = '';
    messages: Message[] = [];
    members: User[] = [];
    groupName = '';
    createdBy = '';
    createdAt = '';
    updatedAt = '';
};
export class CommunicationNotification {
    _id = '';
    count = 0;
    updatedAt = '';
    resourceData = {
        slug: ''
    };
    chapterData = {
        slug: ''
    };
    sectionData = {
        title: '',
        slug: ''
    };
    senderData = {
        _id: '',
        firstName: '',
        lastName: '',
        userType: '',
        profilePicture: {
            thumbPath: ''
        }
    };
}
export interface CommunicationNotificationSuccessData {
    message: string;
    status: string;
    data: [CommunicationNotification];
}
export interface CommunicationDetailsSuccessData {
    message: string;
    status: string;
    data: Communication;
}
export interface CommunicationSuccessData {
    message: string;
    status: string;
    data: [Communication];
}
export interface CommunicationErrorData {
    message: string;
    status: string;
    data: any;
};
