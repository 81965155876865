<app-animate-loading *ngIf="isLoading"></app-animate-loading>
<div class="whole-wrapper">
    <app-header></app-header>
    <router-outlet></router-outlet>
    <div class="exam-timer-wrp" *ngIf="exam && exam.isStarted && !exam.isCompleted">
        <h4>Time Remaining</h4>
        <countdown [config]="{ leftTime: exam.duration * 60, format: 'm:s' }" (event)="onTimerFinished($event)"></countdown>
        <a routerLink="/resources/{{this.exam.resource}}/{{this.exam.chapter}}" [queryParams]="{tab:'tab-interactive', section: exam.section}" *ngIf="showExamBackLink">Back to Exam</a>
    </div>
    <app-footer *ngIf="footerEnabled"></app-footer>
</div>