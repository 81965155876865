import { isPlatformBrowser } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID, TemplateRef } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { DialogModel } from 'src/app/model/dialog.model';
import { CheckoutSession, CheckoutSessionSuccessData, CreateCheckoutSession } from 'src/app/model/subscription.model';
import { Resource } from 'src/app/model/resource.model';
import { PaymentService } from 'src/app/services/payment.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { MessageAlert } from 'src/app/utilities/message.alert';
import { Utility } from 'src/app/utilities/utility';
import { environment } from 'src/environments/environment';
declare let Stripe: any; // Stripe function will call from stripe cdn in the index.html
let _stripe: any;
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} }
  ]
})
export class PaymentComponent implements OnInit {
  @Input() resource = new Resource();
  @Output() paymentProcessing: EventEmitter<any> = new EventEmitter();
  dialogRef!: MatDialogRef<any>;
  btnDisabled = false;
  agreeTerms = false;
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private alert: MessageAlert,
    private _subscriptionService: SubscriptionService,
    @Inject(PLATFORM_ID) private platform: object,
    @Inject(MAT_DIALOG_DATA) public data: DialogModel
  ) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platform)) {
      _stripe = Stripe(environment.STRIPE_KEY);
    }
  }
  createCheckoutSession(checkoutModal: TemplateRef<any>) {
    this.dialogRef = this.dialog.open(checkoutModal, {
      maxWidth: '400px'
    });
    this.dialogRef.afterClosed().subscribe(dialogResult => {
      const result = dialogResult;
      if (result === true) {
        this.paymentProcessing.emit(true);
        this.btnDisabled = true;
        // const _model: CreateCheckoutSession = {
        //   resourceId: this.resource._id,
        //   title: this.resource.title,
        //   totalAmount: this.resource.price
        // };
        // this._paymentService.createCheckoutSession(_model).subscribe((res: HttpResponse<CheckoutSessionSuccessData>) => {
        //   this.btnDisabled = false;
        //   const data = res.body?.data || Object.assign({});
        //   if (data.id) {
        //     this.onCheckout(data);
        //   } else {
        //     this.alert.error('Unable to initiate your request. Please try again');
        //     this.paymentProcessing.emit(false);
        //   }
        // }, (err) => {
        //   this.alert.error(Utility.bindErrors(err));
        //   this.btnDisabled = false;
        //   this.paymentProcessing.emit(false);
        // });
        const _model: CreateCheckoutSession = {
          resourceId: this.resource._id
        };
        this._subscriptionService.createCheckoutSession(_model).subscribe((res: HttpResponse<CheckoutSessionSuccessData>) => {
          this.btnDisabled = false;
          const data = res.body?.data || Object.assign({});
          if (data.id) {
            this.onCheckout(data);
          } else {
            this.alert.error('Unable to initiate your request. Please try again');
            this.paymentProcessing.emit(false);
          }
        }, (err) => {
          this.alert.error(Utility.bindErrors(err));
          this.btnDisabled = false;
          this.paymentProcessing.emit(false);
        });
      }
    });
  }
  onCheckout(session: CheckoutSession) {
    // _stripe.redirectToCheckout({ sessionId: session.id });
    window.location.href = session.url;
  }
}
