export const Config = {
    quillJs: {
        toolbarOptionsSimple: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            ['blockquote'], //, 'code-block'
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
            [{ direction: 'rtl' }], // text direction
            [{ align: [] }],
            ['emoji']
        ],
        toolbarOptionsAdvanced: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            ['blockquote', 'code-block'],
            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
            [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
            [{ direction: 'rtl' }], // text direction
            [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],
            ['clean'],
            ['emoji']
        ],
        options: {
            // debug: 'info',
            modules: {
                toolbar: {}, // will be one of the toolbar options from above declared
                'emoji-toolbar': true
                // "emoji-textarea": true,
                // "emoji-shortname": true,
            },
            placeholder: '',
            theme: 'snow'
        }
    }
};
