import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot
} from '@angular/router';
import { AuthService } from '../services/auth.service';
import decode from 'jwt-decode';
@Injectable()
export class RoleGuardService implements CanActivate {
    constructor(
        public auth: AuthService,
        public router: Router,
        @Inject(PLATFORM_ID) private platform: object,
    ) { }
    canActivate(route: ActivatedRouteSnapshot): boolean {
        if (isPlatformBrowser(this.platform)) {
            // this will be passed from the route config
            // on the data property
            const expectedRole = route.data.expectedRole;
            const token:any  = this.auth.getToken();
            // decode the token to get its payload
            const tokenPayload:any = decode(token);
            if (
                !this.auth.isAuthenticated() || !expectedRole.includes(tokenPayload.role.role_id)
            ) {
                this.router.navigate(['404']);
                return false;
            }
            return true;
        }
        return true;
    }
}