import { OrganizationDetails } from './organization.model';
import { UserDetails } from './user.model';

export class PriceList {
    priceId = ''; // from stripe
    price = 0;
    currency = '';
    updatedAt = '';
}
export class Resource {
    _id = '';
    chapters = [];
    title = '';
    slug = '';
    description = '';
    content = '';
    price = 0;
    publishedAt = '';
    status = '';
    isProtected = false;
    organization = '';
    organizationData = new OrganizationDetails();
    tutorId = '';
    tutorData = new UserDetails();
    userData: any;
    createdBy = '';
    updatedBy = '';
    createdAt = '';
    updatedAt = '';
    accessPermission: string[] = [];
    icon = {
        filename: '',
        folder: '',
        originalName: '',
        path: ''
    };
    position = 0;
    regionalCurrency = '';
    subscriptionPriceList: PriceList[] = [];
    classificationsId = '';
    classificationsList: Resource[] = [];
    isClassifiedClass = false;
    certificateEnabled = false;
    certificateProviderAccess: CertificateProviderAccess[] = [];
    streamDuration = {
        hours: '',
        days: '',
        totalHours: 0
    };
}
class CertificateProviderAccess {
    enabled = false;
    organizationId = '';
    _id = '';
}
export class ResourceDetailsSuccessData {
    message = '';
    status = '';;
    data = new Resource();
}
export class ResourceSuccessData {
    message = '';
    status = '';;
    data: Resource[] = [];
}
export interface ResourceErrorData {
    message: string;
    status: string;
    data: any;
}
