<div class="">
    <div class="">
      <div class="">
        <div class="box_ bx_shadow_ bg-white">
          <div class="row mb-4 align-items-center">
            <div class="col-lg-6 col-md-6">
              <h3>My Exams</h3>
              <span class="sml_ sub_sml"><span class="itemShow" *ngIf="listItems.length>0">Showing {{(limit*(page-1))+1}} -
                {{(limit*(page-1))+listItems.length}} of {{totalCount}} items</span></span>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-lg-12">
              <div class="inpt_wrp">
                <div class="col-lg-5 pl-0 col_6 mb-4 col-md-12 pr_sml_0 flex_sml_0 position-relative">
                  <input type="text" class="form-control flx_item" placeholder="Search" [formControl]="keywords">
                    <button class="search_btn" (click)="page=1;filterList()"><img src="assets/img/search.svg"></button>
                 </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="table_responsive">
                <table class="table srdr_dtls sortable-table custom_tbl">
                  <thead>
                    <tr>
                        <th >Category <i class="fa fa-caret-down" aria-hidden="true"></i> </th>
                        <th >Chapter <i class="fa fa-caret-down" aria-hidden="true"></i> </th>
                        <th >Section <i class="fa fa-caret-down" aria-hidden="true"></i> </th>
                        <th >Total Mark <i class="fa fa-caret-down" aria-hidden="true"></i> </th>
                        <th >Score <i class="fa fa-caret-down" aria-hidden="true"></i> </th>
                        <th >Pass Mark <i class="fa fa-caret-down" aria-hidden="true"></i> </th>
                        <th >Result <i class="fa fa-caret-down" aria-hidden="true"></i> </th>
                        <th >Started At <i class="fa fa-caret-down" aria-hidden="true"></i> </th>
                        <th >Stopped At <i class="fa fa-caret-down" aria-hidden="true"></i> </th>
                        <th >Certificate <i class="fa fa-caret-down" aria-hidden="true"></i> </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of listItems | paginate: { itemsPerPage: this.limit, currentPage: page,totalItems: totalCount } ; let i = index;">
                        <td class="pt-4">{{item.resourceName}}</td>
                        <td class="pt-4">{{item.chapterName}}</td>
                        <td class="pt-4">{{item.sectionName}}</td>
                        <td class="pt-4">{{item.totalMark}}</td>
                        <td class="pt-4">{{item.totalMarkGained}}</td>
                        <td class="pt-4">{{item.passMarkInPercentage}}%</td>
                        <td class="pt-4">{{item.result.name}}</td>
                        <td class="pt-4">{{item.createdAt | localizeTime}}</td>
                        <td class="pt-4">{{item.examStoppedAt | localizeTime}}</td>
                        <td class="pt-4">
                          <div *ngIf="item.certificateIssued">
                            <a class="color_blue" [href]="item.certificateAccredibleData.certificateUrl" target="_blank">Preview</a>
                          </div>
                          <div *ngIf="!item.certificateIssued && (item.result.name!=='' && item.result.name!=='Failed')">
                            Certificate Pending
                          </div>
                          <div *ngIf="!item.certificateIssued && (item.result.name==='' || item.result.name==='Failed')">
                            N/A
                          </div>
                        </td>
                        <!-- <td class="text-right">
                            <div class="dropdown dropleft">
                                <a href="javascript:void(0)" role="button" id="drp_dwn1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#707070" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                </svg></a>
                                <div class="dropdown-menu toggle_sml" aria-labelledby="drp_dwn1">
                                    <a class="dropdown-item" href="javascript:void(0)">
                                        <span >View</span>
                                    </a>
                                </div>
                            </div>
                        </td> -->
                    </tr>
                  </tbody>
                  <tbody *ngIf="isLoading">
                    <tr *ngFor="let row of ' '.repeat(3).split('')">
                        <td><mat-progress-bar mode="buffer"></mat-progress-bar></td>
                        <td><mat-progress-bar mode="buffer"></mat-progress-bar></td>
                        <td><mat-progress-bar mode="buffer"></mat-progress-bar></td>
                        <td><mat-progress-bar mode="buffer"></mat-progress-bar></td>
                        <td><mat-progress-bar mode="buffer"></mat-progress-bar></td>
                        <td><mat-progress-bar mode="buffer"></mat-progress-bar></td>
                        <td><mat-progress-bar mode="buffer"></mat-progress-bar></td>
                        <td><mat-progress-bar mode="buffer"></mat-progress-bar></td>
                        <td><mat-progress-bar mode="buffer"></mat-progress-bar></td>
                        <td><mat-progress-bar mode="buffer"></mat-progress-bar></td>
                      </tr>
                  </tbody>
                </table>
                <app-no-records-found [noResult]="noResult" [httpError]="httpError"></app-no-records-found>
              </div>
              <div class="pagination-container" *ngIf="!noResult">
                <pagination-controls (pageChange)="page = $event;pageChanged($event)" previousLabel="" nextLabel="">
                </pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
