<div class="loading-container">
    <div class="loading-wrapper">
        <div class="loader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <img class="logo" src="assets/img/logo.svg" />
    </div>
</div>