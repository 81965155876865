/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { User } from '../model/user.model';
import { Constants } from '../utilities/constants';
import { Utility } from '../utilities/utility';
type unit = 'bytes' | 'KB' | 'MB' | 'GB' | 'TB' | 'PB';
type unitPrecisionMap = {
  [u in unit]: number;
};
const defaultPrecisionMap: unitPrecisionMap = {
  bytes: 0,
  KB: 0,
  MB: 1,
  GB: 1,
  TB: 2,
  PB: 2
};
@Pipe({ name: 'safeHtml' })
export class EscapeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(style: any) {
    return this.sanitizer.bypassSecurityTrustHtml(style);
  }
}
@Pipe({ name: 'safeResourceUrl' })
export class SafeResourceUrl implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
@Pipe({ name: 'defaultImage' })
export class DefaultImagePipe implements PipeTransform {
  transform(items: any): any {
    if (!items) {
      return 'assets/images/no-image.png';
    } else if (items && items.length === 0) {
      return 'assets/images/no-image.png';
    }
    let defaultFound = false;
    for (const item of items) {
      if (item.type === 'bannerImage') {
        defaultFound = true;
        return item.path;
      }
    }
    if (!defaultFound) {
      return items[0].path;
    }
  }
}
@Pipe({ name: 'fileSize' })
export class FileSizePipe implements PipeTransform {
  private readonly units: unit[] = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

  transform(bytes: number = 0, precision: number | unitPrecisionMap = defaultPrecisionMap): string {
    if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) return '?';

    let unitIndex = 0;

    while (bytes >= 1024) {
      bytes /= 1024;
      unitIndex++;
    }

    const unit = this.units[unitIndex];

    if (typeof precision === 'number') {
      return `${bytes.toFixed(+precision)} ${unit}`;
    }
    return `${bytes.toFixed(precision[unit])} ${unit}`;
  }
};
@Pipe({ name: 'fileType' })
export class FileTypePipe implements PipeTransform {
  transform(fileName: string): string {
    return Utility.getFileType(fileName);
  }
}
@Pipe({ name: 'orderBy' })
export class ArraySortPipe implements PipeTransform {
  transform(array: any, field: string): any[] {
    if (!Array.isArray(array)) {
      return [];
    }
    array.sort((a: any, b: any) => {
      if (a[field] < b[field]) {
        return -1;
      } else if (a[field] > b[field]) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }
};
@Pipe({ name: 'timeAgo' })
export class TimeAgoPipe implements PipeTransform {
  transform(date: string): string {
    if (!date) {
      return '';
    }
    return moment(date).fromNow();
  }
}
@Pipe({ name: 'inboxUserName' })
export class InboxUserNamePipe implements PipeTransform {
  transform(members: any, userId: string): string {
    if (!Array.isArray(members) || members.length === 0) {
      return '';
    }
    const item = members.find((x) => x._id !== userId);
    if (item) {
      return item.firstName + ' ' + item.lastName;
    } else {
      return '';
    }
  }
};
@Pipe({ name: 'chatUserName' })
export class ChatUserNamePipe implements PipeTransform {
  transform(members: any, creatorId: string, authUserId: string): string {
    if (!Array.isArray(members) || members.length === 0) {
      return '';
    }
    const item = members.find((x) => x._id === creatorId);
    if (item && item._id === authUserId) {
      return item.firstName + ' ' + item.lastName + ' <span class="name-lite">(You)</span>';
    } else if (item && item._id !== authUserId) {
      return item.firstName + ' ' + item.lastName + (item.userType === 'Tutor' ? ' <span class="name-lite">(Tutor)</span>' : '');
    } else {
      return '';
    }
  }
};
@Pipe({ name: 'chatUserImage' })
export class ChatUserImagePipe implements PipeTransform {
  transform(members: any, creatorId: string): string {
    if (!Array.isArray(members) || members.length === 0) {
      return '';
    }
    const item = members.find((x) => x._id === creatorId);
    if (item) {
      return item.thumbPath || '';
    } else {
      return '';
    }
  }
};
@Pipe({ name: 'chatUnReadCount' })
export class ChatUnReadCount implements PipeTransform {
  transform(messages: any, authUserId: string): number {
    if (!Array.isArray(messages) || messages.length === 0) {
      return 0;
    }
    const items = messages.filter((x) => x.isRead === false && x.creator !== authUserId);
    return items.length;
  }
};
@Pipe({ name: 'chatOnlyConnectedUsers' })
export class ChatOnlyConnectedUsers implements PipeTransform {
  transform(user: User, createdBy: string, authUser: string): boolean {
    if ((createdBy && authUser) && createdBy !== authUser) {
      return true; // other user can visible only
    }
    if (user.blockedData) {
      return false;
    }
    if (user.invitationData && user.invitationData.status === 'Accepted') {
      return true;
    }
    return false;
  }
};

@Pipe({ name: 'localizeTime' })
export class LocalizeTimePipe implements PipeTransform {
  transform(dateTime: any): any {
    if (!dateTime) {
      return '';
    }
    // const date = new Date(dateTime + ' UTC');
    // const date = moment(dateTime + ' UTC').format('DD/MM/YYYY hh:mm A');
    const date = moment(dateTime).format('DD/MM/YYYY hh:mm A');
    return date;
  }
}
@Pipe({ name: 'currencySymbol' })
export class CurrencySymbol implements PipeTransform {
  transform(currency: string): any {
    try {
      currency = currency.toUpperCase();
      const currencies: any = Constants.currencies;
      return currencies[currency];
    } catch (error) {
      return currency;
    }
  }
}
@Pipe({ name: 'randomColorPick' })
export class RandomColorPickPipe implements PipeTransform {
  transform(name: any): any {
    // return Constants.randomColors[Math.floor(Math.random() * Constants.randomColors.length)];
    if (!name) {
      return randomColor();
    } else {
      const colors: any = Constants.colors;
      const checkForHexRegExp = /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i; // for checking object id
      let char = '';
      if (checkForHexRegExp.test(name)) {
        char = name.slice(-1).toUpperCase();
      } else {
        char = name.substring(0, 1).toUpperCase();
      }
      if (colors[char]) {
        return colors[char];
      } else {
        return randomColor();
      }
    }
    function randomColor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }
  }
};
@Pipe({ name: 'interactiveD3GraphData' })
export class InteractiveD3GraphData implements PipeTransform {
  transform(section: any, sectionsList: any): any {
    const data: any = {
      id: `d3graph_${section.id}`,
      x: '',
      y: '',
      series: [],
      xAxisData: []
    };
    if (section) {
      const table = sectionsList.find((x: any) => x.id === section.value.tableId);
      if (table) {
        for (const line of section.value.lines) {
          const values = [];
          for (const tr of table.value.slice(1, table.value.length)) { // skip first row which is a heading row
            values.push(Number(tr[line.column][0].value));
          }
          data.series.push({
            name: table.value[0][line.column][0].value,
            values,
            lineType: line.lineType,
            lineColor: line.lineColor
          });
        }
        for (const tr of table.value.slice(1, table.value.length)) { // skip first row which is a heading row
          data.xAxisData.push(Number(tr[section.value.xAxis][0].value));
        }
        data.x = table.value[0][section.value.xAxis][0].value;
        data.y = table.value[0][section.value.yAxis][0].value;
        return data;
      } else {
        return data;
      }
    } else {
      return data;
    }
  }
};

@Pipe({ name: 'interactiveLineChartData' })
export class InteractiveLineChartData implements PipeTransform {
  transform(section: any, sectionsList: any): any {
    const config: any = {
      data: {
        labels: [],
        datasets: []
      },
      options: {
        xLabel: '',
        yLabel: '',
        showLegends: true,
        legendPosition: 'right',
        legendAlign: 'center',
        legendLabel: {
          padding: 20,
          boxWidth: 5,
          boxHeight: 5,
          usePointStyle: true
        }
      }
    };
    if (section) {
      let tableId = section.value.tableId;
      if (section.value.selectedDataTable) {
        tableId = section.value.selectedDataTable;
      }
      const table = sectionsList.find((x: any) => x.uuid === tableId);
      if (table) {
        if (section.value.defaultColumnAsDataSet && section.value.selectedDropdownColumn !== undefined && section.value.selectedDropdownColumn !== '') {
          const values = [];
          for (const tr of table.value.slice(1, table.value.length)) { // skip first row which is a heading row
            try {
              values.push(parseFloat(tr[section.value.selectedDropdownColumn][0].value));
            } catch (error) {
              values.push(0);
            }
          }
          const _conf: any = {
            label: table.value[0][section.value.selectedDropdownColumn][0].value,
            data: values,
            fill: false,
            borderDash: [0, 0],
            borderColor: '#000',
            borderWidth: 1,
            backgroundColor: '#000',
            lineTension: 0.3,
            radius: 0,
            showLine: true
          };
          config.data.datasets.push(_conf);
        } else {
          for (const line of section.value.lines) {
            const values = [];
            for (const tr of table.value.slice(1, table.value.length)) { // skip first row which is a heading row
              try {
                values.push(parseFloat(tr[line.column][0].value));
              } catch (error) {
                values.push(0);
              }
            }
            let lineType: number[] = [];
            if (line.lineType === 'dashed') {
              lineType = [10, 5];
            } else if (line.lineType === 'dotted') {
              lineType = [2, 2];
            } else if (line.lineType === 'normal') {
              lineType = [0, 0];
            }
            const _conf: any = {
              label: table.value[0][line.column][0].value,
              data: values,
              fill: false,
              borderDash: lineType,
              borderColor: line.lineColor,
              borderWidth: 1,
              backgroundColor: line.lineColor,
              lineTension: line.lineTension === undefined ? 0.3 : line.lineTension,
              radius: line.lineDotSize === undefined ? 0 : line.lineDotSize,
              showLine: true
            };
            if (line.showLine === undefined) {
              _conf.showLine = true;
            } else if (line.showLine === 'yes') {
              _conf.showLine = true;
            } else if (line.showLine === 'no') {
              _conf.showLine = false;
            }
            if (line.lineDotShowOnlyOneIndex !== undefined && line.lineDotShowOnlyOneIndex !== '') {
              const pointRadius = [];
              const pos = Number(line.lineDotShowOnlyOneIndex);
              for (let i = 0; i < table.value.length - 1; i++) {
                if (pos === i) {
                  pointRadius.push(_conf.radius);
                } else {
                  pointRadius.push(0);
                }
              }
              _conf.pointRadius = pointRadius;
            }
            config.data.datasets.push(_conf);
          }
        }
        for (const tr of table.value.slice(1, table.value.length)) { // skip first row which is a heading row
          config.data.labels.push(tr[section.value.xAxis][0].value);
        }
        config.options.xLabel = table.value[0][section.value.xAxis].length > 0 ? table.value[0][section.value.xAxis][0].value : '';
        config.options.yLabel = section.value.yAxis;
        return config;
      } else {
        return config;
      }
    } else {
      return config;
    }
  }
};

@Pipe({ name: 'interactiveScatterChartData' })
export class InteractiveScatterChartData implements PipeTransform {
  transform(section: any, sectionsList: any): any {
    const config: any = {
      data: {
        labels: [],
        datasets: []
      },
      options: {
        xLabel: '',
        yLabel: '',
        showLegends: true,
        legendPosition: 'right',
        legendAlign: 'center',
        legendLabel: {
          padding: 20,
          boxWidth: 5,
          boxHeight: 5,
          usePointStyle: true
        }
      }
    };
    if (section) {
      let tableId = section.value.tableId;
      if (section.value.selectedDataTable) {
        tableId = section.value.selectedDataTable;
      }
      const table = sectionsList.find((x: any) => x.uuid === tableId);
      if (table) {
        // Data sets
        if (section.value.defaultColumnAsDataSet && section.value.selectedDropdownColumn !== undefined && section.value.selectedDropdownColumn !== '') {
          const values = [];
          for (const tr of table.value.slice(1, table.value.length)) { // skip first row which is a heading row
            try {
              values.push(parseFloat(tr[section.value.selectedDropdownColumn][0].value));
            } catch (error) {
              values.push(0);
            }
          }
          const _conf: any = {
            label: table.value[0][section.value.selectedDropdownColumn][0].value,
            data: values,
            fill: false,
            borderDash: [0, 0],
            borderColor: '#000',
            borderWidth: 1,
            backgroundColor: '#000',
            lineTension: 0.3,
            radius: 0,
            showLine: true
          };
          config.data.datasets.push(_conf);
        } else {
          for (const line of section.value.lines) {
            const values = [];
            for (const tr of table.value.slice(1, table.value.length)) { // skip first row which is a heading row
              values.push({
                x: tr[section.value.xAxis][0].value,
                y: parseFloat(tr[line.column][0].value)
              });
            }
            let lineType: number[] = [];
            if (line.lineType === 'dashed') {
              lineType = [10, 5];
            } else if (line.lineType === 'dotted') {
              lineType = [2, 2];
            } else if (line.lineType === 'normal') {
              lineType = [0, 0];
            }
            const _conf: any = {
              label: table.value[0][line.column][0].value,
              data: values,
              fill: false,
              borderDash: lineType,
              borderColor: line.lineColor,
              borderWidth: 1,
              backgroundColor: line.lineColor,
              lineTension: line.lineTension === undefined ? 0.3 : line.lineTension,
              radius: line.lineDotSize === undefined ? 0 : line.lineDotSize,
              showLine: true
            };
            if (line.showLine === undefined) {
              _conf.showLine = true;
            } else if (line.showLine === 'yes') {
              _conf.showLine = true;
            } else if (line.showLine === 'no') {
              _conf.showLine = false;
            }
            if (line.lineDotShowOnlyOneIndex !== undefined && line.lineDotShowOnlyOneIndex !== '') {
              const pointRadius = [];
              const pos = Number(line.lineDotShowOnlyOneIndex);
              for (let i = 0; i < table.value.length - 1; i++) {
                if (pos === i) {
                  pointRadius.push(_conf.radius);
                } else {
                  pointRadius.push(0);
                }
              }
              _conf.pointRadius = pointRadius;
            }
            config.data.datasets.push(_conf);
          }
        }
        // Data Points
        if (section.value.dataPoints) {
          for (const line of section.value.dataPoints) {
            const values = [];
            const xIndex = Utility.getCellIndex(line.xAxis);
            const yIndex = Utility.getCellIndex(line.yAxis);
            values.push({
              x: parseFloat(sectionsList[xIndex.sectionIndex].value[xIndex.rowIndex][xIndex.colIndex][0].value),
              y: parseFloat(sectionsList[yIndex.sectionIndex].value[yIndex.rowIndex][yIndex.colIndex][0].value)
            });
            let lineType: number[] = [];
            if (line.lineType === 'dashed') {
              lineType = [10, 5];
            } else if (line.lineType === 'dotted') {
              lineType = [2, 2];
            } else if (line.lineType === 'normal') {
              lineType = [0, 0];
            }
            const _conf: any = {
              label: line.title,
              data: values,
              fill: false,
              borderDash: lineType,
              borderColor: line.lineColor,
              borderWidth: 1,
              backgroundColor: line.lineColor,
              lineTension: line.lineTension === undefined ? 0.3 : line.lineTension,
              radius: line.lineDotSize === undefined ? 0 : line.lineDotSize,
              showLine: true
            };
            if (line.showLine === undefined) {
              _conf.showLine = true;
            } else if (line.showLine === 'yes') {
              _conf.showLine = true;
            } else if (line.showLine === 'no') {
              _conf.showLine = false;
            }
            config.data.datasets.push(_conf);
          }
        }
        config.options.xLabel = table.value[0][section.value.xAxis].length > 0 ? table.value[0][section.value.xAxis][0].value : '';
        config.options.yLabel = section.value.yAxis;
        return config;
      } else {
        return config;
      }
    } else {
      return config;
    }
  }
};
@Pipe({ name: 'interactivePieChartData' })
export class InteractivePieChartData implements PipeTransform {
  transform(section: any, sectionsList: any): any {
    const config: any = {
      data: {
        labels: [],
        datasets: []
      },
      options: {
        showLegends: true,
        legendPosition: 'bottom',
        legendAlign: 'center',
        legendLabel: {
          padding: 20,
          boxWidth: 10,
          boxHeight: 10,
          usePointStyle: false
        }
      }
    };
    if (section) {
      const table = sectionsList.find((x: any) => x.uuid === section.value.tableId);
      if (table) {
        const labels = [];
        const values = [];
        const bgColors = [];
        for (const line of section.value.lines) {
          labels.push(table.value[line.row][line.column][0].value);
          values.push(parseFloat(table.value[line.row][line.data][0].value));
          bgColors.push(line.lineColor);
        }
        config.data.datasets.push({
          label: '',
          data: values,
          backgroundColor: bgColors
        });
        config.data.labels = labels;
        return config;
      } else {
        return config;
      }
    } else {
      return config;
    }
  }
};
@Pipe({ name: 'interactiveBarChartData' })
export class InteractiveBarChartData implements PipeTransform {
  transform(section: any, sectionsList: any): any {
    const config: any = {
      data: {
        labels: [],
        datasets: []
      },
      options: {
        xLabel: '',
        yLabel: '',
        showLegends: true,
        legendPosition: 'top',
        legendAlign: 'center',
        legendLabel: {
          padding: 20,
          boxWidth: 40,
          boxHeight: 15,
          usePointStyle: false
        }
      }
    };
    if (section) {
      const table = sectionsList.find((x: any) => x.uuid === section.value.tableId);
      if (table) {
        for (const line of section.value.lines) {
          const values = [];
          for (const tr of table.value.slice(1, table.value.length)) { // skip first row which is a heading row
            try {
              values.push(parseFloat(tr[line.column][0].value));
            } catch (error) {
              values.push(0);
            }
          }
          config.data.datasets.push({
            label: table.value[0][line.column][0].value,
            data: values,
            borderColor: line.lineColor,
            borderWidth: 1,
            backgroundColor: line.lineColor
          });
        }
        for (const tr of table.value.slice(1, table.value.length)) { // skip first row which is a heading row
          config.data.labels.push(tr[section.value.xAxis][0].value);
        }
        config.options.xLabel = table.value[0][section.value.xAxis].length > 0 ? table.value[0][section.value.xAxis][0].value : '';
        config.options.yLabel = section.value.yAxis;
        return config;
      } else {
        return config;
      }
    } else {
      return config;
    }
  }
};
@Pipe({ name: 'interactiveDataTableColumnPipe' })
export class InteractiveDataTableColumnPipe implements PipeTransform {
  transform(section: any, sectionsList: any): any {
    if (section) {
      let tableId = section.value.tableId;
      if (section.value.selectedDataTable) {
        tableId = section.value.selectedDataTable;
      }
      const table = sectionsList.find((x: any) => x.uuid === tableId);
      if (table && table.value.length > 0) {
        const cells = [];
        let i = 0;
        for (const item of table.value[0]) {
          // skip the first column since it is a X-Axis
          if (i > 0) {
            cells.push({
              title: item[0].value,
              value: i
            });
          }
          i++;
        }
        return cells;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }
}
@Pipe({ name: 'interactiveTableCollapsibleWidth' })
export class InteractiveTableCollapsibleWidth implements PipeTransform {
  transform(section: any): string {
    const colsNo = section.value[0].length;
    const defaultWidth = Number(Constants.defaultColWidth.replace('px', ''));
    let width = 0;
    try {
      for (let i = 0; i < colsNo; i++) {
        try {
          const w = Number(section.settings.colSettings[i].width.replace('px', ''));
          width += w;
        } catch (error) {
          width += defaultWidth;
        }
      }
      return `${width}px`;
    } catch (error) {
      for (let i = 0; i < colsNo; i++) {
        width += defaultWidth;
      }
      return `${width}px`;
    }
  }
};
@Pipe({ name: 'customCommaSeparate' })
export class CustomCommaSeparate implements PipeTransform {
  transform(value: any): string {
    try {
      if (/\d/.test(value)) {
        if (value.toString().includes('.')) {
          const data = value.toString().split('.');
          const commaSeparated = data[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          return commaSeparated + '.' + data[1];
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      return value;
    } catch (error) {
      return value;
    }
  }
};
@Pipe({ name: 'dateDiffPipe' })
export class DateDiffPipe implements PipeTransform {
  transform(startDate: any, endDate: any): any {
    const toDate = moment(endDate);
    const fromDate = moment(startDate);
    const count = toDate.diff(fromDate, 'days');
    if (count < 1) {
      return 0;
    } else {
      return count;
    }
  }
};

@Pipe({
  name: 'dateAgo',
  pure: true
})
export class DateAgoPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29) { // less than 30 seconds ago will show as 'Just now'
        return 'Just now';
      }
      const intervals: any = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1
      };
      let counter;
      // eslint-disable-next-line guard-for-in
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0) {
          if (counter === 1) {
            return counter + ' ' + i + ' ago'; // singular (1 day ago)
          } else {
            return counter + ' ' + i + 's ago'; // plural (2 days ago)
          }
        }
      }
    }
    return value;
  }
}
@Pipe({ name: 'interactiveRowSettings' })
export class InteractiveRowSettingsPipe implements PipeTransform {
  transform(settings: any, index: number): any {
    if (!settings) {
      return {};
    }
    if (!settings.rowSettings) {
      return {};
    }
    return settings.rowSettings[index] || {};
  }
}
