<div class="topsec h-auto">
    <div class="sub-banner banner9">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-7 col-xl-5">
                    <h2>FinStream for employers</h2>
                    <p>Could your in-house team benefit from a deeper understanding of important financial subjects?
                        FinStream offers concise content and targeted interactive delivery models to help employees
                        advance their skills.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <section class="video-sec pt-5 pt-lg-6">
    <div class="container">
        <div class="row justify-content-center px-4 px-lg-0">
            <div class="col-lg-10">
                <video controls="" width="100%" poster="images/video-thumb.png">
                    <source src="assets/img/video.mp4" type="video/mp4">
                </video>
            </div>
        </div>
    </div>
</section> -->
<section>
    <div class="container py-4 py-lg-5">
        <div class="row justify-content-center px-4 px-lg-0">
            <div class="col-lg-10 py-2 py-lg-4">
                <h2 class="under-line">Employee development</h2>
                <p class="pt-3">
                    Corporate users can create, tailor and administer their learning streams to their workforces.
                    FinStream’s unique flexible online learning methodology incorporates easy-to-use interactive
                    models that illustrate complex areas of finance. To learn more about the generic features our platform see the below video
                </p>
                <div class="mt-5" style="text-align: center;">
                    <video width="500" poster="assets/img/thumbnail/edk-platform.png" controls>
                        <source src="https://cdn.staging.finstream.com.au/assets/videos/1920_edk_video_02_4.mp4" type="video/mp4">
                        Your browser does not support HTML video.
                    </video>
                </div>
                <!-- <div class="row justify-content-center icon-inline">
                    <div class="col-lg-3 mb-lg-0 mb-5">
                        <a href="foreign-exchange.html">
                            <img src="assets/img/icon11.svg" alt="">
                            <h5>Foreign exchange</h5>
                        </a>
                    </div>
                    <div class="col-lg-3 mb-lg-0 mb-5">
                        <a href="financial-derivatives.html">
                            <img src="assets/img/icon13.svg" alt="">
                            <h5>Derivatives</h5>
                        </a>
                    </div>
                    <div class="col-lg-3">
                        <a href="portfolio-management.html">
                            <img src="assets/img/icon15.svg" alt="">
                            <h5>Portfolio management</h5>
                        </a>
                    </div>
                    <div class="col-lg-12 mt-4">
                        <a class="primaryButton" href="contact.html">Get Started Now</a>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</section>
<section class="outcome for-employers">
    <div class="container">
        <div class="row justify-content-end px-4 px-lg-0">
            <div class="col-xl-8 col-lg-6 ps-lg-5">
                <h2 class="under-line">Bespoke learning</h2>
                <p class="mt-4">The theory and content in FinStream’s stream courses include standard Master of
                    Finance material; however, there is a substantial level of transitional material tailored to
                    those who wish to change paths within the financial industry. FinStream recognises that, even at
                    the masters level, graduates need additional preparation for successful careers in finance.</p>
                <ul class="mt-4">
                    <li><a routerLink="/">Investment portfolio management <img src="assets/img/right.svg" alt=""></a></li>
                    <li><a routerLink="/">Foreign exchange and international finance <img src="assets/img/right.svg"
                                alt=""></a></li>
                    <li><a routerLink="/">Financial derivatives <img src="assets/img/right.svg" alt=""></a></li>
                </ul>
            </div>
        </div>
    </div>
</section>
<div class="btm-wrp pb-4 pb-lg-5">
    <div class="container">
        <div class="row p-4 p-md-5 justify-content-center">
            <div class="col-lg-11 mt-3">
                <h2 class="under-line">Platform administration</h2>
                <p>Our powerful Admin capability offers a self-contained private content workspace. In addition to
                    individual user features, FinStream-Admin offers additional capabilities just for employers.</p>
                <div class="card p-0 mt-5">
                    <div class="card__header">
                        <h3 class="p-2 text-center">Employers have the capability to autonomously:</h3>
                    </div>
                    <div class="card__body">
                        <ul class="stream-lists additional-info mt-3">
                            <li><strong>Access the FinStream content library and create other bespoke streams.</strong> A simple
                                drag-and-drop procedure offers quick access and immediate web publishing. For
                                example, a new stream may have less weight on the very theoretical section and
                                favour the more client-facing sections. Users can also create a stream that targets
                                a specific client Audit.</li>
                            <li><strong>Build content and create bespoke authored content.</strong> Partner administrators can create
                                their own tailored content from scratch in order to build content that is most
                                relevant and important for their teams.</li>
                            <li><strong>Form chat groups and discuss questions.</strong> Single users, under a employer partner
                                umbrella, can be assigned to bespoke streams, ask questions and carry out
                                discussions with their assigned team leaders and industry experts.</li>
                            <li><strong>Access to unique data.</strong> Accurate data helps monitor the usage of the system in order
                                to assess its effectiveness and how it is being used by the team.</li>
                        </ul>
                    </div>
                </div>
                <div class="card p-0 mt-5">
                    <div class="card__header">
                        <h3 class="p-2 text-center">Staff associated with a course have access to streams and can:</h3>
                    </div>
                    <div class="card__body">
                        <ul class="stream-lists additional-info mt-3">
                            <li>Form internal discussion groups and take notes on courses.</li>
                            <li>Ask questions about materials.</li>
                            <li>Gain additional context through materials added by the Course Coordinator.</li>
                            <li>Use a simple drag-and-drop procedure.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="getin-touch">
    <div class="container-fluid">
        <div class="row p-4">
            <div class="col-lg-5">
                <h5>Are you ready to equip your in-house team for success across the financial industry?</h5>
                <p>Contact us to learn more about FinStream’s unique learning platform.</p>
                <a class="secondary-btn" routerLink="/contact">Contact Us</a>
            </div>
        </div>
    </div>
</div>