/* eslint-disable no-array-constructor */
import { Communication } from './communication.model';
import { User } from './user.model';

// export class Members {
//     _id = '';
//     userId = '';
//     status = '';
// }
// export class Group {
//     _id = '';
//     title = '';
//     status = false;
//     members = new Array<Members>();
//     createdAt = '';
//     createdBy = '';
//     updatedAt = '';
//     updatedBy = '';
// }
export class CreateGroup {
    _id = '';
    messageType = '';
    messages = [];
    members: string[] = [];
    groupName = '';
    sectionId = '';
    chapterId = '';
    resourceId = '';
    createdBy = '';
    createdAt = '';
    updatedAt = '';
}
export interface GroupSuccessData {
    message: string;
    status: string;
    data: any;
}
// export interface GroupSearchSuccessData {
//     message: string;
//     status: string;
//     data: {
//         groups: [Group];
//         messages: [Communication];
//         users: [User];
//     };
// }
export interface GroupErrorData {
    message: string;
    status: string;
    data: any;
}
