import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CreateExamModel, ExamSuccessData, StopExamModel } from '../model/exam.model';

@Injectable()
export class ExamService {
    constructor(private http: HttpClient) { }

    filterList(
        keywords: string,
        sortBy: string,
        page: number,
        limit: number
    ): Observable<HttpResponse<ExamSuccessData>> {
        return this.http.get<ExamSuccessData>(environment.API_END_POINT + '/exam', {
            params: {
                title: keywords,
                sortBy,
                page: page.toString(),
                limit: limit.toString()
            },
            observe: 'response'
        });
    }
    startExam(data: CreateExamModel) {
        return this.http.post<any>(environment.API_END_POINT + '/exam', data);
    }
    stopExam(id: string, data: StopExamModel) {
        return this.http.put<any>(environment.API_END_POINT + '/exam/' + id, data);
    }
    checkExamCanStart(sectionId: string) {
        return this.http.get<any>(environment.API_END_POINT + '/exam/query/check-exist/' + sectionId);
    }
}
