import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CertificateSuccessData } from '../model/certificate.model';

@Injectable()
export class CertificateService {
    constructor(private http: HttpClient) { }

    filterList(
        keywords: string,
        sortBy: string,
        page: number,
        limit: number
    ): Observable<HttpResponse<CertificateSuccessData>> {
        return this.http.get<CertificateSuccessData>(environment.API_END_POINT + '/certificate', {
            params: {
                title: keywords,
                sortBy,
                page: page.toString(),
                limit: limit.toString()
            },
            observe: 'response'
        });
    }
}
