<app-animate-loading *ngIf="isProcessingPayment"></app-animate-loading>
<div class="search_wrp d_none">
    <div>
        <a class="close_" href="javascript:void(0)" (click)="onCloseSearchBox()"><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor"
                class="bi bi-x" viewBox="0 0 16 16">
                <path
                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg></a>
        <form >
            <div class="form-group">
                <input type="text" name="search" class="form-control" placeholder="Search" autocomplete="off" [formControl]="chapterSearch">
                <div class="search_result" *ngIf="contentList.length>0">
                  <ul>
                    <ng-container *ngFor="let item of contentList">
                        <li *ngIf="item.type==='chapter'"><a routerLink="/resources/{{resource.slug}}/{{item.slug}}">{{item.title}}</a></li>
                        <ng-container *ngIf="item.type==='section'">
                            <ng-container *ngFor="let ch of item.chapters">
                                <li ><a routerLink="/resources/{{resource.slug}}/{{ch.slug}}"
                                    [queryParams]="item.hasSectionAccess ? {'tab':'tab-theory','section':item.slug} : null">{{item.title}} ({{ch.title}})</a></li>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                  </ul>
                </div>
                <div class="search_result" *ngIf="isLoadingSearch">
                    <ul>
                        <li>
                            <mat-progress-bar mode="buffer"></mat-progress-bar>
                        </li>
                    </ul>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="wrapper">
    <!-- Sidebar  -->
    <nav id="sidebar" class="sml_slide resourceSidebar">

        <ul class="list-unstyled components main-list">
            <h3>STREAMS</h3>
            <ng-container *ngFor="let item of resourceList;let index = index">
                <li id="{{item._id}}" class="stream-item-row" *ngIf="item.userData?.userType === userTypes.MASTER_ADMIN" 
                [ngClass]="{
                    'active-custom':activeParent===item._id,
                    'lock-item':item.isProtected,
                    'have-child-list': item.classificationsList.length>0
                    }" >
                    <a href="javascript:void(0)">
                        <div class="stream-item">
                            <i class="fa fa-plus" *ngIf="item.classificationsList.length>0"></i>
                            <p [ngClass]="{'parent':activeParent===item._id && activeChild==='', 'orange': item.certificateEnabled, 'black': !item.certificateEnabled}" (click)="activeParent = item._id; activeChild = ''; onNavigateChapters(item)">{{item.title}}</p>
                        </div>
                        <!-- <h6>{{item.description}}</h6> -->
                    </a>
                    <ng-container *ngIf="item.isProtected">
                        <i class="fa fa-lock"></i>
                    </ng-container>
                    <ul class="list-unstyled components child-list">
                        <ng-container *ngFor="let subItem of item.classificationsList;">
                            <li id="{{subItem._id}}" *ngIf="subItem.userData?.userType === userTypes.MASTER_ADMIN" [ngClass]="{'active-custom':activeChild===subItem._id,'lock-item':subItem.isProtected}"
                                (click)="activeParent = item._id; activeChild = subItem._id; onNavigateChapters(subItem)">
                                <a href="javascript:void(0)">
                                    <p [ngClass]="{'orange': subItem.certificateEnabled, 'black': !subItem.certificateEnabled}">{{subItem.title}}</p>
                                </a>
                                <ng-container *ngIf="subItem.isProtected">
                                    <i class="fa fa-lock"></i>
                                </ng-container>
                            </li>
                        </ng-container>
                    </ul>
                </li>
            </ng-container>
            <ng-container *ngIf="isLoading">
                <li class="active" *ngFor="let row of ' '.repeat(6).split('')">
                    <a href="javascript:void(0)">
                        <p class="w-25">
                            <mat-progress-bar mode="buffer"></mat-progress-bar>
                        </p>
                        <h6>
                            <mat-progress-bar mode="buffer"></mat-progress-bar>
                        </h6>
                    </a>
                </li>
            </ng-container>
            <h3 class="yellow_border" *ngIf="organization._id">{{organization.title}}</h3>
        </ul>
        <ul class="list-unstyled university">
            <ng-container *ngFor="let item of resourceList;let index = index">
                <li id="{{item._id}}" *ngIf="item.userData?.userType === userTypes.ORGANIZATION" 
                [ngClass]="{
                    'active-custom':activeParent===item._id,
                    'lock-item':item.isProtected,
                    'have-child-list': item.classificationsList.length>0
                }" >
                    <a href="javascript:void(0)">
                        <div class="stream-item">
                            <i class="fa fa-plus" *ngIf="item.classificationsList.length>0"></i>
                            <p [ngClass]="{'parent':activeParent===item._id && activeChild==='', 'orange': item.certificateEnabled, 'black': !item.certificateEnabled}" (click)="activeParent = item._id; activeChild = ''; onNavigateChapters(item)">{{item.title}}</p>
                        </div>
                        <!-- <h6>{{item.description}}</h6> -->
                    </a>
                        <ng-container *ngIf="item.isProtected">
                            <i class="fa fa-lock"></i>
                        </ng-container>
                        <ul class="list-unstyled components child-list">
                            <ng-container *ngFor="let subItem of item.classificationsList;">
                            <li id="{{subItem._id}}" *ngIf="subItem.userData?.userType === userTypes.ORGANIZATION" [ngClass]="{'active-custom':activeChild===subItem._id,'lock-item':subItem.isProtected}"
                                (click)="activeParent = item._id; activeChild = subItem._id; onNavigateChapters(subItem)">
                                <a href="javascript:void(0)">
                                    <p [ngClass]="{'orange': item.certificateEnabled, 'black': !item.certificateEnabled}">{{subItem.title}}</p>
                                </a>
                                <ng-container *ngIf="subItem.isProtected">
                                    <i class="fa fa-lock"></i>
                                </ng-container>
                            </li>
                        </ng-container>
                    </ul>
                </li>
                    <!-- <li *ngIf="item.userData?.userType === userTypes.ORGANIZATION" [ngClass]="{'active':resource._id===item._id,'lock-item':item.isProtected}"
                        (click)="resource = item; onNavigateChapters(item.slug)">
                        <a href="javascript:void(0)">
                            <p>{{item.title}}</p>
                            <h6>{{item.description}}</h6>
                        </a>
                        <ng-container *ngIf="item.isProtected">
                            <i class="fa fa-lock"></i>
                        </ng-container>
                    </li> -->
            </ng-container>
            <ng-container *ngIf="isLoading">
                <li class="active" *ngFor="let row of ' '.repeat(6).split('')">
                    <a href="javascript:void(0)">
                        <p class="w-25">
                            <mat-progress-bar mode="buffer"></mat-progress-bar>
                        </p>
                        <h6>
                            <mat-progress-bar mode="buffer"></mat-progress-bar>
                        </h6>
                    </a>
                </li>
            </ng-container>
        </ul>
    </nav>

    <!-- Page Content  -->
    <div id="content" class="content_wrp">
        <div class="container-fluid">
            <div class="row hidden_md">
                <div class="col-md-12 text-right mob_top_align">
                    <button type="button" id="sidebarCollapse" class="btn btn-info hidden_md">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                            class="bi bi-text-left" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                d="M2 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                        </svg>
                    </button>
                    <span class="search search_icon">
                        <i class="fa fa-search" aria-hidden="true"></i>
                    </span>
                </div>
            </div>

            <div class="row pt-5">
                <div class="col-lg-9 col-md-9 col-sm-12">
                    <h4>{{resource.title}}
                        <ng-container *ngIf="resource.isProtected">
                            <i class="fa fa-lock"></i>
                        </ng-container>
                    </h4>
                    <div class="module" *ngIf="!resource.isClassifiedClass">
                      <p [innerHTML]="resource.content | safeHtml" class="collapse" id="collapseText" aria-expanded="false"></p>
                      <h6>
                        <a role="button" class="collapsed" data-toggle="collapse" href="#collapseText" aria-expanded="false" aria-controls="collapseText"></a>
                      </h6>
                    </div>
                    <div class="module" *ngIf="resource.isClassifiedClass">
                        <p [innerHTML]="resource.content | safeHtml"></p>
                    </div>

                    <app-payment class="mr-2 mt-3 d-block d-sm-block d-md-none" *ngIf="resource.isProtected && resource.price>0" [resource]="resource" (paymentProcessing)="onProcessingPayment($event)"></app-payment>
                </div>
                <div class="col-lg-3 col-md-12 md-text-left text-right visible_sml">
                    <app-payment class="mr-2 mt-2" *ngIf="resource.isProtected && resource.price>0 && authUser.userType===userTypes.INDIVIDUAL" [resource]="resource" (paymentProcessing)="onProcessingPayment($event)"></app-payment>
                    <span class="search search_icon">
                        <i class="fa fa-search" aria-hidden="true"></i>
                    </span>
                </div>
            </div>
        </div>
        <section class="resource_home">
            <div class="container-fluid pt-5">
                <div class="row">
                    <div class="d-flex w-100 flex-wrap">
                        <div class="card card_custom" *ngFor="let item of chaptersList">
                            <ng-container *ngIf="item.status==='Active'">
                                <!-- <img class="card-img-top" [src]="item.image?.thumbPath || 'assets/img/chapter-default.png'" [alt]="item.image?.originalName" (load)="imageIsLoaded('img_'+item._id)">
                                <div class="prev-loader" id="img_{{item._id}}">
                                    <img class="preview-loader" src="assets/img/loading.gif">
                                </div> -->

                                <div class="card-body"
                                [ngClass]="{
                                    'master-access':authUser.userType===userTypes.MASTER_ADMIN,
                                    'student-access':authUser.userType===userTypes.STUDENT,
                                    'tutor-access':authUser.userType===userTypes.TUTOR
                                }">
                                    <mat-expansion-panel class="mat-xp-panel">
                                        <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <div class="lock-chapter mr-2" *ngIf="item.isProtected">
                                                <i class="fa fa-lock"></i>
                                            </div>
                                            <h5 class="card-title">{{item.title}}</h5>
                                        </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <p [innerHTML]="item.description | safeHtml"></p>
                                    </mat-expansion-panel>
                                    <ul>
                                        <li class="card-text interactive-info-container" *ngFor="let s of item.sectionsList | slice:0:4"
                                        [routerLink]=" !s.hasSectionAccess ? null : '/resources/'+resource.slug+'/'+item.slug"
                                        [queryParams]=" s.hasSectionAccess ? {'tab':'tab-theory','section':s.slug} : null">
                                            <span 
                                            *ngIf="s.status==='Active'" 
                                            class="default-section" 
                                            [ngClass]="{
                                                'free-section':s.hasSectionAccess && (resource.isProtected || item.isProtected) ,
                                                'non-free':!s.hasSectionAccess
                                            }">{{s.title}}</span>
                                            <div class="interactive-info" *ngIf="s.showInteractive === undefined || s.showInteractive">
                                                <button 
                                                    mat-raised-button
                                                    matTooltip="Interactive"
                                                    matTooltipPosition="right"
                                                    class="mat-tooltip-custom"
                                                >
                                                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        </li>
                                    </ul>
                                    <ng-container>
                                        <a routerLink="/resources/{{resource.slug}}/{{item.slug}}" *ngIf="item.sectionsList.length>4 && !item.isProtected">View
                                            all sections ></a>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                        <ng-container *ngIf="isLoadingChapters">
                            <div class="card card_custom loading-item" *ngFor="let row of ' '.repeat(6).split('')">
                                <!-- <img class="card-img-top" src="assets/img/chapter-default.png" alt="Card image cap"> -->
                                <div class="card-body">
                                    <ul>
                                        <li class="card-text w-25">
                                            <mat-progress-bar mode="buffer"></mat-progress-bar>
                                        </li>
                                        <li class="card-text">
                                            <mat-progress-bar mode="buffer"></mat-progress-bar>
                                        </li>
                                        <li class="card-text">
                                            <mat-progress-bar mode="buffer"></mat-progress-bar>
                                        </li>
                                        <li class="card-text">
                                            <mat-progress-bar mode="buffer"></mat-progress-bar>
                                        </li>
                                        <li class="card-text">
                                            <mat-progress-bar mode="buffer"></mat-progress-bar>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="noResultChapter && !resource.isClassifiedClass">
                            <div class="no-chapters-container">
                                <div class="no-chapters">
                                    <img src="assets/img/no-results.PNG">
                                    <label>No chapters found</label>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="col text-center view-more add_note" *ngIf="showViewMoreBtn">
                        <a class="cursor-pointer" (click)="pageChanged()">
                            View More
                         </a>
                            <mat-spinner class="btnLoading mt-2" [diameter]="20" *ngIf="isLoading">
                            </mat-spinner>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
