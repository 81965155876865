import { AfterViewInit, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from '../../services/shared.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { User } from '../../model/user.model';
import { isPlatformBrowser } from '@angular/common';
declare const $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  user = new User();
  sub: any = {};
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private _googleAnalyticsService: GoogleAnalyticsService,
    private _sharedService: SharedService,
    @Inject(PLATFORM_ID) private platform: Record<string, unknown>
  ) { }

  ngOnInit(): void {
    this.sub.user = this._sharedService.user.subscribe((res: any) => {
      this.user = res;
    });
  }
  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platform)) {
      $('.owl-carousel').owlCarousel({
        loop: true,
        nav: false,
        dots: false,
        responsiveClass: true,
        items: 1,
        animateOut: 'fadeOut',
        autoplay: true,
        autoplayTimeout: 5000,
        smartSpeed: 1000
    });

    $(document).on('click', '.toggle-container', function(this: any) {
      $(this).toggleClass('change');
    });
    }
  }
  getStart(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }
  getStartLogin() {
    if (this.user._id) {
      this.router.navigate(['/resources']);
    } else {
      document.getElementById('btnTrigger')?.click();
    }
  }
  analyticsEvent(name: string): void {
    this._googleAnalyticsService.sendEvent('buttonClick', { buttonName: name });
  }
  ngOnDestroy() {
    if (this.sub.user) {
      this.sub.user.unsubscribe();
    }
  }
}
