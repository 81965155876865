import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ChapterSuccessData, ContentListSuccessData } from '../model/chapter.model';
import { environment } from '../../environments/environment';

@Injectable()
export class ChapterService {
    constructor(public http: HttpClient) { }

    filterList(
        keywords: string,
        status: string,
        resourceId: string,
        exploreDetails: boolean,
        page: number,
        limit: number,
        sortBy: string
    ): Observable<HttpResponse<ChapterSuccessData>> {
        return this.http.get<ChapterSuccessData>(environment.API_END_POINT + '/chapter?title=' + keywords + '&status=' + status +
            '&resourceIds=' + resourceId + '&exploreDetails=' + exploreDetails + '&page=' + page + '&limit=' + limit + '&sortBy=' + sortBy, { observe: 'response' });
    }
    search(
        keywords: string,
        resourceId: string,
        page: number,
        limit: number,
        sortBy: string
    ): Observable<HttpResponse<ChapterSuccessData>> {
        return this.http.get<ChapterSuccessData>(environment.API_END_POINT + '/chapter/query/search?title=' + keywords +
            '&resourceId=' + resourceId + '&page=' + page + '&limit=' + limit + '&sortBy=' + sortBy, { observe: 'response' });
    }
    chapterSectionSearch(
        keywords: string,
        resourceId: string,
        page: number,
        limit: number,
        sortBy: string
    ): Observable<HttpResponse<ContentListSuccessData>> {
        return this.http.get<ContentListSuccessData>(environment.API_END_POINT + '/chapter/query/chapter-section-search?title=' + keywords +
            '&resourceId=' + resourceId + '&page=' + page + '&limit=' + limit + '&sortBy=' + sortBy, { observe: 'response' });
    }
    getDetails(id: string, resourceId: string): Observable<HttpResponse<ChapterSuccessData>> {
        return this.http.get<ChapterSuccessData>(environment.API_END_POINT + '/chapter/' + id + '?resourceId=' + resourceId, { observe: 'response' });
    }
}
