<div class="message-notification">
    <div class="message-title">
        <img class="avatar" *ngIf="data.picture" [src]="data.picture">
        <span class="group_icon text-capitalize" [style.background-color]="data.firstName | randomColorPick "
            *ngIf="!data.picture">{{data.firstName | slice:0:1}}
        </span>
        <a class="cursor-pointer" target="_blank" (click)="onNavigate(data)">
            <label>{{data.firstName}} {{data.lastName}}</label>
        </a>
        <i class="fa fa-times" aria-hidden="true" (click)="dismiss()"></i>
    </div>
    <div class="message-body">
        <p [innerHTML]="data.message | safeHtml"></p>
        <a class="read-more" target="_blank" (click)="onNavigate(data)">View message</a>
    </div>    
</div>
