import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BookmarkDetail, BookmarkSuccessData } from '../../../model/bookmark.model';
import { DialogModel } from '../../../model/dialog.model';
import { ConfirmDialogComponent } from '../../../common/confirm-dialog/confirm-dialog.component';
import { MessageAlert } from '../../../utilities/message.alert';
import { Utility } from '../../../utilities/utility';
import { BookmarkService } from '../../../services/bookmark.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-my-bookmarks',
  templateUrl: './my-bookmarks.component.html',
  styleUrls: ['./my-bookmarks.component.scss']
})
export class MyBookmarksComponent implements OnInit {
  page = 1;
  limit = 20;
  totalCount = 0;
  listItems: BookmarkDetail[] = [];
  keywords = new FormControl('');
  isLoading = false;
  noResult = false;
  orderBy = '-createdAt';
  httpError: HttpErrorResponse | undefined;
  constructor(
    private bookmarkService: BookmarkService,
    private dialog: MatDialog,
    private alert: MessageAlert
  ) { }

  ngOnInit(): void {
    this.filterList();
    this.keywords.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(data => {
      this.page = 1;
      this.listItems = [];
      this.filterList();
    });
  }
  filterList(): void {
    this.isLoading = true;
    this.noResult = false;
    this.listItems = [];
    this.httpError = undefined;
    this.bookmarkService.filterList(
      this.keywords.value,
      this.page,
      this.limit,
      this.orderBy
    ).subscribe((res: HttpResponse<BookmarkSuccessData>) => {
      this.listItems = res.body?.data || [];
      this.totalCount = Number(res.headers.get('x-total-count'));
      if (this.listItems.length === 0) {
        this.noResult = true;
      }
      this.isLoading = false;
    }, (err: HttpErrorResponse) => {
      this.listItems = [];
      this.noResult = true;
      this.isLoading = false;
      this.httpError = err;
    });
  }
  pageChanged(pno: number) {
    this.page = pno;
    this.filterList();
  }
  onSortBy(field: string) {
    if (this.orderBy.includes('-')) {
      this.orderBy = field;
    } else {
      this.orderBy = '-' + field;
    }
    this.filterList();
  }
  removeBookmark(id: string): void {
    const message = `Are you sure you want to delete this bookmark?`;
    const dialogData = new DialogModel('Confirm Delete', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      const result = dialogResult;
      if (result === true) {
        this.isLoading = true;
        this.bookmarkService.deleteBookmark(id).subscribe((res: any) => {
          this.alert.success(res.message);
          this.filterList();
          this.isLoading = false;
        }, (err) => {
          this.alert.error(Utility.bindErrors(err));
          this.isLoading = false;
        });
      }
    });
  }
}
