<section class="about_sec">
    <div class="container-fluid w_80">
        <div class="row">
            <div class="col-lg-12">
                <h3>FAQ</h3>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container-fluid w_80 mb-5">
        <div class="row">
            <div class="col-lg-12">
                <div class="wrapper_white">
                    <h1>How can we help you?</h1>
                    <div class="panel-group collapsepanel_wrp" id="accordion" role="tablist"
                        aria-multiselectable="true">
                        <div class="panel panel-default">
                            <div class="panel-heading active" role="tab" id="headingOne">
                                <h4 class="panel-title">
                                    <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne"
                                        aria-expanded="true" aria-controls="collapseOne">
                                        Ollit do dolor laboris veniam est est magna?
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseOne" class="panel-collapse in" role="tabpanel"
                                aria-labelledby="headingOne">
                                <div class="panel-body">
                                    Nim ipsum dolore cupidatat consequat do sit. Laboris eu elit ullamco pariatur nulla
                                    qui reprehenderit culpa reprehenderit. Lorem amet excepteur commodo magna do
                                    deserunt id enim excepteur anim qui excepteur elit irure. Duis eiusmod ad mollit
                                    veniam ipsum exercitation in. Lorem id eiusmod et cillum ut reprehenderit. </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingTwo">
                                <h4 class="panel-title">
                                    <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo"
                                        aria-expanded="false" aria-controls="collapseTwo">
                                        It Officia Commodo Ex Quis Ex Fugiat Ullamco In Enim Labore. Cupidatat Elit Nisi
                                        Adipisicing Eu
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel"
                                aria-labelledby="headingTwo">
                                <div class="panel-body">
                                    Nim ipsum dolore cupidatat consequat do sit. Laboris eu elit ullamco pariatur nulla
                                    qui reprehenderit culpa reprehenderit. Lorem amet excepteur commodo magna do
                                    deserunt id enim excepteur anim qui excepteur elit irure. Duis eiusmod ad mollit
                                    veniam ipsum exercitation in. Lorem id eiusmod et cillum ut reprehenderit. </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingThree">
                                <h4 class="panel-title">
                                    <a role="button" data-toggle="collapse" data-parent="#accordion"
                                        href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        It Officia Commodo Ex Quis Ex Fugiat Ullamco In Enim Labore. Cupidatat Elit Nisi
                                        Adipisicing Eu
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseThree" class="panel-collapse collapse" role="tabpanel"
                                aria-labelledby="headingThree">
                                <div class="panel-body">
                                    Nim ipsum dolore cupidatat consequat do sit. Laboris eu elit ullamco pariatur nulla
                                    qui reprehenderit culpa reprehenderit. Lorem amet excepteur commodo magna do
                                    deserunt id enim excepteur anim qui excepteur elit irure. Duis eiusmod ad mollit
                                    veniam ipsum exercitation in. Lorem id eiusmod et cillum ut reprehenderit. </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingFour">
                                <h4 class="panel-title">
                                    <a role="button" data-toggle="collapse" data-parent="#accordion"
                                        href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        It Officia Commodo Ex Quis Ex Fugiat Ullamco In Enim Labore. Cupidatat Elit Nisi
                                        Adipisicing Eu
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseFour" class="panel-collapse collapse" role="tabpanel"
                                aria-labelledby="headingFour">
                                <div class="panel-body">
                                    Nim ipsum dolore cupidatat consequat do sit. Laboris eu elit ullamco pariatur nulla
                                    qui reprehenderit culpa reprehenderit. Lorem amet excepteur commodo magna do
                                    deserunt id enim excepteur anim qui excepteur elit irure. Duis eiusmod ad mollit
                                    veniam ipsum exercitation in. Lorem id eiusmod et cillum ut reprehenderit. </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingFive">
                                <h4 class="panel-title">
                                    <a role="button" data-toggle="collapse" data-parent="#accordion"
                                        href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        It Officia Commodo Ex Quis Ex Fugiat Ullamco In Enim Labore. Cupidatat Elit Nisi
                                        Adipisicing Eu
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseFive" class="panel-collapse collapse" role="tabpanel"
                                aria-labelledby="headingFive">
                                <div class="panel-body">
                                    Nim ipsum dolore cupidatat consequat do sit. Laboris eu elit ullamco pariatur nulla
                                    qui reprehenderit culpa reprehenderit. Lorem amet excepteur commodo magna do
                                    deserunt id enim excepteur anim qui excepteur elit irure. Duis eiusmod ad mollit
                                    veniam ipsum exercitation in. Lorem id eiusmod et cillum ut reprehenderit. </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>