<div class="wrapper">
  <!-- Sidebar  -->
  <nav id="sidebar" class="sml_slide">

    <ul class="list-unstyled subscription_">
      <li [ngClass]="{'active':activeTab==='personal-info'}" (click)="activeTab='personal-info'; handleQueryParams();">
        <a class="cursor-pointer">
          <p><img class="align-middle" src="assets/img/user.svg"> Personal info</p>
        </a>
      </li>
      <ng-container *ngIf="userData.userType!==userTypes.ADMIN && userData.userType!==userTypes.MASTER_ADMIN">
        <li [ngClass]="{'active':activeTab==='bookmarks'}" (click)="activeTab='bookmarks'; handleQueryParams();">
          <a class="cursor-pointer">
            <p><img class="align-middle" src="assets/img/bookmark-white.svg"> Bookmarked classes</p>
          </a>
        </li>
        <ng-container *ngIf="userData.userType === userTypes.INDIVIDUAL">
          <li [ngClass]="{'active':activeTab==='my-orders'}" (click)="activeTab='my-orders'; handleQueryParams();">
            <a class="cursor-pointer">
              <p><img class="align-middle" src="assets/img/subscription.svg">My Subscriptions</p>
            </a>
          </li>
        </ng-container>
        <!-- <li [ngClass]="{'active':activeTab==='my-certificates'}" (click)="activeTab='my-certificates'; handleQueryParams();">
          <a class="cursor-pointer">
            <p><img class="align-middle" src="assets/img/bookmark-white.svg">My Certificates</p>
          </a>
        </li> -->
      </ng-container>
      <li [ngClass]="{'active':activeTab==='my-exams'}" (click)="activeTab='my-exams'; handleQueryParams();">
        <a class="cursor-pointer">
          <p><img class="align-middle" src="assets/img/bookmark-white.svg">My Exams</p>
        </a>
      </li>
    </ul>
  </nav>

  <!-- Page Content  -->
  <div id="content" class="content_wrp">
    <div class="container-fluid">
      <div class="row hidden_md">
        <div class="col-md-12 text-right mob_top_align">
          <button type="button" id="sidebarCollapse" class="btn btn-info hidden_md">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-text-left"
              viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M2 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <section class="resource_home">
      <div class="container-fluid pdngLR_10 pt_15">
        <div class="row">
          <div class="col-lg-12">
            <div class="white_wrpper">
              <div class="row">
                <div class="col-lg-12" *ngIf="activeTab==='personal-info'">
                  <h3>Personal information</h3>
                  <div class="prof_wrapper">
                    <div class="prof_img">
                      <img *ngIf="!userData?.profilePicture?.thumbPath" src="assets/img/prof.svg"
                        class="picture-src">
                      <img *ngIf="userData?.profilePicture?.thumbPath" [src]="userData?.profilePicture?.thumbPath"
                        class="picture-src">
                    </div>
                    <div class="prof_info">
                      <div class="row">
                        <div class="col-lg-3 col-md-3 col-sm-3 col-3 pdng_3">
                          <p>Name</p>
                        </div>
                        <div class="col-lg-9 col-md-9 col-sm-9 col-9">
                          <p><strong>{{userData.firstName}} {{userData.lastName}}</strong></p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-3 col-md-3 col-sm-3 col-3 pdng_3">
                          <p>Email</p>
                        </div>
                        <div class="col-lg-9 col-md-9 col-sm-9 col-9">
                          <p><strong>{{userData.email}}</strong></p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-3 col-md-3 col-sm-3 col-3 pdng_3">
                          <p>Phone</p>
                        </div>
                        <div class="col-lg-9 col-md-9 col-sm-9 col-9">
                          <p><strong>{{userData.phone}}</strong></p>
                        </div>
                      </div>
                      <div class="row">
                        <div *ngIf="userData.address" class="col-lg-3 col-md-3 col-sm-3 col-3 pdng_3">
                          <p>Address</p>
                        </div>
                        <div
                          *ngIf="userData.address || userData.street || userData.city || userData.state || userData.postcode || userData.country"
                          class="col-lg-9 col-md-9 col-sm-9 col-9">
                          <p><strong>{{userData.address}} {{userData.street}}<br>
                              {{userData.city}} {{userData.state}} {{userData.postcode}}<br>
                              {{userData.country}}</strong></p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-12 pdng_3">
                          <p class="ml_space"><a class="links_" href="javascript:void(0)" (click)="edit()">Edit info</a>
                            <a class="links_" routerLink="/account/change-password">Change password</a>
                          </p>
                          <ng-container *ngIf="userData.userType!==userTypes.MASTER_ADMIN">
                            <button class="btn btn-warning" data-toggle="modal" data-target="#close_account">I wish to
                              close my account</button>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12" *ngIf="activeTab==='my-orders'">
                  <app-my-orders></app-my-orders>
                </div>
                <div class="col-lg-12" *ngIf="activeTab==='bookmarks'">
                  <app-my-bookmarks></app-my-bookmarks>
                </div>
                <div class="col-lg-12" *ngIf="activeTab==='my-exams'">
                  <app-my-exams></app-my-exams>
                </div>
                <!-- <div class="col-lg-12" *ngIf="activeTab==='my-certificates'">
                  <app-my-certificates></app-my-certificates>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

</div>

<div class="modal fade close_account" id="close_account" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12">
            <h5>Close Account</h5>
            <p>Are you sure you want to close your account?
              Closing your account will remove access to your app, reports and any pending credits will no longer be
              available.</p>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-start border-0">
        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-info" (click)="onAccountClose()">Close Account</button>
      </div>
    </div>
  </div>
</div>
