import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, HostListener } from '@angular/core';
import { isPlatformBrowser, Location } from '@angular/common';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { SuccessDialogComponent } from '../../common/success-dialog/success-dialog.component';
import { ConfirmDialogComponent } from '../../common/confirm-dialog/confirm-dialog.component';
import { MessageAlert } from '../../utilities/message.alert';
import { ChapterService } from '../../services/chapter.service';
import { ResourceService } from '../../services/resource.service';
import { SectionService } from '../../services/section.service';
import { BookmarkService } from '../../services/bookmark.service';
import { SharedService } from '../../services/shared.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { Bookmark, BookmarkDetail, BookmarkSuccessData } from '../../model/bookmark.model';
import { DialogModel } from '../../model/dialog.model';
import { Chapter, ChapterErrorData, ChapterSuccessData } from '../../model/chapter.model';
import { Resource, ResourceDetailsSuccessData, ResourceErrorData } from '../../model/resource.model';
import { AuthUser } from '../../model/user.model';
import { Constants } from '../../utilities/constants';
import { Utility } from '../../utilities/utility';

@Component({
  selector: 'app-definition',
  templateUrl: './definition.component.html',
  styleUrls: ['./definition.component.scss']
})
export class DefinitionComponent implements OnInit, OnDestroy {
  activeTab = 'tab-theory';
  activeSection = '';
  resourceSlug = '';
  chapterSlug = '';
  resource = new Resource();
  chapter = new Chapter();
  authUser = new AuthUser();
  userTypes = Constants.userTypes;
  btnDisabled = false;
  isBookmarked = true;
  bookmarkList: BookmarkDetail[] = [];
  visibleTheory = false;
  visibleInteractive = false;
  visibleAdditionalContent = false;
  isLoading = true;
  sub: any = {};
  streamDurationStartTime = moment();
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private alert: MessageAlert,
    private _resourceService: ResourceService,
    private _chapterService: ChapterService,
    private _sharedService: SharedService,
    private _bookmarkService: BookmarkService,
    private _googleAnalyticsService: GoogleAnalyticsService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    @Inject(PLATFORM_ID) private platform: Record<string, unknown>) {
    route.queryParams.subscribe(params => {
      if (params.tab) {
        this.activeTab = params.tab;
        this.onClickTab();
      }
      if (params.section) {
        this.activeSection = params.section;
      }
    });
    this.route.params.subscribe((params) => {
      this.resourceSlug = params.resource;
      this.chapterSlug = params.chapter;
    });
  }
  @HostListener('window:beforeunload', ['$event'])
  calculateStreamDuration() {
    if (isPlatformBrowser(this.platform)) {
      if (this.resource.streamDuration && this.resource.streamDuration.totalHours > 0) {
        const streamDurationEndTime = moment();
        const diff = streamDurationEndTime.diff(this.streamDurationStartTime, 'seconds');
        const socket = this._sharedService.webSocketGetInstance();
        // this is also in the interactive page
        socket.emit('customer.stream.duration', { userId: this.authUser._id, resourceId: this.resource._id, duration: diff });
      }
    }
  }

  ngOnInit(): void {
    this.sub.user = this._sharedService.user.subscribe((res: any) => {
      this.authUser = res;
    });
    this.getResourceDetails();
  }
  getResourceDetails() {
    this._resourceService.getDetails(this.resourceSlug).subscribe((res: HttpResponse<ResourceDetailsSuccessData>) => {
      this.resource = res.body?.data || Object.assign({});
      this.getChapterDetails();
    }, (err) => {
      const error: ResourceErrorData = err;
      this.alert.error(err.error.message);
      this.router.navigate(['/resources']);
    });
  }
  getChapterDetails() {
    this.isLoading = true;
    this._chapterService.getDetails(this.chapterSlug, this.resource._id).subscribe((res: HttpResponse<ChapterSuccessData>) => {
      this.isLoading = false;
      if (res.body?.data === null) {
        this.router.navigate(['/resources']);
        return;
      }
      this.chapter = res.body?.data || Object.assign({});
      if (this.chapter.sections.length === 0) {
        this.alert.error('No details found');
        this.goBack();
        return;
      }
      if (this.activeSection) {
        this.setNavTabsVisibility(this.activeSection);
      } else {
        this.setNavTabsVisibility(this.chapter.sectionsList[0].slug);
      }
      if (this.activeTab) {
        this.onClickTab();
      }
      this.fetchBookmarkList();
      this.enterSectionEvent();
      /* const chapter: Chapter = res.body?.data || Object.assign({});
      this._sectionService.filterList(
        '',
        'Active',
        chapter.sections.toString(),
        '',
        1,
        999,
        'title'
      ).subscribe((res1: HttpResponse<SectionSuccessData>) => {
        chapter.sectionsList = res1.body?.data || Object.assign({});
        this.chapter = chapter;
      }, (err) => {
        const error: ChapterErrorData = err;
        this.alert.error(error.message);
      }); */
    }, (err) => {
      const error: ChapterErrorData = err;
      this.isLoading = false;
      this.alert.error(error.message);
    });
  }
  onClickTab() {
    if (isPlatformBrowser(this.platform)) {
      setTimeout(() => {
        document.getElementById(this.activeTab)?.click();
      }, 50);
    }
  }
  onNavigateMainTab(tabName: string) {
    this.activeTab = tabName;
    this.handleQueryParams();
  }
  handleQueryParams() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        tab: this.activeTab,
        section: this.activeSection
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      skipLocationChange: false
      // do not trigger navigation
    });
  }
  setActiveSection(slug: string) {
    this.activeSection = slug;
    this.setNavTabsVisibility(slug);
    this.handleQueryParams();
    this.enterSectionEvent();
    const index = this.bookmarkList.findIndex((record: any) =>
      (record.sectionSlug === this.activeSection && record.chapterSlug === this.chapterSlug && record.resourceSlug === this.resourceSlug));
    if (index === -1) {
      this.isBookmarked = false;
    } else {
      this.isBookmarked = true;
    }
  }
  setNavTabsVisibility(slug: string) {
    const section = this.chapter.sectionsList.find(x => x.slug === slug);
    if (section) {
      if (section.showTheory === undefined || section.showTheory === true) {
        this.visibleTheory = true;
      } else {
        this.visibleTheory = false;
      }
      if (section.showInteractive === undefined || section.showInteractive === true) {
        this.visibleInteractive = true;
      } else {
        this.visibleInteractive = false;
      }
      if (section.showRelatedCourse === true) {
        this.visibleAdditionalContent = true;
      } else {
        this.visibleAdditionalContent = false;
      }

      if (!this.visibleTheory && !this.visibleInteractive) {
        this.activeTab = 'tab-related-course';
      } else if (!this.visibleTheory && !this.visibleAdditionalContent) {
        this.activeTab = 'tab-interactive';
      } else if (!this.visibleInteractive && !this.visibleAdditionalContent) {
        this.activeTab = 'tab-theory';
      }
    }
  }
  goBack() {
    this.location.back();
  }
  enterSectionEvent(): void {
    try {
      const sectionDetails: any = this.chapter.sectionsList.find(record => record.slug === this.activeSection);
      const sectionEventData = {
        sectionId: sectionDetails._id,
        sectionSlug: sectionDetails.slug,
        sectionTitle: sectionDetails.title,
        chapterId: this.chapter._id,
        chapterSlug: this.chapter.slug,
        chapterTitle: this.chapter.title,
        resourceId: this.resource._id,
        resourceTitle: this.resource.title,
        resourceSlug: this.resource.slug,
        tutorId: this.chapter.tutorId,
        userId: this.authUser._id,
        organizationId: this.authUser.organizationId,
        userType: this.authUser.userType
      };
      this._googleAnalyticsService.sendEvent('enterSection', sectionEventData);
    } catch (error) {
    }
  }
  fetchBookmarkList(): void {
    this.bookmarkList = [];
    this._bookmarkService.filterList(
      '',
      1,
      10000,
      '-createdAt'
    ).subscribe((res: HttpResponse<BookmarkSuccessData>) => {
      const listItems = res.body?.data || [];
      this.bookmarkList = listItems;
      const index = listItems.findIndex((record: any) =>
        (record.sectionSlug === this.activeSection && record.chapterSlug === this.chapterSlug && record.resourceSlug === this.resourceSlug));
      if (index === -1) {
        this.isBookmarked = false;
      }
    }, (err: HttpErrorResponse) => {
      this.snackBar.open(err.message);
    });
  }
  addBookmark(): void {
    if (!this.btnDisabled) {
      const sectionDetails = this.chapter.sectionsList.filter(record => record.slug === this.activeSection);
      const bookmarkData: Bookmark = {
        resourceId: this.resource._id,
        chapterId: this.chapter._id,
        sectionId: sectionDetails[0]._id,
        _id: ''
      };
      this._bookmarkService.bookmarkCreate(bookmarkData).subscribe((res: any) => {
        this.showMessage(res.message);
        this.isBookmarked = true;
        this.fetchBookmarkList();
        this.btnDisabled = false;
      }, (err) => {
        console.log('error: ', err);
        this.btnDisabled = false;
        this.snackBar.open('Bookmarking failed...');
      });
    }
  }
  removeBookmark(): void {
    const message = `Are you sure you want to delete this bookmark?`;
    const dialogData = new DialogModel('Confirm Delete', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      const result = dialogResult;
      if (result === true) {
        const itemIndex = this.bookmarkList.findIndex((record: any) =>
          (record.sectionSlug === this.activeSection && record.chapterSlug === this.chapterSlug && record.resourceSlug === this.resourceSlug));
        this._bookmarkService.deleteBookmark(this.bookmarkList[itemIndex]._id).subscribe((res: any) => {
          this.alert.success(res.message);
          this.isBookmarked = false;
          this.fetchBookmarkList();
        }, (err) => {
          this.alert.error(Utility.bindErrors(err));
        });
      }
    });
  }
  showMessage(message: string): void {
    const dialogData = new DialogModel('Bookmark Added', message);
    this.dialog.open(SuccessDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });
  }
  ngOnDestroy() {
    if (this.sub.user) {
      this.calculateStreamDuration();
      this.sub.user.unsubscribe();
    }
  }
}
