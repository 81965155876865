import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { OrganizationSuccessData } from '../model/organization.model';
import { environment } from '../../environments/environment';

@Injectable()
export class OrganizationService {
    constructor(public http: HttpClient) { }

    getDetails(id: string): Observable<HttpResponse<OrganizationSuccessData>> {
        return this.http.get<OrganizationSuccessData>(environment.API_END_POINT + '/organization/' + id, { observe: 'response' });
    }
}
