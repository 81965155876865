import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse
} from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Observable, throwError } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(
        public auth: AuthService,
        @Inject(PLATFORM_ID) private platform: object,
    ) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): any {
        let isRememberMe = '';
        if (isPlatformBrowser(this.platform)) {
            isRememberMe = this.auth.getCookie('user_session') || '';
        }
        const token = this.auth.getToken();
        if (request.url.includes('/auth/regenerate-token')) {
            return next.handle(request);
        }
        if (token && this.auth.isTokenExpired(token) && isRememberMe === 'true') {
            if (!request.url.includes('/auth/regenerate-token')) {
                return this.auth.regenerateToken(token).pipe(mergeMap((response: any) => {
                    const newToken = response.data.token;
                    this.auth.setToken(newToken);
                    request = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${newToken}`
                        }
                    });
                    return next.handle(request).pipe(
                        catchError(error => {
                            console.log(error);
                            if (error.status === 401 || error.status === 403) {
                                // handle error
                                if (isPlatformBrowser(this.platform)) {
                                    window.location.href = '/';
                                }
                            }
                            return throwError(error);
                        })
                    );
                })).pipe(
                    catchError(error => {
                        console.log(error);
                        if (error.status === 401 || error.status === 403) {
                            // handle error
                            if (isPlatformBrowser(this.platform)) {
                                window.location.href = '/';
                            }
                        }
                        return throwError(error);
                    })
                );
            }
        } else if (!token) {
            return next.handle(request);
        } else {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
            return next.handle(request).pipe(
                catchError(error => {
                    console.log(error);
                    if (error.status === 401 || error.status === 403) {
                        // handle error
                        if (isPlatformBrowser(this.platform)) {
                            window.location.href = '/';
                        }
                    }
                    return throwError(error);
                })
            );
        }
    }
}
