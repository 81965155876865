import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private _userData = new BehaviorSubject<any>({});
  user: any = this._userData.asObservable();

  private _organizationData = new BehaviorSubject<any>({});
  organization: any = this._organizationData.asObservable();

  private _resourceData = new BehaviorSubject<any>([]);
  resource: any = this._resourceData.asObservable();

  private _groupWriteEvent = new BehaviorSubject<any>([]);
  groupWriteEvent: any = this._groupWriteEvent.asObservable();

  
  private _examData = new BehaviorSubject<any>({});
  exam: any = this._examData.asObservable();

  tempData: any;
  socket: any;
  constructor() { }

  authUser() {
    return this.user.source._value;
  }
  setUserData(data: any) {
    this._userData.next(data);
  }
  setOrganizationData(data: any) {
    this._organizationData.next(data);
  }
  setResourceData(data: any) {
    this._resourceData.next(data);
  }
  getResource() {
    return this.resource.source._value;
  }
  setGroupWriteEvent(data: boolean) {
    this._groupWriteEvent.next(data);
  }
  getGroupWriteEvent() {
    return this.groupWriteEvent.source._value;
  }
  setTempData(data: any) {
    this.tempData = data;
  }
  getTempData() {
    return this.tempData;
  }
  setExamData(data: any) {
    this._examData.next(Object.assign({}, data));
  }

  webSocketCreate(token: string) {
    this.socket = io(environment.API_BASE_URL, {
      query: { token }
    });
  }
  webSocketGetInstance(): Socket {
    return this.socket;
  }
}
