<section class="about_sec">
    <div class="container-fluid w_80">
        <div class="row">
            <div class="col-lg-12">
                <h3><a routerLink="/resources/{{resourceSlug}}"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                            fill="#fff" class="bi bi-arrow-left" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                        </svg></a> {{chapter.title}}
                </h3>
                <!-- <p class="ch-description" *ngIf="chapter.description" [innerHTML]="chapter.description | safeHtml"></p> -->
            </div>
        </div>
    </div>
</section>
<div class="text-center w-100" *ngIf="isLoading">
    <mat-spinner class="btnLoading" [diameter]="50">
    </mat-spinner>
</div>
<section>
    <div class="container-fluid w_80 mb-5">
        <div class="row">
            <div class="col-lg-12">
                <div class="wrapper_white padding_les pr-0">
                    <span class="bookmark" *ngIf="!isBookmarked" (click)="addBookmark()"><small class="d_none_1200">Bookmark</small> <i class="fa fa-bookmark"
                            aria-hidden="true"></i>
                    </span>
                    <span class="bookmark" *ngIf="isBookmarked" (click)="removeBookmark()"><small class="d_none_1200">Bookmarked</small> <i class="fa fa-bookmark active_icon"
                           aria-hidden="true"></i>
                    </span>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="tab_sec">
                                <ul class="nav nav-tabs" id="Definition" role="tablist">
                                    <ng-container *ngIf="visibleTheory">
                                        <li class="nav-item" (click)="onNavigateMainTab('tab-theory')">
                                            <a class="nav-link cursor-pointer" [ngClass]="{'active': activeTab==='tab-theory'}" id="tab-theory" data-toggle="tab" data-target="#tab_1" 
                                                role="tab" aria-controls="tab_1" aria-selected="true">Theory</a>
                                        </li>
                                    </ng-container>
                                    <ng-container *ngIf="visibleInteractive">
                                        <li class="nav-item" (click)="onNavigateMainTab('tab-interactive')">
                                            <a class="nav-link cursor-pointer" [ngClass]="{'active': activeTab==='tab-interactive'}" id="tab-interactive" data-toggle="tab" data-target="#tab_2" role="tab"
                                                aria-controls="tab_2" aria-selected="false">Interactive</a>
                                        </li>
                                    </ng-container>
                                    <ng-container *ngIf="visibleAdditionalContent && authUser.userType !== userTypes.INDIVIDUAL">
                                        <li class="nav-item" (click)="onNavigateMainTab('tab-related-course')">
                                            <a class="nav-link cursor-pointer" [ngClass]="{'active': activeTab==='tab-related-course'}" id="tab-related-course" data-toggle="tab" data-target="#tab_3" role="tab"
                                                aria-controls="tab_3" aria-selected="false">Additional Content</a>
                                        </li>
                                    </ng-container>
                                    <li class="nav-item" (click)="onNavigateMainTab('tab-communication')" *ngIf="authUser.userType!==userTypes.MASTER_ADMIN">
                                        <a class="nav-link cursor-pointer" [ngClass]="{'active': activeTab==='tab-communication'}" id="tab-communication" data-toggle="tab" data-target="#tab_4" role="tab"
                                            aria-controls="tab_4" aria-selected="false">Communication</a>
                                    </li>
                                </ul>
                                <div class="tab-content pt-4" id="DefinitionTab">
                                    <app-def-theory class="tab-pane fade show active" id="tab_1" role="tabpanel"
                                        aria-labelledby="tab_1-tab" 
                                        [resource]="resource" 
                                        [chapter]="chapter"
                                        [sections]="chapter.sectionsList"
                                        [activeSection]="activeSection"
                                        (emitSlug)="setActiveSection($event)"
                                        *ngIf="visibleTheory && activeTab==='tab-theory'"
                                    ></app-def-theory>   
                                    <div class="tab-pane fade show active" id="tab_2" role="tabpanel" aria-labelledby="tab_2-tab">                         
                                        <app-def-interactive
                                            [resource]="resource" 
                                            [chapter]="chapter"
                                            [sectionList]="chapter.sectionsList"
                                            [activeSection]="activeSection"
                                            (emitSlug)="setActiveSection($event)"
                                            *ngIf="visibleInteractive && activeTab==='tab-interactive'">
                                        </app-def-interactive>
                                    </div>
                                    <div class="tab-pane fade show active" id="tab_3" role="tabpanel" aria-labelledby="tab_3-tab">
                                        <app-def-related-course 
                                            [resource]="resource" 
                                            [chapter]="chapter"
                                            [sections]="chapter.sectionsList" 
                                            [tutor]="resource.tutorData" 
                                            [organization]="resource.organizationData"
                                            [activeSection]="activeSection"
                                            (emitSlug)="setActiveSection($event)"
                                            *ngIf="visibleAdditionalContent && activeTab==='tab-related-course' && authUser.userType !== userTypes.INDIVIDUAL">
                                        </app-def-related-course>
                                    </div>
                                    <ng-container *ngIf="activeTab==='tab-related-course' && authUser.userType === userTypes.INDIVIDUAL">
                                        <app-unauthorized></app-unauthorized>
                                    </ng-container>
                                    <div class="tab-pane fade show active" id="tab_4" role="tabpanel" aria-labelledby="tab_4-tab" *ngIf="authUser.userType!==userTypes.MASTER_ADMIN">
                                        <app-def-communication
                                            [resource]="resource" 
                                            [chapter]="chapter" 
                                            [sections]="chapter.sectionsList" 
                                            [tutor]="resource.tutorData" 
                                            [organization]="resource.organizationData"
                                            [activeSection]="activeSection"
                                            (emitSlug)="setActiveSection($event)"
                                            *ngIf="activeTab==='tab-communication'">
                                        </app-def-communication>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>