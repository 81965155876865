
/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */


export class DialogModel {
    constructor(public title: string, public message: string) {
    }
}
export class MessageNotification {
    userId = '';
    firstName = '';
    lastName = '';
    userType = '';
    picture = '';
    message = '';
    messageType = '';
    threadId = '';
    resourceSlug = '';
    chapterSlug = '';
    sectionSlug = '';
    preClose = () => { };
};
