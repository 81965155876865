import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {

  }

  ngOnInit(): void {
  }
  exploreApp() {
    const redirectTo = this.route.snapshot.queryParamMap.get('next') || '';
    this.router.navigate(['/account/login'], { queryParams: { next: redirectTo } });
  }
}
