<!-- <div class="group_ single d_none">
    <img src="assets/img/member.png">
    <h4>Jessica Miles</h4>
</div> -->
<div class="group_ multiple" *ngIf="messageType==='Group'">
    <div class="flex_bx" >
        <ng-container *ngFor="let item of groupMessage?.members?.slice(0,5)">
            <img [src]="item.profilePicture?.thumbPath" *ngIf="item.profilePicture?.thumbPath" data-toggle="modal" data-target="#create_group_modal" class="cursor-pointer">
            <span class="group_icon text-capitalize cursor-pointer" [style.background-color]="item.firstName | randomColorPick " *ngIf="!item.profilePicture?.thumbPath"
                data-toggle="modal" data-target="#create_group_modal">{{item.firstName | slice:0:1}}</span>
        </ng-container>
        <span *ngIf="groupMessage.members.length>5" data-toggle="modal" data-target="#create_group_modal">+{{groupMessage.members.length-5}}</span>
    </div>
    <h4>{{groupMessage.groupName}}</h4>
</div>
<div class="pr_adjest group_content">
    <h4>{{section.title}}</h4>
    <div id="editorClone" class="editor-wrapper communication">
        <div id="QuillEditor" class="quill-editor">
        </div>
    </div>
    <span id="quillAttachments" class="ql-formats">
        <button type="button" (click)="onClickFileUpload()">
            <img src="assets/img/link.svg">
        </button>
    </span>
    <div class="loading-spin" *ngIf="isLoading">
        <mat-spinner class="btnLoading" [diameter]="40">
        </mat-spinner>
    </div>
    <div class="text-right">
        <button
            type="submit"
            class="comment mr-2"
            *ngIf="messageModel._id"
            [disabled]="btnDisabled"
            (click)="cancelEdit()">
            Cancel
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
        </button>
        <button class="comment" [disabled]="btnDisabled" (click)="sendMessage()">Comment <i class="fas fa-paper-plane" aria-hidden="true"></i></button>
    </div>
    <div class="file-upload-progress" *ngIf=" uploadingPercentage>0 ">
        <mat-progress-bar mode="determinate" [value]="uploadingPercentage"></mat-progress-bar>
    </div>
</div>
<div class="form-group">
    <div class="file-upload file-upload-container row">
        <div class="image-upload-wrap" style="display: none;">
            <div class="drag-drop">
                <p (click)="openFile('btnFileSelector')">
                    <ngx-file-drop dropZoneLabel="Drag & drop/ Attach images"
                        (onFileDrop)="droppedFiles($event)">
                        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                            <div class="drag-text">
                            </div>
                            <button id="btnFileSelector" type="button" (click)="openFileSelector()"
                                class="btn btn-default btnImageUpload">Browse
                                File</button>
                        </ng-template>
                    </ngx-file-drop>
                </p>
            </div>
        </div>
        <ng-container *ngFor="let item of fileUploads; let i = index;">
          <div class="image-preview-container">
              <div class="bar-close" (click)="removeFile(i, item)"><a href="" data-dismiss="alert"
                aria-label="Close"><img src="assets/img/close.svg"></a></div>
                <ng-container *ngIf="item.type==='image'">
                  <img class="image-preview" [src]="item.src" [alt]="item.filename" (load)="imageIsLoaded('imgLoader_'+i)">
                  <img [id]="'imgLoader_'+i" class="preview-loader" src="assets/img/loading-preview.gif">
                </ng-container>
                <img class="image-preview" *ngIf="item.type==='pdf'" src="assets/img/icon-pdf.png" [alt]="item.filename">
                <img class="image-preview" *ngIf="item.type==='doc'" src="assets/img/icon-word.png" [alt]="item.filename">
                <img class="image-preview" *ngIf="item.type==='sheet'" src="assets/img/icon-excel.png" [alt]="item.filename">
                <img class="image-preview" *ngIf="item.type==='text'" src="assets/img/icon-text.png" [alt]="item.filename">
                <img class="image-preview" *ngIf="item.type==='other'" src="assets/img/icon-file.png" [alt]="item.filename">
                <!-- <label *ngIf="item.type!=='image'">{{item.filename}}</label> -->
              </div>
        </ng-container>
    </div>
</div>
<div class="comment_sec pr_adjest">
    <div class="unblock-message" *ngIf="isChatBlocked">
        <label>You blocked this conversation! Please unlock to continue messaging</label>
    </div>
    <div class="sec_split d-flex flex-wrap" [ngClass]="{'new-message-container':!item.isRead && groupMessage.messageType!=='Group' && item.creator !== authUser._id}" *ngFor="let item of groupMessage.messages"
    (click)="item.isRead = true">
        <ng-container *ngFor="let u of groupMessage.members">
            <ng-container *ngIf="item.creator === u._id" >
                <span class="prof_" *ngIf="u.profilePicture?.thumbPath">
                    <img [src]="u.profilePicture?.thumbPath">
                </span>
                <span class="group_icon text-capitalize"
                    [style.background-color]="u.firstName | randomColorPick "
                    *ngIf="!u.profilePicture?.thumbPath">
                    {{u.firstName | slice:0:1}}
                </span>
            </ng-container>
        </ng-container>
        <div class="txt_sec text_custom_edit" [style.width]="item.creator!==authUser._id ? '92%':'83%' ">
            <h6>
                <strong [innerHTML]="groupMessage.members | chatUserName: item.creator : authUser._id"></strong>
                <small>{{item.createdAt | localizeTime}}</small>
                <span class="new-message-badge" *ngIf="!item.isRead && item.creator!==authUser._id">New!</span>
            </h6>
            <p class="pt-2" [innerHTML]="item.content"></p>
            <ng-container *ngIf="item.attachments.length>0">
                <p class="font-weight-bold font_14 pt-4 pb-3">{{item.attachments.length}} Attachments</p>
                <div class="doc file-upload-container row">
                  <ng-container *ngFor="let file of item.attachments; let i = index;">
                    <div class="image-preview-container hover_effect">
                      <a [href]="file.path" target="_blank">
                          <ng-container *ngIf="(file.filename | fileType )==='image'">
                            <img class="image-preview" [src]="file.path" [alt]="file.filename" (load)="imageIsLoaded('imgLoader_'+i)">
                            <img [id]="'imgLoader_'+i" class="preview-loader" src="assets/img/loading-preview.gif">
                          </ng-container>
                          <img class="image-preview" *ngIf="(file.filename | fileType )==='pdf'" src="assets/img/icon-pdf.png" [alt]="file.filename">
                          <img class="image-preview" *ngIf="(file.filename | fileType )==='doc'" src="assets/img/icon-word.png" [alt]="file.filename">
                          <img class="image-preview" *ngIf="(file.filename | fileType )==='sheet'" src="assets/img/icon-excel.png" [alt]="file.filename">
                          <img class="image-preview" *ngIf="(file.filename | fileType )==='text'" src="assets/img/icon-text.png" [alt]="file.filename">
                          <img class="image-preview" *ngIf="(file.filename | fileType )==='other'" src="assets/img/icon-file.png" [alt]="file.filename">
                          <i class="fa fa-cloud-download download_icon d_none" aria-hidden="true"></i>
                        </a>
                      </div>
                  </ng-container>
                </div>
              </ng-container>
        </div>
        <ng-container *ngIf="item.creator===authUser._id">
            <div class="edit_sec edit-message">
                <span class="vertical_menu">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                        class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                        <path
                            d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z">
                        </path>
                    </svg>
                </span>
                <div class="vertical_toggle" style="display: none;">
                    <span (click)="editMessage(item)">Edit</span>
                    <span (click)="deleteMessage(item._id)">Delete</span>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<div class="modal fade" id="create_group_modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-12">
                        <app-create-chat-group
                            [groupMessage]="groupMessage"
                            [newGroupCheckedIds]="updateGroupCheckedIds"
                            [viewName]="'update-group'"
                            [section]="section"
                            [chapter]="chapter"
                            [resource]="resource"
                            *ngIf="authUser.userType===userTypes.STUDENT || authUser.userType===userTypes.TUTOR">
                        </app-create-chat-group>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
