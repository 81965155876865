<div class="d-flex flex-wrap">
    <div class="form_wrpr">
        <div class="left_sec pr_adjest interactive-content">
            <app-interactive-content 
                [resource]="resource" 
                [chapter]="chapter"
                [section]="section" 
                [jsonData]="section.contentRelatedCourse"
            ></app-interactive-content>
        </div>
    </div>
    <div class="right_sec">
        <div class="absolutr_sec">
            <img class="menu_icon" src="assets/img/menu.svg">
            <div class="right_cntnt">
                <h4 class="pt-3">Sections ({{sections.length}})</h4>
                <ul class="item_wrp">
                    <li class="interactive-info-container-1" [ngClass]="{'active':section._id===item._id,'section-disabled':!item.hasSectionAccess }" *ngFor="let item of sections; let index = index"
                        (click)="selectSection(item)">
                        <a href="javascript:void(0)"><span class="pr-2">{{index+1}}.</span>
                            {{item.title}}
                        </a>
                        <div class="interactive-info" *ngIf="item.showInteractive === undefined || item.showInteractive">
                            <button 
                                mat-raised-button
                                matTooltip="Interactive"
                                matTooltipPosition="right"
                                class="mat-tooltip-custom"
                            >
                                <i class="fa fa-info-circle" aria-hidden="true"></i>
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<!-- <div class="d-flex flex-wrap">
    <div class="form_wrpr">
        <div class="left_sec pr_adjest interactive-content">
            <app-interactive-content [section]="section" [jsonData]="section.contentRelatedCourse"></app-interactive-content>
        </div>
    </div>
    <div class="right_sec">
        <div class="absolutr_sec">
            <img class="menu_icon" src="assets/img/menu.svg">
            <div class="right_cntnt">
                <h4 class="pt-3">Sections ({{sections.length}})</h4>
                <ul class="item_wrp">
                    <li [ngClass]="{'active':section._id===item._id}" *ngFor="let item of sections; let index = index"
                        (click)="selectSection(item)">
                        <a href="javascript:void(0)"><span class="pr-2">{{index+1}}.</span>
                            {{item.title}}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div> -->