<div class="topsec h-auto">
   <nav class="navbar navbar-expand-lg" [ngClass]="{'transparent-nav': !enableHeaderClass,'pt-3': !enableHeaderClass,'nav-bg': enableHeaderClass}">
       <div class="container-fluid">
            <a class="navbar-brand" routerLink="/">
               <img src="assets/img/logo.svg" alt="Finstream" *ngIf="!organization.showBrandingOnWebsite">
               <img [src]="organization.logo.path" style="max-width: 150px; max-height: 71px;" *ngIf="organization.showBrandingOnWebsite">
            </a>
           <div class="d-flex align-items-center d-md-flex d-lg-none">
               <div class="position-relative me-2" *ngIf="user._id">
                   <div class="dropdown" *ngIf="notificationsList.length > 0">
                       <a type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff"
                               class="bi bi-bell-fill" viewBox="0 0 16 16">
                               <path
                                   d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
                           </svg>
                       </a>
                       <ul class="dropdown-menu dropdown-menu-end animate slideIn p-0 start-auto end-0" aria-labelledby="dropdownMenu2">
                        <a class="dropdown-item px-3 notification-wrp" *ngFor="let item of notificationsList">
                            <div>
                                <span class="prof">
                                    <ng-container *ngIf="!item.senderData.profilePicture">
                                        <div class="user">{{item.senderData.firstName| slice:0:2}}</div>
                                     </ng-container>
                                     <ng-container *ngIf="item.senderData.profilePicture">
                                        <div class="user-image">
                                           <img [src]="item.senderData.profilePicture?.thumbPath">
                                        </div>
                                     </ng-container>
                                </span>
                            </div>
                            <div class="cursor-pointer" routerLink="/resources/{{item.resourceData.slug}}/{{item.chapterData.slug}}" 
                                 [queryParams]="{tab:'tab-communication', section: item.sectionData.slug, subtab:item.senderData.userType === 'Tutor' ? 'Tutor' : 'Groups'  }">
                                <span class="user-name">New message from {{item.senderData.firstName}} {{item.senderData.lastName}} on</span>
                                <p>{{item.updatedAt | dateAgo}}</p>
                            </div>
                        </a>
                    </ul>
                   </div>
                   <span class="notify" *ngIf="notificationsList.length > 0">
                     <span *ngIf="notificationsList.length > 0 && notificationsList.length <10">{{notificationsList.length}}</span>
                     <span *ngIf="notificationsList.length >= 10">9+</span>
                   </span>
               </div>
               <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                   aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                   <div class="toggle-container">
                       <div class="bar1"></div>
                       <div class="bar2"></div>
                       <div class="bar3"></div>
                   </div>
               </button>
           </div>

           <div class="collapse navbar-collapse" id="navbarNav">
               <ul class="navbar-nav nav-additional mb-lg-3">
                  <ng-container *ngIf="enableHeaderMenu">
                     <!-- <li class="nav-item" [ngClass]="{'active': currentPage === '/'}">
                        <a class="nav-link active" aria-current="page" routerLink="/">HOME</a>
                     </li> -->
                     <!-- <li class="nav-item" [ngClass]="{'active': currentPage === '/about'}">
                        <a class="nav-link" aria-current="page" routerLink="/about">ABOUT</a>
                     </li> -->
                  </ng-container>
                   <!-- <li class="nav-item" [ngClass]="{'active': currentPage === '/contact'}" *ngIf="!organization.showBrandingOnWebsite">
                       <a class="nav-link" routerLink="/contact">CONTACT</a>
                   </li>
                   <li class="nav-item d-none d-md-none d-lg-block" [ngClass]="{'active': currentPage === '/account/login'}" *ngIf="!user._id">
                       <a class="nav-link" routerLink="/account/login">Login </a>
                   </li> -->
                   <!-- <li class="nav-item align-items-center d-none d-md-none d-lg-flex" [ngClass]="{'active': currentPage === '/account/profile'}">
                     <div class="dropdown ">
                         <a type="button" id="dropdownMenu3" class="pb-0" data-toggle="dropdown" aria-expanded="false">
                           {{user.firstName}}
                         </a>
                         <ul class="dropdown-menu dropdown-menu-end animate slideIn start-auto end-0" aria-labelledby="dropdownMenu3">
                             <a class="dropdown-item px-3" routerLink="/account/profile">Profile settings</a>
                             <a class="dropdown-item px-3" href="/logout" (click)="socialSignOut()">Logout</a>
                         </ul>
                     </div>
                  </li> -->
                   <li class="nav-item align-items-center ms-0 position-relative d-none d-md-none d-lg-block" *ngIf="user._id">
                       <div class="dropdown " *ngIf="notificationsList.length > 0">
                           <a type="button" id="dropdownMenu02" data-toggle="dropdown" aria-expanded="false">
                               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                   class="bi bi-bell-fill" viewBox="0 0 16 16">
                                   <path
                                       d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
                               </svg>
                           </a>
                           <ul class="dropdown-menu dropdown-menu-end animate slideIn p-0 start-auto end-0" aria-labelledby="dropdownMenu02">
                            <a class="dropdown-item px-3 notification-wrp" *ngFor="let item of notificationsList">
                                <div>
                                    <span class="prof">
                                        <ng-container *ngIf="!item.senderData.profilePicture">
                                            <div class="user">{{item.senderData.firstName| slice:0:2}}</div>
                                         </ng-container>
                                         <ng-container *ngIf="item.senderData.profilePicture">
                                            <div class="user-image">
                                               <img [src]="item.senderData.profilePicture?.thumbPath">
                                            </div>
                                         </ng-container>
                                    </span>
                                </div>
                                <div class="cursor-pointer" routerLink="/resources/{{item.resourceData.slug}}/{{item.chapterData.slug}}" 
                                     [queryParams]="{tab:'tab-communication', section: item.sectionData.slug, subtab:item.senderData.userType === 'Tutor' ? 'Tutor' : 'Groups'  }">
                                    <span class="user-name">New message from {{item.senderData.firstName}} {{item.senderData.lastName}} on</span>
                                    <p>{{item.updatedAt | dateAgo}}</p>
                                </div>
                            </a>
                          </ul>
                       </div>
                       <span class="notify" *ngIf="notificationsList.length > 0">
                           <span *ngIf="notificationsList.length > 0 && notificationsList.length <10">{{notificationsList.length}}</span>
                           <span *ngIf="notificationsList.length >= 10">9+</span>
                        </span>
                   </li>
               </ul>
               <ul class="navbar-nav ml-auto">

                   <!-- <li class="nav-item" [ngClass]="{'active': currentPage === '/what-is-finstream'}">
                       <a routerLink="/what-is-finstream" class="nav-link">WHAT IS FINSTREAM?</a>
                   </li> -->
                   <!-- <li class="nav-item">
                       <a href="javascript:void(0)" class="nav-link arw" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">STREAMS</a>
                        <div class="dropdown-menu dropdown-menu-end animate slideIn" aria-labelledby="navbarDropdown">
                           <a class="dropdown-item" routerLink="/streams/foreign-exchange-international-finance">Foreign Exchange &<br> International Finance</a>
                           <a class="dropdown-item" routerLink="/streams/financial-derivatives">Financial Derivatives</a>
                           <a class="dropdown-item" routerLink="/streams/portfolio-management">Portfolio Management</a>
                        </div>
                   </li> -->
                  <ng-container *ngIf="enableHeaderMenu">
                     <li class="nav-item" [ngClass]="{'active': currentPage === '/'}">
                        <a class="nav-link active" aria-current="page" routerLink="/">HOME</a>
                     </li>
                     <li class="nav-item" [ngClass]="{'active': currentPage === '/partners'}">
                        <a routerLink="/partners" class="nav-link">PARTNERS</a>
                     </li>
                     <li class="nav-item" [ngClass]="{'active': currentPage === '/contact'}">
                        <a routerLink="/contact" class="nav-link">CONTACT</a>
                     </li>
                     <li class="nav-item" [ngClass]="{'active': currentPage === '/about'}">
                        <a routerLink="/about" class="nav-link">ABOUT US</a>
                     </li>
                     <li class="nav-item d-md-block" [ngClass]="{'d-lg-none': currentPage === '/account/login'}" *ngIf="!user._id">
                        <a class="nav-link" routerLink="/account/login">Login </a>
                     </li>
                     <li class="nav-item align-items-center d-md-flex" [ngClass]="{'active': currentPage === '/account/profile'}">
                        <div class="dropdown ">
                            <a type="button" id="dropdownMenu3" class="pb-0" data-toggle="dropdown" aria-expanded="false">
                              {{user.firstName}}
                            </a>
                            <ul class="dropdown-menu dropdown-menu-end animate slideIn start-auto end-0" aria-labelledby="dropdownMenu3">
                                <a class="dropdown-item px-3" routerLink="/account/profile">Profile settings</a>
                                <a class="dropdown-item px-3" href="/logout" (click)="socialSignOut()">Logout</a>
                            </ul>
                        </div>
                     </li>
                     <!-- <li class="nav-item" [ngClass]="{'active': currentPage === '/for-individuals'}">
                        <a routerLink="/for-individuals" class="nav-link">FOR INDIVIDUALS</a>
                     </li> -->
                     <!-- <li class="nav-item" [ngClass]="{'active': currentPage === '/platform'}">
                        <a routerLink="/platform" class="nav-link">PLATFORM</a>
                     </li> -->
                     <!-- <li class="nav-item" [ngClass]="{'active': currentPage === '/certification'}">
                        <a routerLink="/certification" class="nav-link">CERTIFICATION</a>
                     </li> -->
                  </ng-container>
                  <!-- <li class="nav-item d-md-block d-lg-none" [ngClass]="{'active': currentPage === '/account/login'}" *ngIf="!user._id">
                     <a class="nav-link" routerLink="/account/login">Login </a>
                 </li> -->
                 <!-- <li class="nav-item align-items-center d-md-flex d-lg-none" [ngClass]="{'active': currentPage === '/account/profile'}">
                   <div class="dropdown ">
                       <a type="button" id="dropdownMenu3" class="pb-0" data-toggle="dropdown" aria-expanded="false">
                         {{user.firstName}}
                       </a>
                       <ul class="dropdown-menu dropdown-menu-end animate slideIn start-auto end-0" aria-labelledby="dropdownMenu3">
                           <a class="dropdown-item px-3" routerLink="/account/profile">Profile settings</a>
                           <a class="dropdown-item px-3" href="/logout" (click)="socialSignOut()">Logout</a>
                       </ul>
                   </div>
                </li> -->
               </ul>
           </div>
       </div>
   </nav>
</div>
<!-- <div class="nav_top nav_bar_expnd" [ngClass]="{'bg_blue':enableHeaderClass}">
   <nav class="navbar navbar-expand-lg">
      <a class="navbar-brand logo" routerLink="/"><img src="assets/img/logo.svg" /></a>
      <ng-container *ngIf="enableHeaderClass">
         <a id="btnToggle" class="navbar-brand pl-6 toggle_icon" href="#toggle_icon" data-toggle="collapse" role="button"
            aria-expanded="false" aria-controls="toggle_icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="23" fill="currentColor" class="bi bi-list"
               viewBox="0 0 16 16">
               <path fill-rule="evenodd"
                  d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
            </svg><span class="d-none d-md-inline-block">Browse</span></a>
         <div id="toggle_icon" class="browse_items collapse">
            <div id="topDropdownWrapper" class="d-flex flex-wrap justify-content-between">
               <div class="item_cntnt" *ngFor="let item of resourcesList">
                  <a routerLink="/resources/{{item.slug}}"><img [src]=" item.icon?.path || 'assets/img/efx.svg'"></a>
                  <div>
                     <a routerLink="/resources/{{item.slug}}">
                        <h5>{{item.title}}</h5>
                        <p>{{item.description}}</p>
                     </a>
                  </div>
               </div>
            </div>
         </div>
      </ng-container>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
         <div class="menu_container">
           <div class="bar1"></div>
           <div class="bar2"></div>
           <div class="bar3"></div>
         </div>
      </button>
      <div class="collapse navbar-collapse flex-grow-1 flex-basis-100" id="navbarNav">
         <div class="navbar-nav ml-auto flex_column align-items-center">
           <a class="nav-item nav-link pr-5" routerLink="/">
             <span>About Us</span></a>
           <a class="nav-item nav-link pr-5" routerLink="/contact-us">
            <span>Contact</span></a>
             <a class="nav-item nav-link pr-5" href="#">
               <span>Partners</span></a>
           <a class="nav-item nav-link green" routerLink="/account/login" *ngIf="!user._id">
             <span>Login</span></a>
             <div class="navbar-collapse" *ngIf="user._id">
               <div class="navbar-nav ml-auto align-items-center position-relative">
                  <div class="notification-wrapper">
                     <div class="drp_dwn cursor-pointer">
                        <i class="fa fa-bell"></i>
                        <div class="count" *ngIf="notificationsList.length > 0 && notificationsList.length <10">{{notificationsList.length}}</div>
                        <div class="count" *ngIf="notificationsList.length >= 10">9+</div>
                     </div>
                     <div class="drp_dwn_contnt d_none">
                        <ul>
                           <li class="row" *ngFor="let item of notificationsList">
                              <div class="col-md-2">
                                 <ng-container *ngIf="!item.senderData.profilePicture">
                                    <div class="user">{{item.senderData.firstName| slice:0:2}}</div>
                                 </ng-container>
                                 <ng-container *ngIf="item.senderData.profilePicture">
                                    <div class="user-image">
                                       <img [src]="item.senderData.profilePicture?.thumbPath">
                                    </div>
                                 </ng-container>
                              </div>
                              <div class="col-md-10">
                                 <span class="user-name">New message from {{item.senderData.firstName}} {{item.senderData.lastName}} on</span>
                                 <span class="section"> 
                                    <a 
                                       routerLink="/resources/{{item.resourceData.slug}}/{{item.chapterData.slug}}" 
                                       [queryParams]="{tab:'tab-communication', section: item.sectionData.slug, subtab:item.senderData.userType === 'Tutor' ? 'Tutor' : 'Groups'  }"
                                    > {{item.sectionData.title}} </a> </span>
                                 <span class="time">{{item.updatedAt | dateAgo}}</span>
                              </div>
                           </li>
                           <li *ngIf="notificationsList.length === 0">
                              <label class="col text-center">No new messages found!</label>
                           </li>
                        </ul>
                     </div>
                  </div>
                 <a class="nav-item nav-link p_adjest" href="javascript:void(0)">
                   <span class="w_logo"><img [src]="organization.logo?.thumbPath"></span></a>
                  <ng-container>
                     <a class="nav-item nav-link drp_dwn cursor-pointer">
                     <ng-container *ngIf="!photoUrl">
                        <span class="w_prof border-0" *ngIf="user.profilePicture?.thumbPath"><img [src]="user.profilePicture?.thumbPath"></span>
                        <span class="no-image-sm" *ngIf="!user.profilePicture?.thumbPath">{{user.firstName| slice:0:1}}</span>
                     </ng-container>
                     <ng-container *ngIf="photoUrl">
                        <span class="w_prof border-0" *ngIf="photoUrl"><img [src]="photoUrl"></span>
                        <span class="no-image-sm" *ngIf="!photoUrl">{{user.firstName| slice:0:1}}</span>
                     </ng-container>
                  </a>
                  <div class="drp_dwn_contnt log_out_menu d_none">
                     <h6><a routerLink="/account/profile">Profile settings</a></h6>
                     <h6><a href="/logout" (click)="socialSignOut()">Logout</a></h6>
                  </div>
                  </ng-container>
               </div>
             </div>
         </div>
      </div>
   </nav>
</div> -->
