import { Component, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { SectionService } from '../../../services/section.service';
import { OrganizationDetails } from '../../../model/organization.model';
import { SectionErrorData, SectionList } from '../../../model/section.model';
import { UserDetails } from '../../../model/user.model';
import { MessageAlert } from '../../../utilities/message.alert';
import { Resource } from '../../../model/resource.model';
import { Chapter } from '../../../model/chapter.model';

@Component({
  selector: 'app-def-related-course',
  templateUrl: './def-related-course.component.html',
  styleUrls: ['./def-related-course.component.scss']
})
export class DefRelatedCourseComponent implements OnInit, OnChanges {
  @Input() resource = new Resource();
  @Input() chapter = new Chapter();
  @Input() sections: SectionList[] = [];
  @Input() tutor = new UserDetails();
  @Input() activeSection = '';
  @Input() organization = new OrganizationDetails();
  @Output() emitSlug: EventEmitter<any> = new EventEmitter();
  section = new SectionList();
  constructor(
    private alert: MessageAlert,
    private _sectionService: SectionService
  ) {
  }

  ngOnInit(): void {
  }
  ngOnChanges(): void {
    this.sections = this.sections.filter((x) => x.showRelatedCourse === true);
    if (this.sections.length > 0) {
      if (this.activeSection) {
        this.section = this.sections.find(x => x.slug === this.activeSection) || this.sections[0];
      } else {
        this.section = this.sections[0];
      }
      this.activeSection = this.section.slug;
      this.getRelatedCourseDetails();
    }
  }
  getRelatedCourseDetails() {
    this._sectionService.relatedCourseDetails(this.section._id).subscribe((res: any) => {
      this.section.contentRelatedCourse = res.data?.content || '';
    }, (err) => {
      const error: SectionErrorData = err;
      this.alert.error(error.message);
    });
  }
  selectSection(section: SectionList) {
    this.section = section;
    this.activeSection = this.section.slug;
    this.handleSection();
  }
  handleSection() {
    this.emitSlug.emit(this.activeSection);
  }
}
