<section class="bg-2 pb-0" *ngIf="this.resourceList.length>0">
    <div class="">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <!-- <img src="assets/img/owl.svg" alt=""> -->
                <div class="d-flex flex-wrap certificate-course row p-4 p-sm-5 pt-0">
                    <ng-container *ngFor="let item of this.resourceList; let odd = odd">
                        <ul class="col-lg-4 lists mb-3" *ngIf="item.classificationsList.length>0">
                            <h2 class="pt-2">
                                <a class="black" routerLink="/resources/{{item.slug}}">{{item.title}} </a>
                            </h2>
                            <!-- <a class="cursor-pointer"> > Learn More</a> -->
                            <li *ngFor="let c of item.classificationsList">
                                <a [ngClass]="{'orange': c.certificateEnabled, 'black': !c.certificateEnabled}" routerLink="/resources/{{c.slug}}">{{c.title}} </a>
                                <!-- <img src="assets/img/tick-green.png" *ngIf="c.certificateEnabled">
                                <img src="assets/img/cross-red.png" *ngIf="!c.certificateEnabled"> -->
                                <!-- <button 
                                    mat-raised-button
                                    matTooltip="Exam and Certificate offering"
                                    matTooltipPosition="right"
                                    class="mat-tooltip-custom"
                                    *ngIf="c.certificateEnabled"
                                >
                                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                                </button> -->
                            </li>
                        </ul>
                        <!-- <div class="divider col-lg-12 mt-2 mb-2" *ngIf="odd"></div> -->
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</section>