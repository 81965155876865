
 
    <div class="topsec h-auto">
        <div class="sub-banner banner6">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-7 col-xl-5">
                        <h2 class="under-line-sub">FinStream for<br> individual users</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section>
        <div class="container pt-4 pt-lg-5">
            <div class="row justify-content-center px-4 px-lg-0">
                <div class="col-lg-10 py-2 py-lg-4">
                    <p class="pb-4">
                        Are you a postgraduate candidate? New to the financial industry profession? FinStream could be
                        the perfect way to bridge the gap between your postgraduate education and a successful career in
                        finance.
                    </p>
                    <p class="pb-5">We deliver online financial learning to individual users including postgraduate
                        candidates and those new to the professional finance industry. At FinStream, we took note of the
                        lack of practical application in finance courses at the university level and wanted to provide a
                        more interactive approach to key industry concepts.</p>
                </div>
            </div>
        </div>
    </section>
    <!-- <section class="video-sec">
        <div class="container">
            <div class="row justify-content-center px-4 px-lg-0">
                <div class="col-lg-10">
                    <video controls width="100%" poster="images/video-thumb.png">
                        <source src="assets/img/video.mp4" type="video/mp4">
                    </video>
                </div>
            </div>
        </div>
    </section> -->
    <section class="course-content individual-bg">
        <div class="container">
            <div class="row justify-content-end px-4 px-lg-0">
                <div class="col-lg-8">
                    <h2 class="py-2 under-line">FinStream offers accelerated<br> learning for individuals who are:</h2>
                    <div class="d-flex flex-wrap">
                        <ul class="lists">
                            <li>Learning within an existing course.</li>
                            <li>Looking to transition to the finance industry.</li>
                            <li>Considering changing roles within the industry.</li>
                        </ul>
                    </div>
                    <p class="pt-4">Our platform aims to clarify important finance concepts through concise theory. We
                        also provide
                        an interactive section that offers guidelines to facilitate the theory content.</p>
                    <div class="pt-0 pt-lg-2">
                        <a class="primaryButton" routerLink="/what-is-finstream">Learn more about FinStream’s Platform
                            here</a>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="midwrp">
        <div class="container">
            <div class="row p-4 p-sm-5 justify-content-center">
                <div class="col-lg-12">
                    <div class="row justify-content-center">
                        <div class="card p-0 mt-0 card-iconwrp">
                            <div class="card__header">
                                <h3>The Streams we currently offer include:</h3>
                            </div>
                            <div class="card__body">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="icon-item">
                                            <img src="assets/img/icon10.svg" alt="">
                                            <h5><a routerLink="/">Financial Derivatives <img
                                                        src="assets/img/right.svg" alt=""></a></h5>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="icon-item">
                                            <img src="assets/img/icon11.svg" alt="">
                                            <h5><a routerLink="/">FX and International Finance <img
                                                        src="assets/img/right.svg" alt=""></a></h5>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="icon-item">
                                            <img src="assets/img/icon12.svg" alt="">
                                            <h5><a routerLink="/">Portfolio Management <img
                                                        src="assets/img/right.svg" alt=""></a>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-9 mt-5">
                            <p class="pt-lg-4">Our finance library contains theoretical topics at MBA, CFA and Master’s
                                levels along with more practical industry-related topics within the finance
                                sector.</p>
                            <div class="text-center pt-lg-4">
                                <a href="" class="secondary-btn">Get Started Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section>
        <div class="container py-4 py-lg-5">
            <div class="row justify-content-center px-4 px-lg-0">
                <div class="col-lg-9 py-2 py-lg-5">
                    <h2 class="under-line">Course supplementation</h2>
                    <p class="pt-1">
                        If you’re worried about losing touch with the course material due to time pressures, our online
                        learning streams offer additional continual resources. Streams include topics such as FX,
                        Derivatives, Portfolio Theory, and Structured Products to help with your degree.
                    </p>
                    <h2 class="under-line pt-5 pt-lg-6">Skills enhancement</h2>
                    <p class="pt-1">
                        Are you working through an educational course and feel you would like more advanced materials?
                        You can choose several streams to help you prepare for more elevated finance concepts. Advanced
                        topics include mathematical topics and industry-specific skill sets.
                    </p>
                    <p class="pt-4">The streams target specific sectors of the finance industry including:</p>
                    <ul class="stream-lists mt-3">
                        <li>Funds management.</li>
                        <li>FX corporate risk management.</li>
                        <li>Structured products.</li>
                        <li>Commodities.</li>
                    </ul>
                    <h2 class="under-line pt-5 pt-lg-6">Career advancement</h2>
                    <p class="pt-1">
                        Are you a current finance professional looking to broaden your expertise? Those in the finance
                        industry can quickly and continually access key concepts for accelerated learning. They can also
                        gain extended knowledge to make a smooth transition within the industry.
                    </p>
                    <p class="pt-4"> Current professionals can benefit from targeted areas of the finance industry including:</p>
                    <ul class="stream-lists mt-3">
                        <li>Portfolio theory concepts.</li>
                        <li>FX and international finance.</li>
                        <li>Structured products analysis.</li>
                        <li>Derivative Theory.</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <div class="getin-touch">
        <div class="container-fluid">
            <div class="row p-4">
                <div class="col-lg-5">
                    <h5>If you’re looking to gain a deeper understanding of concepts that help build success in finance,
                        FinStream was created for you.</h5>
                    <ul>
                        <li><a routerLink="/contact">Contact us to learn more <img src="assets/img/right.svg" alt=""></a></li>
                        <li><a href="javascript:void(0)">Register for free to get started <img src="assets/img/right.svg" alt=""></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>