import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageNotificationComponent } from '../common/templates/message-notification/message-notification.component';

@Injectable()
export class MessageAlert {
    constructor(
        private snackBar: MatSnackBar
    ) { }

    success(message: string) {
        this.snackBar.open(message, 'DISMISS', { panelClass: 'snackbar-success' });
    }
    warning(message: string) {
        this.snackBar.open(message, 'DISMISS', { panelClass: 'snackbar-warning' });
    }
    error(message: string) {
        this.snackBar.open(message, 'DISMISS', { panelClass: 'snackbar-error' });
    }
    messageNotification(data: any) {
        data.preClose = () => { this.snackBar.dismiss(); };
        this.snackBar.openFromComponent(MessageNotificationComponent, {
            data,
            panelClass: 'snackbar-success',
            duration: 1500000,
            verticalPosition: 'bottom',
            horizontalPosition: 'end'
        });
    }
}
