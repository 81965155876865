import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { GroupSuccessData, CreateGroup } from '../model/group.model';
import { Note, NoteSuccessData } from '../model/note.model';
import { CommunicationSuccessData, CommunicationDetailsSuccessData, CreateMessage, CommunicationNotification, CommunicationNotificationSuccessData } from '../model/communication.model';
import { environment } from '../../environments/environment';
import { CustomTagSuccessData } from '../model/tag.model';

@Injectable()
export class CommunicationService {
    constructor(public http: HttpClient) { }

    // messages
    messages(
        messageType: string,
        sectionId: string,
        chapterId: string,
        resourceId: string,
        page: number,
        limit: number,
        sortBy: string
    ): Observable<HttpResponse<CommunicationSuccessData>> {
        return this.http.get<CommunicationSuccessData>(environment.API_END_POINT + '/communication?messageType=' + messageType +
            '&sectionId=' + sectionId + '&chapterId=' + chapterId + '&resourceId=' + resourceId +
            '&page=' + page + '&limit=' + limit + '&sortBy=' + sortBy, { observe: 'response' });
    }
    messageDetails(id: string): Observable<HttpResponse<CommunicationDetailsSuccessData>> {
        return this.http.get<CommunicationDetailsSuccessData>(environment.API_END_POINT + '/communication/' + id, { observe: 'response' });
    }
    messageCreate(data: CreateMessage) {
        return this.http.post<any>(environment.API_END_POINT + '/communication', data);
    }
    messageUpdate(id: string, data: CreateMessage) {
        return this.http.put<any>(environment.API_END_POINT + '/communication/' + id, data);
    }
    messageDelete(id: string, messageId: string, messageType: string) {
        return this.http.delete(environment.API_END_POINT + '/communication/' + id + '?messageType=' + messageType + '&messageId=' + messageId);
    }

    messageThreads(
        senderId: string,
        receiverId: string,
        sectionId: string,
        chapterId: string,
        resourceId: string): Observable<HttpResponse<CommunicationDetailsSuccessData>> {
        return this.http.get<CommunicationDetailsSuccessData>(environment.API_END_POINT + '/communication/thread/details?senderId=' + senderId +
            '&receiverId=' + receiverId + '&sectionId=' + sectionId + '&chapterId=' + chapterId + '&resourceId=' + resourceId, { observe: 'response' });
    }
    deleteThread(id: string) {
        return this.http.delete(environment.API_END_POINT + '/communication/thread/' + id);
    }
    exitGroup(id: string, userId: string) {
        return this.http.delete(environment.API_END_POINT + '/communication/thread/' + id + '/member/' + userId);
    }

    // notification list
    messageNotificationsList() {
        return this.http.get<CommunicationNotificationSuccessData>(environment.API_END_POINT + '/communication/notifications/list', { observe: 'response' });
    }

    // group
    // searchGroups(
    //     keywords: string,
    //     status: string,
    //     globalSearch: boolean,
    //     page: number,
    //     limit: number,
    //     sortBy: string
    // ): Observable<HttpResponse<GroupSearchSuccessData>> {
    //     return this.http.get<GroupSearchSuccessData>(environment.API_END_POINT + '/communication-group?title=' + keywords +
    //         '&globalSearch=' + globalSearch + '&status=' + status + '&page=' + page + '&limit=' + limit + '&sortBy=' + sortBy, { observe: 'response' });
    // }
    getGroupDetails(id: string): Observable<HttpResponse<GroupSuccessData>> {
        return this.http.get<GroupSuccessData>(environment.API_END_POINT + '/communication-group/' + id, { observe: 'response' });
    }
    createGroup(data: CreateGroup): Observable<GroupSuccessData> {
        return this.http.post<any>(environment.API_END_POINT + '/communication-group', data);
    }
    updateGroup(id: string, data: CreateGroup): Observable<GroupSuccessData> {
        return this.http.put<any>(environment.API_END_POINT + '/communication-group/' + id, data);
    }
    deleteGroup(id: string) {
        return this.http.delete(environment.API_END_POINT + '/communication-group/' + id);
    }

    // notes
    searchNotes(
        keywords: string,
        resourceId: string,
        chapterId: string,
        sectionId: string,
        tagId: string,
        page: number,
        limit: number,
        sortBy: string
    ): Observable<HttpResponse<NoteSuccessData>> {
        return this.http.get<NoteSuccessData>(environment.API_END_POINT + '/communication-note?title=' + keywords +
            '&resourceId=' + resourceId + '&chapterId=' + chapterId + '&sectionId=' + sectionId + '&tagId=' + tagId +
            '&page=' + page + '&limit=' + limit + '&sortBy=' + sortBy, { observe: 'response' });
    }
    getNoteDetails(id: string): Observable<HttpResponse<Note>> {
        return this.http.get<Note>(environment.API_END_POINT + '/communication-note/' + id, { observe: 'response' });
    }
    createNote(data: Note): Observable<NoteSuccessData> {
        return this.http.post<any>(environment.API_END_POINT + '/communication-note', data);
    }
    updateNote(id: string, data: Note): Observable<NoteSuccessData> {
        return this.http.put<any>(environment.API_END_POINT + '/communication-note/' + id, data);
    }
    deleteNote(id: string) {
        return this.http.delete(environment.API_END_POINT + '/communication-note/' + id);
    }

    // tags
    searchTags(
        keywords: string,
        page: number,
        limit: number,
        sortBy: string
    ): Observable<HttpResponse<CustomTagSuccessData>> {
        return this.http.get<CustomTagSuccessData>(environment.API_END_POINT + '/communication-tag?title=' + keywords +
            '&page=' + page + '&limit=' + limit + '&sortBy=' + sortBy, { observe: 'response' });
    }
}
