import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthUser } from 'src/app/model/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { Constants } from 'src/app/utilities/constants';
import { MessageNotification } from '../../../model/dialog.model';

@Component({
  selector: 'app-message-notification',
  templateUrl: './message-notification.component.html',
  styleUrls: ['./message-notification.component.scss']
})
export class MessageNotificationComponent implements OnInit {
  authUser = new AuthUser();
  constructor(
    private router: Router,
    private _authService: AuthService,
    @Inject(MAT_SNACK_BAR_DATA) public data: MessageNotification,
    @Inject(PLATFORM_ID) private platform: object
  ) { }

  ngOnInit(): void {
    this.authUser = this._authService.authUser();
  }
  onNavigate(data: MessageNotification) {
    let subTab = '';
    if (data.messageType === Constants.messageType.STUDENT || data.messageType === Constants.messageType.INDIVIDUAL) {
      subTab = 'Groups';
    } else if (data.messageType === Constants.messageType.TUTOR) {
      if (this.authUser.userType === Constants.userTypes.TUTOR) {
        subTab = 'Groups';
      } else {
        subTab = 'Tutor';
      }
    }
    const url = `/resources/${data.resourceSlug}/${data.chapterSlug}?tab=tab-communication&section=${data.sectionSlug}&subtab=${subTab}&thread=${data.threadId}`;
    // this.router.navigate([url]);
    if (isPlatformBrowser(this.platform)) {
      window.open(url, '_blank');
    }
    this.dismiss();
  }
  dismiss() {
    this.data.preClose(); // access preClose function when you want to close snackbar
  }
}
