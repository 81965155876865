export class OrganizationDetails {
    _id = '';
    title = '';
    contactEmail = '';
    webAddress = '';
    logo = {
        filename: '',
        folder: '',
        originalName: '',
        path: '',
        thumbFilename: '',
        thumbFolder: '',
        thumbPath: ''
    };
    logoSecondary = {
        filename: '',
        folder: '',
        originalName: '',
        path: '',
        thumbFilename: '',
        thumbFolder: '',
        thumbPath: ''
    };
    showBrandingOnWebsite = false;
    address = '';
    street = '';
    city = '';
    state = '';
    country = '';
    postcode = '';
}
export class OrganizationSuccessData {
    message = '';
    status = '';
    data = new OrganizationDetails();
}
export interface OrganizationErrorData {
    message: string;
    status: string;
    data: any;
}