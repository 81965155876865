<div class="wrapper">
  <!-- Sidebar  -->
  <nav id="sidebar" class="sml_slide">

    <ul class="list-unstyled subscription_">
      <li class="active">
        <a href="">
          <p><img class="align-middle" src="assets/img/user.svg"> Personal info</p>
        </a>
      </li>
      <li *ngIf="userData.userType!==userTypes.ADMIN && userData.userType!==userTypes.MASTER_ADMIN">
          <a routerLink="/account/profile" [queryParams]="{tab: 'bookmarks'}">
              <h6><img class="align-middle" src="assets/img/bookmark-white.svg"> Bookmarked classes</h6>
          </a>
      </li>
      <li *ngIf="userData.userType === userTypes.INDIVIDUAL">
          <a routerLink="/account/profile" [queryParams]="{tab: 'my-orders'}">
              <h6><img class="align-middle" src="assets/img/bookmark-white.svg"> My Subscriptions</h6>
          </a>
      </li>
    </ul>
  </nav>

  <!-- Page Content  -->
  <div id="content" class="content_wrp">
    <div class="container-fluid">
      <div class="row hidden_md">
        <div class="col-md-12 text-right mob_top_align">
          <button type="button" id="sidebarCollapse" class="btn btn-info hidden_md">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-text-left"
              viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M2 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <section class="resource_home">
      <div class="container-fluid pdngLR_10 pt_15">
        <div class="row">
          <div class="col-lg-12">
            <div class="white_wrpper">
              <div class="row">
                <div class="col-lg-7">
                  <h3><a routerLink="/account/profile"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                        fill="#232323" class="bi bi-arrow-left" viewBox="0 3 12 12">
                        <path fill-rule="evenodd"
                          d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z">
                        </path>
                      </svg></a> Edit info</h3>
                  <div class="picture-container mb-5">
                    <div class="picture">
                      <ng-container *ngIf="userData?.profilePicture?.path && !image.src">
                        <img [src]="userData?.profilePicture?.path" class="picture-src" height="auto;" width="auto;"
                          id="wizardPicturePreview" title="">
                      </ng-container>
                      <img *ngIf="!image.src && !userData?.profilePicture?.path" src="assets/img/prof.svg"
                        class="picture-src" id="wizardPicturePreview" title="">
                      <img *ngIf="image.src" [src]="image.src" class="picture-src" height="auto;" width="auto;"
                        id="wizardPicturePreview" title="">
                      <input type="file" #file id="wizard-picture" class="" accept='image/*'
                        (change)="onFileChange($event)">
                      <img src="assets/img/upload.svg" class="upload_icon" (click)="uploadImage()">
                    </div>
                    <small [@inOutAnimation] class="form-text error" [innerHTML]="imageError"></small>
                  </div>
                  <form [formGroup]="formGroup" #form="ngForm" (ngSubmit)="onFormSubmit(form)" autocomplete="off">
                    <div class="form-group">
                      <label>First Name</label>
                      <input type="text" class="form-control" formControlName="firstName" name="firstName">
                      <ng-container
                        *ngIf="formGroup.controls.firstName.invalid && (formGroup.controls.firstName.dirty || formGroup.controls.firstName.touched)">
                        <small [@inOutAnimation] class="form-text error"
                          *ngIf="formGroup.controls.firstName.hasError('required')">
                          Please fill this field
                        </small>
                      </ng-container>
                    </div>
                    <div class="form-group">
                      <label>Last Name</label>
                      <input type="text" class="form-control" formControlName="lastName" name="lastName">
                      <ng-container
                        *ngIf="formGroup.controls.lastName.invalid && (formGroup.controls.lastName.dirty || formGroup.controls.lastName.touched)">
                        <small [@inOutAnimation] class="form-text error"
                          *ngIf="formGroup.controls.lastName.hasError('required')">
                          Please fill this field
                        </small>
                      </ng-container>
                    </div>
                    <div class="form-group">
                      <label>Email Address</label>
                      <input type="email" class="form-control" formControlName="email" name="email">
                      <ng-container
                        *ngIf="formGroup.controls.email.invalid && (formGroup.controls.email.dirty || formGroup.controls.email.touched)">
                        <small [@inOutAnimation] class="form-text error"
                          *ngIf="formGroup.controls.email.hasError('required')">
                          Please fill this field
                        </small>
                        <small [@inOutAnimation] class="form-text error"
                          *ngIf="formGroup.controls.email.hasError('pattern')">
                          Please enter a valid email
                        </small>
                      </ng-container>
                    </div>
                    <div class="form-group">
                      <label>Phone</label>
                      <input type="number" class="form-control" formControlName="phone" name="phone">
                    </div>
                    <div class="form-group">
                      <label>Address</label>
                      <input type="text" class="form-control" formControlName="address" name="address">
                    </div>
                    <div class="form-group">
                      <label>Street</label>
                      <input type="text" class="form-control" formControlName="street" name="street">
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label>Town/City</label>
                        <input type="text" class="form-control inpt_width" formControlName="city" name="city">
                      </div>
                      <div class="form-group col-md-6">
                        <label>State/Regon</label>
                        <input type="text" class="form-control inpt_width ml-auto" formControlName="state" name="state">
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label>Postcode</label>
                        <input type="text" class="form-control inpt_width" formControlName="postcode" name="postcode">
                        <small [@inOutAnimation] class="form-text error" [innerHTML]="errorMessage"></small>
                      </div>
                      <div class="form-group col-md-6">
                        <label for="inputState">Country</label>
                        <select id="inputState" class="form-control inpt_width ml-auto" formControlName="country"
                          name="country">
                          <option [ngValue]="null" disabled>Select-</option>
                          <!-- <option selected>-Select-</option> -->
                          <option *ngFor="let country of countryList" [ngValue]="country.value">
                            {{ country.value }}
                          </option>
                          <!-- <option>Australia</option>
                          <option>Germany</option>
                          <option>UK</option> -->
                        </select>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="text-left col-lg-6 col-md-6  col-sm-6">
                        <a class="common_btn" [ngStyle]="{'pointer-events': btnDisabled ? 'none' : 'auto'}"
                          href="javascript:void(0)" (click)="onFormSubmit(form)">Save</a>
                      </div>
                    </div>
                  </form>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>