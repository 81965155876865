import { Tag } from './tag.model';

export class Note {
    _id = '';
    title = '';
    description = '';
    tags: any = [];
    resourceId = '';
    chapterId = '';
    sectionId = '';
    userId = '';
    createdAt = '';
    updatedAt = '';
    tagsList: Tag[] = [];
}
export class NoteSuccessData {
    message = '';
    status = '';
    data: Note[] = [];
}
export interface NoteErrorData {
    message: string;
    status: string;
    data: any;
}
