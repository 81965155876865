
    <div class="topsec h-auto">
        <div class="sub-banner banner7">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-7 col-xl-6">
                        <h2 class="under-line-sub">FinStream’s foreign<br> exchange and international<br> finance course
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section>
        <div class="container py-4 py-lg-5">
            <div class="row justify-content-center px-4 px-lg-0">
                <div class="col-lg-10 py-2 py-lg-4">
                    <p class="pb-4">
                        A more global understanding of the international financial market is a valuable skill in the
                        industry. As organisations expand and continue to do business with countries around the world,
                        current students and existing professionals can gain a competitive edge if they have in-depth
                        knowledge about international banking and global finance.
                    </p>
                    <p class="pb-5">FinStream offers foreign exchange and
                        international finance courses designed to help individuals and employers study and upskill their
                        financial knowledge. FOREX, forward and option pricing, parity relations, and risk management
                        for importers and exporters and structured FX for investors can be complex concepts, so having a
                        fundamental, rigorous and interactive course is key to succeed in the industry.</p>
                </div>
            </div>
        </div>
    </section>
    <div class="midwrp">
        <div class="container">
            <div class="row p-4 p-sm-5 justify-content-center">
                <div class="col-lg-11">
                    <h2 class="under-line">How our foreign exchange and<br> international trade courses can help you
                    </h2>
                    <p class="pt-2 pb-4">How our foreign exchange and international trade courses can help you</p>
                    <ul class="lists lefticon">
                        <li>Use relevant skills to be able to handle different FX related roles across various
                            departments within an organisation.</li>
                        <li>Apply basic foreign exchange concepts to the foreign exchange market and recognise key
                            institutional features and quotations.</li>
                        <li>Apply basic exchange rate determination models to detect arbitrage opportunities.</li>
                        <li>Explain how currency derivatives like features, forwards, swaps and options can be used to
                            hedge and speculate based on anticipated exchange rates.</li>
                        <li>Make recommendations on the hedging practices of international corporations based on the
                            knowledge and principles of currency derivatives.</li>
                        <li>Obtain the expertise and confidence needed to successfully work for multinational companies.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <section class="course-content bg-2">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-10 pt-lg-5">
                    <img src="assets/img/owl.svg" alt="">
                    <h2 class="pt-2">Course Content</h2>
                    <div class="d-flex flex-wrap">
                        <ul class="lists">
                            <li>FX Basics</li>
                            <li>FX Cross Rates</li>
                            <li>FX Forwards</li>
                            <li>FX Forward Hedging</li>
                            <li>FX Option Hedging</li>
                            <li>FX Option Pricing</li>
                            <li>FX Option Volatility</li>
                            <li>FX Risk Management</li>
                        </ul>
                        <ul class="lists">
                            <li>FX Structured:Hedging</li>
                            <li>FX Structured:Investors</li>
                            <li>FX Swaps</li>
                            <li>Interest Rate Parity</li>
                            <li>Real Interest Rate Parity</li>
                            <li>Purchasing Power Parity(PPP)</li>
                            <li>Relative Purchasing Power Parity(RPPP)</li>
                        </ul>
                    </div>
                    <div class="pt-0 pt-lg-3 pb-4 pb-lg-5">
                        <a class="primaryButton cursor-pointer" (click)="exploreApp()">Explore our library - and register for free!</a>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="midwrp">
        <div class="container">
            <div class="row p-4 p-sm-5 justify-content-center">
                <div class="col-lg-11">
                    <h2 class="under-line">Who’s the forex and international<br> finance course for?</h2>
                    <p class="pt-2">The theoretical aspect of our foreign exchange and international finance course
                        resembles the standard Masters and CFA derivative market content, but also includes additional
                        online course content to help bridge the transition to the financial sector.</p>
                    <p class="pt-4">FinStream’s foreign exchange and international finance courses are best suited for:
                    </p>
                    <div class="row justify-content-between">

                        <div class="card w-half p-0">
                            <div class="card__header dark-bg">
                                <h3>Professionals</h3>
                            </div>
                            <div class="card__body">
                                <ul class="lists pe-0">
                                    <li>
                                        <p>Institutional Traders</p>
                                    </li>
                                    <li>
                                        <p>Investment Advisors</p>
                                    </li>
                                    <li>
                                        <p>Risk Management Professionals</p>
                                    </li>
                                    <li>
                                        <p>Derivative Structuring Corporate Officers</p>
                                    </li>
                                    <li>
                                        <p>Corporate Finance Specialists/Analysts</p>
                                    </li>
                                    <li>
                                        <p>International Portfolio Managers</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="card w-half p-0">
                            <div class="card__header dark-bg">
                                <h3>Students</h3>
                            </div>
                            <div class="card__body">
                                <ul class="lists pe-0">
                                    <li>
                                        <p>Masters of Finance Students</p>
                                    </li>
                                    <li>
                                        <p>CFA Students</p>
                                    </li>
                                    <li>
                                        <p>Students looking to enhance their financial knowledge</p>
                                    </li>
                                    <li>
                                        <p>Students that need help passing a finance course</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="btm-wrp pb-4 pb-lg-5">
        <div class="container">
            <div class="row p-4 justify-content-center">
                <div class="col-lg-11">
                    <div class="icon-wrp">
                        <img src="assets/img/icon9.svg" alt="">
                        <h2 class="pt-3">Certification</h2>
                        <p clas="mt-2"> Increase your prospects in the financial job market with a FinStream
                            certification, that showcases your new-found theoretical proficiency in foreign exchange and
                            international finance.</p>
                    </div>
                    <div class="go-link mt-4">
                        <a class="primaryButton" routerLink="/certification">Learn more about FinStream’s
                            certifications</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="other-streams">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <img class="w-100" src="assets/img/play-img.png" alt="">
                    </div>
                    <div class="col-lg-6 ps-lg-5">
                        <h2>Interested in learning more about financial topics?</h2>
                        <h2>Explore our other streams.</h2>
                        <p class="pt-4 pb-3"><a routerLink="/streams/financial-derivatives">Financial Derivatives <img
                                    src="assets/img/right.svg" alt=""></a> </p>
                        <p><a routerLink="/streams/portfolio-management">Portfolio Management <img src="assets/img/right.svg" alt=""></a> </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="getin-touch">
        <div class="container-fluid">
            <div class="row p-4">
                <div class="col-lg-5">
                    <h5>Start your financial derivative education with Finstream today.</h5>
                    <a class="secondary-btn" routerLink="contact">Contact Us</a>
                </div>
            </div>
        </div>
    </div>