import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../environments/environment';
import { SharedService } from './services/shared.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { AuthUser } from './model/user.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'efin-website';
  authUser = new AuthUser();
  constructor(
    private router: Router,
    private sharedService: SharedService,
    private googleAnalyticsService: GoogleAnalyticsService,
    @Inject(PLATFORM_ID) private platform: object
    ) {}
  ngOnInit(): void {
    this.sharedService.user.subscribe((res: any) => {
      this.authUser = res;
    });
    setTimeout(() => {
      if (isPlatformBrowser(this.platform)) {
        this.router.events.subscribe(event => {
          if (event instanceof NavigationEnd) {
            this.googleAnalyticsService.sendEvent('page_view', {
              page_path: event.urlAfterRedirects,
              userId: this.authUser._id,
              organizationId: this.authUser.organizationId,
              userType: this.authUser.userType
            });
          }
        });
        this.addGAScript();
      }
    }, 1000);
  }
  addGAScript() {
    const gtagScript: HTMLScriptElement = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.GA_TRACKING_ID;
    document.head.prepend(gtagScript);
    /** Disable automatic page view hit to fix duplicate page view count  **/
    this.googleAnalyticsService.addTrackingId(environment.GA_TRACKING_ID, { send_page_view: false });
  }
}
