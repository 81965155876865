import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/model/user.model';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {
  user = new User();
  sub: any = {};
  constructor(
    private router: Router,
    private _sharedService: SharedService) { }

  ngOnInit(): void {
    this.sub.user = this._sharedService.user.subscribe((res: any) => {
      this.user = res;
    });
  }
  exploreApp() {
    if (this.user._id) {
      this.router.navigate(['/resources']);
    } else {
      this.router.navigate(['/account/login']);
    }
  }
  ngOnDestroy() {
    if (this.sub.user) {
      this.sub.user.unsubscribe();
    }
  }

}
