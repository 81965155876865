import { Injectable, Inject, PLATFORM_ID, Optional } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens';
import { Request, Response } from 'express';
import jwt_decode from 'jwt-decode';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { EncryptionService } from './encryption.service';
import { User } from '../model/user.model';
import { Constants } from '../utilities/constants';

@Injectable()
export class AuthService {
    constructor(
        public http: HttpClient,
        private cookieService: CookieService,
        private encryptionService: EncryptionService,
        @Optional() @Inject(REQUEST) private req: Request<any>,
        @Optional() @Inject(RESPONSE) private res: Response<any>,
        @Inject(PLATFORM_ID) private platform: object
    ) { }

    public getToken(): any {
        if (this.req !== null && this.req.cookies) {
            return this.encryptionService.decryptData(this.req.cookies.token) || '';
        } else if (isPlatformBrowser(this.platform)) {
            return this.encryptionService.decryptData(this.cookieService.get(Constants.Cookies.TOKEN) || '');
        }
    }
    public setToken(token: string) {
        const encToken = this.encryptionService.encryptData(token);
        this.cookieService.set(Constants.Cookies.TOKEN, encToken, 356, '/');
    }
    public setCookie(key: string, value: string) {
        this.cookieService.set(key, value, 356, '/');
    }
    public getCookie(key: string) {
        return this.cookieService.get(key);
    }
    public removeCookie(key: string) {
        this.cookieService.delete(key);
    }
    public isAuthenticated(): boolean {
        // get the token
        const token = this.getToken();
        // return a boolean reflecting
        // whether or not the token is expired
        // return tokenNotExpired(null, token);
        if (!token) {
            return false;
        }
        const decoded: any = jwt_decode(token);
        if (decoded._id) {
            return true;
        } else {
            return false;
        }
    }
    public authUser(): any {
        const token = this.getToken();
        if (!token) {
            return null;
        }
        return jwt_decode(token);
    }
    getTokenExpirationDate(token: string): any {
        const decoded: any = jwt_decode(token);

        if (decoded.exp === undefined) return null;

        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        return date;
    }

    isTokenExpired(token?: any): boolean {
        if (!token) token = this.getToken();
        if (!token) return true;

        const date = this.getTokenExpirationDate(token);
        if (date === undefined) return false;
        return !(date.valueOf() > new Date().valueOf());
    }

    register(data: User): Observable<any> {
        return this.http.post(environment.API_END_POINT + '/auth/register', data);
    }
    verifyAccount(key: string) {
        return this.http.put(environment.API_END_POINT + '/auth/verify-account', { key }).pipe(share());
    }
    login(email: string, password: string, ip: string) {
        return this.http.post(environment.API_END_POINT + '/auth/login', {
            email,
            password
        },
            {
                headers: new HttpHeaders({
                    'client-ip': ip
                })
            });
    }
    regenerateToken(token: string) {
        return this.http.post(environment.API_END_POINT + '/auth/regenerate-token', { token });
    }
    currentUser() {
        return this.http.get(environment.API_END_POINT + '/auth/current-user');
    }
    updatePassword(oldPassword: string, newPassword: string, userId: string) {
        return this.http.post(environment.API_END_POINT + '/auth/change-password', {
            oldPassword,
            newPassword,
            userId
        });
    }
    forgotPassword(email: string) {
        return this.http.post(environment.API_END_POINT + '/auth/forgot-password', { email });
    }
    validateForgotPasswordLink(key: string) {
        return this.http.get(environment.API_END_POINT + '/auth/forgot-password?key=' + key);
    }
    resetPassword(key: string, password: string) {
        return this.http.post(environment.API_END_POINT + '/auth/reset-password', { key, password });
    }

    getIp() {
        return this.http.get('https://api.ipify.org/?format=json');
    }
    verifyLink(key: string) {
        return this.http.get(environment.API_END_POINT + '/auth/invite-user?key=' + key);
    }
    changePassword(key: string, password: string) {
        return this.http.post(environment.API_END_POINT + '/auth/invite-user', { key, password });
    }
    socialSignUp(data: any) {
        return this.http.post(environment.API_END_POINT + '/auth/socialLogin', data);
    }
}
