<footer>
    <div class="container">
        <div class="row pb-4">
            <div class="col-lg-3 mb-4 mb-lg-0">
                <ng-container *ngIf="!organization.showBrandingOnWebsite">
                    <img src="assets/img/ftr-logo.svg">
                    <ul class="social-icons">
                        <li><a href="https://www.linkedin.com/company/finstreamsydney/about/" target="_blank"><img src="assets/img/linkedin.svg"></a></li>
                        <li><a href="javascript:void(0)"><img src="assets/img/fb.svg"></a></li>
                        <li><a href="javascript:void(0)"><img src="assets/img/twitter.svg"></a></li>
                        <li><a href="javascript:void(0)"><img src="assets/img/insta.svg"></a></li>
                        <li><a href="javascript:void(0)"><img src="assets/img/youtube.svg"></a></li>
                    </ul>
                </ng-container>
                <ng-container *ngIf="organization.showBrandingOnWebsite">
                    <img [src]="organization.logoSecondary.path" style="max-width: 180px; max-height: 84px;">
                </ng-container>
            </div>
            <div class="col-lg-3 mb-4 mb-lg-0">
                <ng-container *ngIf="!organization.showBrandingOnWebsite">
                    <h5>CONTACT</h5>
                    <h6>info@finstream.com</h6>
                    <h6>www.finstream.com</h6>
                </ng-container>
                <ng-container *ngIf="organization.showBrandingOnWebsite">
                    <h5>CONTACT</h5>
                    <h6>{{organization.title}}</h6>
                    <h6>{{organization.contactEmail}}</h6>
                    <h6>{{organization.webAddress}}</h6>
                </ng-container>
            </div>
            <div class="col-lg-3">
                <ng-container *ngIf="!organization.showBrandingOnWebsite">
                    <h5>ADDRESS</h5>
                    <h6>Level 4<br> 75 Pitt Street<br> Sydney, 2000 Australia</h6>
                </ng-container>
                <ng-container *ngIf="organization.showBrandingOnWebsite">
                    <h5>ADDRESS</h5>
                    <h6>{{organization.address}}<br> {{organization.street}}<br> {{organization.city}} {{organization.state}}, {{organization.postcode}} {{organization.country}}</h6>
                </ng-container>
            </div>
        </div>
        <div class="row pt-3 pt-lg-5">
            <div class="col-lg-12 text-center" *ngIf="!organization.showBrandingOnWebsite">
                <p>Copyright© FinStream 2024 | <a routerLink="/privacy-policy">Privacy Policy</a> | <a routerLink="/terms-conditions">Terms of Use</a> | <a
                    routerLink="/cookie-policy">Cookie Notice</a></p>
            </div>
        </div>
    </div>
</footer>
<!-- <footer>
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-4">
                <img class="logo" src="assets/img/ftr-logo.svg">
                <ul class="social mt-4">
                  <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                  <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                  <li><a href="#"><i class="fas fa-paper-plane"></i></a></li>
                  <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                  <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                  <li><a href="#"><i class="fab fa-youtube"></i></a></li>
              </ul>
            </div>
            <div class="col-lg-4 col-md-4">
                <h5>Contact</h5>
                <ul class="links">
                    <li><a href= "mailto: Info@finstream.com">Info@finstream.com</a></li>
                    <li><a href="www.finstream.com" target="_blank">www.finstream.com</a></li>
                </ul>
            </div>
            <div class="col-lg-4 col-md-4">
              <h5>Address</h5>
                <p>1 Macquarie St<br>
                  Sydney, 2000, New South Wales, <br>
                  Australia</p>
            </div>
        </div>
    </div>
</footer>
<section class="sub_ftr">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-8 col-md-6 col-sm-6">
                <p>Copyright {{currentYear}}. eFin Pty Ltd</p>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <p><a href="#">Terms & Conditions</a> | <a href="#">Cookies Policy</a></p>
            </div>
        </div>
    </div>
</section> -->
