import { Component, Inject, Input, OnInit, OnChanges, PLATFORM_ID } from '@angular/core';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { FormControl, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Animation } from '../../../../utilities/animation';
import { Utility } from '../../../../utilities/utility';
import { MessageAlert } from '../../../../utilities/message.alert';
import { AuthService } from '../../../../services/auth.service';
import { SharedService } from '../../../../services/shared.service';
import { CommunicationService } from '../../../../services/communication.service';
import { UserService } from '../../../../services/user.service';
import { Note, NoteSuccessData } from '../../../../model/note.model';
import { Section } from '../../../../model/section.model';
import { Resource } from '../../../../model/resource.model';
import { Chapter } from '../../../../model/chapter.model';
import { CustomTag, CustomTagSuccessData } from '../../../../model/tag.model';
import { DialogModel } from '../../../../model/dialog.model';
import { ConfirmDialogComponent } from '../../../../common/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-tab-notes',
  templateUrl: './tab-notes.component.html',
  styleUrls: ['./tab-notes.component.scss'],
  animations: [Animation.inOutAnimation()]
})
export class TabNotesComponent implements OnInit, OnChanges {
  @Input() resource = new Resource();
  @Input() chapter = new Chapter();
  @Input() section = new Section();
  noteModel = new Note();
  listItems: Note[] = [];
  keywords = new FormControl('');
  selectedTag = new CustomTag();
  page = 1;
  limit = 20;
  errorMessage = '';
  isLoading = false;
  showViewMoreBtn = false;
  isError = false;
  noResult = false;
  btnDisabled = false;
  httpError: any = null;

  tagKeywords = new FormControl('');
  tagSearch = new FormControl('');
  tagList: CustomTag[] = [];
  tagMainList: CustomTag[] = [];
  tagFilterList: CustomTag[] = [];
  tagPage = 1;
  tagLimit = 10000;
  tagIsLoading = false;
  tagIsError = false;
  tagNoResult = false;

  constructor(
    private alert: MessageAlert,
    private dialog: MatDialog,
    private _communicationService: CommunicationService
  ) { }

  ngOnInit(): void {
    this.selectedTag.display = 'All';
    this.getTags();
    this.keywords.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(data => {
      this.page = 1;
      this.listItems = [];
      this.showViewMoreBtn = false;
      this.filterList();
    });
    this.tagSearch.valueChanges.pipe(debounceTime(0), distinctUntilChanged()).subscribe(name => {
      this.tagFilterList = this.tagMainList.filter(x => x.display.includes(name));
    });
  }
  ngOnChanges() {
    if (this.resource._id && this.chapter._id && this.section._id) {
      this.page = 1;
      this.listItems = [];
      this.showViewMoreBtn = false;
      this.filterList();
    }
  }
  filterList() {
    this.isLoading = true;
    this.isError = false;
    this.noResult = false;
    this.httpError = null;
    this._communicationService.searchNotes(
      this.keywords.value,
      this.resource._id,
      this.chapter._id,
      this.section._id,
      this.selectedTag.value,
      this.page,
      this.limit,
      '-createdAt'
    ).subscribe((res: HttpResponse<NoteSuccessData>) => {
      const data = res.body?.data || [];
      if (this.listItems.length === 0) {
        this.listItems = data;
      } else {
        this.listItems = [...this.listItems, ...data];
      }
      if (this.listItems.length === 0) {
        this.noResult = true;
      }
      if (this.listItems.length >= this.limit && data.length !== 0) {
        this.showViewMoreBtn = true;
      } else {
        this.showViewMoreBtn = false;
      }
      this.isLoading = false;
    }, (err: HttpErrorResponse) => {
      this.isLoading = false;
      this.isError = true;
      this.httpError = err;
    });
  }
  pagChanged() {
    this.page++;
    this.filterList();
  }
  addNew() {
    this.noteModel = new Note();
  }
  edit(note: Note) {
    this.noteModel = note;
    this.noteModel.tags = note.tagsList.map((x) => {
      return { display: x.title, value: x._id, _id: x._id }
    });
  }
  onSubmit(f: NgForm) {
    this.noteModel.resourceId = this.resource._id;
    this.noteModel.chapterId = this.chapter._id;
    this.noteModel.sectionId = this.section._id;
    const tags = [];
    for (const tag of this.noteModel.tags) {
      if (tag._id) {
        tags.push(tag._id);
      } else {
        tags.push(tag.value);
      }
    }
    const _model = Object.assign({}, this.noteModel);
    _model.tags = JSON.stringify(tags);
    if (f.form.invalid) {
      // this.errorMessage = 'Please fill your required fields';
      return;
    }
    if (this.noteModel._id) {
      this.update(f, _model);
    } else {
      this.create(f, _model);
    }
  }
  create(f: NgForm, noteModel: Note) {
    this.btnDisabled = true;
    this._communicationService.createNote(noteModel).subscribe((res) => {
      this.alert.success(res.message);
      this.btnDisabled = false;
      this.listItems = [];
      this.page = 1;
      this.filterList();
      this.getTags();
      f.resetForm();
      document.getElementById('btnCancel')?.click();
    }, (err) => {
      this.btnDisabled = false;
      this.errorMessage = Utility.bindErrors(err);
    });
  }
  update(f: NgForm, noteModel: Note) {
    this.btnDisabled = true;
    this._communicationService.updateNote(noteModel._id, noteModel).subscribe((res) => {
      this.alert.success(res.message);
      this.btnDisabled = false;
      this.listItems = [];
      this.page = 1;
      this.filterList();
      this.getTags();
      f.resetForm();
      document.getElementById('btnCancel')?.click();
    }, (err) => {
      this.btnDisabled = false;
      this.errorMessage = Utility.bindErrors(err);
    });
  }
  confirmDelete(note: Note) {
    const message = `Are you sure you want to delete this note?`;
    const dialogData = new DialogModel('Confirm Delete', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      const result = dialogResult;
      if (result === true) {
        this._communicationService.deleteNote(note._id).subscribe((res: any) => {
          this.alert.success(res.message);
          this.page = 1;
          this.listItems = [];
          this.filterList();
        }, (err) => {
          this.alert.error(err.error.message);
        });
      }
    });
  }
  selectSection(section: Section) {
    this.section = section;
  }
  getTags() {
    this.tagIsLoading = true;
    this.tagIsError = false;
    this.tagNoResult = false;
    this._communicationService.searchTags(
      this.tagKeywords.value,
      this.tagPage,
      this.tagLimit,
      '-createdAt'
    ).subscribe((res: HttpResponse<CustomTagSuccessData>) => {
      this.tagList = res.body?.data || [];
      if (this.tagList.length === 0) {
        this.tagNoResult = true;
      }
      if (this.tagKeywords.value === '' && this.tagPage === 1) {
        this.tagMainList = res.body?.data || [];
        this.tagFilterList = res.body?.data || [];
      }
      this.tagIsLoading = false;
    }, () => {
      this.tagIsLoading = false;
      this.tagIsError = true;
      this.tagNoResult = true;
    });
  }
}
