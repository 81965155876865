import { Component, ElementRef, OnInit, AfterViewInit, OnDestroy, ViewChild, Input, Inject, PLATFORM_ID, ChangeDetectorRef } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Chart, registerables } from 'chart.js';
@Component({
  selector: 'app-scatter-chart',
  templateUrl: './scatter-chart.component.html',
  styleUrls: ['./scatter-chart.component.scss']
})
export class ScatterChartComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('myChart') myCanvas!: ElementRef<HTMLCanvasElement>;
  @Input() config: any = {};
  ctx: any;
  myChart: any = null;
  constructor(
    private ref: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platform: Record<string, unknown>
  ) { }

  ngOnInit(): void {

  }
  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platform)) {
      Chart.register(...registerables);
      this.buildChart();
    }
  }
  buildChart(): void {
    if (!this.config.data) {
      return;
    };
    setTimeout(() => {
      this.ctx = this.myCanvas.nativeElement.getContext('2d');
    }, 20);
    setTimeout(() => {
      this.myChart = new Chart(this.ctx, {
        type: 'scatter',
        data: {
          labels: this.config.data.labels,
          datasets: this.config.data.datasets
        },
        options: {
          responsive: true,
          scales: {
            x: {
              beginAtZero: true,
              grid: {
                display: false
              },
              title: {
                display: true,
                text: this.config.options.xLabel
              }
            },
            y: {
              beginAtZero: false,
              grid: {
                display: true,
                lineWidth: (context) => {
                  if (context.tick.value === 0) {
                    return 1;
                  } else {
                    return 0;
                  }
                }
              },
              title: {
                display: true,
                text: this.config.options.yLabel
              }
            }
          },
          hover: {
            intersect: true
          },
          elements: {
            point: {
              radius: 2
            }
          },
          plugins: {
            legend: {
              position: 'right',
              labels: {
                padding: 20,
                boxWidth: 5,
                boxHeight: 5,
                usePointStyle: true
              }
            }
          }
        }
      });
    }, 50);
    setTimeout(() => {
      // this.myCanvas.nativeElement.click();
      // this.ref.detectChanges();
      // window.dispatchEvent(new Event('resize'));
      // this.myChart.update();
    }, 100);
  }
  ngOnDestroy() {
    try {
      if (this.ctx) {
        this.ctx.destroy();
      }
    } catch (error) {
    }
  }
}
