<div class="topsec h-auto">
    <div class="sub-banner banner1">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-7 col-xl-5">
                    <h2>What is FinStream?</h2>
                    <p class="under-line-sub">FinStream is an interactive content platform that provides users with
                        a deeper knowledge of
                        finance.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<section>
    <div class="container py-4 py-lg-5">
        <div class="row justify-content-center px-4 px-lg-0">
            <div class="col-lg-10 py-2 py-lg-4">
                <p class="pb-5">
                    Step into an educational environment that helps you take the next step in your financial path,
                    whether that’s your schooling, career or both. Our variety of accessible theory lessons and
                    interactive problems are designed to deepen your knowledge of financial markets and build your
                    confidence around more complex subject matters.
                </p>
                <h2 class="under-line">Expand your finance knowledge</h2>
                <p class="pt-3">
                    Our online learning streams serve as additional resources to help you obtain your degree or
                    advance within your company or career. If you’re up for an additional challenge, we offer more
                    advanced mathematical material that will help you establish more industry-related skill sets.
                </p>
                <p class="mt-4">
                    If you’re already working in the finance industry, our educational streams will let you quickly
                    and
                    continually expand your understanding of key foundational concepts, enabling faster learning to
                    help you excel in this industry.
                </p>
                <app-certificate-video></app-certificate-video>
            </div>
        </div>
    </div>
</section>
<section class="certificate-wrp pt-0 pb-0">
    <div class="row p-4 p-sm-5 pt-0 pb-0 justify-content-center" style="padding-top: 0 !important; padding-bottom: 0 !important;">
        <div class="col-lg-12">
            <h2 class="under-line pb-0">The Streams we currently offer include</h2>
        </div>
    </div>
    <app-certificate-courses></app-certificate-courses>
</section>
<section>
    <div class="container pb-4">
        <div class="row justify-content-center pb-5">    
            <div class="">
                <a class="primaryButton cursor-pointer" (click)="exploreApp()">Explore our library - and register for free!</a>
            </div>
        </div>
    </div>
</section>
<section class="outcome">
    <div class="container">
        <div class="row justify-content-end px-4 px-lg-0">
            <div class="col-xl-8 col-lg-6 ps-lg-5 pl-lg-10">
                <h2 class="under-line">Learning outcomes</h2>
                <p class="mt-4">The FinStream library comprises topics at the MBA, CFA and Master’s level, as well
                    as
                    more practical industry-related topics within the finance industry. Users get more
                    indepth clarification on important concepts through concise theory and interactive
                    learning. </p>
                <p class="mt-4">Users can access the FinStream content library and create tailored streams
                    with a simple drag and drop procedure and immediate web publishing. Current streams
                    include:</p>
                <!-- <ul class="mt-4">
                    <li><a routerLink="/streams/portfolio-management">Investment portfolio management. <img src="assets/img/right.svg" alt=""></a></li>
                    <li><a routerLink="/streams/foreign-exchange-international-finance">Foreign exchange and international finance. <img src="assets/img/right.svg"
                                alt=""></a></li>
                    <li><a routerLink="/streams/financial-derivatives">Financial derivatives. <img src="assets/img/right.svg" alt=""></a></li>
                </ul> -->
            </div>
        </div>
    </div>
</section>
<div class="btm-wrp pb-4 pb-lg-5">
    <div class="container">
        <div class="row p-4 p-sm-5 justify-content-center">
            <div class="col-lg-11 mt-3">
                <h2 class="under-line">How it works for individual users</h2>
                <p>FinStream caters to individuals that are students, interns, recent graduates or new to the
                    finance industry. We aim to create a learning environment that works with your needs and
                    delivers expert materials to help you enhance your financial skill set</p>

                <div class="icon-wrp">
                    <img src="assets/img/icon7.svg" alt="">
                    <h5>Learning at your own pace.</h5>
                    <p clas="mt-2"> Never worry about losing touch with where you’re at in the course. Every
                        FinStream online course is set up so you can move through it at an accelerated pace if
                        needed. If
                        you’re a working finance professional and need to slowly work through the course, you have
                        the
                        freedom to do so.</p>
                </div>
                <div class="icon-wrp">
                    <img src="assets/img/icon8.svg" alt="">
                    <h5>Expert-created materials.</h5>
                    <p clas="mt-2"> We offer resources that include standard Master of
                        Finance material, as well as transitional material for people that want education so they
                        can transition to or within the workforce. Our industry experts create all course material
                        to ensure it is relevant and tailored to what employers look for in candidates. You’ll also
                        have access to our team of financial experts to help you through your FinStream educational
                        journey.</p>
                </div>
                <div class="icon-wrp">
                    <img src="assets/img/icon9.svg" alt="">
                    <h5> Interactive exams and certification.</h5>
                    <p clas="mt-2"> Give your resume an upgrade by becoming a
                        Registered FinStream Certified user. You’ll begin one of our finance courses, complete
                        learning materials and exams and ultimately earn your certification. A FinStream
                        certification displays your extended skillset and makes you even more valuable to employers.
                    </p>
                </div>
                <div class="go-link mt-4">
                    <a routerLink="/certification">Learn more about FinStream’s certifications. <img src="assets/img/right.svg" alt=""></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="midwrp">
    <div class="container">
        <div class="row p-4 p-sm-5 justify-content-center">
            <div class="col-lg-11">
                <h2 class="under-line">How it works for corporate partners</h2>
                <p class="pt-2">When logging in as a corporate user you will have access to additional
                    administrative capability
                    to help your teams focus and learn key concepts. Administrators can assign courses to a specific
                    user set through a drop and drag procedure. Additionally, users can create internal discussion
                    groups, take notes and ask their tutor questions. Administrators can also add materials they
                    find important or relevant.</p>

                <div class="card">
                    <div class="card__header">
                        <h3>Tailored learning streams.</h3>
                    </div>
                    <div class="card__body">
                        <p>Our platform gives you the option to create, tailor and administer your team’s learning
                            streams.</p>
                        <div class="text-center">
                            <a class="primaryButton" routerLink="/for-employers">Learn more</a>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card__header">
                        <h3>Support for corporate teams.</h3>
                    </div>
                    <div class="card__body">
                        <p>FinStream will also work hand-in-hand with your company to ensure your staff gets the
                            educational materials that they need. We’ll provide you with admin rights that allows
                            you to pick specific topics — including both theory problems and interactive element
                            problems — and create a customised stream.</p>
                        <div class="text-center">
                            <a class="primaryButton" routerLink="/for-employers">Learn more</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="getin-touch">
    <div class="container-fluid">
        <div class="row p-4">
            <div class="col-lg-5">
                <h5 class="under-line">FinStream is here to bring enhanced Financial education to the finance industry.</h5>
                <p>Contact our team today to learn more about our practice-based financial education platform.</p>
                <a class="secondary-btn" routerLink="/contact">Contact Us</a>
            </div>
        </div>
    </div>
</div>