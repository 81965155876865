import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { User, UserSuccessData, UserErrorData } from '../model/user.model';
import { environment } from '../../environments/environment';

@Injectable()
export class UserService {
    constructor(public http: HttpClient) { }
    filterList(title: string, userType: string, resource: string, status: boolean, page: number, limit: number, sortBy: string): Observable<HttpResponse<UserSuccessData>> {
        return this.http.get<UserSuccessData>(environment.API_END_POINT + '/user?title=' + title + '&userType=' + userType + '&status=' + status +
            '&resource=' + resource + '&sortBy=' + sortBy + '&page=' + page + '&limit=' + limit, { observe: 'response' });
    }
    getDetails(id: string) {
        return this.http.get(environment.API_END_POINT + '/user/' + id, { observe: 'response' });
    }
    update(id: string, data: FormData): Observable<any> {
        return this.http.put(environment.API_END_POINT + '/user/' + id, data, {
            observe: 'events' as 'body',
            reportProgress: true
        });
    }
    validateUserInviteLink(key: string) {
        return this.http.get(environment.API_END_POINT + '/user/invitation/' + key);
    }
    acceptInvitation(key: string, data: object): Observable<any> {
        return this.http.post(environment.API_END_POINT + '/user/invitation/' + key, data);
    }
    closeAccount(id: string): Observable<any> {
        return this.http.put(environment.API_END_POINT + '/user/' + id + '/status', { isAccountClosed: 1 });
    }
    uploadProfilePicture(data: FormData): Observable<any> {
        return this.http.post<any>(environment.API_END_POINT + '/file-upload/image', data, {
            observe: 'events' as 'body',
            reportProgress: true
        });
    }

    // chat preferences
    sendInvitation(toUser: string): Observable<any> {
        return this.http.post(environment.API_END_POINT + '/user/chat-preference/invitation', { toUser });
    }
    respondInvitation(id: string, status: string): Observable<any> {
        return this.http.put(environment.API_END_POINT + '/user/chat-preference/invitation/' + id, { status });
    }
    blockUser(toUser: string): Observable<any> {
        return this.http.post(environment.API_END_POINT + '/user/chat-preference/block-user', { toUser });
    }
    unBlockUser(id: string): Observable<any> {
        return this.http.delete(environment.API_END_POINT + '/user/chat-preference/block-user/' + id);
    }
}
