export const Utility = {
    fileUploadProgress: (event: any) => {
        const percentDone = Math.round(100 * event.loaded / event.total);
        return { status: 'progress', message: percentDone };
    },
    bindErrors: (error: any) => {
        let errorMessage = '';
        if (error.status === 400) {
            for (const field of error.error.data) {
                errorMessage += '* ' + field.msg + '\n';
            }
        } else if (error.status === 401 || error.status === 500) {
            errorMessage = error.error.message;
        } else {
            errorMessage = 'Something went wrong!';
        }
        return errorMessage;
    },
    chunkArray: (myArray: any, chunkSize: number) => {
        let index = 0;
        const arrayLength = myArray.length;
        const tempArray = [];

        for (index = 0; index < arrayLength; index += chunkSize) {
            const myChunk = myArray.slice(index, index + chunkSize);
            // Do something if you want with the group
            tempArray.push(myChunk);
        }
        return tempArray;
    },
    getFileType: (fileName: string) => {
        const item = fileName.split('.').pop() || '';
        const fileExtension = item.toLowerCase();
        let type = '';
        if (fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png') {
            type = 'image';
        } else if (fileExtension === 'pdf') {
            type = 'pdf';
        } else if (fileExtension === 'docx') {
            type = 'doc';
        } else if (fileExtension === 'xls' || fileExtension === 'xlsx') {
            type = 'sheet';
        } else if (fileExtension === 'txt') {
            type = 'text';
        } else {
            type = 'other';
        }
        return type;
    },
    getFunctionParams: (func: any): string[] => {
        let str = func.toString();
        str = str.replace(/\/\*[\s\S]*?\*\//g, '')
            .replace(/\/\/(.)*/g, '')
            .replace(/{[\s\S]*}/, '')
            .replace(/=>/g, '')
            .trim();
        // Start parameter names after first '('
        const start = str.indexOf('(') + 1;
        // End parameter names is just before last ')'
        const end = str.length - 1;
        const result = str.substring(start, end).split(', ');
        const params: string[] = [];
        result.forEach((element: string) => {
            // Removing any default value
            element = element.replace(/=[\s\S]*/g, '').trim();
            if (element.length > 0) {
                params.push(element);
            }
        });
        return params;
    },
    getCellIndex: (cell: string) => {
        const chunks: string[] = [];
        cell = cell.replace('{', '').replace('}', '');
        cell.split('-').forEach((element: any) => {
            if (element !== '' && !isNaN(element)) {
                chunks.push(element);
            }
        });
        const sectionIndex = Number(chunks[0]);
        const row = Number(chunks[1]) - 1;
        const col = Number(chunks[2]) - 1;
        return {
            sectionIndex,
            rowIndex: row,
            colIndex: col
        };
    }
};
