/* eslint-disable no-shadow */
/* eslint-disable no-eval */
import { Component, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { Chapter } from '../../../model/chapter.model';
import { Resource } from '../../../model/resource.model';
import { AuthUser } from '../../../model/user.model';
import { SharedService } from '../../../services/shared.service';
import { Constants } from '../../../utilities/constants';
import { SectionList } from '../../../model/section.model';
import { MessageAlert } from '../../../utilities/message.alert';
import { Utility as Helper } from '../../../utilities/utility';

@Component({
  selector: 'app-def-interactive',
  templateUrl: './def-interactive.component.html',
  styleUrls: ['./def-interactive.component.scss']
})
export class DefInteractiveComponent implements OnInit, OnChanges {
  @Input() resource = new Resource();
  @Input() chapter = new Chapter();
  @Input() sectionList: SectionList[] = [];
  @Input() activeSection = '';
  @Output() emitSlug: EventEmitter<any> = new EventEmitter();
  section = new SectionList();
  sections: any = [];
  authUser = new AuthUser();
  userTypes = Constants.userTypes;
  constructor(
    private alert: MessageAlert,
    private _sharedService: SharedService) {
  }

  ngOnInit(): void {
  }
  ngOnChanges(): void {
    this.sectionList = this.sectionList.filter((x) => x.showInteractive === undefined || x.showInteractive === true);
    if (this.sectionList.length > 0) {
      if (this.activeSection) {
        this.section = this.sectionList.find((x: SectionList) => x.slug === this.activeSection) || this.sectionList[0];
      } else {
        this.section = this.sectionList[0];
      }
      this.activeSection = this.section.slug;
      this.sections = JSON.parse(this.section.contentInteractive) || [];
    }
    this.authUser = this._sharedService.authUser();
  }
  selectSection(section: SectionList) {
    if (section.hasSectionAccess) {
      this.section = section;
      this.sections = JSON.parse(this.section.contentInteractive);
      this.activeSection = this.section.slug;
      this.handleSection();
    }
  }
  handleSection() {
    this.emitSlug.emit(this.activeSection);
  }
}
