<div class="topsec h-auto">
    <div class="sub-banner banner8">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-7 col-xl-6">
                    <h2 class="under-line-sub">FinStream’s investment<br> portfolio theory courses</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<section>
    <div class="container py-4 py-lg-5">
        <div class="row justify-content-center px-4 px-lg-0">
            <div class="col-lg-10 py-2 py-lg-4">
                <p class="pb-4">
                    Portfolio theory requires skill and an understanding of decision making under uncertainty and
                    how the risk tolerance of the investor can be embedded in the design of portfolios. FinStream’s
                    Investment Portfolio Management course presents the key foundational and practical knowledge for
                    this to happen.
                </p>
                <p class="pb-5">Whether you’re a student or already a finance professional, read on to learn more
                    about what you’ll uncover in our training sessions.</p>
            </div>
        </div>
    </div>
</section>
<div class="midwrp">
    <div class="container">
        <div class="row p-4 p-sm-5 justify-content-center">
            <div class="col-lg-11">
                <h2 class="under-line">How portfolio management<br> can benefit your finance career</h2>
                <p class="pt-2 pb-4">Modern Portfolio Theory is still highly influential in investment management
                    and essential to succeed in today’s financial market. If you’re building or maintaining a
                    portfolio, there’s a chance that the following key foundations will still factor into your
                    plans:</p>
                <ul class="lists lefticon">
                    <li>Maximise returns for a given amount of risk.</li>
                    <li>Find the balance between portfolio risk and reward.</li>
                    <li>Understand why the CAPM model and the market portfolio are still crucial ideas.</li>
                    <li>See why beating the financial market without taking on additional risk is difficult.</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<section class="course-content bg-3">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10 pt-lg-5">
                <img src="assets/img/owl.svg" alt="">
                <h2 class="pt-2">Course Content</h2>
                <div class="d-flex flex-wrap">
                    <ul class="lists">
                        <li>Utility and Risk Aversion</li>
                        <li>Mean-Variance Optimisation</li>
                        <li>Efficient Markets and Alpha</li>
                        <li>CAPM and Beta</li>
                        <li>Multi-Factor models</li>
                        <li>Behavioural Finance</li>
                    </ul>
                </div>
                <div class="pt-0 pt-lg-3 pb-4 pb-lg-5">
                    <a class="primaryButton cursor-pointer" (click)="exploreApp()">Explore our library - and register for free!</a>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="midwrp">
    <div class="container">
        <div class="row p-4 p-sm-5 justify-content-center">
            <div class="col-lg-11">
                <h2 class="under-line">Who’s this investment portfolio<br> management course for?</h2>
                <p class="pt-2">The theoretical aspect of our investment portfolio management course resembles the
                    standard Masters and CFA portfolio and asset management content, but also includes additional
                    online course content to help bridge the transition to the financial sector.</p>
                <p class="pt-4">FinStream’s portfolio management courses are best suited for:
                </p>
                <div class="row justify-content-between">

                    <div class="card w-half p-0">
                        <div class="card__header dark-bg">
                            <h3>Professionals</h3>
                        </div>
                        <div class="card__body">
                            <ul class="lists pe-0">
                                <li>
                                    <p>Institutional Traders</p>
                                </li>
                                <li>
                                    <p>Investment Advisors/Investment Professionals</p>
                                </li>
                                <li>
                                    <p>Risk Management Professionals</p>
                                </li>
                                <li>
                                    <p>Corporate Finance Specialists</p>
                                </li>
                                <li>
                                    <p>Financial Analysis Specialists</p>
                                </li>
                                <li>
                                    <p>Portfolio Managers</p>
                                </li>
                                <li>
                                    <p>Financial Management/Wealth Management Professionals</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card w-half p-0">
                        <div class="card__header dark-bg">
                            <h3>Students</h3>
                        </div>
                        <div class="card__body">
                            <ul class="lists pe-0">
                                <li>
                                    <p>Masters of Finance Students</p>
                                </li>
                                <li>
                                    <p>CFA Students</p>
                                </li>
                                <li>
                                    <p>Students looking to enhance their financial knowledge</p>
                                </li>
                                <li>
                                    <p>Students that need help passing a finance course</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="btm-wrp pb-4 pb-lg-5">
    <div class="container">
        <div class="row p-4 justify-content-center">
            <div class="col-lg-11">
                <div class="icon-wrp">
                    <img src="assets/img/icon9.svg" alt="">
                    <h2 class="pt-3">Certification</h2>
                    <p clas="mt-2"> Increase your prospects in the financial job market with a FinStream
                        certification, that showcases your new-found theoretical proficiency in investing and
                        investment portfolio management.</p>
                </div>
                <div class="go-link mt-4">
                    <a class="primaryButton" routerLink="/certification">Learn more about FinStream’s
                        certifications</a>
                </div>
            </div>
        </div>
    </div>
</div>
<div>
    <div class="other-streams">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <img class="w-100" src="assets/img/play-img.png" alt="">
                </div>
                <div class="col-lg-6 ps-lg-5">
                    <h2>Interested in learning more about financial topics?</h2>
                    <h2>Explore our other streams.</h2>
                    <p class="pt-4 pb-3"><a routerLink="/streams/foreign-exchange-international-finance">Foreign Exchange and International Finance <img src="assets/img/right.svg" alt=""></a>
                    </p>
                    <p><a routerLink="/streams/financial-derivatives">Financial Derivatives <img src="assets/img/right.svg" alt=""></a> </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="getin-touch">
    <div class="container-fluid">
        <div class="row p-4">
            <div class="col-lg-5">
                <h5>Start your financial derivative education with Finstream today.</h5>
                <a class="secondary-btn" routerLink="/contact">Contact Us</a>
            </div>
        </div>
    </div>
</div>