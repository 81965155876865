import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { CheckoutSessionSuccessData, CreateCheckoutSession, SubscriptionSuccessData } from '../model/subscription.model';

@Injectable()
export class SubscriptionService {
  constructor(public http: HttpClient) { }
  createCheckoutSession(data: CreateCheckoutSession): Observable<HttpResponse<CheckoutSessionSuccessData>> {
    return this.http.post<CheckoutSessionSuccessData>(environment.API_END_POINT + '/subscription/checkout/create', data, { observe: 'response' });
  }
  verifyPayment(sessionId: string) {
    return this.http.get<any>(environment.API_END_POINT + '/subscription/checkout/verify?sessionId=' + sessionId);
  }
  filterList(
    keywords: string,
    status: string,
    page: number,
    limit: number,
    sortBy: string,
    resourceId: string
  ): Observable<HttpResponse<SubscriptionSuccessData>> {
    return this.http.get<SubscriptionSuccessData>(environment.API_END_POINT + '/subscription?title=' + keywords +
      '&status=' + status + '&page=' + page + '&limit=' + limit + '&sortBy=' + sortBy +
      '&resourceId=' + resourceId, { observe: 'response' });
  }
  unsubscribe(subscriptionId: string) {
    return this.http.put<CheckoutSessionSuccessData>(environment.API_END_POINT + '/subscription/unsubscribe', { subscriptionId });
  }
}
