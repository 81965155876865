import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SectionSuccessData } from '../model/section.model';
import { environment } from '../../environments/environment';

@Injectable()
export class SectionService {
    constructor(public http: HttpClient) { }

    filterList(
        keywords: string,
        status: string,
        sectionIds: string,
        resource: string,
        page: number,
        limit: number,
        sortBy: string
    ): Observable<HttpResponse<SectionSuccessData>> {
        return this.http.get<SectionSuccessData>(environment.API_END_POINT + '/section?title=' + keywords +
            '&resource=' + resource + '&sectionIds=' + sectionIds + '&status=' + status + '&page=' + page + '&limit=' + limit +
            '&sortBy=' + sortBy, { observe: 'response' });
    }
    getDetails(id: string): Observable<HttpResponse<SectionSuccessData>> {
        return this.http.get<SectionSuccessData>(environment.API_END_POINT + '/section/' + id, { observe: 'response' });
    }
    relatedCourseDetails(id: string) {
        return this.http.get<any>(environment.API_END_POINT + '/section/' + id + '/related-course');
    }
}
