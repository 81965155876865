<div class="container text-center noResultsBg">
    <div class="no-records-found" *ngIf="httpError===undefined && noResult === true">
        <div class="noResultsCont">
            <img src="assets/img/noResults.svg">
            <div class="noresultTxt">
                <label>No Results Found</label>
                <span>We couldn't find what you're looking for</span>
            </div>
        </div>
    </div>
    <div class="no-records-found text-center" *ngIf="httpError!==undefined">
        <ng-container *ngIf="httpError.status===500">
            <div class="noResultsCont">
                <img src="assets/img/error-500.svg">
                <div class="noresultTxt">
                    <label>OOPS! </label>
                    <p class="mt-3 text-center">Something went wrong!</p>
                </div>
            </div>
        </ng-container>
        <img src="assets/img/error-401.jpg" *ngIf="httpError.status===401">
    </div>
</div>
