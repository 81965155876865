import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AES, enc } from 'crypto-ts';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {
  KEY = environment.encKey;
  constructor() { }

  encryptData(data: string) {
    return AES.encrypt(data, this.KEY).toString();
  }
  decryptData(encryptedData: string) {
    try {
      if (encryptedData) {
        const bytes = AES.decrypt(encryptedData, this.KEY);
        const plaintext = bytes.toString(enc.Utf8);
        return plaintext;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }

  }

}
