<div class="topsec h-auto">
    <div class="sub-banner banner9">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-7 col-xl-5">
                    <h2>FinStream for Partners</h2> 
                    <p class="">
                        Could your students or staff benefit from Finstream's theory and interactive templates.
                        <br><br>
                        Perhaps you are an expert in a field and want to use a powerful, self-contained and cost-effective platform
                        to deliver specialised content for your audience.
                        <br><br>
                        If so let us know so we can organise a trial for you.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<section>
    <div class="container py-4 py-lg-5">
        <div class="row justify-content-center px-4 px-lg-0">
            <div class="col-lg-10 py-2 py-lg-4">
                <h2 class="">
                    Partner platform access
                </h2>
                <p class="pt-3">
                    Our powerful administrative capability offers a self-contained private workspace.
                </p>
                <p class="pt-3 pb-5">
                    There is no need to replace your current LMS for specialised content for your audience. Use our streams, create your own, or combine them.
                </p>
                <div class="card p-0 mt-5">
                    <div class="card__header">
                        <h3 class="p-2 text-center">Platform features available to Partners:</h3>
                    </div>
                    <div class="card__body">
                        <ul class="stream-lists additional-info mt-3">
                            <li>Easy assignment of users to the streams via csv file upload</li>
                            <li>White labelling of your brand</li>
                            <li>Define the beginning and end dates of the learning stream</li>
                            <li>Users can create Internal chat groups</li>
                            <li>Access stream frequency of use and other analytics</li>
                            <li>Assign or invite your experts or tutors to a stream</li>
                            <li>Create certified specialised short courses</li>
                        </ul>
                    </div>
                </div>
                <div class="card p-0 mt-5">
                    <div class="card__header">
                        <h3 class="p-2 text-center">In addition Partners can create their own bespoke courses:</h3>
                    </div>
                    <div class="card__body">
                        <ul class="stream-lists additional-info mt-3">
                            <li>Access to powerful bespoke private content creation capability</li>
                            <li>Interactive drag and drop tools</li>
                            <li>Exam creation and automatic marking for multiple choice questions</li>
                            <li>Simple updating of content and web publishing to a user base.</li>
                        </ul>
                    </div>
                </div>
                <div class="mt-5" style="text-align: center;">
                    <video width="500" poster="assets/img/thumbnail/edk-platform.png" controls>
                        <source src="https://cdn.staging.finstream.com.au/assets/videos/1920_edk_video_02_4.mp4" type="video/mp4">
                        Your browser does not support HTML video.
                    </video>
                </div>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container pb-4">
        <div class="row justify-content-center pb-5">    
            <div class="">
                <a class="primaryButton cursor-pointer" (click)="exploreApp()">Explore our library - and register for free!</a>
            </div>
        </div>
    </div>
</section>


<div class="getin-touch">
    <div class="container-fluid">
        <div class="row p-4">
            <div class="col-lg-5">
                <h5 class="under-line">FinStream is here to bring enhanced Financial education to the finance industry.</h5>
                <p>Contact our team today to learn more about our practice-based financial education platform.</p>
                <a class="secondary-btn" routerLink="/contact">Contact Us</a>
            </div>
        </div>
    </div>
</div>