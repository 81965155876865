<div class="">
    <div class="">
      <div class="">
        <div class="box_ bx_shadow_ bg-white">
          <div class="row mb-4 align-items-center">
            <div class="col-lg-6 col-md-6">
              <h3>My Subscriptions</h3>
              <span class="sml_ sub_sml"><span class="itemShow" *ngIf="listItems.length>0">Showing {{(limit*(page-1))+1}} -
                {{(limit*(page-1))+listItems.length}} of {{totalCount}} items</span></span>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-lg-12">
              <div class="inpt_wrp">
                <div class="col-lg-5 pl-0 col_6 mb-4 col-md-12 pr_sml_0 flex_sml_0 position-relative">
                  <input type="text" class="form-control flx_item" placeholder="Search" [formControl]="keywords">
                    <button class="search_btn" (click)="page=1;filterList()"><img src="assets/img/search.svg"></button>
                 </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="table_responsive">
                <table class="table srdr_dtls sortable-table custom_tbl">
                  <thead>
                    <tr>
                      <th (click)="onSortBy('subscriptionId')">Subscription ID <i class="fa fa-caret-down" aria-hidden="true"></i> </th>
                      <th (click)="onSortBy('subscriptionFrom')">Start Date <i class="fa fa-caret-down" aria-hidden="true"></i> </th>
                      <th (click)="onSortBy('subscriptionTo')">End Date <i class="fa fa-caret-down" aria-hidden="true"></i> </th>
                      <th (click)="onSortBy('resourceDetails.title')">Resource <i class="fa fa-caret-down" aria-hidden="true"></i> </th>
                      <!-- <th (click)="onSortBy('status')">Payment <i class="fa fa-caret-down" aria-hidden="true"></i> </th>                       -->
                      <th>Remaining Days</th>
                      <th (click)="onSortBy('subscriptionStatus')">Subscription <i class="fa fa-caret-down" aria-hidden="true"></i> </th>
                      <th (click)="onSortBy('totalAmount')">Total (INC.GST) </th>
                      <th >Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of listItems | paginate: { itemsPerPage: this.limit, currentPage: page,totalItems: totalCount } ; let i = index;">
                      <td scope="row" class="color_blue pt-4">{{item.subscriptionId}}</td>
                      <td class="pt-4">{{item.subscriptionFrom | date: 'dd/MM/YYYY'}}</td>
                      <td class="pt-4">{{item.subscriptionTo |  date: 'dd/MM/YYYY'}}</td>
                      <td class="pt-4">
                        <a routerLink="/resources/{{item.resourceData[0]?.slug}}">{{item?.resourceDetails?.title}}</a>
                      </td>
                      <!-- <td class="pt-4">
                        <span  *ngIf="item.status !== 'paid' && item.status !== 'pending' && item.status !== 'Payment Failed'" class="otherStatus text-capitalize">{{item.status}}</span>
                        <span *ngIf="item.status === 'paid'" class="publish text-capitalize">{{item.status}}</span>
                        <span *ngIf="item.status === 'pending'" class="active text-capitalize">{{item.status}}</span>
                        <span *ngIf="item?.status === 'Payment Failed'" class="payment_Failed text-capitalize">{{item.status}}</span>                      
                      </td> -->
                      <td class="pt-4 text-center">
                        <ng-container *ngIf="item.status === 'paid'">
                          <span class="remaining-info" *ngIf="(today | dateDiffPipe : item.subscriptionTo) > 9">{{today | dateDiffPipe : item.subscriptionTo}}</span>
                          <span class="remaining-warning" *ngIf="(today | dateDiffPipe : item.subscriptionTo) < 10"> {{today | dateDiffPipe : item.subscriptionTo}}</span>
                        </ng-container>
                        <ng-container *ngIf="item.status !== 'paid'">
                          <span class="remaining-warning"> 0 </span>
                        </ng-container>
                      </td>
                      <td class="pt-4">
                          <span class="text-capitalize" [ngClass]="{'otherStatus':item.subscriptionStatus!=='active','active':item.subscriptionStatus==='active'}">{{item.subscriptionStatus}}</span>
                      </td>
                      <td class="pt-4 text-center">{{item.currency | currencySymbol}}{{item.totalAmount}}</td>
                      <td class="">
                        <ng-container *ngIf="item.subscriptionStatus === 'active' &&!item.subscriptionCancelRequested &&!item.subscriptionCanceledAt">
                          <button class="btn btn-outline-danger m-0 p-1" (click)="confirmUnsubscribe(item)" >Unsubscribe</button>
                        </ng-container>
                        <ng-container *ngIf="item.subscriptionStatus === 'active' && item.subscriptionCancelRequested">
                          <span>Unsubscribe Requested</span>
                          <div class="cancel-time" *ngIf="item.subscriptionStatus === 'active' && item.subscriptionCancelRequested">
                            <span (click)="viewUnSubInfo(item)">
                              <label>Cancels {{item.subscriptionTo |  date: 'dd/MM'}}</label>
                              <i class="fa fa-clock"></i></span>
                          </div>
                        </ng-container>
                      </td>
                    </tr>
                  </tbody>
                  <tbody *ngIf="isLoading">
                      <tr *ngFor="let row of ' '.repeat(3).split('')">
                        <td><mat-progress-bar mode="buffer"></mat-progress-bar></td>
                        <td><mat-progress-bar mode="buffer"></mat-progress-bar></td>
                        <td><mat-progress-bar mode="buffer"></mat-progress-bar></td>
                        <td><mat-progress-bar mode="buffer"></mat-progress-bar></td>
                        <td><mat-progress-bar mode="buffer"></mat-progress-bar></td>
                        <td><mat-progress-bar mode="buffer"></mat-progress-bar></td>
                        <td><mat-progress-bar mode="buffer"></mat-progress-bar></td>
                        <td><mat-progress-bar mode="buffer"></mat-progress-bar></td>
                      </tr>
                  </tbody>
                </table>
                <app-no-records-found [noResult]="noResult" [httpError]="httpError"></app-no-records-found>
              </div>
              <div class="pagination-container" *ngIf="!noResult">
                <pagination-controls (pageChange)="page = $event;pageChanged($event)" previousLabel="" nextLabel="">
                </pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
