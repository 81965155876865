import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { Constants } from '../../utilities/constants';
import { SharedService } from '../../services/shared.service';
import { AuthService } from '../../services/auth.service';
import { User } from '../../model/user.model';
import { CountdownEvent } from 'ngx-countdown';
import { InteractiveExamination } from 'src/app/model/section.model';
import { SuccessDialogComponent } from 'src/app/common/success-dialog/success-dialog.component';
import { DialogModel } from 'src/app/model/dialog.model';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  user = new User();
  isLoading = true;
  footerEnabled = true;
  showExamBackLink = true;
  exam = new InteractiveExamination();
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private _authService: AuthService,
    private _sharedService: SharedService,
    @Inject(PLATFORM_ID) private platform: Record<string, unknown>
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // if (event.url === '/' ||
        //   event.url === '/home' ||
        //   event.url === '/individuals' ||
        //   event.url === '/course-providers' ||
        //   event.url === '/corporate-learning' ||
        //   event.url.includes('/account/')) {
        //   this.footerEnabled = false;
        // } else {
        //   this.footerEnabled = true;
        // }
        if (event.url.includes('/account/login')) {
          this.footerEnabled = false;
        } else {
          this.footerEnabled = true;
        }
        if (isPlatformBrowser(this.platform)) {
          window.scrollTo(0, 0);
        }
        this.onShowExamBackLink();
      }
    });
  }

  ngOnInit(): void {
    this._sharedService.user.subscribe((res: any) => {
      this.user = res;
    });
    this._sharedService.exam.subscribe((data: InteractiveExamination) => {
      this.exam = data;
      this.onShowExamBackLink();
    });
    // get authenticated user
    const token = this._authService.getToken();
    if (token) {
      this._authService.currentUser().subscribe((res: any) => {
        this._sharedService.setUserData(res.data);
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
      }, () => {
        this.clearSession();
      });
    } else {
      setTimeout(() => {
        this.isLoading = false;
      }, 1500);
    }
  }
  onShowExamBackLink() {
    if (this.exam && this.exam.isStarted) {
      if (window.location.href === window.location.protocol + '//' + window.location.host + window.location.port + '/resources/' + this.exam.resource + '/' + this.exam.chapter + '?tab=tab-interactive&section=' + this.exam.section) {
        this.showExamBackLink = false;
      } else {
        this.showExamBackLink = true;
      }
    }
  }
  onTimerFinished(e: CountdownEvent) {
    if (e.action === 'done') {
      // your code here
      this.exam.isCompleted = true;
      this.exam.isStarted = false;
      this._sharedService.setExamData(this.exam);
      this.showMessage('Message', 'Exam time is over. Please visit my profile to see result.');
    }
  }
  showMessage(title: string, content: string): void {
    const dialogData = new DialogModel(title, content);
    this.dialog.open(SuccessDialogComponent, {
      maxWidth: '600px',
      minWidth: '450px',
      data: dialogData
    });
  }
  clearSession() {
    console.log('logging out....');
    this.isLoading = false;
    this._authService.removeCookie(Constants.Cookies.TOKEN);
    this._sharedService.setUserData({});
    this.router.navigate(['/'], { queryParams: { next: window.location.pathname + window.location.search } });
  }
}
