/* eslint-disable @typescript-eslint/semi */
/* eslint-disable no-lone-blocks */
/* eslint-disable camelcase */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/naming-convention */

export const InteractiveLib = {
    BlackScholes: (callput: string, S: number, K: number, T: number, rd: number, rf: number, vol: number, FV: number) => {
        const d1 = (Math.log(S / K) + (rd - rf + vol * vol / 2) * T) / (vol * Math.sqrt(T));
        const d2 = d1 - vol * Math.sqrt(T);
        if (callput === 'call') {
            return ((S * Math.exp(-rf * T) * CND(d1) - K * Math.exp(-rd * T) * CND(d2)) * FV).toFixed(4);
        } else {
            return ((K * Math.exp(-rd * T) * CND(-d2) - S * Math.exp(-rf * T) * CND(-d1)) * FV).toFixed(4);
        }
    },
    CND: (x: number) => CND(Number(x)).toFixed(6),
    NormSInv: (p: number) => {
        const a1 = -39.6968302866538; const a2 = 220.946098424521; const a3 = -275.928510446969;
        const a4 = 138.357751867269; const a5 = -30.6647980661472; const a6 = 2.50662827745924;
        const b1 = -54.4760987982241; const b2 = 161.585836858041; const b3 = -155.698979859887;
        const b4 = 66.8013118877197; const b5 = -13.2806815528857; const c1 = -7.78489400243029E-03;
        const c2 = -0.322396458041136; const c3 = -2.40075827716184; const c4 = -2.54973253934373;
        const c5 = 4.37466414146497; const c6 = 2.93816398269878; const d1 = 7.78469570904146E-03;
        const d2 = 0.32246712907004; const d3 = 2.445134137143; const d4 = 3.75440866190742;
        const pLow = 0.02425; const pHigh = 1 - pLow;
        let q, r;
        let retVal;

        if ((p < 0) || (p > 1)) {
            alert('NormSInv: Argument out of range.');
            retVal = 0;
        } else if (p < pLow) {
            q = Math.sqrt(-2 * Math.log(p));
            retVal = (((((c1 * q + c2) * q + c3) * q + c4) * q + c5) * q + c6) / ((((d1 * q + d2) * q + d3) * q + d4) * q + 1);
        } else if (p <= pHigh) {
            q = p - 0.5;
            r = q * q;
            retVal = (((((a1 * r + a2) * r + a3) * r + a4) * r + a5) * r + a6) * q / (((((b1 * r + b2) * r + b3) * r + b4) * r + b5) * r + 1);
        } else {
            q = Math.sqrt(-2 * Math.log(1 - p));
            retVal = -(((((c1 * q + c2) * q + c3) * q + c4) * q + c5) * q + c6) / ((((d1 * q + d2) * q + d3) * q + d4) * q + 1);
        }

        return retVal;
    },
    stdNormal: (x: number, mu: number, sigma: number) => {
        return stdNormal((x - mu) / sigma);
        function stdNormal(z: number) {
            let j, k, kMax, m, values, total, subtotal, item, z2, z4, a, b;

            // Power series is not stable at these extreme tail scenarios
            if (z < -6) { return 0; }
            if (z > 6) { return 1; }

            m = 1; // m(k) == (2**k)/factorial(k)
            b = z; // b(k) == z ** (2*k + 1)
            z2 = z * z; // cache of z squared
            z4 = z2 * z2; // cache of z to the 4th
            values = [];

            // Compute the power series in groups of two terms.
            // This reduces floating point errors because the series
            // alternates between positive and negative.
            for (k = 0; k < 100; k += 2) {
                a = 2 * k + 1;
                item = b / (a * m);
                item *= (1 - (a * z2) / ((a + 1) * (a + 2)));
                values.push(item);
                m *= (4 * (k + 1) * (k + 2));
                b *= z4;
            }

            // Add the smallest terms to the total first that
            // way we minimize the floating point errors.
            total = 0;
            for (k = 49; k >= 0; k--) {
                total += values[k];
            }

            // Multiply total by 1/sqrt(2*PI)
            // Then add 0.5 so that stdNormal(0) === 0.5
            return 0.5 + 0.3989422804014327 * total;
        }
    },
    normalcdf: (X: number) => { // HASTINGS. MAX ERROR = .000001
        const T = 1 / (1 + 0.2316419 * Math.abs(X));
        const D = 0.3989423 * Math.exp(-X * X / 2);
        let Prob = D * T * (0.3193815 + T * (-0.3565638 + T * (1.781478 + T * (-1.821256 + T * 1.330274))));
        if (X > 0) {
            Prob = 1 - Prob;
        }
        return Prob.toFixed(6);
    },
    OptPayoff: (type: string, InvertQuote: string, S: number, K: number, FV: number, Premium = 0) => _OptPayoff(type, InvertQuote, S, K, FV, Premium),
    IfStrConcat: (str: string, str1: string, str2: string, str3: string, str4: string) => {
        if (str === 'Yes') {
            return (str1 + str2);
        } else if (str === 'No') {
            return (str3 + str4);
        } else {
            return '#NA';
        }
    },
    IfNumConcat: (num1: number, num2: number, str1: string, str2: string, str3: string, str4: string) => {
        if (Number(num1) >= Number(num2)) {
            return (str1 + str2);
        } else {
            return (str3 + str4);
        }
    },
    FXBlackScholes: (output: string, cp: string, invertquote: string, S: number, K: number, T: number, rdom: number, rfor: number, vol: number, Notional: number) => {
        let rd;
        let rf;
        let callput;
        let FV;

        rd = rdom;
        rf = rfor;
        callput = cp;
        FV = Notional;

        if (invertquote === 'Yes') {
            if (S > 0 && K > 0) {
                S = 1.0 / S;
                K = 1.0 / K;
                rd = rfor; /* swap rates around */
                rf = rdom;
            } else {
                return 'N/A'; /* exit function dont proceed */
            }
        }

        const d1 = (Math.log(S / K) + (rd - rf + vol * vol / 2) * T) / (vol * Math.sqrt(T));
        const d2 = d1 - vol * Math.sqrt(T);

        if (output === 'price') {
            if (callput === 'call') {
                return ((S * Math.exp(-rf * T) * CND(d1) - K * Math.exp(-rd * T) * CND(d2)) * FV).toFixed(4);
            } else if (callput === 'put') {
                return ((K * Math.exp(-rd * T) * CND(-d2) - S * Math.exp(-rf * T) * CND(-d1)) * FV).toFixed(4);
            } else if (callput === 'fwd') {
                return ((S * Math.exp(-rf * T) - K * Math.exp(-rd * T)) * FV).toFixed(4);
            } else {
                return 'N/A';
            }
        } else if (output === 'delta') {
            if (callput === 'call') {
                return (Math.exp(-rf * T) * CND(d1) * FV).toFixed(4);
            } else if (callput === 'put') {
                return (-Math.exp(-rf * T) * CND(-d1) * FV).toFixed(4);
            } else if (callput === 'fwd') {
                return (Math.exp(-rf * T) * FV).toFixed(4);
            } else {
                return 'N/A';
            }
        } else if (output === 'gamma') {
            if (callput === 'call') {
                return (Math.exp(-rf * T) * phi(d1) / (S * vol * Math.sqrt(T)) * FV).toFixed(4);
            } else if (callput === 'put') {
                return (Math.exp(-rf * T) * phi(d1) / (S * vol * Math.sqrt(T)) * FV).toFixed(4);
            } else if (callput === 'fwd') {
                return 0.0;
            } else {
                return 'N/A';
            }
        } else if (output === 'vega') {
            if (callput === 'call') {
                return (S * Math.exp(-rf * T) * phi(d1) * Math.sqrt(T) * FV).toFixed(4);
            } else if (callput === 'put') {
                return (S * Math.exp(-rf * T) * phi(d1) * Math.sqrt(T) * FV).toFixed(4);
            } else if (callput === 'fwd') {
                return 0.0;
            } else {
                return 'N/A';
            }
        } else {
            return 'N/A';
        }
    },
    BarrierOptPayoff: (KIKO: string, abovebelow: string, C: string, S: number, H: number, K: number, FV: number, Premium = 0) => _BarrierOptPayoff(KIKO, abovebelow, C, S, H, K, FV, Premium),
    InverseNormal: (mean: number) => { // parameter is used to  trigger interactive function call.
        let u = 0; let v = 0;
        while (u === 0) u = Math.random(); // Converting [0,1) to (0,1)
        while (v === 0) v = Math.random();
        return (Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v)).toFixed(6);
    },
    getCommodityUnits: (comm: string, property: string) => {
        const myObj: any = {
            Wheat: { Prod_Units: 'tonne', Futures_Quote: 'cts/bushel', OTC_Quote: '$/tonne', Conversion: 0.367437 },
            Canola: { Prod_Units: 'tonne', Futures_Quote: '$/tonne', OTC_Quote: '$/tonne', Conversion: 1.0 },
            Cotton: { Prod_Units: 'bale', Futures_Quote: 'cts/lb', OTC_Quote: '$/bale', Conversion: 5.0 },
            Corn: { Prod_Units: 'Tonne', Futures_Quote: 'cts/bshl', OTC_Quote: '$/tonne', Conversion: 0.39368 },
            Soybeans: { Prod_Units: 'Tonne', Futures_Quote: 'cts/bshl', OTC_Quote: '$/tonne', Conversion: 0.367437 },
            Soymeal: { Prod_Units: 'Tons', Futures_Quote: '$/ton', OTC_Quote: '$/ton', Conversion: 1.00 },
            Soyoil: { Prod_Units: 'Tonne', Futures_Quote: 'cts/lb', OTC_Quote: '$/tonne', Conversion: 1.00 },
            Sugar11: { Prod_Units: 'Tonne', Futures_Quote: 'cts/lb', OTC_Quote: '$/tonne', Conversion: 22.0460 },
            Coffee: { Prod_Units: 'Bags', Futures_Quote: 'cts/lb', OTC_Quote: '$/bag', Conversion: 1.32276 },
            Cocoa: { Prod_Units: 'Tonne', Futures_Quote: '$/tonne', OTC_Quote: '$/tonne', Conversion: 1.00 }
        };
        return myObj[comm][property];
    },
    FX_Exotics: (output: string, callput: string, payoff: string, invertquote: string, S: number, K: number, H: number, T: number, rdom: number, rfor: number, vol: number, FV: number) => {
        if (payoff === 'Vanilla') {
            return _FXVanilla(callput, invertquote, S, K, T, rdom, rfor, vol, FV);
        } else if (payoff === 'UpOut' || payoff === 'UpIn' || payoff === 'DownOut' || payoff === 'DownIn') {
            return _FXBarrier(callput, payoff, invertquote, S, K, H, T, rdom, rfor, vol, FV);
        } else if (payoff === 'Binary') {
            return _FXBinary(callput, invertquote, S, K, T, rdom, rfor, vol, FV);
        } else if (payoff === 'Asset') {
            return _FXAsset(invertquote, S, FV);
        } else {
            return 'N/A';
        }
    },
    FXAsset: (invertquote: string, S: number, FV: number) => _FXAsset(invertquote, S, FV),
    FXBarrier: (callput: string, payoff: string, invertquote: string, S: number, K: number, H: number, T: number, rdom: number, rfor: number, vol: number, FV: number) => _FXBarrier(callput, payoff, invertquote, S, K, H, T, rdom, rfor, vol, FV),
    FXBinary: (callput: string, invertquote: string, S: number, K: number, T: number, rdom: number, rfor: number, vol: number, FV: number) => _FXBinary(callput, invertquote, S, K, T, rdom, rfor, vol, FV),
    /* don't need to replace put-call and change FV. That is just equivalence */
    FXVanilla: (callput: string, invertquote: string, S: number, K: number, T: number, rdom: number, rfor: number, vol: number, FV: number) => _FXVanilla(callput, invertquote, S, K, T, rdom, rfor, vol, FV),
    implied_volatility: (cp: string, p: number, S: number, K: number, rd: number, rf: number, T: number, guess: number) => {
        const EPS = 0.000001;
        const DELTA_VOL = 0.000001;
        const maxIter = 250;

        let vol1 = guess;
        let vol2 = guess;
        let optval1 = p;
        let optval2 = p;
        let dsigma = 0;
        let i = 0;

        do {
            optval1 = _VanillaOption(cp, S, K, T, rd, rf, vol1);
            vol2 = vol1 - DELTA_VOL;
            optval2 = _VanillaOption(cp, S, K, T, rd, rf, vol2);
            dsigma = (optval2 - optval1) / DELTA_VOL;

            i++;
        } while (Math.abs(dsigma) < EPS || i === maxIter); {
            vol1 = vol1 - (p - optval1) / dsigma;
            i = i + 1;
        };

        return vol1;
    },
    VanillaOption: (callput: string, S: number, K: number, T: number, rd: number, rf: number, vol: number) => _VanillaOption(callput, S, K, T, rd, rf, vol),
    FXPayoffs: (opttype: string, payofftype: string, InvertQuote: string, S: number, K: number, H: number, FV: number, Premium = 0) => {
        let ans: any = 0;
        if (InvertQuote === 'Yes') {
            S = 1 / S;
            K = 1 / K;
            H = 1 / H;

            InvertQuote = 'No';
        }
        if (payofftype === 'Vanilla') {
            ans = _OptPayoff(opttype, InvertQuote, S, K, FV, Premium);
        } else if (payofftype === 'Binary') {
            ans = _BinaryPayoff(opttype, InvertQuote, S, K, FV, Premium);
        } else {
            if (payofftype === 'UpOut') {
                ans = _BarrierOptPayoff('KO', 'above', opttype, S, H, K, FV, Premium);
            } else if (payofftype === 'UpIn') {
                ans = _BarrierOptPayoff('KI', 'above', opttype, S, H, K, FV, Premium);
            } else if (payofftype === 'DownOut') {
                ans = _BarrierOptPayoff('KO', 'below', opttype, S, H, K, FV, Premium);
            } else if (payofftype === 'DownIn') {
                ans = _BarrierOptPayoff('KI', 'below', opttype, S, H, K, FV, Premium);
            } else {
                return '#NA';
            }
        }
        return ans;
    },
    BinaryPayoff: (opttype: string, InvertQuote: string, S: number, K: number, FV: number, Premium = 0) => _BinaryPayoff(opttype, InvertQuote, S, K, FV, Premium),

};
/* The cummulative Normal distribution function: */
const CND = (x: number): number => {
    if (x < 0) {
        return (1 - CND(-x));
    } else {
        const k = 1 / (1 + 0.2316419 * x);
        return (1 - Math.exp(-x * x / 2) / Math.sqrt(2 * Math.PI) * k * (0.31938153 + k * (-0.356563782 + k * (1.781477937 + k * (-1.821255978 + k * 1.330274429)))));
    }
};

const phi = (x: number): number => Math.exp(-x * x / 2) / Math.sqrt(2 * Math.PI);

const _FXVanilla = (callput: string, invertquote: string, S: number, K: number, T: number, rdom: number, rfor: number, vol: number, FV: number) => {
    let rd;
    let rf;

    rd = rdom;
    rf = rfor;

    if (invertquote === 'Yes') {
        if (S > 0 && K > 0) {
            S = 1.0 / S;
            K = 1.0 / K;
            rd = rfor;
            rf = rdom;
        } else {
            return 'N/A';
        }
    }

    const d1 = (Math.log(S / K) + (rd - rf + vol * vol / 2) * T) / (vol * Math.sqrt(T));
    const d2 = d1 - vol * Math.sqrt(T);

    if (callput === 'call') {
        return ((S * Math.exp(-rf * T) * CND(d1) - K * Math.exp(-rd * T) * CND(d2)) * FV).toFixed(4);
    } else if (callput === 'put') {
        return ((K * Math.exp(-rd * T) * CND(-d2) - S * Math.exp(-rf * T) * CND(-d1)) * FV).toFixed(4);
    } else if (callput === 'fwd') {
        return ((S * Math.exp(-rf * T) - K * Math.exp(-rd * T)) * FV).toFixed(4);
    } else {
        return 'N/A';
    }
};
const _FXBinary = (callput: string, invertquote: string, S: number, K: number, T: number, rdom: number, rfor: number, vol: number, FV: number) => {
    let rd;
    let rf;

    rd = rdom;
    rf = rfor;

    if (invertquote === 'Yes') {
        if (S > 0 && K > 0) {
            S = 1.0 / S;
            K = 1.0 / K;
            rd = rfor;
            rf = rdom;
        } else {
            return 'N/A';
        }
    }

    const d1 = (Math.log(S / K) + (rd - rf + vol * vol / 2) * T) / (vol * Math.sqrt(T));
    const d2 = d1 - vol * Math.sqrt(T);

    if (callput === 'call') {
        return (Math.exp(-rd * T) * CND(d2) * FV).toFixed(6);
    } else if (callput === 'put') {
        return (Math.exp(-rd * T) * CND(-d2) * FV).toFixed(6);
    } else {
        return 'N/A';
    }
};
const _FXBarrier = (callput: string, payoff: string, invertquote: string, S: number, K: number, H: number, T: number, rdom: number, rfor: number, vol: number, FV: number) => {
    let rd;
    let rf;

    rd = rdom;
    rf = rfor;

    if (invertquote === 'Yes') {
        if (S > 0 && K > 0) {
            S = 1.0 / S;
            K = 1.0 / K;
            H = 1.0 / H; /* if S > K it is now S < K  */
            rd = rfor;
            rf = rdom;
        } else {
            return 'N/A';
        }
    }

    const lambda = (rd - rf + 0.5 * vol * vol) / (vol * vol);
    const volsqrtT = vol * Math.sqrt(T);
    const c1 = H * H / (S * K);
    const y = Math.log(c1) / volsqrtT + lambda * volsqrtT;
    const x1 = Math.log(S / H) / volsqrtT + lambda * volsqrtT;
    const y1 = Math.log(H / S) / volsqrtT + lambda * volsqrtT;

    const d1 = (Math.log(S / K) + (rd - rf + vol * vol / 2) * T) / (vol * Math.sqrt(T));
    const d2 = d1 - vol * Math.sqrt(T);

    const c = S * Math.exp(-rf * T) * CND(d1) - K * Math.exp(-rd * T) * CND(d2);
    const cdi = S * Math.exp(-rf * T) * Math.pow(H / S, 2.0 * lambda) * CND(y) - K * Math.exp(-rd * T) * Math.pow(H / S, 2.0 * lambda - 2.0) * CND(y - volsqrtT);
    const cdo = S * Math.exp(-rf * T) * CND(x1) - K * Math.exp(-rd * T) * CND(x1 - volsqrtT) - S * Math.exp(-rf * T) * Math.pow(H / S, 2.0 * lambda) * CND(y1) + K * Math.exp(-rd * T) * Math.pow(H / S, 2.0 * lambda - 2.0) * CND(y1 - volsqrtT);
    const cui = S * Math.exp(-rf * T) * CND(x1) - K * Math.exp(-rd * T) * CND(x1 - volsqrtT) - S * Math.exp(-rf * T) * Math.pow(H / S, 2.0 * lambda) * (CND(-y) - CND(-y1)) + K * Math.exp(-rd * T) * Math.pow(H / S, 2.0 * lambda - 2.0) * (CND(-y + volsqrtT) - CND(-y1 + volsqrtT));

    const p = K * Math.exp(-rd * T) * CND(-d2) - S * Math.exp(-rf * T) * CND(-d1);
    const pui = -S * Math.exp(-rf * T) * Math.pow(H / S, 2.0 * lambda) * CND(-y) + K * Math.exp(-rd * T) * Math.pow(H / S, 2.0 * lambda - 2.0) * CND(-y + volsqrtT);
    const puo = -S * Math.exp(-rf * T) * CND(-x1) + K * Math.exp(-rd * T) * CND(-x1 + volsqrtT) + S * Math.exp(-rf * T) * Math.pow(H / S, 2.0 * lambda) * CND(-y1) - K * Math.exp(-rd * T) * Math.pow(H / S, 2.0 * lambda - 2.0) * CND(-y1 + volsqrtT);
    const pdi = -S * Math.exp(-rf * T) * CND(-x1) + K * Math.exp(-rd * T) * CND(-x1 + volsqrtT) + S * Math.exp(-rf * T) * Math.pow(H / S, 2.0 * lambda) * (CND(y) - CND(y1)) - K * Math.exp(-rd * T) * Math.pow(H / S, 2.0 * lambda - 2.0) * (CND(y - volsqrtT) - CND(y1 - volsqrtT));

    if (payoff === 'UpOut') {
        if (callput === 'call') {
            if (H < K) {
                return 0.0;
            } else {
                return FV * (c - cui);
            }
        } else if (callput === 'put') {
            if (H < K) {
                return FV * puo;
            } else {
                return FV * (p - pui);
            }
        } else if (callput === 'fwd') {
            return 'N/A';
        } else {
            return 'N/A';
        }
    } else if (payoff === 'UpIn') {
        if (callput === 'call') {
            if (H < K) {
                return FV * c;
            } else {
                return FV * cui;
            }
        } else if (callput === 'put') {
            if (H < K) {
                return FV * (p - puo);
            } else {
                return FV * pui;
            }
        } else if (callput === 'fwd') {
            return 'N/A';
        } else {
            return 'N/A';
        }
    } else if (payoff === 'DownOut') {
        if (callput === 'call') {
            if (H < K) {
                return FV * (c - cdi);
            } else {
                return FV * cdo;
            }
        } else if (callput === 'put') {
            if (H < K) {
                return FV * (p - pdi);
            } else {
                return 0.0;
            }
        } else if (callput === 'fwd') {
            return 'N/A';
        } else {
            return 'N/A';
        }
    } else if (payoff === 'DownIn') {
        if (callput === 'call') {
            if (H < K) {
                return FV * cdi;
            } else {
                return FV * (c - cdo);
            }
        } else if (callput === 'put') {
            if (H < K) {
                return FV * pdi;
            } else if (H >= K) {
                return FV * p;
            }
        } else if (callput === 'fwd') {
            return 'N/A';
        } else {
            return 'N/A';
        }
    } else {
        return 'N/A';
    }
    return 'N/A';
};
const _FXAsset = (invertquote: string, S: number, FV: number) => {
    if (invertquote === 'Yes') {
        if (S > 0) {
            S = 1.0 / S;
            return FV * S;
        } else {
            return 'N/A';
        }
    } else {
        return FV * S;
    }
};
const _VanillaOption = (callput: string, S: number, K: number, T: number, rd: number, rf: number, vol: number) => {
    const d1 = (Math.log(S / K) + (rd - rf + vol * vol / 2) * T) / (vol * Math.sqrt(T));
    const d2 = d1 - vol * Math.sqrt(T);
    if (callput === 'call') {
        return Number(((S * Math.exp(-rf * T) * CND(d1) - K * Math.exp(-rd * T) * CND(d2)) * 1.0).toFixed(4));
    } else {
        return Number(((K * Math.exp(-rd * T) * CND(-d2) - S * Math.exp(-rf * T) * CND(-d1)) * 1.0).toFixed(4));
    }
};
const _OptPayoff = (type: string, InvertQuote: string, S: number, K: number, FV: number, Premium = 0) => {
    let Payoff = 0;
    if (type === 'call') {
        if (InvertQuote === 'No') {
            Payoff = FV * Math.max(S - K, 0);
        } else if (InvertQuote === 'Yes') {
            Payoff = FV * Math.max(1 / S - 1 / K, 0);
        } else {
            return '#NA';
        }
    } else if (type === 'put') {
        if (InvertQuote === 'No') {
            Payoff = FV * Math.max(K - S, 0);
        } else if (InvertQuote === 'Yes') {
            Payoff = FV * Math.max(1 / K - 1 / S, 0);
        } else {
            return '#NA';
        }
    } else if (type === 'fwd' || type === 'asset') {
        if (InvertQuote === 'No') {
            Payoff = FV * (S - K);
        } else if (InvertQuote === 'Yes') {
            Payoff = FV * (1 / S - 1 / K);
        } else {
            return '#NA';
        }
    } else if (type === 'spot') {
        if (InvertQuote === 'No') {
            Payoff = FV * S;
        } else if (InvertQuote === 'Yes') {
            Payoff = FV / S;
        } else {
            return '#NA';
        }
    } else {
        return ' #NA#';
    }
    return Payoff + Premium;
};
const _BarrierOptPayoff = (KIKO: string, abovebelow: string, cp: string, S: number, H: number, K: number, FV: number, Premium = 0) => {
    let Payoff: any;

    /* KIKO is KI or KO */
    /* abovebelow is above or below */
    /* no invert quote  */

    if (KIKO === 'KI') {
        if (abovebelow === 'above') {
            if (S >= H) {
                if (cp === 'call') {
                    Payoff = FV * Math.max(S - K, 0);
                } else if (cp === 'put') {
                    Payoff = FV * Math.max(K - S, 0);
                } else if (cp === 'fwd') {
                    Payoff = FV * (S - K);
                } else {
                    Payoff = 'NA';
                }
            } else {
                Payoff = 0.0; /* never KI's */
            }
        } else {
            if (S <= H) {
                if (cp === 'call') {
                    Payoff = FV * Math.max(S - K, 0);
                } else if (cp === 'put') {
                    Payoff = FV * Math.max(K - S, 0);
                } else if (cp === 'fwd') {
                    Payoff = FV * (S - K);
                } else {
                    Payoff = 'NA';
                }
            } else {
                Payoff = 0.0; /* never KO's */
            }
        }
    } else {
        if (abovebelow === 'above') {
            if (S >= H) {
                Payoff = 0.0; /*  KO's */
            } else {
                if (cp === 'call') {
                    Payoff = FV * Math.max(S - K, 0);
                } else if (cp === 'put') {
                    Payoff = FV * Math.max(K - S, 0);
                } else if (cp === 'fwd') {
                    Payoff = FV * (S - K);
                } else {
                    Payoff = 'NA';
                }
            }
        } else {
            if (S <= H) {
                Payoff = 0.0; /*  KO's */
            } else {
                if (cp === 'call') {
                    Payoff = FV * Math.max(S - K, 0);
                } else if (cp === 'put') {
                    Payoff = FV * Math.max(K - S, 0);
                } else if (cp === 'fwd') {
                    Payoff = FV * (S - K);
                } else {
                    Payoff = 'NA';
                }
            }
        }
    } /* KIKO */
    return Payoff + Premium;
};
const _BinaryPayoff = (opttype: string, InvertQuote: string, S: number, K: number, FV: number, Premium = 0) => {
    let Payoff = 0;

    if (opttype === 'call') {
        if (S > K) {
            Payoff = FV;
        } else {
            Payoff = 0.0;
        }
    } else if (opttype === 'put') {
        if (S < K) {
            Payoff = FV;
        } else {
            Payoff = 0.0;
        }
    } else {
        return '#NA';
    }
    return Payoff + Premium;
};
