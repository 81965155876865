<section class="about_sec">
    <div class="container-fluid w_80">
      <div class="row">
        <div class="col-lg-12">
          <h3>Terms of Use</h3>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="container-fluid w_80 mb-5">
      <div class="row">
        <div class="col-lg-12">
          <div class="wrapper_white p-7">
            <h1>Our terms and services</h1>
            <div class="common-content mt-4">
              <ol>
                <li>
                  <h3> Intellectual Property Rights</h3>
                  <ul>
                    <li>
                      FinStream own, or are the licensee of, all right, title and interest (including all Intellectual
                      Property Rights) in the Platform, including courses and chapters created by us (even if done so
                      on your instruction), source code, object code, translations, compilations, partial copies,
                      derivative works, text, photos, graphic designs, images and video content (Our IP). This
                      agreement does not transfer any Intellectual Property Rights from us to you or any third
                      parties,
                      including in or to Our IP. If you infringe our Intellectual Property Rights or any other third
                      party,
                      including in or to Our IP, we have the right to deny access to or terminate your use of the
                      Platform immediately.
                    </li>
                    <li>
                      You agree you may not
                      <ul>
                        <li>copy, modify, or create derivative works based on Our IP;</li>
                        <li>distribute, transfer, sublicense, lease, lend, or rent Our IP to any third party;</li>
                        <li>modify, decompile, or disassemble Our IP; or</li>
                        <li>do anything that would prejudice our right, title or interest in Our IP.</li>
                      </ul>
                    </li>
                    <li>The Finstream name, logo and other Finstream trademarks, service marks, graphics, and logos
                      used in connection with the Platform are trademarks or registered trademarks of ours in
                      Australia and internationally. You are granted no right or licence with respect to these
                      trademarks.</li>
                  </ul>
                </li>
                <li>
                  <h3>Acceptable Use</h3>
                  <ul>
                    <li>You must not:
                      <ul>
                        <li>use the Platform for any purpose that is illegal, fraudulent, or is otherwise
                          objectionable,
                          offensive, unlawful, deceptive or harmful;</li>
                        <li>copy, modify, or create derivative works based on the content available on or through the
                          Platform;</li>
                        <li>infringe the Intellectual Property Rights, privacy or confidentiality of any third party;
                        </li>
                        <li>engage in any activity that may result in injury, death, property damage, and/or liability
                          of
                          any kind;</li>
                        <li>interfere or disrupt the Platform, servers or networks connected to the Platform or
                          another
                          person’s use of the Platform, including by transmitting any worms, viruses, spyware,
                          malware or any other code of a destructive or disruptive nature;</li>
                        <li>distribute viruses, corrupt files, or any other similar software or programs that may
                          damage the operation of any computer hardware or software; or</li>
                        <li>engage in any other conduct that inhibits any other person from using or enjoying the
                          Platform.</li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <h3> Updates and back-ups</h3>
                  <ul>
                    <li>We will provide, configure, install and maintain any and all updates, upgrades, enhancements,
                      releases, corrections, bug fixes, patches and modifications to the Platform as we deem
                      necessary (Updates).</li>
                    <li>We may suspend access to, or functionality on, the Platform from time to time to implement
                      such Updates. We will use reasonable efforts to notify you of any Update that may interrupt the
                      Platform.</li>
                    <li>You must accept all Updates necessary for the proper function and security of the Platform if
                      and when such Updates are released by us.</li>
                  </ul>
                </li>
                <li>
                  <h3>Liability</h3>
                  <ul>
                    <li>To the extent permitted by law:
                      <ul>
                        <li>the services and all content delivered to you through the Platform are (except as
                          expressly stated by us) provided &quot;as is&quot; and &quot;as available&quot; for your use
                          without warranties
                          of any kind, either express or implied, including all implied warranties of merchantability,
                          fitness for a particular purpose, title, and non-infringement. You acknowledge that the
                          public internet is an inherently insecure environment and that we have no control over the
                          privacy of any communications or the security of any data outside of our internal systems;
                        </li>
                        <li>we, our directors, officers, employees, affiliates, agents, contractors, or licensors will
                          not
                          be liable for any direct, indirect, incidental, punitive, special, or consequential damages
                          arising from your use of the Platform or for any other claim related in any way to your use
                          of the Platform and/or content, including any errors or omissions in any content, or any
                          loss or damage of any kind incurred as a result of the use of any content posted,
                          transmitted, or otherwise made available via the Platform; and</li>
                        <li>our aggregate liability in connection with this agreement whether in contract, tort
                          (including negligence), statute or otherwise will not exceed an amount equal to the Fees
                          paid by you to us in the 12 months preceding any claim.</li>
                      </ul>
                    </li>
                    <li>You acknowledge that the use of the Platform is at your sole risk. You are responsible for
                      backing up your own system, including any content or materials acquired, developed, created,
                      through the Platform. All content on the Platform (both theory and interactive components)
                      should not be used for trading or pricing. Any calculators are provided for educational purposes
                      only.</li>
                    <li>This clause relates to consumers (as defined in the Competition and Consumer Act 2010 (Cth)):
                      These warranties are in addition to other rights and remedies that are available to you at law.
                      Our services come with guarantees that cannot be excluded under the Australian Consumer
                      Law. For major failures with the Platform, you are entitled:
                      <ul>
                        <li>to cancel your contract with us; and</li>
                        <li>to a refund for the unused portion, or to compensation for its reduced value.</li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <h3>Data</h3>
                  <ul>
                    <li>You acknowledge and agree:
                      <ul>
                        <li>we may collect aggregated information about your activities, and details of how you use
                          the Platform, the types of content you engage with or the frequency and duration of your
                          activities;</li>
                        <li>we may collect metadata, which is technical data that can describe the details of how
                          User Content was collected and how that content is formatted; and</li>
                        <li>we may automatically store in log files, including IP addresses, browser type and
                          language, Internet service provider (ISP), referring and exit websites and applications,
                          operating system, date/time stamp, and clickstream data to analyse trends, to administer
                          the Platform, to generally improve the Platform and for marketing.</li>
                        <li></li>
                      </ul>
                    </li>
                    <li>You acknowledge and agree we may:
                      <ul>
                        <li>collect aggregated information about your activities, and details of how you use the
                          Platform, the types of content you engage with or the frequency and duration of your
                          activities;</li>
                        <li>collect metadata, which is technical data that can describe the details of how users
                          interact with the Platform; and</li>
                        <li>automatically store in log files, including IP addresses, browser type and language,
                          Internet service provider (ISP), referring and exit websites and applications, operating
                          system, date/time stamp, and clickstream data to analyse trends, to administer the
                          Platform, to generally improve the Platform and for marketing.</li>

                      </ul>
                    </li>
                    <li>You agree we may, for our own business purposes, use and incorporate any ideas,
                      suggestions, concepts, know-how or techniques contained in information received from you via
                      the Platform that directly relates to the Platform, including any suggested changes or
                      modifications to the Platform.</li>
                  </ul>
                </li>
                <li>
                  <h3>Force majeure</h3>
                  <ul>
                    <li>If a Force Majeure Event occurs, the affected party must notify the other party and the
                      obligations of the party will be suspended to the extent that they are affected by the relevant
                      Force Majeure Event until that Force Majeure Event has ceased.</li>
                  </ul>
                </li>
                <li>
                  <h3>Definitions and interpretation</h3>
                  <ul class="no-list-style">
                    <li><strong>Force Majeure Event</strong> means any act, event or cause including earthquakes,
                      cyclones, floods,
                      fires, lightening, storms or other acts of God, strikes or industrial disputes, riots, terrorist
                      acts,
                      civil disturbances, breakages of machinery, or industrial conditions, or arising out of any
                      other
                      unexpected and exceptional cause, delays in transportation and dispositions or orders of
                      governmental authority, which:
                      <ul>
                        <li>directly or indirectly results in a party being prevented from or delayed in performing
                          any
                          of its obligations under this agreement; and</li>
                        <li>is beyond the reasonable control of that party.</li>
                      </ul>
                    </li>
                    <li><strong>Intellectual Property Rights</strong> means all present and future rights conferred by
                      statute, common
                      law or equity in or in relation to any copyright, trade marks, designs, patents, circuit
                      layouts,
                      plant varieties, business and domain names, confidential information, inventions and other
                      results of intellectual activity in the industrial, commercial, scientific, literary or artistic
                      fields
                      whether or not registered, registrable or patentable.</li>
                    <li><strong>Platform</strong> means our SaaS online learning platform and access to applicable
                      Streams.</li>
                    <li><strong>Professional Services</strong> means the educational platform regarding financial
                      subjects through
                      concise content and targeted interactive delivery models.</li>
                    <li><strong>Start Date</strong> means the date set out in the Order Form.</li>
                    <li><strong>Stream</strong> means the Streams set out in the Order Form or as otherwise agreed
                      from time to time.</li>
                  </ul>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>