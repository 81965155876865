import { Component, Inject, OnInit, PLATFORM_ID, OnDestroy } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ResourceService } from '../../../services/resource.service';
import { ChapterService } from '../../../services/chapter.service';
import { SharedService } from '../../../services/shared.service';
import { Resource } from '../../../model/resource.model';
import { Chapter, ChapterSuccessData } from '../../../model/chapter.model';
import { User } from '../../../model/user.model';
declare let $: any;
declare function onLoadCarousel(): any; // from custom.js
@Component({
  selector: 'app-inner-home',
  templateUrl: './inner-home.component.html',
  styleUrls: ['./inner-home.component.scss']
})
export class InnerHomeComponent implements OnInit, OnDestroy {
  user = new User();
  resource = new Resource();
  resourcesList: Resource[] = [];
  chaptersList: Chapter[] = [];
  httpError: HttpErrorResponse | undefined;
  $resourceSub: any;
  page = '';
  isLoading = false;
  isLoadingChapters = false;
  isFetchChapterData = false;
  noResult = false;
  noResultChapter = false;
  sub: any = {};
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private _sharedService: SharedService,
    private _chapterService: ChapterService,
    @Inject(PLATFORM_ID) private platform: object
  ) {
    this.route.data.subscribe((data: any) => {
      this.page = data.page;
    });
  }

  ngOnInit(): void {
    this.sub.user = this._sharedService.user.subscribe((res: any) => {
      this.user = res;
    });
    this.$resourceSub = this._sharedService.resource.subscribe((res: Resource[]) => {
      this.resourcesList = res || [];
      if (this.resourcesList.length === 0) {
        this.noResult = true;
      } else {
        this.resource = this.resourcesList[0];
        this.getChapters(this.resource._id);
      }
    });
  }
  getChapters(resourceId: string): void {
    if (this.isFetchChapterData) { // avoid multiple request
      return;
    }
    this.isFetchChapterData = true;
    this.isLoadingChapters = true;
    this.noResultChapter = false;
    this.httpError = undefined;
    this._chapterService.search(
      '',
      resourceId,
      1,
      4,
      'title'
    ).subscribe((res: HttpResponse<ChapterSuccessData>) => {
      if (isPlatformBrowser(this.platform)) {
        $('#resource_slider1').trigger('destroy.owl.carousel');
      }
      this.chaptersList = res.body?.data || [];
      if (this.chaptersList.length === 0) {
        this.noResultChapter = true;
      }
      if (isPlatformBrowser(this.platform)) {
        setTimeout(() => {
          onLoadCarousel();
          this.isLoadingChapters = false;
        }, 1000);
      }
      setTimeout(() => {
        this.isFetchChapterData = false;
      }, 3000);
    }, (err: HttpErrorResponse) => {
      this.isLoadingChapters = false;
      this.noResultChapter = true;
      this.httpError = err;
      setTimeout(() => {
        this.isFetchChapterData = false;
      }, 3000);
    });
  }
  getStart(el: HTMLElement) {
    // el.scrollIntoView({ behavior: 'smooth' });
    this.getStartLogin();
  }
  getStartLogin() {
    if (this.user._id) {
      if (this.resource._id) {
        this.router.navigate(['/resources/' + this.resource.slug]);
      } else {
        this.router.navigate(['/resources']);
      }
    } else {
      document.getElementById('btnTrigger')?.click();
    }
  }
  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 2000
    });
  }
  imageIsLoaded(id: string) {
    try {
      document.getElementById(id)?.remove();
    } catch (error) {
    }
  }
  ngOnDestroy() {
    if (this.$resourceSub) {
      this.$resourceSub.unsubscribe();
    }
    if (this.sub.user) {
      this.sub.user.unsubscribe();
    }
  }
}
