<section class="banner_sec">
    <div class="position-relative">
        <img src="assets/img/login_bg.jpg" class="img-fluid main_bg">
        <div class="text_overlay">
            <div class="form_log">
                <!-- <img src="assets/img/University_of_Sydney2.png"> -->
                <h1>Sign in to continue to your account</h1>
                <form  id="login_sec" [formGroup]="formGroup" #form="ngForm" (ngSubmit)="login()" autocomplete="off" novalidate>
                    <div class="form-row">
                        <div class="col-12">
                            <input type="email" class="form-control inputRadius" formControlName="email" placeholder="Email">
                            <small [@inOutAnimation] class="form-text error"
                                *ngIf="form.submitted && formGroup.controls['email'].hasError('required')">Please
                                fill this
                                field</small>
                            <small [@inOutAnimation] class="form-text error"
                                *ngIf="form.submitted && formGroup.controls['email'].hasError('pattern')">Invalid
                                email
                            </small>
                        </div>
                        <div class="col-12">
                            <input type="password" class="form-control inputRadius" formControlName="password" placeholder="Password" autocomplete="new-password">
                            <small [@inOutAnimation] class="form-text error"
                                *ngIf="form.submitted && formGroup.controls['password'].hasError('required')">
                                Please fill this field
                            </small>
                            <small [@inOutAnimation] class="form-text error" *ngIf="errorMessage">
                                {{errorMessage}}
                            </small>
                        </div>
                        <div class="col-12 text-left pt-2">
                            <label class="check-container">Keep me signed in
                                <input type="checkbox" name="rememberMe" formControlName="rememberMe">
                                <span class="checkmark"></span>
                            </label>
                            <a class="frgt_pswd" id="forget" href="#">Forgot password?</a>
                        </div>
                        <div class="col-lg">
                            <button class="common_btn mr-0 ml-0 mt-3 w-100" [disabled]="btnDisabled">Sign in
                                <mat-spinner class="btnLoading" [diameter]="20" *ngIf="btnDisabled">
                                </mat-spinner>
                            </button>
                        </div>
                        <div class="col-12 text-left">
                            <h6 class="pt-4">Not a member yet? <strong><a href="#" class="back_signup"
                                        data-toggle="modal" data-target="#createAccount">Sign Up</a></strong></h6>

                        </div>
                    </div>
                </form>
                <form  id="forget_pswd" class="d_none" (ngSubmit)="forgotPassword()" autocomplete="off">
                    <div class="form-row ">
                        <div class="col-12">
                            <input type="email" class="form-control" placeholder="Email address" name="email"
                                [(ngModel)]="userModel.email">
                            <small [@inOutAnimation] class="form-text error" *ngIf="errorMessage"
                                [innerHTML]="errorMessage">
                            </small>
                        </div>
                        <button type="submit" class="common_btn mt-4 w-100" [disabled]="btnDisabled">Submit
                            <mat-spinner class="btnLoading white" [diameter]="20" *ngIf="btnDisabled">
                            </mat-spinner>
                        </button>
                        <div class="col-12 text-left">
                            <h6 class="pt-4">Not a member yet? <strong><a href="#" class="back_signup"
                                        data-toggle="modal" data-target="#createAccount">Sign Up</a></strong></h6>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</section>
<div class="scroll_parallax">
    <section class="login_abt">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <img src="assets/img/component1.png" class="img-fluid">
                </div>
                <div class="col-lg-4">
                    <h2>World class resources at <br class="breake_lg">your fingertips</h2>
                    <p>Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                        dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
                        ea rebum stet clita kasd gubergren no sea takimata sanctus est
                    </p>
                </div>
            </div>
        </div>
    </section>
    <div class="container-fluid pt-5 mb-5">
        <div class="row">
            <div class="col-md-4 col-lg-4">
                <div class="white_wrp">
                    <img src="assets/img/education.svg">
                    <h4>Industry Leaders<br> and Experts</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed efficitur tortor nulla, vel
                        pellentesque urna </p>
                    <a href="about.html" class="secondary_btn">View Experts</a>
                </div>
            </div>
            <div class="col-md-4 col-lg-4">
                <div class="white_wrp">
                    <img src="assets/img/learn.svg">
                    <h4>Learn and Refine<br> Your Skills Online</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed efficitur tortor nulla, vel
                        pellentesque urna pulvinar sed.</p>
                    <a href="resources-efx.html" class="secondary_btn">Find out more</a>
                </div>
            </div>
            <div class="col-md-4 col-lg-4">
                <div class="white_wrp">
                    <img src="assets/img/document.svg">
                    <h4>New Content<br> Added Frequently</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed efficitur tortor nulla, vel
                        pellentesque urna pulvinar sed.</p>
                    <a href="resources-efx.html" class="secondary_btn">View New Content</a>
                </div>
            </div>
        </div>
    </div>
    <!-- <section class="partners">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2>Partners</h2>
                    <div class="owl-carousel owl-theme" id="partners">
                        <div class="slide_item"><img src="assets/img/University_of_Sydney1.png"></div>
                        <div class="slide_item"><img src="assets/img/2000px-Deloitte.svg.png"></div>
                        <div class="slide_item"><img src="assets/img/ernst-young-logo.png"></div>
                        <div class="slide_item"><img
                                src="assets/img/faculty-of-education-university-of-cambridge-clare-hall-cambridge-university-of-edinburgh-school-university-logo-1f36fc36a2621e52e7e3a8e9348e2421.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <app-footer></app-footer>
</div>
<!-- Modal -->
<app-signup-modal></app-signup-modal>
