<p class="mt-4">
    Several of our learning units have exam and <span style="color: #e66000;">certificate offerings</span> with others rolling out in the near future.
    To learn more about this and the general features of the learning streams see These videos on "Fundamentals of FOREX" and "Carbon and Emission Markets"
</p>
<div class="mt-5" style="text-align: center;">
    <div class="col-lg-12">
        <div class="row">
            <div class="col-lg-6">
                <video width="400" poster="assets/img/thumbnail/foreign-exchange1.png" controls>
                    <source src="https://cdn.staging.finstream.com.au/assets/videos/1920_home.mp4" type="video/mp4">
                    Your browser does not support HTML video.
                </video>
            </div>
            <div class="col-lg-6">
                <video width="400" poster="assets/img/thumbnail/carbon1.png" controls>
                    <source src="https://cdn.staging.finstream.com.au/assets/videos/carbon.mp4" type="video/mp4">
                    Your browser does not support HTML video.
                </video>
            </div>
        </div>
        <br><br>
        <div class="divider"></div>
    </div>
</div>