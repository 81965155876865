import { Component, OnInit, Input, Output, EventEmitter, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { FormGroup, FormControl, Validators, FormGroupDirective } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { Animation } from '../../../utilities/animation';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  animations: [Animation.inOutAnimation()]
})
export class ResetPasswordComponent implements OnInit {
  formGroup = new FormGroup({});
  errorMessage = '';
  btnDisabled = false;
  key: any = '';
  page = '';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _authService: AuthService,
    private _userService: UserService,
    @Inject(PLATFORM_ID) private platform: any
  ) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platform)) {
      this.formGroup = new FormGroup({
        password: new FormControl('', Validators.compose([
          Validators.required,
          Validators.minLength(7)
        ])),
        confirmPassword: new FormControl('', Validators.compose([
          Validators.required,
          Validators.minLength(7)
        ]))
      });
      this.key = this.route.snapshot.queryParamMap.get('key');
      if (this.router.url.includes('account/reset-password')) {
        this.page = 'reset-password';
        this._authService.validateForgotPasswordLink(this.key).subscribe((res) => {
          console.log(res);
        }, (err) => {
          console.log(err);
          this.router.navigate(['/login']);
        });
      } else if (this.router.url.includes('account/set-password')) {
        this.page = 'set-password';
        this._userService.validateUserInviteLink(this.key).subscribe((res) => {
          console.log(res);
        }, (err) => {
          console.log(err);
          this.router.navigate(['/page-not-found']);
        });
      }
    }
  }
  onFormSubmit(form: FormGroupDirective) {
    this.errorMessage = '';
    if (this.formGroup.invalid) {
      // this.errorMessage = '* Some fields are not valid!';
      return;
    }
    if (this.formGroup.value.password !== this.formGroup.value.confirmPassword) {
      this.errorMessage = 'Passwords not matching!';
      return;
    }
    this.btnDisabled = true;
    if (this.router.url.includes('account/reset-password')) {
      this._authService.resetPassword(this.key, this.formGroup.value.password).subscribe((res: any) => {
        this.router.navigate(['/account/login']);
        this.btnDisabled = false;
      }, (err) => {
        console.log('error: ', err);
        this.errorMessage = err.error.message;
        this.btnDisabled = false;
      });
    } else if (this.router.url.includes('account/set-password')) {
      this._userService.acceptInvitation(this.key, { password: this.formGroup.value.password }).subscribe((res: any) => {
        this.router.navigate(['/account/login']);
        this.btnDisabled = false;
      }, (err) => {
        console.log('error: ', err);
        this.errorMessage = err.error.message;
        this.btnDisabled = false;
      });
    }
  }
}
