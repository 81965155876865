import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Input, OnChanges, SimpleChanges, Inject, PLATFORM_ID } from '@angular/core';
import { GlobalService } from '../../services/global.service';
@Component({
  selector: 'mathjax',
  inputs: ['content'],
  templateUrl: './mathjax.component.html',
  styleUrls: ['./mathjax.component.scss']
})
export class MathjaxComponent implements OnInit, OnChanges {
  @Input() content: any = '';
  mathJaxObject: any;

  constructor(
    public gs: GlobalService,
    @Inject(PLATFORM_ID) private platform: object
  ) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platform)) {
      this.loadMathConfig();
      this.renderMath();
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (isPlatformBrowser(this.platform)) {
      if (changes['content']) {
        this.renderMath();
      }
    }
  }

  renderMath() {
    const nativeGlobal: any = this.gs.nativeGlobal();
    this.mathJaxObject = nativeGlobal['MathJax'];
    let angObj = this;
    setTimeout(() => {
      angObj.mathJaxObject.Hub.Queue(['Typeset', angObj.mathJaxObject.Hub], 'mathContent');
    }, 1000);
  }
  loadMathConfig() {
    const nativeGlobal: any = this.gs.nativeGlobal();
    this.mathJaxObject = nativeGlobal['MathJax'];
    this.mathJaxObject.Hub.Config({
      showMathMenu: false,
      tex2jax: { inlineMath: [['$', '$'], ['\\(', '\\)']] },
      menuSettings: { zoom: 'Double-Click', zscale: '150%' },
      CommonHTML: { linebreaks: { automatic: true } },
      'HTML-CSS': { linebreaks: { automatic: true } },
      SVG: { linebreaks: { automatic: true } }
    });
  }
}
