import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SocialAuthService, FacebookLoginProvider, SocialUser, GoogleLoginProvider } from 'angularx-social-login';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SuccessDialogComponent } from '../../../../common/success-dialog/success-dialog.component';
import { AuthService } from '../../../../services/auth.service';
import { SharedService } from '../../../../services/shared.service';
import { GoogleAnalyticsService } from '../../../../services/google-analytics.service';
import { Animation } from '../../../../utilities/animation';
import { Utility } from '../../../../utilities/utility';
import { Login } from '../../../../model/login.model';
import { DialogModel } from '../../../../model/dialog.model';
import { User } from '../../../../model/user.model';

@Component({
  selector: 'app-signup-modal',
  templateUrl: './signup-modal.component.html',
  styleUrls: ['./signup-modal.component.scss'],
  animations: [Animation.inOutAnimation()]
})
export class SignupModalComponent implements OnInit {
  userModel = new User();
  formGroupLogin = new FormGroup({});
  formGroupRegister = new FormGroup({});
  errorMessage = '';
  btnDisabled = false;
  socialUser: SocialUser = new SocialUser();
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private _authService: AuthService,
    private _sharedService: SharedService,
    private _googleAnalyticsService: GoogleAnalyticsService,
    private socialAuthService: SocialAuthService
  ) { }

  ngOnInit(): void {
    this.formGroupLogin = new FormGroup({
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
      ])),
      password: new FormControl('', Validators.compose([
        Validators.required
      ])),
      rememberMe: new FormControl(false)
    });
    this.formGroupRegister = new FormGroup({
      firstName: new FormControl('', Validators.compose([
        Validators.required
      ])),
      lastName: new FormControl('', Validators.compose([
        Validators.required
      ])),
      phone: new FormControl(''),
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
      ])),
      password: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(7)
      ])),
      // confirmPassword: new FormControl('', Validators.compose([
      //   Validators.required,
      //   Validators.minLength(7)
      // ]))
    });
    this.socialAuthService.authState.subscribe((user) => {
      this.socialUser = user;
      if (user != null) {
        this.socialLogin();
      }
    });
  }
  login() {
    this.errorMessage = '';
    if (this.formGroupLogin.invalid) {
      return;
    }
    this.btnDisabled = true;
    const log: Login = this.formGroupLogin.value;
    this._authService.login(log.email, log.password, '').subscribe((res: any) => {
      this._authService.setToken(res.data.token);
      this._authService.currentUser().subscribe((resp: any) => {
        this._sharedService.setUserData(resp.data);
        if (log.rememberMe) {
          this._authService.setCookie('user_session', 'true');
        }
        // this.router.navigate(['/resources']);
        const redirectTo = this.route.snapshot.queryParamMap.get('next') || '';
        if (redirectTo) {
          this.router.navigateByUrl(redirectTo);
        } else {
          this.router.navigate(['/resources']);
        }
        this.btnDisabled = false;
        this.snackBar.open('Successfully logged in', 'DISMISS', { duration: 2000 });
        document.getElementById('btnClose')?.click();
      }, () => {
        this.snackBar.open('Something went wrong...', 'DISMISS', { duration: 2000 });
      });
      this.btnDisabled = false;
    }, (err) => {
      console.log('error: ', err);
      this.btnDisabled = false;
      this.errorMessage = err.error.message;
    });
  }
  forgotPassword() {
    if (!this.userModel.email) {
      return;
    }
    this.btnDisabled = true;
    this._authService.forgotPassword(this.userModel.email).subscribe((res: any) => {
      this.btnDisabled = false;
      this.userModel.email = '';
      this.showMessage(res.message);
      document.getElementById('btnClose')?.click();
    }, (err) => {
      this.btnDisabled = false;
      this.errorMessage = Utility.bindErrors(err);
    });
  }
  register() {
    this.errorMessage = '';
    if (this.formGroupRegister.invalid) {
      this.errorMessage = '* Some fields are not valid!';
      return;
    }
    // if (this.formGroupRegister.value.password !== this.formGroupRegister.value.confirmPassword) {
    //   this.errorMessage = 'Passwords not matching!';
    //   return;
    // }
    this.btnDisabled = true;
    const registerModel: User = this.formGroupRegister.value;
    this._authService.register(registerModel).subscribe((res: any) => {
      // trigger new user event
      this._googleAnalyticsService.sendEvent('newUserSignUp', { userType: 'Individual' });
      this.showMessage(res.message);
      // this.router.navigate(['/']);
      const redirectTo = this.route.snapshot.queryParamMap.get('next') || '';
      if (redirectTo) {
        this.router.navigateByUrl(redirectTo);
      } else {
        this.router.navigate(['/']);
      }
      this.btnDisabled = false;
      document.getElementById('btnClose')?.click();
    }, (err) => {
      this.errorMessage = Utility.bindErrors(err);
      this.btnDisabled = false;
    });
  }
  loginWithFacebook(): void {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }
  loginWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }
  socialLogin(): void {
    if (this.socialUser != null && !this.btnDisabled) {
      this.btnDisabled = true;
      const data: any = {
        provider: this.socialUser.provider,
        firstName: this.socialUser.firstName,
        lastName: this.socialUser.lastName,
        email: this.socialUser.email,
        authToken: this.socialUser.authToken
      };
      if (this.socialUser.provider === 'FACEBOOK') {
        data.facebookId = this.socialUser.response.id;
      } else if (this.socialUser.provider === 'GOOGLE') {
        data.googleId = this.socialUser.id;
        data.access_token = this.socialUser.response.access_token;
        data.id_token = this.socialUser.response.id_token;
      }
      this._authService.socialSignUp(data).subscribe((res: any) => {
        if (res.data) {
          this._googleAnalyticsService.setData({ user_id: res.data._id });
          const loginDetails = {
            email: res.data.email,
            firstName: res.data.firstName,
            userType: res.data.userType,
            userId: res.data._id,
            organizationId: res.data.organizationId,
            loginMethod: this.socialUser.provider
          };
          this._googleAnalyticsService.sendEvent('login', loginDetails);
          this._authService.setToken(res.data.token);
          this._authService.currentUser().subscribe((resp: any) => {
            resp.data.photoUrl = this.socialUser.photoUrl;
            this._sharedService.setUserData(resp.data);
            // this.router.navigate(['/resources']);
            const redirectTo = this.route.snapshot.queryParamMap.get('next') || '';
            if (redirectTo) {
              this.router.navigateByUrl(redirectTo);
            } else {
              this.router.navigate(['/']);
            }
            this.snackBar.open('Successfully logged in', 'DISMISS', { duration: 2000 });
            document.getElementById('btnClose')?.click();
          }, () => {
            this.snackBar.open('Something went wrong...', 'DISMISS', { duration: 2000 });
          });
        } else {
          this.showMessage(res.message);
          this.router.navigate(['/']);
          document.getElementById('btnClose')?.click();
          this.socialSignOut();
        }
        this.btnDisabled = false;
      }, (err) => {
        this.btnDisabled = false;
        this.errorMessage = err.error.message;
        this.socialSignOut();
      });
    }
  }
  socialSignOut(): void {
    this.socialAuthService.signOut(true);
  }
  showMessage(message: string): void {
    const dialogData = new DialogModel('Message Send', message);
    this.dialog.open(SuccessDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });
  }
}
