import { Section, SectionList } from './section.model';
export class Chapter {
    _id = '';
    image = {
        filename: '',
        folder: '',
        originalName: '',
        path: '',
        thumbFilename: '',
        thumbFolder: '',
        thumbPath: ''
    };
    sections = [];
    sectionsList: SectionList[] = [];
    tags = [];
    title = '';
    slug = '';
    description = '';
    content = '';
    publishedAt = '';
    status = '';
    resourceId = '';
    createdBy = '';
    updatedBy = '';
    createdAt = '';
    updatedAt = '';
    tutorId = '';
    isProtected = false;
}
export class ContentList {
    _id = '';
    title = '';
    slug = '';
    type = '';
    chapters: Chapter[] = [];
    hasSectionAccess: any = {};
}
export class ContentListSuccessData {
    message = '';
    status = '';
    data: ContentList[] = [];
}
export class ChapterSuccessData {
    message = '';
    status = '';
    data: Chapter[] = [];
}
export class ChapterErrorData {
    message = '';
    status = '';
    data: any;
}
