<!-- innerpage -->
<div class="innerpage">
    <div class="container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item"><a routerLink="/account/login">Sign In</a></li>
                <li class="breadcrumb-item active" aria-current="page">Forgot Password</li>
            </ol>
        </nav>
        <!-- SignInCont -->
        <div class="innerpageCont SignInContBg">
            <!-- row -->
            <div class="row">
                <div class="container">
                    <div class="SignInCont">
                        <div class="signtop">
                            <h3>Forgot Password?</h3>
                            <p>Please enter your account's email address below. If the account exists, you will receive
                                an email with instructions on how to reset your password.</p>
                        </div>
                        <form [formGroup]="formGroup" #form="ngForm" (ngSubmit)="login()" autocomplete="off">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Email</label>
                                <input type="email" class="form-control inputRadius" formControlName="email">
                                <small [@inOutAnimation] class="form-text error"
                                    *ngIf="form.submitted && formGroup.controls['email'].hasError('required')">Please
                                    fill this
                                    field</small>
                                <small [@inOutAnimation] class="form-text error"
                                    *ngIf="form.submitted && formGroup.controls['email'].hasError('pattern')">Invalid
                                    email
                                </small>
                                <small [@inOutAnimation] class="form-text error" *ngIf="errorMessage">
                                    {{errorMessage}}
                                </small>
                            </div>
                            <div class="signbtnBg">
                                <button type="submit" class="btn cmnBtn" [disabled]="btnDisabled">Submit
                                    &nbsp; <i class="fa fa-circle-o-notch fa-spin" *ngIf="btnDisabled"></i>
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>