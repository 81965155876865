import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Resource, ResourceSuccessData } from '../../model/resource.model';
import { AuthUser } from '../../model/user.model';
import { ResourceService } from '../../services/resource.service';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-certificate-courses',
  templateUrl: './certificate-courses.component.html',
  styleUrls: ['./certificate-courses.component.scss']
})
export class CertificateCoursesComponent implements OnInit {
  user = new AuthUser();
  resourceList: Resource[] = [];
  allResources: Resource[] = [];
  isLoading = false;
  noResult = false;
  pageType = 'individual';
  authStatus = '';
  constructor(
    private _resourceService: ResourceService,
    private _sharedService: SharedService) { }

  ngOnInit(): void {
    this._sharedService.user.subscribe((res: any) => {
      this.user = res;
      if (this.user && this.user.organizationId) {
        this.authStatus = 'true';
      } else {
        this.authStatus = 'false';
      }
      this.getResources();
    });
  }
  getResources(): void {
    if (this.allResources.length > 0) {
      return;
    }
    this.isLoading = true;
    this._resourceService.filterList(
      '',
      'Active',
      '',
      1,
      100,
      'position',
      this.authStatus,
      'true',
      this.pageType
    ).subscribe((res: HttpResponse<ResourceSuccessData>) => {
      this.allResources = res.body?.data || [];
      const resourceGroups = this.allResources.filter(x => x.isClassifiedClass === true);
      for (const r of resourceGroups) {
        r.classificationsList = this.allResources.filter((x) => x.classificationsId === r._id).sort((a, b) => (a.position) - (b.position));
      }
      this.resourceList = resourceGroups;
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
  }
}
