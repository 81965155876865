<section class="about_sec">
    <div class="container-fluid w_80">
        <div class="row">
            <div class="col-lg-12">
                <h3>Facebook Data Deletion Instructions.</h3>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container-fluid w_80 mb-5">
        <div class="row">
            <div class="col-lg-12">
                <div class="wrapper_white">
                    <h1>Facebook Data Deletion Instructions</h1>
                    <div class="panel-group collapsepanel_wrp" id="accordion" role="tablist"
                        aria-multiselectable="true">
                        <div class="panel panel-default">
                            <div class="panel-heading active" role="tab" id="headingOne">
                                <h4 class="panel-title">
                                    <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne"
                                        aria-expanded="true" aria-controls="collapseOne">
                                        Facebook Data Deletion Instructions
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseOne" class="panel-collapse in" role="tabpanel"
                                aria-labelledby="headingOne">
                                <div class="panel-body">
                                    <ol>
                                        <li>1. Go to Your Facebook Account’s Setting & Privacy. Click ” Setting “.</li>
                                        <li>2. Then, go to ” Apps and Websites” and you will see all of your Apps activities.</li>
                                        <li>3. Select the option box of Finstream - Web - Staging.</li>
                                        <li>4. Click ” Remove” button.</li>
                                        <li>5. Congratulation , you are successfully remove your activities</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>