import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../common/confirm-dialog/confirm-dialog.component';
import { Constants } from '../../../../utilities/constants';
import { MessageAlert } from '../../../../utilities/message.alert';
import { Utility } from '../../../../utilities/utility';
import { CommunicationService } from '../../../../services/communication.service';
import { UserService } from '../../../../services/user.service';
import { Communication } from '../../../../model/communication.model';
import { DialogModel } from '../../../../model/dialog.model';
import { AuthUser, User } from '../../../../model/user.model';

@Component({
  selector: 'app-inbox-actions',
  templateUrl: './inbox-actions.component.html',
  styleUrls: ['./inbox-actions.component.scss']
})
export class InboxActionsComponent implements OnInit, OnChanges {
  @Input() user = new User();
  @Input() groupMessage = new Communication();
  @Input() authUser = new AuthUser();
  @Input() messageType = '';
  @Output() refreshList: EventEmitter<any> = new EventEmitter();
  messageTypes = Constants.messageType;
  btnDisabled = false;
  isLoading = false;
  constructor(
    private dialog: MatDialog,
    private alert: MessageAlert,
    private _userService: UserService,
    private _communicationService: CommunicationService) { }

  ngOnInit(): void {
  }
  ngOnChanges(): void {
    // this is only for users who already started conversation with auth user
    if (!this.user._id) {
      const toUser = this.groupMessage.members.find((x) => x._id !== this.authUser._id);
      if (toUser) {
        this.user._id = toUser._id;
        this.user.blockedData = toUser.blockedData;
      } else {
        this.user.blockedData = Object.assign({});
      }
    }
  }
  blockUser() {
    this.btnDisabled = true;
    this.isLoading = true;
    this._userService.blockUser(this.user._id).subscribe((res) => {
      this.btnDisabled = false;
      this.isLoading = false;
      this.alert.success(res.message);
      this.refreshList.emit(true);
    }, (err) => {
      this.alert.error(Utility.bindErrors(err));
      this.btnDisabled = false;
      this.isLoading = false;
    });
  }
  unBlockUser() {
    this.btnDisabled = true;
    this.isLoading = true;
    this._userService.unBlockUser(this.user.blockedData._id).subscribe((res) => {
      this.btnDisabled = false;
      this.isLoading = false;
      this.alert.success(res.message);
      this.refreshList.emit(true);
    }, (err) => {
      this.alert.error(Utility.bindErrors(err));
      this.btnDisabled = false;
      this.isLoading = false;
    });
  }
  exitFromGroup() {
    const message = `Are you sure you want to exit from this group?`;
    const dialogData = new DialogModel('Confirm Exit', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      const result = dialogResult;
      if (result === true) {
        this.btnDisabled = true;
        this.isLoading = true;
        this._communicationService.exitGroup(this.groupMessage._id, this.authUser._id).subscribe((res: any) => {
          this.btnDisabled = false;
          this.isLoading = false;
          this.alert.success(res.message);
          this.refreshList.emit(true);
        }, (err) => {
          this.alert.error(Utility.bindErrors(err));
          this.btnDisabled = false;
          this.isLoading = false;
        });
      }
    });
  }
  deleteGroup() {
    const message = `Are you sure you want to delete this group?`;
    const dialogData = new DialogModel('Confirm Delete', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      const result = dialogResult;
      if (result === true) {
        this.btnDisabled = true;
        this.isLoading = true;
        this._communicationService.deleteThread(this.groupMessage._id).subscribe((res: any) => {
          this.btnDisabled = false;
          this.isLoading = false;
          this.alert.success(res.message);
          this.refreshList.emit(true);
        }, (err) => {
          this.alert.error(Utility.bindErrors(err));
          this.btnDisabled = false;
          this.isLoading = false;
        });
      }
    });
  }
}
