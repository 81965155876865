/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, Input, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { FormGroup, FormControl, Validators, FormGroupDirective } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Constants } from '../../../utilities/constants';
import { Animation } from '../../../utilities/animation';
import { AuthService } from '../../../services/auth.service';
import { SharedService } from '../../../services/shared.service';
import { GoogleAnalyticsService } from '../../../services/google-analytics.service';
import { DialogModel } from '../../../model/dialog.model';
import { UserDetails } from '../../../model/user.model';
import { SuccessDialogComponent } from '../../../common/success-dialog/success-dialog.component';
declare function onLoadCarousel(): any; // from custom.js

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
  animations: [Animation.inOutAnimation()]
})
export class SetPasswordComponent implements OnInit, OnDestroy {
  @Input() page = '';
  userData = new UserDetails();
  userTypes = Constants.userTypes;
  subscription = new Subscription();

  formGroup = new FormGroup({});
  errorMessage = '';
  btnDisabled = false;
  key: any = '';
  isInitialVerification = false;
  isLoading = false;
  message = '';
  isVerificationError = false;
  userId = '';
  viewSettings = {
    old_passwordTextType: false,
    new_passwordTextType: false,
    confirm_passwordTextType: false
  };
  logo = '';
  userDetails: any = {};

  constructor(
    private authService: AuthService,
    private sharedService: SharedService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) private platform: any
  ) { }

  ngOnInit(): void {
    const sub = this.sharedService.user.subscribe((res: any) => {
      this.userData = res;
    });
    this.subscription.add(sub);
    if (isPlatformBrowser(this.platform)) {
      this.key = this.route.snapshot.queryParamMap.get('key');
      if (this.router.url.includes('/account/invite')) {
        this.isInitialVerification = true;
        this.page = 'initialPasswordCreation';
        this.formGroup = new FormGroup({
          password: new FormControl('', Validators.compose([
            Validators.required,
            Validators.minLength(7)
          ])),
          confirmPassword: new FormControl('', Validators.compose([
            Validators.required,
            Validators.minLength(7)
          ]))
        });
        this.isLoading = true;
        this.authService.verifyLink(this.key).subscribe((res: any) => {
          this.userDetails = res.data;
          if (res.data.organization) {
            this.logo = res.data.organization.logo.thumbPath;
          }
          this.message = res.message;
          this.isLoading = false;
        }, (err) => {
          console.log('verify_error', err);
          this.message = err.error.message;
          this.isVerificationError = true;
          this.isLoading = false;
        });
      } else if (this.router.url.includes('/account/change-password')) {
        this.page = 'change-password';
        this.sharedService.user.subscribe((res: any) => {
          this.userId = res._id;
        });
        this.formGroup = new FormGroup({
          old_password: new FormControl('', Validators.compose([
            Validators.required
          ])),
          new_password: new FormControl('', Validators.compose([
            Validators.required,
            Validators.minLength(7)
          ])),
          confirm_password: new FormControl('', Validators.compose([
            Validators.required,
            Validators.minLength(7)
          ]))
        });
      }
      if (this.page === 'initialPasswordCreation') {
        setTimeout(() => {
          onLoadCarousel();
        }, 1000);
      }
    }
  }
  changePassword(): void {
    this.errorMessage = '';
    if (this.formGroup.invalid) {
      return;
    }
    if (this.formGroup.value.password !== this.formGroup.value.confirmPassword) {
      this.errorMessage = 'Passwords not matching!';
      return;
    }
    this.btnDisabled = true;
    this.authService.changePassword(this.key, this.formGroup.value.password).subscribe((res: any) => {
      if (this.userDetails.userType) {
        this.googleAnalyticsService.sendEvent('newUserSignUp', { userType: this.userDetails.userType });
      }
      this.router.navigate(['/account/login']);
      this.btnDisabled = false;
    }, (err) => {
      console.log('error: ', err);
      this.errorMessage = err.error.message;
      this.btnDisabled = false;
    });
  }
  onFormSubmit(form: FormGroupDirective): void {
    this.errorMessage = '';
    if (this.formGroup.invalid) {
      // this.errorMessage = '* Some fields are not valid!';
      return;
    }
    if (this.formGroup.value.new_password !== this.formGroup.value.confirm_password) {
      this.errorMessage = 'Passwords not matching!';
      return;
    }
    this.btnDisabled = true;
    if (this.page === 'change-password') {
      this.authService.updatePassword(this.formGroup.value.old_password, this.formGroup.value.new_password, this.userId)
        .subscribe((res: any) => {
          this.showMessage('Please login with new password');
          this.router.navigate(['/logout']);
          //
          // try {
          //   if (isPlatformBrowser(this.platform)) {
          //     document.getElementById('pwdBackBtn')?.click();
          //   }
          // } catch (error) {
          //   //
          // }
        }, (err) => {
          if (err.status === 401) {
            this.errorMessage = err.error.message;
          } else {
            this.errorMessage = 'Oops error!';
          }
          this.btnDisabled = false;
        });
    }
  }
  toggleFieldTextType(type: string): void {
    if (type === 'old_password') {
      this.viewSettings.old_passwordTextType = !this.viewSettings.old_passwordTextType;
    } else if (type === 'new_password') {
      this.viewSettings.new_passwordTextType = !this.viewSettings.new_passwordTextType;
    } else if (type === 'confirm_password') {
      this.viewSettings.confirm_passwordTextType = !this.viewSettings.confirm_passwordTextType;
    }
  }
  showMessage(message: string): void {
    const dialogData = new DialogModel('Password updated', message);
    this.dialog.open(SuccessDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
