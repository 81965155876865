import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthUser } from '../model/user.model';
import { Constants } from '../utilities/constants';
import { AuthService } from './auth.service';
declare const gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  userTypes = Constants.userTypes;
  authUser = new AuthUser();
  constructor(
    private _authService: AuthService
  ) {
  }
  sendEvent(eventName: string, eventData: any): void {
    this.authUser = this._authService.authUser() || {};
    if (this.authUser.userType !== this.userTypes.MASTER_ADMIN && environment.production) {
      gtag('event', eventName, eventData);
    }
  }
  setData(data: any): void {
    this.authUser = this._authService.authUser() || {};
    if (this.authUser.userType !== this.userTypes.MASTER_ADMIN && environment.production) {
      gtag('set', data);
    }
  }
  addTrackingId(id: string, data: any): void {
    this.authUser = this._authService.authUser() || {};
    if (this.authUser.userType !== this.userTypes.MASTER_ADMIN && environment.production) {
      gtag('config', id, data);
    }
  }
}
