/* eslint-disable @typescript-eslint/naming-convention */
export const Constants = {
    // userRole: {
    //     1: 'Super Admin',
    //     2: 'Admin',
    //     3: 'Consumer'
    // },
    userTypes: {
        MASTER_ADMIN: 'Master Admin',
        ADMIN: 'Admin',
        TUTOR: 'Tutor',
        ORGANIZATION: 'Organization',
        COURSE_PROVIDER: 'Course Provider',
        STUDENT: 'Student',
        INDIVIDUAL: 'Individual',
        ORGANIZATION_SUB_ADMIN: 'Organization SubAdmin'
    },
    messageType: {
        GROUP: 'Group',
        INDIVIDUAL: 'Individual',
        STUDENT: 'Student',
        TUTOR: 'Tutor'
    },
    Cookies: {
        TOKEN: 'token'
    },
    defaultColWidth: '150px',
    colors: {
        A: '#4caf50',
        B: '#e91e63',
        C: '#9c27b0',
        D: '#673ab7',
        E: '#3f51b5',
        F: '#2196f3',
        G: '#03a9f4',
        H: '#00bcd4',
        I: '#009688',
        J: '#af9b4c',
        K: '#8bc34a',
        L: '#cddc39',
        M: '#ffeb3b',
        N: '#ffc107',
        O: '#ff9800',
        P: '#6a1b9a',
        Q: '#f06292',
        R: '#64b5f6',
        S: '#b39ddb',
        T: '#5c6bc0',
        U: '#4db6ac',
        V: '#03a9f4',
        W: '#00acc1',
        X: '#dce775',
        Y: '#f44336',
        Z: '#ffcc80',
        0: '#8c9eff',
        1: '#009688',
        2: '#004d40',
        3: '#ffa726',
        4: '#fb8c00',
        5: '#9e9e9e',
        6: '#90a4ae',
        7: '#6c757d',
        8: '#f8bbd0',
        9: '#7A54A2'
    },
    randomColors: ['#7A54A2', '#76DC94', '#3f51b5', '#ffc107', '#f44336', '#6c757d', '#495057', '#007bff', '#f8bbd0', '#ba68c8', '#90caf9'],
    currencies: {
        AED: 'AED',
        AFN: 'Af',
        ALL: 'ALL',
        AMD: 'AMD',
        ARS: 'AR$',
        AUD: 'AU$',
        AZN: 'man.',
        BAM: 'KM',
        BDT: 'Tk',
        BGN: 'BGN',
        BHD: 'BD',
        BIF: 'FBu',
        BND: 'BN$',
        BOB: 'Bs',
        BRL: 'R$',
        BWP: 'BWP',
        BYN: 'Br',
        BZD: 'BZ$',
        CAD: 'CA$',
        CDF: 'CDF',
        CHF: 'CHF',
        CLP: 'CL$',
        CNY: 'CN¥',
        COP: 'CO$',
        CRC: '₡',
        CVE: 'CV$',
        CZK: 'Kč',
        DJF: 'Fdj',
        DKK: 'Dkr',
        DOP: 'RD$',
        DZD: 'DA',
        EEK: 'Ekr',
        EGP: 'EGP',
        ERN: 'Nfk',
        ETB: 'Br',
        EUR: '€',
        GBP: '£',
        GEL: 'GEL',
        GHS: 'GH₵',
        GNF: 'FG',
        GTQ: 'GTQ',
        HKD: 'HK$',
        HNL: 'HNL',
        HRK: 'kn',
        HUF: 'Ft',
        IDR: 'Rp',
        ILS: '₪',
        INR: '₹',
        IQD: 'IQD',
        IRR: 'IRR',
        ISK: 'Ikr',
        JMD: 'J$',
        JOD: 'JD',
        JPY: '¥',
        KES: 'Ksh',
        KHR: 'KHR',
        KMF: 'CF',
        KRW: '₩',
        KWD: 'KD',
        KZT: 'KZT',
        LBP: 'LB£',
        LKR: 'SLRs',
        LTL: 'Lt',
        LVL: 'Ls',
        LYD: 'LD',
        MAD: 'MAD',
        MDL: 'MDL',
        MGA: 'MGA',
        MKD: 'MKD',
        MMK: 'MMK',
        MOP: 'MOP$',
        MUR: 'MURs',
        MXN: 'MX$',
        MYR: 'RM',
        MZN: 'MTn',
        NAD: 'N$',
        NGN: '₦',
        NIO: 'C$',
        NOK: 'Nkr',
        NPR: 'NPRs',
        NZD: 'NZ$',
        OMR: 'OMR',
        PAB: 'B/.',
        PEN: 'S/.',
        PHP: '₱',
        PKR: 'PKRs',
        PLN: 'zł',
        PYG: '₲',
        QAR: 'QR',
        RON: 'RON',
        RSD: 'din.',
        RUB: 'RUB',
        RWF: 'RWF',
        SAR: 'SR',
        SDG: 'SDG',
        SEK: 'Skr',
        SGD: 'S$',
        SOS: 'Ssh',
        SYP: 'SY£',
        THB: '฿',
        TND: 'DT',
        TOP: 'T$',
        TRY: 'TL',
        TTD: 'TT$',
        TWD: 'NT$',
        TZS: 'TSh',
        UAH: '₴',
        UGX: 'USh',
        USD: '$',
        UYU: '$U',
        UZS: 'UZS',
        VEF: 'Bs.F.',
        VND: '₫',
        XAF: 'FCFA',
        XOF: 'CFA',
        YER: 'YR',
        ZAR: 'R',
        ZMK: 'ZK',
        ZWL: 'ZWL$'
    }
};
