import { Component, OnInit, OnChanges, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SuccessDialogComponent } from '../../../common/success-dialog/success-dialog.component';
import { AuthService } from '../../../services/auth.service';
import { SharedService } from '../../../services/shared.service';
import { GoogleAnalyticsService } from '../../../services/google-analytics.service';
import { Animation } from '../../../utilities/animation';
import { Utility } from '../../../utilities/utility';
import { Login } from '../../../model/login.model';
import { User } from '../../../model/user.model';
import { DialogModel } from '../../../model/dialog.model';
import { Constants } from '../../../utilities/constants';
declare function onLoadCarousel(): any; // from custom.js
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [Animation.inOutAnimation()]
})
export class LoginComponent implements OnInit, OnChanges {
  userModel = new User();
  formGroup = new FormGroup({});
  errorMessage = '';
  btnDisabled = false;
  ipAddress = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private _authService: AuthService,
    private _sharedService: SharedService,
    private _googleAnalyticsService: GoogleAnalyticsService,
    @Inject(PLATFORM_ID) private platform: object
  ) { }

  ngOnInit(): void {
    if (this._authService.isAuthenticated()) {
      this.router.navigate(['/']);
    }
    this.formGroup = new FormGroup({
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
      ])),
      password: new FormControl('', Validators.compose([
        Validators.required
      ])),
      rememberMe: new FormControl(false)
    });
    setTimeout(() => {
      if (isPlatformBrowser(this.platform)) {
        onLoadCarousel();
      }
    }, 1000);
  }
  ngOnChanges() {
    //
  }

  login() {
    this.errorMessage = '';
    if (this.formGroup.invalid) {
      return;
    }
    this.btnDisabled = true;
    const log: Login = this.formGroup.value;
    this._authService.login(log.email, log.password, this.ipAddress).subscribe((res: any) => {
      this._googleAnalyticsService.setData({ user_id: res.data._id });
      const loginDetails = {
        email: res.data.email,
        firstName: res.data.firstName,
        userType: res.data.userType,
        userId: res.data._id,
        organizationId: res.data.organizationId,
        loginMethod: 'Website'
      };
      this._googleAnalyticsService.sendEvent('login', loginDetails);
      this._authService.setToken(res.data.token);
      this._authService.currentUser().subscribe((resp: any) => {
        this._sharedService.setUserData(resp.data);
        if (log.rememberMe) {
          this._authService.setCookie('user_session', 'true');
        }
        const redirectTo = this.route.snapshot.queryParamMap.get('next') || '';
        if (redirectTo) {
          this.router.navigateByUrl(redirectTo);
        } else {
          this.router.navigate(['/resources']);
        }
      }, () => {
        this.snackBar.open('Something went wrong...');
      });
      this.btnDisabled = false;
    }, (err) => {
      console.log('error: ', err);
      this.btnDisabled = false;
      this.errorMessage = err.error.message;
    });
  }
  forgotPassword() {
    this.errorMessage = '';
    if (!this.userModel.email) {
      return;
    }
    this.btnDisabled = true;
    this._authService.forgotPassword(this.userModel.email).subscribe((res: any) => {
      this.btnDisabled = false;
      this.userModel.email = '';
      this.showMessage(res.message);
      document.getElementById('btnClose')?.click();
    }, (err) => {
      this.btnDisabled = false;
      this.errorMessage = Utility.bindErrors(err);
    });
  }
  showMessage(message: string): void {
    const dialogData = new DialogModel('Message Send', message);
    this.dialog.open(SuccessDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });
  }
}

@Component({
  selector: 'app-logout',
  template: '<div style="height:60vh">...</div>'
})
export class LogoutComponent implements OnInit {
  constructor(
    private router: Router,
    private _authService: AuthService,
    private _sharedService: SharedService,
    @Inject(PLATFORM_ID) private platform: Record<string, unknown>
  ) { }

  ngOnInit(): void {
    this._sharedService.setUserData({});
    if (isPlatformBrowser(this.platform)) {
      try {
        const socket = this._sharedService.webSocketGetInstance();
        socket.disconnect();
      } catch (error) {
      }
      this._authService.removeCookie(Constants.Cookies.TOKEN);
      setTimeout(() => {
        window.location.href = '/account/login';
      }, 100);
    }
  }
}
