import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { SocialAuthService, SocialUser } from 'angularx-social-login';
import { MessageAlert } from '../../../utilities/message.alert';
import { Constants } from '../../../utilities/constants';
import { SharedService } from '../../../services/shared.service';
import { ResourceService } from '../../../services/resource.service';
import { OrganizationService } from '../../../services/organization.service';
import { CommunicationService } from '../../../services/communication.service';
import { AuthService } from '../../../services/auth.service';
import { AuthUser } from '../../../model/user.model';
import { OrganizationDetails, OrganizationSuccessData } from '../../../model/organization.model';
import { Resource, ResourceErrorData, ResourceSuccessData } from '../../../model/resource.model';
import { CommunicationNotification, CommunicationNotificationSuccessData } from '../../../model/communication.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  user = new AuthUser();
  resourcesList: Resource[] = [];
  organization = new OrganizationDetails();
  currentPage = '';
  pageType = '';
  authStatus = '';
  isFetchResourceData = false;
  enableHeaderClass = false;
  enableHeaderMenu = true;
  socialUser: SocialUser = new SocialUser();
  isSocialLoggedIn: any = false;
  photoUrl: any = '';
  notificationsList: CommunicationNotification[] = [];
  userTypes = Constants.userTypes;
  constructor(
    private router: Router,
    private alert: MessageAlert,
    private snackBar: MatSnackBar,
    private _sharedService: SharedService,
    private _authService: AuthService,
    private _resourceService: ResourceService,
    private _organizationService: OrganizationService,
    private socialAuthService: SocialAuthService,
    private _communicationService: CommunicationService,
    @Inject(PLATFORM_ID) private platform: Record<string, unknown>
  ) {
    router.events.subscribe((event: any) => {
      if (event.snapshot && event.snapshot.data && event.snapshot.data.page !== undefined) {
        this.pageType = event.snapshot.data.page;
        this.authStatus = 'false';
        this.getResourceList();
      }
      if (event instanceof NavigationEnd) {
        this.currentPage = event.url;
        // if (event.url === '/' || event.url === '/account/login' || event.url === '/contact') {
        //   this.enableHeaderClass = false;
        // } else {
        //   this.enableHeaderClass = true;
        // }
        if (event.url === '/account/login' || event.url === '/contact') {
          this.enableHeaderClass = false;
        } else {
          this.enableHeaderClass = true;
        }
      }
    });
  }

  ngOnInit(): void {
    this.currentPage = this.router.url;
    this._sharedService.user.subscribe((res: any) => {
      this.user = res;
      this.photoUrl = res.photoUrl;
      if (this.user && this.user.organizationId) {
        this.authStatus = 'true';
        this.getOrganization(this.user.organizationId);
        // this.getResourceList();
      } else {
        this.authStatus = 'false';
        this.organization = new OrganizationDetails();
        this._sharedService.setOrganizationData(this.organization);
        // this.getResourceList();
      }
      if (this.user && this.user.userType === this.userTypes.STUDENT) {
        this.enableHeaderMenu = false;
      } else {
        this.enableHeaderMenu = true;
      }
      if (isPlatformBrowser(this.platform)) {
        setTimeout(() => {
          this.onListenWebsocket();
        }, 2000);
        if (this.user && this.user._id) {
          this.getNotifications();
        }
      }
    });
    this.socialAuthService.authState.subscribe((user) => {
      this.socialUser = user;
      this.isSocialLoggedIn = (user != null);
    });
  }
  onListenWebsocket() {
    this._sharedService.webSocketCreate(this._authService.getToken());
    const socket = this._sharedService.webSocketGetInstance();
    if (this.user) {
      socket.on(`event.message.created.${this.user._id}`, (message: any) => {
        this.alert.messageNotification(message);
      });
      socket.on(`event.notification.unread.${this.user._id}`, (data: any) => {
        this.getNotifications();
      });
    } else {
      socket.disconnect();
    }
  }
  getOrganization(id: string) {
    this._organizationService.getDetails(id).subscribe((res: HttpResponse<OrganizationSuccessData>) => {
      this.organization = res.body?.data || Object.assign({});
      this._sharedService.setOrganizationData(this.organization);
    }, (err: HttpErrorResponse) => {
      const ss: ResourceErrorData = err.error;
      this.snackBar.open(ss.message, 'DISMISS', {
        duration: 2000
      });
    });
  }
  getResourceList() {
    if (this.isFetchResourceData) { // avoid multiple request
      return;
    }
    this.isFetchResourceData = true;
    this._resourceService.filterList(
      '',
      'Active',
      '',
      1,
      6,
      'position',
      this.authStatus,
      'true',
      this.pageType
    ).subscribe((res: HttpResponse<ResourceSuccessData>) => {
      this.resourcesList = res.body?.data || [];
      this._sharedService.setResourceData(this.resourcesList);
      setTimeout(() => {
        this.isFetchResourceData = false;
      }, 1500);
    }, (err: HttpErrorResponse) => {
      const e: ResourceErrorData = err.error;
      this.snackBar.open(e.message, 'DISMISS', {
        duration: 2000
      });
      this.resourcesList = [];
      setTimeout(() => {
        this.isFetchResourceData = false;
      }, 1500);
    });
  }
  socialSignOut(): void {
    if (this.isSocialLoggedIn) {
      this.socialAuthService.signOut(true);
    }
  }
  getNotifications() {
    this._communicationService.messageNotificationsList().subscribe((res: HttpResponse<CommunicationNotificationSuccessData>) => {
      this.notificationsList = res.body?.data || [];
    });
  }
}
