import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { SuccessDialogComponent } from 'src/app/common/success-dialog/success-dialog.component';
import { DialogModel } from 'src/app/model/dialog.model';
import { SubscriptionSuccessData, Subscription } from 'src/app/model/subscription.model';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss']
})
export class MyOrdersComponent implements OnInit {
  listItems: Subscription[] = [];
  keywords = new FormControl('');
  page = 1;
  limit = 20;
  totalCount = 0;
  // status = 'true';
  orderBy = '-createdAt';
  errorMessage = '';
  today = moment().format('YYYY-MM-DD');
  isLoading = false;
  noResult = false;
  btnDisabled = false;
  httpError: HttpErrorResponse | undefined;
  constructor(
    private dialog: MatDialog,
    private subscriptionService: SubscriptionService
  ) { }

  ngOnInit(): void {
    this.filterList();
    this.keywords.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(data => {
      this.page = 1;
      this.listItems = [];
      this.filterList();
    });
  }
  filterList() {
    this.isLoading = true;
    this.noResult = false;
    this.listItems = [];
    this.httpError = undefined;
    this.subscriptionService.filterList(
      this.keywords.value,
      '',
      this.page,
      this.limit,
      this.orderBy,
      ''
    ).subscribe((res: HttpResponse<SubscriptionSuccessData>) => {
      this.listItems = res.body?.data || [];
      this.totalCount = Number(res.headers.get('x-total-count'));
      if (this.listItems.length === 0) {
        this.noResult = true;
      }
      this.isLoading = false;
    }, (err: HttpErrorResponse) => {
      this.listItems = [];
      this.noResult = true;
      this.isLoading = false;
      this.httpError = err;
    });
  }
  pageChanged(pno: number) {
    this.page = pno;
    this.filterList();
  }
  confirmUnsubscribe(item: Subscription) {
    const message = `Your subscription for this course is valid until <strong> ${moment(item.subscriptionTo).format('DD-MM-YYYY')} </strong>
    and you can still access this course until ${moment(item.subscriptionTo).format('DD-MM-YYYY')} if you wish to unsubscribe now. 
    You will not be charged for this course from ${moment(item.subscriptionTo).format('DD-MM-YYYY')} if you proceed to unsubscribe this course. Are you sure ?`;
    const dialogData = new DialogModel('Request for unsubscribe', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      const result = dialogResult;
      if (result === true) {
        this.subscriptionService.unsubscribe(item.subscriptionId).subscribe((res) => {
          this.showMessage('Message', 'Unsubscribe request success');
          this.filterList();
        }, () => {
          this.showMessage('Failed', 'Request failed. Please try again later');
        });
      }
    });
  }
  viewUnSubInfo(item: Subscription) {
    const message = `Your request for subscription cancellation was made on ${moment(item.subscriptionCancelRequestedAt).format('DD-MM-YYYY HH:mm')}
    and it will be automatically deactivated at ${moment(item.subscriptionTo).format('DD-MM-YYYY')}.`;
    this.showMessage('Info', message);
  }
  showMessage(title: string, message: string): void {
    const dialogData = new DialogModel(title, message);
    this.dialog.open(SuccessDialogComponent, {
      maxWidth: '600px',
      data: dialogData
    });
  }
  onSortBy(field: string) {
    if (this.orderBy.includes('-')) {
      this.orderBy = field;
    } else {
      this.orderBy = '-' + field;
    }
    this.filterList();
  }
}
