import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TagInputModule } from 'ngx-chips';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPaginationModule } from 'ngx-pagination';
import { CookieService } from 'ngx-cookie-service';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { CountdownModule } from 'ngx-countdown';

import { ArraySortPipe, ChatOnlyConnectedUsers, ChatUnReadCount, ChatUserImagePipe, ChatUserNamePipe, CurrencySymbol, DefaultImagePipe, EscapeHtmlPipe, FileSizePipe, FileTypePipe, InboxUserNamePipe, InteractiveD3GraphData, InteractiveLineChartData, LocalizeTimePipe, RandomColorPickPipe, SafeResourceUrl, TimeAgoPipe, InteractivePieChartData, InteractiveBarChartData, InteractiveTableCollapsibleWidth, CustomCommaSeparate, InteractiveScatterChartData, InteractiveDataTableColumnPipe, DateDiffPipe, DateAgoPipe, InteractiveRowSettingsPipe } from './pipe/EscapeHtmlPipe';

import { AuthService } from './services/auth.service';
import { AuthGuardService } from './auth/auth-guard.service';
import { RoleGuardService } from './auth/role-guard.service';
import { TokenInterceptor } from './auth/token.interceptor';
import { MessageAlert } from './utilities/message.alert';

import { SharedService } from './services/shared.service';
import { SeoService } from './services/seo.service';
import { EncryptionService } from './services/encryption.service';
import { CommunicationService } from './services/communication.service';
import { UserService } from './services/user.service';
import { ResourceService } from './services/resource.service';
import { ChapterService } from './services/chapter.service';
import { SectionService } from './services/section.service';
import { OrganizationService } from './services/organization.service';
import { PaymentService } from './services/payment.service';
import { SubscriptionService } from './services/subscription.service';
import { FileUploadService } from './services/file-upload.service';
import { GlobalService } from './services/global.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MathjaxComponent } from './common/mathjax/mathjax.component';
import { ConfirmDialogComponent } from './common/confirm-dialog/confirm-dialog.component';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { LayoutComponent } from './components/layout/layout.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { NotfoundComponent } from './common/notfound/notfound.component';
import { LoginComponent, LogoutComponent } from './components/account/login/login.component';
import { SetPasswordComponent } from './components/account/set-password/set-password.component';
import { NoRecordsFoundComponent } from './common/no-records-found/no-records-found.component';
import { ForgotPasswordComponent } from './components/account/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/account/reset-password/reset-password.component';
import { AnimateLoadingComponent } from './common/animate-loading/animate-loading.component';
import { ResourcesComponent } from './components/resources/resources.component';
import { DefinitionComponent } from './components/definition/definition.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { FaqComponent } from './components/faq/faq.component';
import { SignupModalComponent } from './components/account/login/signup-modal/signup-modal.component';
import { UnauthorizedComponent } from './common/unauthorized/unauthorized.component';
import { DefTheoryComponent } from './components/definition/def-theory/def-theory.component';
import { DefInteractiveComponent } from './components/definition/def-interactive/def-interactive.component';
import { DefRelatedCourseComponent } from './components/definition/def-related-course/def-related-course.component';
import { DefCommunicationComponent } from './components/definition/def-communication/def-communication.component';
import { TabNotesComponent } from './components/definition/def-communication/tab-notes/tab-notes.component';
import { SuccessDialogComponent } from './common/success-dialog/success-dialog.component';
import { VerifyAccountComponent } from './components/account/verify-account/verify-account.component';
import { ChatBoxComponent } from './components/definition/def-communication/chat-box/chat-box.component';
import { CreateChatGroupComponent } from './components/definition/def-communication/create-chat-group/create-chat-group.component';
import { UserProfileComponent } from './components/account/user-profile/user-profile.component';
import { ProfileEditComponent } from './components/account/user-profile/profile-edit/profile-edit.component';
import { MessageNotificationComponent } from './common/templates/message-notification/message-notification.component';
import { ChatWithNewUserComponent } from './components/definition/def-communication/chat-with-new-user/chat-with-new-user.component';
import { InboxActionsComponent } from './components/definition/def-communication/inbox-actions/inbox-actions.component';
import { PaymentComponent } from './components/payment/payment.component';
import { PaymentSuccessComponent } from './components/payment/payment-success/payment-success.component';
import { PaymentFailedComponent } from './components/payment/payment-failed/payment-failed.component';
import { MyOrdersComponent } from './components/account/my-orders/my-orders.component';
import { InteractiveContentComponent } from './common/templates/interactive-content/interactive-content.component';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { environment } from 'src/environments/environment';
import { D3GraphComponent } from './charts/d3-graph/d3-graph.component';
import { LineChartComponent } from './charts/line-chart/line-chart.component';
import { PieChartComponent } from './charts/pie-chart/pie-chart.component';
import { BarChartComponent } from './charts/bar-chart/bar-chart.component';
import { InnerHomeComponent } from './components/home/inner-home/inner-home.component';
import { PrivacyPolicyComponent } from './components/static-pages/privacy-policy/privacy-policy.component';
import { DataDeletionComponent } from './components/data-deletion/data-deletion.component';
import { MyBookmarksComponent } from './components/account/my-bookmarks/my-bookmarks.component';
import { ScatterChartComponent } from './charts/scatter-chart/scatter-chart.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { WhatIsFinstreamComponent } from './components/static-pages/what-is-finstream/what-is-finstream.component';
import { ForIndividualsComponent } from './components/static-pages/for-individuals/for-individuals.component';
import { ForEmployersComponent } from './components/static-pages/for-employers/for-employers.component';
import { CertificationComponent } from './components/static-pages/certification/certification.component';
import { StreamFxComponent } from './components/static-pages/stream-fx/stream-fx.component';
import { StreamFdComponent } from './components/static-pages/stream-fd/stream-fd.component';
import { StreamPmComponent } from './components/static-pages/stream-pm/stream-pm.component';
import { TermsConditionsComponent } from './components/static-pages/terms-conditions/terms-conditions.component';
import { CookiePolicyComponent } from './components/static-pages/cookie-policy/cookie-policy.component';
import { MyCertificatesComponent } from './components/account/my-certificates/my-certificates.component';
import { CertificateService } from './services/certificate.service';
import { ExamService } from './services/exam.service';
import { MyExamsComponent } from './components/account/my-exams/my-exams.component';
import { CertificateCoursesComponent } from './common/certificate-courses/certificate-courses.component';
import { CertificateVideoComponent } from './common/certificate-video/certificate-video.component';
import { HomeNewComponent } from './components/home-new/home-new.component';
import { PartnersComponent } from './components/static-pages/partners/partners.component';

@NgModule({
  declarations: [
    EscapeHtmlPipe,
    SafeResourceUrl,
    DefaultImagePipe,
    FileSizePipe,
    FileTypePipe,
    ArraySortPipe,
    TimeAgoPipe,
    InboxUserNamePipe,
    ChatUserNamePipe,
    ChatUserImagePipe,
    ChatUnReadCount,
    ChatOnlyConnectedUsers,
    LocalizeTimePipe,
    CurrencySymbol,
    RandomColorPickPipe,
    CustomCommaSeparate,
    InteractiveD3GraphData,
    InteractiveLineChartData,
    InteractivePieChartData,
    InteractiveBarChartData,
    InteractiveScatterChartData,
    InteractiveTableCollapsibleWidth,
    InteractiveDataTableColumnPipe,
    DateDiffPipe,
    DateAgoPipe,
    InteractiveRowSettingsPipe,

    AppComponent,
    MathjaxComponent,
    ConfirmDialogComponent,
    HomeComponent,
    HeaderComponent,
    LayoutComponent,
    FooterComponent,
    NotfoundComponent,
    LoginComponent,
    LogoutComponent,
    SetPasswordComponent,
    NoRecordsFoundComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    AnimateLoadingComponent,
    ResourcesComponent,
    DefinitionComponent,
    AboutUsComponent,
    ContactUsComponent,
    FaqComponent,
    SignupModalComponent,
    UnauthorizedComponent,
    DefTheoryComponent,
    DefInteractiveComponent,
    DefRelatedCourseComponent,
    DefCommunicationComponent,
    TabNotesComponent,
    SuccessDialogComponent,
    VerifyAccountComponent,
    ChatBoxComponent,
    CreateChatGroupComponent,
    UserProfileComponent,
    ProfileEditComponent,
    MessageNotificationComponent,
    ChatWithNewUserComponent,
    InboxActionsComponent,
    PaymentComponent,
    PaymentSuccessComponent,
    PaymentFailedComponent,
    MyOrdersComponent,
    InteractiveContentComponent,
    D3GraphComponent,
    LineChartComponent,
    PieChartComponent,
    BarChartComponent,
    InnerHomeComponent,
    PrivacyPolicyComponent,
    DataDeletionComponent,
    MyBookmarksComponent,
    ScatterChartComponent,
    WhatIsFinstreamComponent,
    ForIndividualsComponent,
    ForEmployersComponent,
    CertificationComponent,
    StreamFxComponent,
    StreamFdComponent,
    StreamPmComponent,
    TermsConditionsComponent,
    CookiePolicyComponent,
    MyCertificatesComponent,
    MyExamsComponent,
    CertificateCoursesComponent,
    CertificateVideoComponent,
    HomeNewComponent,
    PartnersComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TagInputModule,
    NgxFileDropModule,
    NgSelectModule,
    NgxPaginationModule,
    MatSnackBarModule,
    MatDialogModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatExpansionModule,
    MatCheckboxModule,
    SocialLoginModule,
    CountdownModule,
    NgxCaptchaModule,
    NgxYoutubePlayerModule.forRoot()
  ],
  providers: [
    AuthService,
    AuthGuardService,
    RoleGuardService,
    SharedService,
    SeoService,
    ResourceService,
    ChapterService,
    CommunicationService,
    UserService,
    GlobalService,
    SectionService,
    OrganizationService,
    PaymentService,
    SubscriptionService,
    FileUploadService,
    CertificateService,
    ExamService,

    EncryptionService,
    MessageAlert,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.GOOGLE_APP_ID
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(
              environment.FACEBOOK_APP_ID
            )
          }
        ]
      } as SocialAuthServiceConfig
    },
    CookieService,
    {
      provide: 'req',
      useValue: null
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
