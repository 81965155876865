import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Bookmark, BookmarkSuccessData } from '../model/bookmark.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BookmarkService {
  constructor(public http: HttpClient) { }
  bookmarkCreate(data: Bookmark): Observable<any> {
    return this.http.post<any>(environment.API_END_POINT + '/user/bookmark', data);
  }
  filterList(
    keywords: string,
    page: number,
    limit: number,
    sortBy: string
  ): Observable<HttpResponse<BookmarkSuccessData>> {
    return this.http.get<BookmarkSuccessData>(environment.API_END_POINT + '/user/bookmark/list?title=' + keywords +
      '&page=' + page + '&limit=' + limit + '&sortBy=' + sortBy, { observe: 'response' });
  }
  deleteBookmark(id: string): Observable<any> {
    return this.http.delete(environment.API_END_POINT + '/user/bookmark/' + id);
  }
}
