<div class="modal fade createAccount" id="createAccount" tabindex="-1" role="dialog" aria-labelledby="createAccount"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" id="btnClose" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="assets/img/close.svg">
                </button>
                <div class="split_2">
                    <div class="bg_blue">
                        <h3>CREATE YOUR ACCOUNT NOW</h3>
                        <div class="img_create">
                            <img src="assets/img/create_img.png">
                        </div>
                    </div>
                    <div class="bg_green">
                        <div class="subscription_area">
                            <button type="button" class="btn btn-link" (click)="loginWithFacebook()" [disabled] ="btnDisabled"><img src="assets/img/fb.svg" class="img-fluid"></button>
                            <button type="button" class="btn btn-link" (click)="loginWithGoogle()" [disabled] ="btnDisabled"> <img src="assets/img/google.svg" class="img-fluid"></button>
                            <img src="assets/img/apple.svg" class="img-fluid">
                            <img class="mt-3 img-fluid" src="assets/img/or.svg">
                            <div class="sign_up_txt text-center">
                                <small [@inOutAnimation] class="form-text error" *ngIf="errorMessage"
                                  [innerHTML]="errorMessage">
                                </small>
                                <h4 class="pt-4"><a href="#" class="signup_email">Sign Up Using Email</a></h4>
                                <h6 class="pt-3">Already a member? <strong><a href="#" class="sign_in">Sign
                                            in</a></strong></h6>
                                <p class="pt-5">By completing the signup process you <br>agree to the <a href="">Terms
                                        and Conditions.</a></p>
                            </div>
                            <div class="sign_up_sec d_none">
                                <form [formGroup]="formGroupRegister" #form="ngForm" (ngSubmit)="register()"
                                    autocomplete="off">
                                    <div class="form-row">
                                        <div class="col">
                                            <input type="text" class="form-control" placeholder=""
                                                formControlName="firstName" placeholder="First Name">
                                            <small [@inOutAnimation] class="form-text error"
                                                *ngIf="form.submitted && formGroupRegister.controls['firstName'].hasError('required')">Please
                                                fill this
                                                field</small>
                                        </div>
                                        <div class="col">
                                            <input type="text" class="form-control" placeholder=""
                                                formControlName="lastName" placeholder="Last Name">
                                            <small [@inOutAnimation] class="form-text error"
                                                *ngIf="form.submitted && formGroupRegister.controls['lastName'].hasError('required')">Please
                                                fill this
                                                field</small>
                                        </div>
                                        <div class="col-12">
                                            <input type="email" class="form-control" placeholder=""
                                                formControlName="email" placeholder="Email">
                                            <small [@inOutAnimation] class="form-text error"
                                                *ngIf="form.submitted && formGroupRegister.controls['email'].hasError('required')">Please
                                                fill this
                                                field</small>
                                            <small [@inOutAnimation] class="form-text error"
                                                *ngIf="form.submitted && formGroupRegister.controls['email'].hasError('pattern')">Invalid
                                                email
                                            </small>
                                        </div>
                                        <div class="col-12">
                                            <input type="password" class="form-control inputRadius" formControlName="password" placeholder="Password" autocomplete="new-password">
                                            <small [@inOutAnimation] class="form-text error"
                                                *ngIf="form.submitted && formGroupRegister.controls['password'].hasError('required')">Please
                                                fill this
                                                field</small>
                                            <small [@inOutAnimation] class="form-text error"
                                                *ngIf="form.submitted && formGroupRegister.controls['password'].hasError('minlength')">Password
                                                should be minimum
                                                {{formGroupRegister.controls['password'].errors?.minlength.requiredLength}}
                                                characters long.</small>
                                        </div>
                                        <div class="col-12">
                                            <small [@inOutAnimation] class="form-text error" *ngIf="errorMessage"
                                                [innerHTML]="errorMessage">
                                            </small>
                                            <button class="btn_blue mt-3 w-100" [disabled]="btnDisabled">Sign Up
                                                <mat-spinner class="btnLoading white" [diameter]="20"
                                                    *ngIf="btnDisabled">
                                                </mat-spinner>
                                            </button>
                                            <h6 class="pt-3">Already a member? <strong><a href="#" class="sign_in">Sign
                                                        in</a></strong></h6>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="login_form d_none pt-3">
                                <form [formGroup]="formGroupLogin" #form="ngForm" (ngSubmit)="login()"
                                    autocomplete="off">
                                    <div class="form-row">
                                        <div class="col-12">
                                            <input type="email" class="form-control inputRadius" placeholder="Email"
                                                formControlName="email">
                                            <small [@inOutAnimation] class="form-text error"
                                                *ngIf="form.submitted && formGroupLogin.controls['email'].hasError('required')">Please
                                                fill this
                                                field</small>
                                            <small [@inOutAnimation] class="form-text error"
                                                *ngIf="form.submitted && formGroupLogin.controls['email'].hasError('pattern')">Invalid
                                                email
                                            </small>
                                        </div>
                                        <div class="col-12">
                                            <input type="password" class="form-control inputRadius" placeholder="Password"
                                                formControlName="password" autocomplete="new-password">
                                            <small [@inOutAnimation] class="form-text error"
                                                *ngIf="form.submitted && formGroupLogin.controls['password'].hasError('required')">
                                                Please fill this field
                                            </small>
                                            <small [@inOutAnimation] class="form-text error" *ngIf="errorMessage"
                                                [innerHTML]="errorMessage">
                                            </small>
                                        </div>
                                        <div class="col-12">
                                            <label class="check-container">Keep me signed in
                                                <input type="checkbox" formControlName="rememberMe">
                                                <span class="checkmark"></span>
                                            </label>
                                            <a href="#" class="forgot_pswd">Forgot password</a>
                                        </div>
                                        <div class="col-lg">
                                            <button class="btn_blue mt-3 w-100" [disabled]="btnDisabled">Sign in
                                                <mat-spinner class="btnLoading white" [diameter]="20"
                                                    *ngIf="btnDisabled">
                                                </mat-spinner>
                                            </button>
                                        </div>
                                        <div class="col-12 text-left">
                                            <!-- <h4 class="pt-4"><a href="#" class="signup_email">Sign Up Using Email</a>
                                            </h4> -->
                                            <h6 class="pt-3">Not a member yet? <strong><a href="#"
                                                        class="back_signup signup_email">Sign Up</a></strong></h6>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="forgot_pswd_sec d_none pt-3">
                                <form (ngSubmit)="forgotPassword()" autocomplete="off">
                                    <div class="form-row">
                                        <div class="col-12">
                                            <input type="email" class="form-control" placeholder="Email address"
                                                name="email" [(ngModel)]="userModel.email">
                                            <small [@inOutAnimation] class="form-text error" *ngIf="errorMessage"
                                                [innerHTML]="errorMessage">
                                            </small>
                                        </div>
                                        <button type="submit" class="btn_blue mt-3 w-100"
                                            [disabled]="btnDisabled">Submit
                                            <mat-spinner class="btnLoading white" [diameter]="20" *ngIf="btnDisabled">
                                            </mat-spinner>
                                        </button>
                                        <div class="col-12 text-left">
                                            <h6 class="pt-3">Already a member? <strong><a href="#" class="sign_in">Sign
                                                        in</a></strong>
                                            </h6>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>