import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { SharedService } from '../../../services/shared.service';
import { Animation } from '../../../utilities/animation';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  animations: [Animation.inOutAnimation()]
})
export class ForgotPasswordComponent implements OnInit {
  formGroup = new FormGroup({});
  errorMessage = '';
  btnDisabled = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
      ])),
    });
  }

  login() {
    this.errorMessage = '';
    if (this.formGroup.invalid) {
      return;
    }
    this.btnDisabled = true;
    this.authService.forgotPassword(this.formGroup.value.email).subscribe((res: any) => {
      this.router.navigate(['/account/login']);
      this.btnDisabled = false;
    }, (err) => {
      console.log('error: ', err);
      this.btnDisabled = false;
      this.errorMessage = err.error.message;
    });
  }
}