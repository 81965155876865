import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthUser, User } from 'src/app/model/user.model';
import { UserService } from 'src/app/services/user.service';
import { Constants } from 'src/app/utilities/constants';
import { MessageAlert } from 'src/app/utilities/message.alert';
import { Utility } from 'src/app/utilities/utility';

@Component({
  selector: 'app-chat-with-new-user',
  templateUrl: './chat-with-new-user.component.html',
  styleUrls: ['./chat-with-new-user.component.scss']
})
export class ChatWithNewUserComponent implements OnInit {
  @Input() user = new User();
  @Input() authUser = new AuthUser();
  @Output() newMessage: EventEmitter<any> = new EventEmitter();
  @Output() refreshList: EventEmitter<any> = new EventEmitter();
  userTypes = Constants.userTypes;
  messageTypes = Constants.messageType;
  chatId = '';
  messageType = '';
  btnDisabled = false;
  isLoading = false;
  constructor(
    private alert: MessageAlert,
    private _userService: UserService) { }

  ngOnInit(): void {
  }
  sendInvitation(user: User) {
    this.btnDisabled = true;
    this.isLoading = true;
    this._userService.sendInvitation(user._id).subscribe((res) => {
      this.btnDisabled = false;
      this.isLoading = false;
      this.alert.success(res.message);
      this.refreshList.emit(true);
    }, (err) => {
      this.alert.error(Utility.bindErrors(err));
      this.btnDisabled = false;
      this.isLoading = false;
    });
  }
  respondInvitation(status: string) {
    this.btnDisabled = true;
    this.isLoading = true;
    this._userService.respondInvitation(this.user.invitationData._id, status).subscribe((res) => {
      this.btnDisabled = false;
      this.isLoading = false;
      this.alert.success(res.message);
      this.refreshList.emit(true);
    }, (err) => {
      this.alert.error(Utility.bindErrors(err));
      this.btnDisabled = false;
      this.isLoading = false;
    });
  }
  startNewConversation(user: User) {
    if (user.blockedData && user.blockedData._id) {
      this.alert.warning('Unblock this user to start messaging');
      return;
    }
    if (user.invitationData && user.invitationData.status === 'Accepted') {
      this.chatId = '';
      if (this.authUser.userType === this.userTypes.STUDENT) {
        this.messageType = this.messageTypes.STUDENT;
      } else if (this.authUser.userType === this.userTypes.TUTOR) {
        this.messageType = this.messageTypes.TUTOR;
      }
      this.newMessage.emit({
        chatId: this.chatId,
        receiverId: user._id,
        messageType: this.messageType
      });
    } else {
      this.alert.warning('Please send request to start messaging');
    }
  }
}
