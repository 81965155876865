<div class="col-lg-12 modal-custom Add_Note">
  <div class="header">
    <h4 class="confirm_delete_head" *ngIf="!title">
      Confirm Delete
    </h4>
    <h4 class="confirm_delete_head" *ngIf="title">
      {{title}}
    </h4>
  </div>
  <div class="row body">
    <div class="col-lg-12">
      <h6 class="confirm_delete_txt" *ngIf="!message">
        Are you sure you
        want to delete
        this?</h6>
        <h6 class="confirm_delete_txt" *ngIf="message" [innerHTML]="message"></h6>
    </div>
  </div>
  <div class="modal-footer justify-content-end border-0 pb-4">
    <button type="button" class="btn btn-secondary" (click)="onConfirm()">Yes</button>
    <button type="button" class="btn btn-secondary" (click)="onDismiss()">No</button>
  </div>
</div>