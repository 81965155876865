<button id="checkout-button" type="button" class="common_btn buy-now" (click)="createCheckoutSession(checkoutModal)">Subscribe {{resource.regionalCurrency | currencySymbol}}{{resource.price}}</button>
<ng-template #checkoutModal>
    <div class="col-lg-12 modal-custom Add_Note">
        <div class="header">
            <h4 class="confirm_delete_head">
                Confirm Checkout
            </h4>
        </div>
        <div class="row body">
            <div class="col-lg-12">
                <h6 class="confirm_delete_txt">Are you sure you want proceed to checkout? You will be redirect to payment page</h6>
            </div>
            <div class="col-lg-12 mt-3">
                <div class="d-flex">
                    <mat-checkbox class="example-margin" [(ngModel)]="agreeTerms"></mat-checkbox>
                    <p class="ml-2">I agree to the <a href="/terms-conditions" target="_blank">Terms & Conditions</a> of use </p>
                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-end border-0 pb-4">
            <button type="button" class="btn btn-secondary" (click)="this.dialogRef.close(true)" [disabled]="!agreeTerms">Proceed</button>
            <button type="button" class="btn btn-secondary" (click)="this.dialogRef.close(false)">Cancel</button>
        </div>
    </div>
</ng-template>