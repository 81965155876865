<div class="d-flex flex-wrap">
    <div class="left_sec">
        <div class="">
            <ul class="nav nav-tabs subTabs" id="subTabs" role="tablist">
                <li class="nav-item" >
                    <a class="nav-link active" id="Notes0" data-toggle="tab" href="#Mynotes" role="tab"
                        aria-controls="Mynotes" aria-selected="true" (click)="setActiveChildTab('Notes');">My
                        notes</a>
                </li>
                <li class="nav-item" *ngIf="authUser.userType !== userTypes.INDIVIDUAL">
                    <a class="nav-link" id="Groups0" data-toggle="tab" href="#Groups" role="tab"
                        aria-controls="Groups" aria-selected="false" (click)="setActiveChildTab('Groups');">Groups</a>
                </li>
                <li class="nav-item" *ngIf="authUser.userType !== userTypes.TUTOR">
                    <a class="nav-link" id="Tutor0" data-toggle="tab" href="#Tutor" role="tab" aria-controls="Tutor"
                        aria-selected="false" (click)="setActiveChildTab('Tutor');">My tutor</a>
                </li>
            </ul>
            <div class="tab-content pt-3" id="subTabs_content">
                <app-tab-notes
                    class="tab-pane fade show active pr_adjest"
                    id="Mynotes"
                    role="tabpanel"
                    aria-labelledby="Mynotes-tab"
                    [resource]="resource"
                    [chapter]="chapter"
                    [section]="section"
                    *ngIf="activeChildTab==='Notes'">
                </app-tab-notes>
                <app-chat-box
                    class="tab-pane fade"
                    id="Groups"
                    role="tabpanel"
                    aria-labelledby="Groups-tab"
                    [messageType]="messageType"
                    [chatId]="chatId"
                    [receiverId]="receiverId"
                    [section]="section"
                    [chapter]="chapter"
                    [resource]="resource"
                    [groupMessage]="groupMessage"
                    [groupModel]="groupModel"
                    [isChatBlocked]="isSelectedChatBlocked"
                    (refreshMessage)="onRefreshMessage($event)"
                    *ngIf="activeChildTab==='Groups' && authUser.userType !== userTypes.INDIVIDUAL">
                </app-chat-box>
                <app-chat-box
                    class="tab-pane fade"
                    id="Tutor"
                    role="tabpanel"
                    aria-labelledby="Tutor-tab"
                    [messageType]="'Tutor'"
                    [chatId]="chatId"
                    [receiverId]="receiverId"
                    [section]="section"
                    [chapter]="chapter"
                    [resource]="resource"
                    [groupMessage]="groupMessage"
                    [groupModel]="groupModel"
                    [isChatBlocked]="isSelectedChatBlocked"
                    (refreshMessage)="onRefreshMessage($event)"
                    *ngIf="activeChildTab==='Tutor' && authUser.userType !== userTypes.TUTOR">
                </app-chat-box>
                <ng-container *ngIf="(activeChildTab==='Groups') && authUser.userType === userTypes.INDIVIDUAL">
                    <app-unauthorized></app-unauthorized>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="right_sec">
        <div class="absolutr_sec" *ngIf="activeChildTab==='Tutor' && organization?._id" [@inOutAnimation]>
            <div>
                <img class="menu_icon" src="assets/img/menu.svg">
                <div class="right_cntnt">
                    <h4 class="pb-3">Provider</h4>
                    <img class="provider" [src]="organization?.logoSecondary?.thumbPath" [alt]="organization?.title">
                    <h6 class="universty">{{organization?.title}}</h6>
                    <h4 class="pt-3 pb-2">Tutor</h4>
                    <ng-select class="single with-image"
                        name="tutorId"
                        [items]="tutorsList"
                        [(ngModel)]="selectedTutor"
                        bindLabel="firstName"
                        bindValue="_id"
                        [clearable]="false"
                        [searchable]="false"
                        (change)="getThreadByUser(selectedTutor)">
                            <ng-template ng-label-tmp let-item="item">
                                <div class="tutor">
                                    <img class="tutor-image" *ngIf="item.profilePicture?.thumbPath" [src]="item.profilePicture?.thumbPath">
                                    <span class="group_icon text-capitalize" *ngIf="!item.profilePicture?.thumbPath" [style.background-color]="item.firstName | randomColorPick ">{{ item.firstName | slice:0:1}}</span>
                                    {{item.firstName}} {{item.lastName}}
                                </div>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                <div class="tutor">
                                    <img class="tutor-image" *ngIf="item.profilePicture?.thumbPath" [src]="item.profilePicture?.thumbPath">
                                    <span class="group_icon text-capitalize" *ngIf="!item.profilePicture?.thumbPath" [style.background-color]="item.firstName | randomColorPick ">{{ item.firstName | slice:0:1}}</span>
                                    {{item.firstName}} {{item.lastName}}
                                </div>
                            </ng-template>
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="absolutr_sec" *ngIf="activeChildTab==='Notes'" [@inOutAnimation]>
            <img class="menu_icon" src="assets/img/menu.svg">
            <div class="right_cntnt">
                <h4 class="pt-3">Sections ({{sections.length}})</h4>
                <ul class="item_wrp">
                    <li [ngClass]="{'active':section._id===item._id,'section-disabled': !item.hasSectionAccess}" *ngFor="let item of sections; let index = index"
                        (click)="selectSection(item)">
                        <a href="javascript:void(0)"><span class="pr-2">{{index+1}}.</span>
                            {{item.title}}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="absolutr_sec" *ngIf="activeChildTab==='Groups' && authUser.userType !== userTypes.INDIVIDUAL" [@inOutAnimation]>
            <img class="menu_icon" src="assets/img/menu.svg">
            <div class="right_cntnt">
                <div class="all_list">
                    <div class="add_note w-100 mt-3 mb-3">
                        <a href="javascript:void(0)" class="toggle_to_list">Create Group</a>
                    </div>
                    <div class="position-relative">
                        <input type="text" class="form-control search_inpt no_icon" [formControl]="keywords"
                            placeholder="Search members/group">
                        <button class="btn search_inpt" (click)="memberSearch()"></button>
                    </div>
                    <ul class="item_wrp item_clck scroll-member" (scroll)="onScroll($event)">
                        <li class="border-radius-0 pdng_1 border_bottom_1 inbox-list" [ngClass]="{'active': chatId===item._id}" *ngFor="let item of messageList">
                            <a href="javascript:void(0)" class="grp_list" *ngIf="item.messageType===messageTypes.GROUP"
                                (click)="chatId=item._id; receiverId=''; messageType=item.messageType;updateReadCount(this.chatId);">
                                <span class="group_icon text-capitalize" [style.background-color]="item.groupName | randomColorPick ">{{ item.groupName | slice:0:1}}</span>
                                <div class="details_">
                                    <h6 style="font-weight: bold !important;">{{item.groupName}}</h6>
                                    <small>{{item.members.length}} Members</small>
                                </div>
                                <div class="time-ago">
                                    <span class="small_" *ngIf="item.messages.length>0">{{item.messages[item.messages.length-1].createdAt | timeAgo}}</span>
                                    <span [id]="'count_'+item._id" class="count" *ngIf="(item.messages | chatUnReadCount: authUser._id) > 0">{{item.messages | chatUnReadCount: authUser._id}}</span>
                                </div>
                            </a>
                            <a href="javascript:void(0)" class="grp_list" *ngIf="item.messageType===messageTypes.STUDENT || item.messageType===messageTypes.TUTOR"
                                (click)="getThreadDetails(item);">
                                <ng-container *ngFor="let u of item.members">
                                    <ng-container *ngIf="authUser._id!==u._id" >
                                        <span class="member_icon" *ngIf="u.profilePicture?.thumbPath">
                                            <img [src]="u.profilePicture?.thumbPath">
                                        </span>
                                        <span class="group_icon text-capitalize"  [style.background-color]="u.firstName | randomColorPick " *ngIf="!u.profilePicture?.thumbPath">{{u.firstName | slice:0:1}}</span>
                                    </ng-container>
                                </ng-container>
                                <div class="details_">
                                    <ng-container
                                        *ngFor="let m of item.messages | slice:item.messages.length-1:item.messages.length">
                                        <h6>{{item.members | inboxUserName: authUser._id}}</h6>
                                        <div [innerHTML]="m.content | safeHtml" class="default-html inbox-thread" [style.font-weight]="m.isRead === false && m.creator !== authUser._id ? 'bold':'normal'"> </div>
                                    </ng-container>
                                </div>
                                <div class="time-ago">
                                    <span class="small_" *ngIf="item.messages.length>0">{{item.messages[item.messages.length-1].createdAt | timeAgo}}</span>
                                    <span [id]="'count_'+item._id" class="count" *ngIf="(item.messages | chatUnReadCount: authUser._id) > 0">{{item.messages | chatUnReadCount: authUser._id}}</span>
                                </div>
                            </a>
                            <app-inbox-actions [messageType]="item.messageType" [groupMessage]="item" [authUser]="authUser" (refreshList)="memberSearch()"></app-inbox-actions>
                        </li>
                        <li class="border-radius-0 pdng_1 border_bottom_1 inbox-list" [ngClass]="{'active': receiverId===item._id && messageType !==messageTypes.GROUP }" *ngFor="let item of membersList">
                            <app-chat-with-new-user
                                [user]="item"
                                [authUser]="authUser"
                                (newMessage)="startNewConversation($event)"
                                (refreshList)="memberSearch()">
                            </app-chat-with-new-user>
                        </li>
                        <ng-container *ngIf="isLoading">
                            <li class="border-radius-0 pdng_1 border_bottom_1 inbox-list" *ngFor="let row of ' '.repeat(6).split('')">
                                <a href="javascript:void(0)" class="grp_list">
                                    <span class="group_icon text-capitalize" [style.background-color]="'' | randomColorPick " ></span>
                                    <div class="details_">
                                        <h6 class="w-25 pt-2">
                                            <mat-progress-bar mode="buffer"></mat-progress-bar>
                                        </h6>
                                        <p class="w-25 pt-2">
                                            <mat-progress-bar mode="buffer"></mat-progress-bar>
                                        </p>
                                    </div>
                                </a>
                            </li>
                        </ng-container>
                        <!-- To avoid some issue for virtual scrolling -->
                        <!-- <div style="height: 100px;"></div> -->
                    </ul>
                </div>
                <app-create-chat-group
                    [viewName]="'create-group'"
                    [section]="section"
                    [chapter]="chapter"
                    [resource]="resource"
                ></app-create-chat-group>
            </div>
        </div>
    </div>
</div>
