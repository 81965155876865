/* eslint-disable no-underscore-dangle */
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Constants } from '../../utilities/constants';
import { MessageAlert } from '../../utilities/message.alert';
import { ResourceService } from '../../services/resource.service';
import { ChapterService } from '../../services/chapter.service';
import { SharedService } from '../../services/shared.service';
import { AuthService } from '../../services/auth.service';
import { Resource, ResourceSuccessData, ResourceDetailsSuccessData } from '../../model/resource.model';
import { Chapter, ChapterSuccessData, ContentList, ContentListSuccessData } from '../../model/chapter.model';
import { AuthUser } from '../../model/user.model';
import { OrganizationDetails } from '../../model/organization.model';
declare const $: any;
@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {
  keywords = '';
  resourceSlug = '';
  resource = new Resource();
  resourceList: Resource[] = [];
  allResources: Resource[] = [];
  chaptersList: Chapter[] = [];
  contentList: ContentList[] = [];
  chapterSearch = new FormControl('');
  organization = new OrganizationDetails();
  httpError: HttpErrorResponse | undefined;
  userTypes = Constants.userTypes;
  authUser = new AuthUser();
  chapterPage = 1;
  chapterLimit = 100;
  selectedResourceId = '';
  activeParent = '';
  activeChild = '';
  isLoading = false;
  isLoadingChapters = false;
  isLoadingSearch = false;
  noResult = false;
  noResultChapter = false;
  showViewMoreBtn = false;
  isProcessingPayment = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private alert: MessageAlert,
    private _sharedService: SharedService,
    private _authService: AuthService,
    private _resourceService: ResourceService,
    private _chapterService: ChapterService,
    @Inject(PLATFORM_ID) private platform: any
  ) {
    this.route.params.subscribe((params) => {
      this.chapterPage = 1;
      this.chaptersList = [];
      this.resourceSlug = params.slug || '';
      let resourceData = this.findResource();
      if (resourceData !== undefined) {
        resourceData = this.setRegionalPrice(resourceData);
        this.resource = resourceData;
        this.getChapters(this.resource._id);
        this.setActiveLink(this.resource);
      } else if (this.resourceSlug) {
        this.getResourceDetails(this.resourceSlug);
      }
    });
  }

  ngOnInit(): void {
    this.authUser = this._authService.authUser();
    this.getResources();
    this._sharedService.organization.subscribe((res: any) => {
      this.organization = res;
    });
    this.chapterSearch.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(keywords => {
      if (keywords === '') {
        this.contentList = [];
        return;
      }
      this.isLoadingSearch = true;
      this._chapterService.chapterSectionSearch(keywords, this.selectedResourceId, 1, 20, 'title').subscribe((res: HttpResponse<ContentListSuccessData>) => {
        this.contentList = res.body?.data || [];
        this.isLoadingSearch = false;
      }, (err: HttpErrorResponse) => {
        console.log(err);
      });
    });
  }
  getResourceDetails(id: string) {
    this._resourceService.getDetails(id).subscribe((res: HttpResponse<ResourceDetailsSuccessData>) => {
      let resource = res.body?.data || Object.assign({});
      if (!resource._id) {
        this.alert.error('No details found Or you don\'t have permission to view this content');
        this.router.navigate(['/resources']);
        return;
      }
      resource = this.setRegionalPrice(resource);
      this.resource = resource;
      this.getChapters(this.resource._id);
      this.setActiveLink(this.resource);
    }, (err) => {
      if (err.status === 400) {
        this.alert.error('No details found Or you don\'t have permission to view this content');
        this.router.navigate(['/resources']);
      }
    });
  }
  getResources() {
    if (this.allResources.length > 0) {
      return;
    }
    this.isLoading = true;
    this._resourceService.filterList(
      '',
      'Active',
      '',
      1,
      100,
      'position',
      'true',
      '',
      ''
    ).subscribe((res: HttpResponse<ResourceSuccessData>) => {
      this.allResources = res.body?.data || [];
      const resourceGroups = this.allResources.filter(x => x.isClassifiedClass === true);
      for (const r of resourceGroups) {
        r.classificationsList = this.allResources.filter((x) => x.classificationsId === r._id).sort((a, b) => (a.position) - (b.position));
      }
      const nonClassifiedItems = this.getNonClassifiedResources(resourceGroups);
      if (nonClassifiedItems.classificationsList.length > 0) {
        this.resourceList = [...resourceGroups, ...[nonClassifiedItems]];
      } else {
        this.resourceList = resourceGroups;
      }
      if (this.resourceList.length === 0) {
        this.noResult = true;
      } else {
        if (!this.resourceSlug) {
          this.resource = this.findResource();
          this.getChapters(this.resource._id);
          this.setActiveLink(this.resource);
        }
      }
      this.isLoading = false;
      if (isPlatformBrowser(this.platform)) {
        setTimeout(() => {
          $('#' + this.resource._id).closest('li').find('.fa').addClass('fa-minus').removeClass('fa-plus');
          $('#' + this.resource._id).parent('ul').css({ display: 'block' });
          setTimeout(() => {
            // $('#' + this.resource._id).click();
          }, 100);
        }, 100);
      }
    }, () => {
      this.isLoading = false;
    });
  }
  getNonClassifiedResources(resourceGroups: Resource[]): Resource {
    let classifiedIDs: string[] = [];
    for (const item of resourceGroups) {
      classifiedIDs.push(item._id);
      const subIDS = item.classificationsList.map((x) => x._id);
      classifiedIDs = [...classifiedIDs, ...subIDS];
    }
    const category = new Resource();
    const categories: Resource[] = [];
    for (const resource of this.allResources) {
      if (!classifiedIDs.includes(resource._id)) {
        categories.push(resource);
      }
    }
    category.title = 'Others';
    category.userData = {};
    category.userData.userType = this.userTypes.MASTER_ADMIN;
    category.classificationsList = categories;
    return category;
  }
  findResource(): Resource {
    if (this.resourceSlug) {
      return this.allResources.find((x) => x.slug === this.resourceSlug) || this.allResources[0];
    } else {
      return this.allResources[0];
    }
  }
  setRegionalPrice(resource: Resource): Resource {
    resource.subscriptionPriceList = resource.subscriptionPriceList || [];
    let priceData = resource.subscriptionPriceList.find((x) => x.currency === resource.regionalCurrency);
    if (priceData !== undefined) {
      resource.price = priceData.price;
    } else {
      // price will be default and currency will be default
      priceData = resource.subscriptionPriceList.find((x) => x.currency === 'usd');
      if (priceData !== undefined) {
        resource.price = priceData.price;
      }
    }
    return resource;
  }
  onNavigateChapters(resource: Resource) {
    if (!resource.slug) {
      // for classifications "Others"
      return;
    }
    this.resource = resource;
    this.router.navigate(['/resources/' + resource.slug]);
  }
  setActiveLink(resource: Resource) {
    if (resource.isClassifiedClass) {
      this.activeParent = resource._id;
    } else {
      this.activeChild = resource._id;
    }
  }
  getChapters(resourceId: string): void {
    this.selectedResourceId = resourceId;
    this.isLoadingChapters = true;
    this.noResultChapter = false;
    this.httpError = undefined;
    this._chapterService.filterList(
      this.keywords,
      'Active',
      resourceId,
      true,
      this.chapterPage,
      this.chapterLimit,
      ''
    ).subscribe((res: HttpResponse<ChapterSuccessData>) => {
      const data = res.body?.data || [];
      if (this.chaptersList.length === 0) {
        this.chaptersList = data;
      } else {
        this.chaptersList = [...this.chaptersList, ...data];
      }
      if (this.chaptersList.length === 0) {
        this.noResultChapter = true;
      }
      if (this.chaptersList.length >= this.chapterLimit && data.length !== 0) {
        this.showViewMoreBtn = true;
      } else {
        this.showViewMoreBtn = false;
      }
      this.isLoadingChapters = false;
    }, (err: HttpErrorResponse) => {
      this.isLoadingChapters = false;
      this.noResultChapter = true;
      this.httpError = err;
    });
  }
  pageChanged() {
    this.chapterPage++;
    this.getChapters(this.selectedResourceId);
  }
  onCloseSearchBox() {
    this.chapterSearch.setValue('');
    this.contentList = [];
  }
  onProcessingPayment(isLoading: any) {
    this.isProcessingPayment = isLoading;
  }
  imageIsLoaded(id: string) {
    try {
      document.getElementById(id)?.remove();
    } catch (error) {
    }
  }
}
