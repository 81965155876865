/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { Component, Input, OnInit, OnChanges, Output, EventEmitter, Inject, PLATFORM_ID } from '@angular/core';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { NgxFileDropEntry } from 'ngx-file-drop';
import * as moment from 'moment';
import { MessageAlert } from '../../../../utilities/message.alert';
import { CommunicationService } from '../../../../services/communication.service';
import { GoogleAnalyticsService } from '../../../../services/google-analytics.service';
import { ConfirmDialogComponent } from '../../../../common/confirm-dialog/confirm-dialog.component';
import { CreateMessage, Communication, CommunicationDetailsSuccessData, Message } from '../../../../model/communication.model';
import { DialogModel, MessageNotification } from '../../../../model/dialog.model';
import { Config } from '../../../../utilities/config';
import { Utility } from '../../../../utilities/utility';
import { Constants } from '../../../../utilities/constants';
import { AuthService } from '../../../../services/auth.service';
import { FileUploadService } from '../../../../services/file-upload.service';
import { User } from '../../../../model/user.model';
import { CreateGroup } from '../../../../model/group.model';
import { Resource } from '../../../../model/resource.model';
import { Chapter } from '../../../../model/chapter.model';
import { Section } from '../../../../model/section.model';
import { SharedService } from 'src/app/services/shared.service';
declare let Quill: any;
declare let $: any;
let editor: any;

@Component({
  selector: 'app-chat-box',
  templateUrl: './chat-box.component.html',
  styleUrls: ['./chat-box.component.scss']
})
export class ChatBoxComponent implements OnInit, OnChanges {
  @Input() messageType = '';
  @Input() chatId = '';
  @Input() receiverId = '';
  @Input() resource = new Resource();
  @Input() chapter = new Chapter();
  @Input() section = new Section();
  @Input() groupMessage = new Communication();
  @Input() groupModel = new CreateGroup();
  @Input() isChatBlocked = false;
  @Output() refreshMessage: EventEmitter<any> = new EventEmitter();
  authUser = new User();
  messageModel = new CreateMessage();
  fileUploads: any = new Array();
  userTypes = Constants.userTypes;
  deletedFiles: string[] = [];
  updateGroupCheckedIds: any = {};
  uploadingPercentage = 0;
  updateGroupName = '';
  organizationId = '';
  errorMessage = '';
  btnDisabled = false;
  isLoading = false;
  constructor(
    private alert: MessageAlert,
    private dialog: MatDialog,
    private _authService: AuthService,
    private _communicationService: CommunicationService,
    public _fileUploadService: FileUploadService,
    private _sharedService: SharedService,
    private _googleAnalyticsService: GoogleAnalyticsService,
    @Inject(PLATFORM_ID) private platform: Record<string, unknown>) { }

  ngOnInit(): void {
    this.authUser = this._authService.authUser();
    this.organizationId = this._authService.authUser().organizationId;
    const options = Object.assign({}, Config.quillJs.options);
    options.modules.toolbar = Config.quillJs.toolbarOptionsSimple;
    editor = new Quill('#QuillEditor', options);

    setTimeout(() => {
      const socket = this._sharedService.webSocketGetInstance();
      socket.on(`event.message.created.${this.authUser._id}`, (message: MessageNotification) => {
        if (this.chatId === message.threadId) {
          this.getMessageDetails();
        }
      });
      socket.on(`event.message.updated.${this.authUser._id}`, (message: MessageNotification) => {
        if (this.chatId === message.threadId) {
          this.getMessageDetails();
        }
      });
      socket.on(`event.message.deleted.${this.authUser._id}`, (message: MessageNotification) => {
        if (this.chatId === message.threadId) {
          this.getMessageDetails();
        }
      });
    }, 2000);
  }
  ngOnChanges(): void {
    this.messageModel = new CreateMessage();
    this.messageModel.messageType = this.messageType;
    this.messageModel.receiverId = this.receiverId;
    this.getMessageDetails();
    this.onjQueryEvents();
  }
  getMessageDetails() {
    this.updateGroupCheckedIds = {};
    if (this.chatId) {
      this.isLoading = true;
      this._communicationService.messageDetails(this.chatId).subscribe((res: HttpResponse<CommunicationDetailsSuccessData>) => {
        this.groupMessage = new Communication();
        this.groupModel = new CreateGroup();
        this.groupMessage = res.body?.data || Object.assign({});
        try {
          const updateGroupCheckedIds: any = {};
          this.groupMessage.members.forEach(element => {
            updateGroupCheckedIds[element._id] = true;
          });
          this.updateGroupCheckedIds = updateGroupCheckedIds;
        } catch (error) {
        }
        this.groupModel._id = this.groupMessage._id;
        this.groupModel.groupName = this.groupMessage.groupName;
        this.groupModel.messageType = this.groupMessage.messageType;
        this.isLoading = false;
        this.resetVerticalScrolling();
      });
    } else {
      this.groupMessage = new Communication();
      this.groupModel = new CreateGroup();
    }
  }
  editMessage(data: any) {
    this.messageModel = Object.assign({}, data);
    this.messageModel.messageType = this.messageType;
    for (const item of this.messageModel.attachments) {
      this.fileUploads.push({
        _id: item._id,
        src: item.path,
        filename: item.filename,
        file: null,
        type: Utility.getFileType(item.filename),
        isNewItem: false
      });
    }
    this.setContent(this.messageModel.content);
    if (isPlatformBrowser(this.platform)) {
      // window.scrollTo(0, 0);
      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: 0
      });
    }
  }
  cancelEdit() {
    this.messageModel._id = '';
    this.messageModel.content = '';
    this.fileUploads = [];
    this.deletedFiles = [];
    this.setContent('');
  }
  sendMessage() {
    if (!this.chatId && !this.messageModel.receiverId) {
      this.alert.warning('Please select group or user for send message');
      return;
    }
    if (this.isChatBlocked) {
      this.alert.warning('Please unblock this conversation to send message');
      return;
    }
    this.messageModel.sectionId = this.section._id;
    this.messageModel.chapterId = this.chapter._id;
    this.messageModel.resourceId = this.resource._id;
    this.messageModel.receiverId = this.receiverId;

    if (!this.messageModel._id) {
      this.createMessage();
    } else {
      this.updateMessage();
    }
  }
  createMessage() {
    this.messageModel.content = editor.root.innerHTML;
    const text = $(this.messageModel.content).text();
    if (!text) {
      return this.alert.warning('Please write your message');
    }
    this.btnDisabled = true;
    this.isLoading = true;
    const _model = Object.assign({}, this.messageModel);
    _model.groupId = this.chatId;
    _model.resourceSlug = this.resource.slug;
    _model.chapterSlug = this.chapter.slug;
    _model.sectionSlug = this.section.slug;
    this._communicationService.messageCreate(_model).subscribe((res) => {
      const tutorRequestData = {
        sectionId: _model.sectionId,
        chapterId: _model.chapterId,
        resourceId: _model.resourceId,
        resourceSlug: _model.resourceSlug,
        chapterSlug: _model.chapterSlug,
        sectionSlug: _model.sectionSlug,
        content: text,
        tutorId: _model.receiverId,
        userId: this.authUser._id,
        organizationId: this.organizationId,
        userType: this.authUser.userType
      };
      this._googleAnalyticsService.sendEvent('Tutor Request', tutorRequestData);
      if (this.fileUploads.length > 0) {
        this.uploadFiles(res.data._id, res.data.message._id);
      } else {
        this.btnDisabled = false;
        this.isLoading = false;
        this.cancelEdit();
        this.getMessageDetails();
        this.onSendResponse(res.data._id);
        this.alert.success(res.message);
      }
    }, (err) => {
      this.alert.error(Utility.bindErrors(err));
      this.btnDisabled = false;
      this.isLoading = false;
    });
  }
  updateMessage() {
    this.messageModel.content = editor.root.innerHTML;
    this.messageModel.deletedAttachmentNames = this.deletedFiles.toString();
    const text = $(this.messageModel.content).text();
    if (!text) {
      return this.alert.warning('Please write your message');
    }
    this.btnDisabled = true;
    this.isLoading = true;
    const _model = Object.assign({}, this.messageModel);
    _model.groupId = this.chatId;
    this._communicationService.messageUpdate(this.chatId, _model).subscribe((res) => {
      const hasNewFile = this.fileUploads.find((x: any) => x.isNewItem);
      if (hasNewFile) {
        this.uploadFiles(this.chatId, res.data.messageId);
      } else {
        this.btnDisabled = false;
        this.isLoading = false;
        this.cancelEdit();
        this.getMessageDetails();
        this.onSendResponse(res.data._id);
        this.alert.success(res.message);
      }
    }, (err) => {
      this.alert.error(Utility.bindErrors(err));
      this.btnDisabled = false;
      this.isLoading = false;
    });
  }
  uploadFiles(id: string, messageId: string) {
    const formData = this.buildFormData(id, messageId);
    this._fileUploadService.uploadFiles(formData).subscribe((event: any) => {
      this.uploadingPercentage = Utility.fileUploadProgress(event).message;
      if (event.body) {
        this.onSendResponse(id);
        this.setContent('');
        this.fileUploads = [];
        this.btnDisabled = false;
        this.isLoading = false;
        this.alert.success('Message send successfully');
      }
    }, (err: HttpErrorResponse) => {
      this.btnDisabled = false;
      this.errorMessage = Utility.bindErrors(err);
      this.alert.error('Image not uploaded');
    });
  }
  buildFormData(id: string, messageId: string) {
    const formData = new FormData();
    for (const item of this.fileUploads) {
      if (item.file && item.isNewItem) {
        formData.append('documentId', id);
        formData.append('documentName', 'communications'); // check name in API
        formData.append('subDocumentId', messageId);
        formData.append('subDocumentName', 'messages'); // check name in API
        formData.append('folder', ''); // dynamic folder
        formData.append('files', item.file || '');
      }
    }
    return formData;
  }
  deleteMessage(messageId: string) {
    const message = `Are you sure you want to delete this message?`;
    const dialogData = new DialogModel('Confirm Delete', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      const result = dialogResult;
      if (result === true) {
        this.isLoading = true;
        this._communicationService.messageDelete(this.chatId, messageId, 'Group').subscribe((res: any) => {
          this.alert.success(res.message);
          if (this.groupMessage.messages.length === 0) {
            this.onSendResponse('');
          } else {
            this.onSendResponse(this.chatId);
          }
          this.isLoading = false;
          this.getMessageDetails();
        }, (err) => {
          this.alert.error(Utility.bindErrors(err));
          this.isLoading = false;
        });
      }
    });
  }
  onSendResponse(id: string) {
    let chatId = '';
    if (this.messageType === 'Group') {
      chatId = this.chatId;
    } else {
      chatId = id; // new id
    }
    this.chatId = chatId;
    this.refreshMessage.emit({ messageType: this.messageType, chatId, receiverId: this.receiverId });
  }
  onClickFileUpload() {
    this.openFile('btnFileSelector');
  }
  setContent(content: string) {
    const delta = editor.clipboard.convert(content);
    editor.setContents(delta, 'silent');
    if (!content) {
      $('.ql-picker-options').find('.ql-picker-item').last().click();
    }
  }
  onCheckedMember(checked: boolean, id: string) {
    if (!checked) {
      delete this.updateGroupCheckedIds[id];
    }
  }
  droppedFiles(files: NgxFileDropEntry[]) {
    const items = files;
    items.forEach((item: any) => {
      if (item.fileEntry.isFile) {
        const fileExtension = (item.fileEntry.name.split('.').pop()).toLowerCase();
        item.fileEntry.file((file: any) => {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            let type = '';
            if (fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png') {
              type = 'image';
            } else if (fileExtension === 'pdf') {
              type = 'pdf';
            } else if (fileExtension === 'docx') {
              type = 'doc';
            } else if (fileExtension === 'xls' || fileExtension === 'xlsx') {
              type = 'sheet';
            } else if (fileExtension === 'txt') {
              type = 'text';
            } else {
              type = 'other';
            }
            this.fileUploads.push({
              _id: moment().valueOf(),
              src: e.target.result,
              filename: file.name,
              file,
              type,
              isNewItem: true
            });
          };
          reader.readAsDataURL(file);
        });
      }
    });
  }
  removeFile(index: number, file: any) {
    this.fileUploads.splice(index, 1);
    if (file.isNewItem === false) {
      this.deletedFiles.push(file.filename);
    }
  }
  openFile(id: string) {
    document.getElementById(id)?.click();
  }
  imageIsLoaded(id: string) {
    try {
      document.getElementById(id)?.remove();
    } catch (error) {
    }
  }
  resetVerticalScrolling() {
    setTimeout(() => {
      try {
        const chatWrapperHeight = document.getElementById('Groups')?.clientHeight || 0;
        const membersSection: any = document.getElementsByClassName('scroll-member')[0];
        membersSection.style.minHeight = '300px';
        membersSection.style.maxHeight = (chatWrapperHeight - 100) + 'px';
        membersSection.style.overflowY = 'scroll';
      } catch (error) {
      }
    }, 10);
    if (isPlatformBrowser(this.platform)) {
      // window.scrollTo(0, 0);
    }
  }
  onjQueryEvents() {
    $(document).ready(function () {
      $('#editorClone').find('.ql-toolbar').detach().appendTo('.editor-wrapper');
      $('#quillAttachments').detach().appendTo('#editorClone .ql-toolbar');
      $('.ql-picker-options').find('.ql-picker-item').last().click();
    });
  }
}
