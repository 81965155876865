import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Constants } from '../../../../utilities/constants';
import { UserService } from '../../../../services/user.service';
import { UserDetails } from '../../../../model/user.model';
import { DialogModel } from '../../../../model/dialog.model';
import { Animation } from '../../../../utilities/animation';
import { SuccessDialogComponent } from '../../../../common/success-dialog/success-dialog.component';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss'],
  animations: [Animation.inOutAnimation()]
})
export class ProfileEditComponent implements OnInit {
  id = '';
  formGroup = new FormGroup({});
  userData = new UserDetails();
  errorMessage = '';
  btnDisabled = false;
  countryList = [
    { name: 'Australia', value: 'Australia' },
    { name: 'Germany', value: 'Germany' },
    { name: 'UK', value: 'UK' }
  ];
  image = { src: '', filename: '', file: null };
  sizeLimitInKb = 300;
  imageError = '';
  userTypes = Constants.userTypes;
  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id') || '';
    if (this.id) {
      this.fetchUserDetails();
    }
    this.formGroup = new FormGroup({
      firstName: new FormControl('', Validators.compose([
        Validators.required
      ])),
      lastName: new FormControl('', Validators.compose([
        Validators.required
      ])),
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
      ])),
      phone: new FormControl('', Validators.compose([
      ])),
      address: new FormControl('', Validators.compose([
      ])),
      street: new FormControl('', Validators.compose([
      ])),
      city: new FormControl('', Validators.compose([
      ])),
      state: new FormControl('', Validators.compose([
      ])),
      postcode: new FormControl('', Validators.compose([
      ])),
      country: new FormControl(null, Validators.compose([
      ]))
    });
  }
  fetchUserDetails(): void {
    this.userService.getDetails(this.id).subscribe((res: any) => {
      this.userData = res.body.data;
      this.formGroup.controls.firstName.setValue(this.userData.firstName);
      this.formGroup.controls.lastName.setValue(this.userData.lastName);
      this.formGroup.controls.email.setValue(this.userData.email);
      this.formGroup.controls.phone.setValue(this.userData.phone);
      this.formGroup.controls.address.setValue(this.userData.address);
      this.formGroup.controls.street.setValue(this.userData.street);
      this.formGroup.controls.city.setValue(this.userData.city);
      this.formGroup.controls.state.setValue(this.userData.state);
      this.formGroup.controls.postcode.setValue(this.userData.postcode);
      this.formGroup.controls.country.setValue(this.userData.country);
    });
  }
  onFormSubmit(form: FormGroupDirective): void {
    this.errorMessage = '';
    if (this.formGroup.invalid) {
      this.errorMessage = '* Some fields are not valid!';
      setTimeout(() => { this.errorMessage = ''; }, 3000);
      return;
    }
    this.btnDisabled = true;
    this.userService.update(this.userData._id, this.formGroup.value).subscribe((res: any) => {
      this.showMessage('Updated', 'Updated successfully');
      if (this.image.file) {
        this.uploadImage();
      }
      this.btnDisabled = false;
    }, (err) => {
      if (err.status === 401) {
        this.errorMessage = err.error.message;
      } else {
        this.errorMessage = 'Oops error!';
      }
      setTimeout(() => { this.errorMessage = ''; }, 3000);
      this.btnDisabled = false;
    });
  }
  showMessage(heading: string, message: string): void {
    const dialogData = new DialogModel(heading, message);
    this.dialog.open(SuccessDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });
  }
  onFileChange(event: any): any {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      if (file.size > this.sizeLimitInKb * 1024) {
        this.imageError = `profile picture size limited to ${this.sizeLimitInKb} KB`;
        setTimeout(() => { this.imageError = ''; }, 3000);
        return false;
      }
      reader.readAsDataURL(file);
      reader.onload = (e: any) => {
        this.image = {
          src: e.target.result,
          filename: file.name,
          file
        };
      };
    }
  }
  buildFormData(): FormData {
    const formData = new FormData();
    if (this.image.file) {
      formData.append('documentId', this.userData._id);
      formData.append('documentName', 'users'); // check name in API
      formData.append('file', this.image.file || '');
    }
    return formData;
  }
  uploadImage(): void {
    if (this.image.file) {
      const formData = this.buildFormData();
      this.userService.uploadProfilePicture(formData).subscribe((event: any) => {
        if (event.body) {
          this.showMessage('Uploaded !', 'Profile picture updated successfully');
          this.image = { src: '', filename: '', file: null };
          this.userData.profilePicture = event.body.data;
        }
      }, (err: HttpErrorResponse) => {
        // this.toastr.showErrorToastr('Image not uploaded');
      });
    }
  }
}
