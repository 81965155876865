<section class="about_sec">
    <div class="container-fluid w_80">
      <div class="row">
        <div class="col-lg-12">
          <h3>Privacy Policy</h3>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="container-fluid w_80 mb-5">
      <div class="row">
        <div class="col-lg-12">
          <div class="wrapper_white p-7">
            <h1>Our privacy policy</h1>
            <div class="common-content mt-4 policies">
              <ol>
                <li>
                  <h3> Introduction</h3>
                  <h4>Welcome to Finstream. </h4>
                  <p><strong>Finstream</strong> (“us”, “we”, or “our”) operates <strong>https:// finstream.com
                      /</strong> (hereinafter referred to as <strong>“Service”</strong>).</p>
                  <p>Our Privacy Policy governs your visit to https:// finstream.com / and explains how we collect,
                    safeguard and disclose information that results from your use of our Service.
                  </p>
                  <p>We use your data to provide and improve Service. By using Service, you agree to the collection
                    and use of information in accordance with this policy. Unless otherwise defined in this Privacy
                    Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and
                    Conditions.
                  </p>
                  <p>Our Terms and Conditions (<strong>“Terms”</strong>) govern all use of our Service and together
                    with the Privacy Policy constitutes your agreement with us (<strong>“agreement”</strong>).
                  </p>
                </li>
                <li>
                  <h3 class="pb-3">Definitions</h3>
                  <p><strong>SERVICE</strong>  means the <strong>https:// finstream.com / </strong> operated by
                    Finstream.
                    <strong> PERSONAL DATA </strong> data about a living individual who can be identified from those
                    data (or from
                    those and other information either in our possession or likely to come into our possession).<br>
                    <strong>USAGE DATA</strong> is data collected automatically either generated by the use of Service
                    or from Service infrastructure itself (for example, the duration of a page visit).
                    <strong>COOKIES</strong>  are small files stored on your device (computer or mobile device).<br>
                    <strong>DATA CONTROLLER</strong>  means a natural or legal person who (either alone or jointly or
                    in common with
                    other persons) determines the purposes for which and the manner in which any personal data are, or
                    are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your
                    data.<br>
                    <strong>DATA PROCESSORS (OR SERVICE PROVIDERS)</strong>  means any natural or legal person who
                    processes the data on behalf of the Data Controller. We may use the services of various Service
                    Providers in order to process your data more effectively.
                    <strong>DATA SUBJECT</strong>  is any living individual who is the subject of Personal Data.<br>
                    <strong>THE USER</strong>  is the individual using our Service. The User corresponds to the Data
                    Subject, who is the subject of Personal Data.

                  </p>
                </li>
                <li>
                  <h3 class="pb-3"> Information Collection and Use</h3>
                  <p>We collect several different types of information for various purposes to provide and improve our
                    Service to you.</p>
                </li>
                <li>
                  <h3>Types of Data Collected</h3>
                  <h4>Personal Data</h4>
                  <p>While using our Service, we may ask you to provide us with certain personally identifiable
                    information that can be used to contact or identify you (“Personal Data”). Personally identifiable
                    information may include, but is not limited to:</p>
                  <ul>
                    <li>Email address</li>
                    <li>First name and last name</li>
                    <li>Phone number</li>
                    <li>Address, Country, State, Province, ZIP/Postal code, City</li>
                    <li>Cookies and Usage Data</li>
                  </ul>
                  <p>We may use your Personal Data to contact you with newsletters, marketing or promotional materials
                    and other information that may be of interest to you. You may opt out of receiving any, or all, of
                    these communications from us by following the unsubscribe link.
                  </p>
                  <h4>Usage Data</h4>
                  <p>We may also collect information that your browser sends whenever you visit our Service or when
                    you access Service by or through any device (<strong>“Usage Data”</strong>).
                  </p>
                  <p>This Usage Data may include information such as your computer’s Internet Protocol address (e.g.
                    IP address), browser type, browser version, the pages of our Service that you visit, the time and
                    date of your visit, the time spent on those pages, unique device identifiers and other diagnostic
                    data.
                  </p>
                  <p>When you access Service with a device, this Usage Data may include information such as the type
                    of device you use, your device unique ID, the IP address of your device, your device operating
                    system, the type of Internet browser you use, unique device identifiers and other diagnostic data.
                  </p>
                  <h4>Other Data</h4>
                  <p>While using our Service, we may also collect the following information: sex, age, date of birth,
                    place of birth, passport details, citizenship, registration at place of residence and actual
                    address, telephone number (work, mobile), details of documents on education, qualification,
                    professional training, employment agreements,  information on bonuses and compensation,
                    information on marital status, family members, social security (or other taxpayer identification)
                    number, office location and other data.
                  </p>
                </li>
                <li>
                  <h3 class="pb-3">Use of Data</h3>
                  <p>Finstream uses the collected data for various purposes</p>
                  <ul>
                    <li>to provide and maintain our Service;</li>
                    <li>to notify you about changes to our Service;</li>
                    <li>to allow you to participate in interactive features of our Service when you choose to do so;
                    </li>
                    <li>to provide customer support;</li>
                    <li>to gather analysis or valuable information so that we can improve our Service;</li>
                    <li>to monitor the usage of our Service; </li>
                    <li>to detect, prevent and address technical issues;</li>
                    <li>to fulfil any other purpose for which you provide it;</li>
                    <li>to carry out our obligations and enforce our rights arising from any contracts entered into
                      between you and us, including for billing and collection;</li>
                    <li>to provide you with notices about your account and/or subscription, including expiration and
                      renewal notices, email-instructions, etc.;</li>
                    <li>to provide you with news, special offers and general information about other goods, services
                      and events which we offer that are similar to those that you have already purchased or enquired
                      about unless you have opted not to receive such information;</li>
                    <li>in any other way we may describe when you provide the information;</li>
                    <li>for any other purpose with your consent.</li>
                  </ul>
                </li>
                <li>
                  <h3 class="pb-3">Retention of Data</h3>
                  <p>We will retain your Personal Data only for as long as is necessary for the purposes set out in
                    this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply
                    with our legal obligations (for example, if we are required to retain your data to comply with
                    applicable laws), resolve disputes, and enforce our legal agreements and policies.
                  </p>
                  <p>We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained
                    for a shorter period, except when this data is used to strengthen the security or to improve the
                    functionality of our Service, or we are legally obligated to retain this data for longer time
                    periods.
                  </p>
                </li>
                <li>
                  <h3 class="pb-3">Transfer of Data</h3>
                  <p>Your information, including Personal Data, may be transferred to – and maintained on – computers
                    located outside of your state, province, country or other governmental jurisdiction where the data
                    protection laws may differ from those of your jurisdiction.<br>
                    If you are located outside Australia and choose to provide information to us, please note that we
                    transfer the data, including Personal Data, to Australia and process it there.
                  </p>
                  <p>Your consent to this Privacy Policy followed by your submission of such information represents
                    your agreement to that transfer.
                    <br>
                    Finstream will take all the steps reasonably necessary to ensure that your data is treated
                    securely and in accordance with this Privacy Policy and no transfer of your Personal Data will
                    take place to an organisation or a country unless there are adequate controls in place including
                    the security of your data and other personal information.
                  </p>
                </li>
                <li>
                  <h3 class="pb-3">Disclosure of Data</h3>
                  <p>We may disclose personal information that we collect, or you provide:</p>
                  <ul>
                    <li>Business Transaction.
                      <p class="ml-0">If we or our subsidiaries are involved in a merger, acquisition or asset sale,
                        your Personal
                        Data may be transferred.</p>
                        </li>
                    <li>
                      Other cases. We may disclose your information also:
                      <ul>
                        <li>to our subsidiaries and affiliates;</li>
                        <li>to contractors, service providers, and other third parties we use to support our
                          business;</li>
                        <li>to fulfill the purpose for which you provide it;</li>
                        <li>for the purpose of including your company’s logo on our website;</li>
                        <li>for any other purpose disclosed by us when you provide the information;</li>
                        <li>with your consent in any other cases;</li>
                        <li>if we believe disclosure is necessary or appropriate to protect the rights, property, or
                          safety of the Company, our customers, or others.</li>
                      </ul>
                    </li>
                </ul>
                </li>
                <li>
                  <h3 class="pb-3">Security of Data</h3>
                  <p>The security of your data is important to us but remember that no method of transmission over the
                    Internet or method of electronic storage is 100% secure. While we strive to use commercially
                    acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
                </li>
                <li>
                  <h3 class="pb-3">Your Data Protection Rights Under General Data Protection Regulation (GDPR)</h3>
                  <p>If you are a resident of the European Union (EU) and European Economic Area (EEA), you have
                    certain data protection rights, covered by GDPR.<br>
                    We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your
                    Personal Data.<br>
                    If you wish to be informed what Personal Data we hold about you and if you want it to be removed
                    from our systems, please email us at  <a href="">Info@finstream.com</a>.
                  </p>
                  <p>In certain circumstances, you have the following data protection rights:</p>
                  <ul class="mb-0">
                    <li>the right to access, update or to delete the information we have on you;</li>
                    <li>the right of rectification. You have the right to have your information rectified if that
                      information is inaccurate or incomplete;
                    </li>
                    <li>the right to object. You have the right to object to our processing of your Personal Data;
                    </li>
                    <li>the right of restriction. You have the right to request that we restrict the processing of
                      your personal information;</li>
                    <li>the right to data portability. You have the right to be provided with a copy of your Personal
                      Data in a structured, machine-readable and commonly used format;</li>
                    <li>the right to withdraw consent. You also have the right to withdraw your consent at any time
                      where we rely on your consent to process your personal information;
                      <p class="ml-0">Please note that we may ask you to verify your identity before responding to
                        such requests. Please note, we may not able to provide Service without some necessary data.
                      </p>
                    </li>
                  </ul>
                  <p>You have the right to complain to a Data Protection Authority about our collection and use of
                    your Personal Data. For more information, please contact your local data protection authority in
                    the European Economic Area (EEA).
                  </p>
                  <p>This Privacy Policy is also notification to individuals of the matters required to be notified by
                    the Australian Privacy Principles.
                  </p>
                </li>
                <li>
                  <h3 class="pb-3">Service Providers</h3>
                  <p>We may employ third party companies and individuals to facilitate our Service (<strong>“Service
                      Providers”</strong>), provide Service on our behalf, perform Service-related services or assist
                    us in
                    analysing how our Service is used.</p>
                  <p>These third parties have access to your Personal Data only to perform these tasks on our behalf
                    and are obligated not to disclose or use it for any other purpose.</p>
                </li>
                <li>
                  <h3 class="pb-3">Analytics</h3>
                  <p>We may use third-party Service Providers to monitor and analyse the use of our Service.</p>
                </li>
                <li>
                  <h3 class="pb-3">CI/CD tools</h3>
                  <p>We may use third-party Service Providers to automate the development process of our Service.</p>
                </li>
                <li>
                  <h3 class="pb-3">Payments</h3>
                  <p>We may provide paid products and/or services within Service. In that case, we use third-party
                    services for payment processing (e.g. payment processors).
                    We will not store or collect your payment card details. That information is provided directly to
                    our third-party payment processors whose use of your personal information is governed by their
                    Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the
                    PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American
                    Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.
                  </p>
                </li>
                <li>
                  <h3 class="pb-3">Links to Other Sites</h3>
                  <p>Our Service may contain links to other sites that are not operated by us. If you click a third
                    party link, you will be directed to that third party’s site. We strongly advise you to review the
                    Privacy Policy of every site you visit.
                    We have no control over and assume no responsibility for the content, privacy policies or
                    practices of any third party sites or services.</p>
                </li>
                <li>
                  <h3 class="pb-3">Changes to This Privacy Policy</h3>
                  <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting
                    the new Privacy Policy on this page.
                    We will let you know via email and/or a prominent notice on our Service, prior to the change
                    becoming effective and update “effective date” at the top of this Privacy Policy.</p>
                  <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this
                    Privacy Policy are effective when they are posted on this page.
                  </p>
                </li>
                <li>
                  <h3 class="pb-3">Contact Us</h3>
                  <p>If you have any questions about this Privacy Policy, please contact us by email:  <strong><a href="mailto:info@finstream.com">Info@finstream.com</a></strong>.</p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>