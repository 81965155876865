<ng-container *ngIf="isInitialVerification">
    <div class="col not-found-page" *ngIf="isLoading">
        <div class="noResultsCont">
            <div *ngIf="isLoading" class="content-height" style="margin-top: 10%;">
                <mat-spinner class="btnLoading" [diameter]="75">
                </mat-spinner>
            </div>
        </div>
    </div>
    <div class="col not-found-page" *ngIf="!isLoading && isVerificationError">
        <div class="noResultsCont">
            <div>
                <img src="assets/img/error-500.svg">
                <div class="noresultTxt">
                    <label>Invalid request!</label>
                    <p class="mt-3">{{message}}</p>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!isLoading && !isVerificationError">
        <section class="banner_sec">
            <div class="position-relative">
                <img src="assets/img/login_bg.jpg" class="img-fluid main_bg">
                <div class="text_overlay">
                    <div class="form_log">
                        <img class="uni-logo" *ngIf="logo" [src]="logo">
                        <h1>Create your password</h1>
                        <form id="login_sec" [formGroup]="formGroup" #form="ngForm" (ngSubmit)="changePassword()"
                            autocomplete="off" novalidate>
                            <div class="form-row">

                                <div class="col-12">
                                    <input type="password" class="form-control inputRadius" placeholder="new password"
                                        formControlName="password">
                                    <small [@inOutAnimation] class="form-text error"
                                        *ngIf="form.submitted && formGroup.controls['password'].hasError('required')">
                                        Please fill this field
                                    </small>
                                    <small [@inOutAnimation] class="form-text error"
                                    *ngIf="form.submitted && formGroup.controls['password'].hasError('minlength')">
                                    Password must have at least 7 characters
                                   </small>
                                </div>
                                <div class="col-12">
                                    <input type="password" class="form-control inputRadius"
                                        placeholder="Re-enter new password" formControlName="confirmPassword">
                                    <small [@inOutAnimation] class="form-text error"
                                        *ngIf="form.submitted && formGroup.controls['confirmPassword'].hasError('required')">
                                        Please fill this field
                                    </small>
                                    <small [@inOutAnimation] class="form-text error"
                                       *ngIf="form.submitted && formGroup.controls['confirmPassword'].hasError('minlength')">
                                       Confirm password must have at least 7 characters
                                   </small>
                                    <small [@inOutAnimation] class="form-text error" *ngIf="errorMessage">
                                        {{errorMessage}}
                                    </small>
                                </div>
                                <div class="col-lg">
                                    <button class="common_btn mt-3 w-100" [disabled]="btnDisabled">Submit
                                        <mat-spinner class="btnLoading" [diameter]="20" *ngIf="btnDisabled">
                                        </mat-spinner>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</ng-container>
<ng-container *ngIf="page === 'change-password'">
    <div class="wrapper">
        <!-- Sidebar  -->
        <nav id="sidebar" class="sml_slide">

            <ul class="list-unstyled subscription_">
                <li class="active">
                    <a routerLink="/account/profile">
                        <p><img class="align-middle" src="assets/img/user.svg"> Personal info</p>
                    </a>
                </li>
                <li *ngIf="userData.userType!==userTypes.ADMIN && userData.userType!==userTypes.MASTER_ADMIN">
                    <a routerLink="/account/profile" [queryParams]="{tab: 'bookmarks'}">
                        <h6><img class="align-middle" src="assets/img/bookmark-white.svg"> Bookmarked classes</h6>
                    </a>
                </li>
                <li *ngIf="userData.userType === userTypes.INDIVIDUAL">
                    <a routerLink="/account/profile" [queryParams]="{tab: 'my-orders'}">
                        <h6><img class="align-middle" src="assets/img/bookmark-white.svg"> My Subscriptions</h6>
                    </a>
                </li>
            </ul>
        </nav>

        <!-- Page Content  -->
        <div id="content" class="content_wrp">
            <div class="container-fluid">
                <div class="row hidden_md">
                    <div class="col-md-12 text-right mob_top_align">
                        <button type="button" id="sidebarCollapse" class="btn btn-info hidden_md">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                                class="bi bi-text-left" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                    d="M2 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <section class="resource_home">
                <div class="container-fluid pdngLR_10 pt_15">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="white_wrpper">
                                <div class="row">
                                    <div class="col-lg-7">
                                        <h3><a routerLink="/account/profile"><svg xmlns="http://www.w3.org/2000/svg"
                                                    width="25" height="25" fill="#232323" class="bi bi-arrow-left"
                                                    viewBox="0 3 12 12">
                                                    <path fill-rule="evenodd"
                                                        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z">
                                                    </path>
                                                </svg></a> Change password</h3>
                                        <form [formGroup]="formGroup" #form="ngForm" (ngSubmit)="onFormSubmit(form)"
                                            autocomplete="off">
                                            <div class="form-group position-relative">
                                                <label>Old password</label>
                                                <input [type]="viewSettings.old_passwordTextType ? 'text' : 'password'"
                                                    name="old_password" id="password1" class="form-control" required
                                                    formControlName="old_password">
                                                <i *ngIf="!viewSettings.old_passwordTextType" toggle="#password2"
                                                    class="far fa-eye togglePassword"
                                                    (click)="toggleFieldTextType('old_password')"></i>
                                                <i *ngIf="viewSettings.old_passwordTextType" toggle="#password2"
                                                    class="far fa-eye-slash togglePassword"
                                                    (click)="toggleFieldTextType('old_password')"></i>
                                                <small [@inOutAnimation] class="form-text error"
                                                *ngIf="form.submitted && formGroup.controls['old_password'].hasError('required')">
                                                    Please fill this field
                                                </small>
                                            </div>
                                            <div class="form-group position-relative">
                                                <label>New password</label>
                                                <input [type]="viewSettings.new_passwordTextType ? 'text' : 'password'"
                                                    name="new_password" id="password2" class="form-control" required
                                                    formControlName="new_password">
                                                <i *ngIf="!viewSettings.new_passwordTextType" toggle="#password2"
                                                    class="far fa-eye togglePassword"
                                                    (click)="toggleFieldTextType('new_password')"></i>
                                                <i *ngIf="viewSettings.new_passwordTextType" toggle="#password2"
                                                    class="far fa-eye-slash togglePassword"
                                                    (click)="toggleFieldTextType('new_password')"></i>
                                                <ng-container
                                                    *ngIf="formGroup.controls['new_password'].invalid && form.submitted">
                                                    <small [@inOutAnimation] class="form-text error"
                                                        *ngIf="formGroup.controls['new_password'].hasError('required')">
                                                        Please fill this field
                                                    </small>
                                                    <small [@inOutAnimation] class="form-text error"
                                                        *ngIf="formGroup.controls['new_password'].hasError('minlength')">Password
                                                        should be minimum
                                                        {{formGroup.controls['new_password'].errors?.minlength.requiredLength}}
                                                        characters long.</small>
                                                </ng-container>
                                            </div>
                                            <div class="form-group position-relative">
                                                <label>Re-enter new password</label>
                                                <input
                                                    [type]="viewSettings.confirm_passwordTextType ? 'text' : 'password'"
                                                    name="confirm_password" id="password3" class="form-control" required
                                                    formControlName="confirm_password">
                                                <i *ngIf="!viewSettings.confirm_passwordTextType" toggle="#password2"
                                                    class="far fa-eye togglePassword"
                                                    (click)="toggleFieldTextType('confirm_password')"></i>
                                                <i *ngIf="viewSettings.confirm_passwordTextType" toggle="#password2"
                                                    class="far fa-eye-slash togglePassword"
                                                    (click)="toggleFieldTextType('confirm_password')"></i>
                                                <ng-container
                                                    *ngIf="formGroup.controls['confirm_password'].invalid && form.submitted">
                                                    <small [@inOutAnimation] class="form-text error"
                                                        *ngIf="formGroup.controls['confirm_password'].hasError('required')">
                                                        Please fill this field
                                                    </small>
                                                    <small [@inOutAnimation] class="form-text error"
                                                        *ngIf="formGroup.controls['confirm_password'].hasError('minlength')">Password
                                                        should be minimum
                                                        {{formGroup.controls['confirm_password'].errors?.minlength.requiredLength}}
                                                        characters long.</small>
                                                </ng-container>
                                                <small [@inOutAnimation] class="form-text error"
                                                    [innerHTML]="errorMessage"></small>
                                            </div>
                                            <div class="form-row">
                                                <div class="text-left col-lg-12 col-md-12  col-sm-12">
                                                    <!-- <a class="common_btn" href="javascript:void(0)"
                                                        (click)="onFormSubmit(form)">Change Password
                                                        <mat-spinner class="btnLoading" [diameter]="20" *ngIf="btnDisabled">
                                                        </mat-spinner>
                                                    </a> -->
                                                    <button class="common_btn m-0" type="submit"  [disabled]="btnDisabled"> Change Password
                                                        <mat-spinner class="btnLoading" [diameter]="20" *ngIf="btnDisabled">
                                                        </mat-spinner>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</ng-container>