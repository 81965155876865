import { NgModule } from '@angular/core';

import { AuthGuardService as AuthGuard } from './auth/auth-guard.service';

import { Routes, RouterModule } from '@angular/router';
import { NotfoundComponent } from './common/notfound/notfound.component';
import { ForgotPasswordComponent } from './components/account/forgot-password/forgot-password.component';
import { LoginComponent, LogoutComponent } from './components/account/login/login.component';
import { ResetPasswordComponent } from './components/account/reset-password/reset-password.component';
import { DefinitionComponent } from './components/definition/definition.component';
import { HomeComponent } from './components/home/home.component';
import { LayoutComponent } from './components/layout/layout.component';
import { ResourcesComponent } from './components/resources/resources.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { FaqComponent } from './components/faq/faq.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { UnauthorizedComponent } from './common/unauthorized/unauthorized.component';
import { VerifyAccountComponent } from './components/account/verify-account/verify-account.component';
import { SetPasswordComponent } from './components/account/set-password/set-password.component';
import { UserProfileComponent } from './components/account/user-profile/user-profile.component';
import { ProfileEditComponent } from './components/account/user-profile/profile-edit/profile-edit.component';
import { PaymentSuccessComponent } from './components/payment/payment-success/payment-success.component';
import { PaymentFailedComponent } from './components/payment/payment-failed/payment-failed.component';
import { InnerHomeComponent } from './components/home/inner-home/inner-home.component';
import { DataDeletionComponent } from './components/data-deletion/data-deletion.component';
import { WhatIsFinstreamComponent } from './components/static-pages/what-is-finstream/what-is-finstream.component';
import { StreamFxComponent } from './components/static-pages/stream-fx/stream-fx.component';
import { StreamFdComponent } from './components/static-pages/stream-fd/stream-fd.component';
import { StreamPmComponent } from './components/static-pages/stream-pm/stream-pm.component';
import { CertificationComponent } from './components/static-pages/certification/certification.component';
import { ForIndividualsComponent } from './components/static-pages/for-individuals/for-individuals.component';
import { ForEmployersComponent } from './components/static-pages/for-employers/for-employers.component';
import { TermsConditionsComponent } from './components/static-pages/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './components/static-pages/privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from './components/static-pages/cookie-policy/cookie-policy.component';
import { HomeNewComponent } from './components/home-new/home-new.component';
import { PartnersComponent } from './components/static-pages/partners/partners.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      // { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: '', component: HomeNewComponent, pathMatch: 'full' },
      // { path: '', component: WhatIsFinstreamComponent, pathMatch: 'full' },
      {
        path: 'account',
        children: [
          { path: 'login', component: LoginComponent, pathMatch: 'full' },
          { path: 'forgot-password', component: ForgotPasswordComponent, pathMatch: 'full' },
          { path: 'reset-password', component: ResetPasswordComponent, pathMatch: 'full' },
          { path: 'set-password', component: ResetPasswordComponent, pathMatch: 'full' },
          { path: 'verify', component: VerifyAccountComponent, pathMatch: 'full' },
          { path: 'invite', component: SetPasswordComponent, pathMatch: 'full' },
          { path: 'change-password', component: SetPasswordComponent, pathMatch: 'full', canActivate: [AuthGuard] },
          { path: 'profile', component: UserProfileComponent, pathMatch: 'full', canActivate: [AuthGuard] },
          { path: 'profile/edit/:id', component: ProfileEditComponent, pathMatch: 'full', canActivate: [AuthGuard] }
        ]
      },
      // { path: 'home', component: HomeComponent, pathMatch: 'full' },
      { path: 'home', component: HomeNewComponent, pathMatch: 'full' },
      // { path: 'home', component: WhatIsFinstreamComponent, pathMatch: 'full' },
      // { path: 'what-is-finstream', component: WhatIsFinstreamComponent, pathMatch: 'full' },
      // { path: 'for-individuals', component: ForIndividualsComponent, pathMatch: 'full' },
      // { path: 'platform', component: ForEmployersComponent, pathMatch: 'full' },
      { path: 'partners', component: PartnersComponent, pathMatch: 'full' },
      // { path: 'streams/foreign-exchange-international-finance', component: StreamFxComponent, pathMatch: 'full' },
      // { path: 'streams/financial-derivatives', component: StreamFdComponent, pathMatch: 'full' },
      // { path: 'streams/portfolio-management', component: StreamPmComponent, pathMatch: 'full' },
      // { path: 'certification', component: CertificationComponent, pathMatch: 'full' },
      // { path: 'individuals', component: InnerHomeComponent, pathMatch: 'full', data: { page: 'individual' } },
      // { path: 'course-providers', component: InnerHomeComponent, pathMatch: 'full', data: { page: 'university' } },
      // { path: 'corporate-learning', component: InnerHomeComponent, pathMatch: 'full', data: { page: 'corporate' } },
      { path: 'resources', component: ResourcesComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'resources/:slug', component: ResourcesComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'about', component: AboutUsComponent, pathMatch: 'full' },
      { path: 'contact', component: ContactUsComponent, pathMatch: 'full' },
      { path: 'faq', component: FaqComponent, pathMatch: 'full' },
      { path: 'privacy-policy', component: PrivacyPolicyComponent, pathMatch: 'full' },
      { path: 'cookie-policy', component: CookiePolicyComponent, pathMatch: 'full' },
      { path: 'data-deletion', component: DataDeletionComponent, pathMatch: 'full' },
      { path: 'payment/payment-success', component: PaymentSuccessComponent, pathMatch: 'full' },
      { path: 'payment/payment-failed', component: PaymentFailedComponent, pathMatch: 'full' },
      { path: 'terms-conditions', component: TermsConditionsComponent, pathMatch: 'full' },

      { path: 'logout', component: LogoutComponent, pathMatch: 'full' },
      { path: 'page-not-found', component: NotfoundComponent, pathMatch: 'full' },
      { path: '404', component: NotfoundComponent, pathMatch: 'full' },
      { path: '401', component: UnauthorizedComponent, pathMatch: 'full' },

      // dynamic path
      { path: 'resources/:resource/:chapter', component: DefinitionComponent, pathMatch: 'full', canActivate: [AuthGuard] }
    ]
  },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
