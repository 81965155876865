<div class="topsec h-auto">
    <div class="sub-banner banner4">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-7 col-xl-5">
                    <h2 class="under-line-sub">FinStream’s<br> derivatives<br> training courses</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<section>
    <div class="container py-4 py-lg-5">
        <div class="row justify-content-center px-4 px-lg-0">
            <div class="col-lg-10 py-2 py-lg-4">
                <p class="pb-4">
                    Our financial derivatives training courses focus on the key elements of pricing and the usage of
                    derivatives for risk management and speculation. With a range of interactive models, you’ll also
                    have the opportunity to price key products and explore how they are used. Our derivatives
                    training streams interface theory and practice with guided instructions to help prepare you for
                    a career in the finance industry.
                </p>
                <p class="pb-5">Read on to learn more about what you’ll uncover in our
                    training sessions.</p>
                <h2 class="under-line">Why should I study this<br> derivatives course?</h2>
                <p class="pt-1">
                    The FinStream Derivatives market training course will cover a number of derivatives products,
                    including options, futures, forwards, swaps, structured products, commodities and FX, as well as
                    practical usage by producer and consumers, and exporters and importers.
                </p>
            </div>
        </div>
    </div>
</section>
<section class="course-content pt-0">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10">
                <img src="assets/img/owl.svg" alt="">
                <h2 class="pt-2">Course Content</h2>
                <div class="d-flex flex-wrap">
                    <ul class="lists">
                        <li>Option Payoffs</li>
                        <li>Structured Products</li>
                        <li>Black Scholes Models</li>
                        <li>Hedging with Forwards</li>
                        <li>Option Price Sensitivities</li>
                        <li>Option Pricing: Binomial</li>
                        <li>Option Pricing: Monte Carlo</li>
                        <li>Hedging with Options</li>
                    </ul>
                    <ul class="lists">
                        <li>Interest Rates: Basics</li>
                        <li>Interest Rates: Futures and Bills</li>
                        <li>Interest Rate Swaps</li>
                        <li>Interest Rate Options</li>
                        <li>Interest Rate: Swaptions</li>
                        <li>FX Derivatives</li>
                        <li>And more</li>
                    </ul>
                </div>
                <div class="pt-0 pt-lg-3 pb-4 pb-lg-5">
                    <a class="primaryButton cursor-pointer" (click)="exploreApp()">Explore our library - and register for free!</a>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="midwrp">
    <div class="container">
        <div class="row p-4 p-sm-5 justify-content-center">
            <div class="col-lg-11">
                <h2 class="under-line">Who’s the derivatives course for?</h2>
                <p class="pt-2">The theoretical aspect of our derivatives training course resembles the standard
                    Masters and CFA derivative market content, but also includes additional online course content to
                    help bridge the transition to the financial sector. FinStream’s derivatives courses are best
                    suited for:</p>
                <div class="row justify-content-between">

                    <div class="card w-half p-0">
                        <div class="card__header dark-bg">
                            <h3>Professionals</h3>
                        </div>
                        <div class="card__body">
                            <ul class="lists pe-0">
                                <li><p>Institutional Traders</p></li>
                                <li><p>Investment Advisors</p></li>
                                <li><p>Risk Management Professionals</p></li>
                                <li><p>Derivative Structuring Corporate Officers</p></li>
                                <li><p>Corporate Finance Specialists/Analysts</p></li>
                                <li><p>Portfolio Managers</p></li>
                            </ul>
                        </div>
                    </div>
                    <div class="card w-half p-0">
                        <div class="card__header dark-bg">
                            <h3>Students</h3>
                        </div>
                        <div class="card__body">
                            <ul class="lists pe-0">
                                <li><p>Masters of Finance Students</p></li>
                                <li><p>CFA Students</p></li>
                                <li><p>Students looking to enhance their financial knowledge</p></li>
                                <li><p>Students that need help passing a finance course</p></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="btm-wrp pb-4 pb-lg-5">
    <div class="container">
        <div class="row p-4 justify-content-center">
            <div class="col-lg-11">
                <div class="icon-wrp">
                    <img src="assets/img/icon9.svg" alt="">
                    <h2 class="pt-3">Certification</h2>
                    <p clas="mt-2"> Increase your prospects in the financial job market with a FinStream certification, that showcases your new-found theoretical proficiency in financial derivatives.</p>
                </div>
                <div class="go-link mt-4">
                    <a class="primaryButton" routerLink="/certification">Learn more about FinStream’s certifications</a>
                </div>
            </div>
        </div>
    </div>
</div>
<div>
    <div class="other-streams">
      <div class="container">
         <div class="row align-items-center">
             <div class="col-lg-6">
               <img class="w-100" src="assets/img/play-img.png" alt="">
             </div>
             <div class="col-lg-6 ps-lg-5">
                <h2>Interested in learning more about financial topics?</h2>
                <h2>Explore our other streams.</h2>  
                <p class="pt-4 pb-3"><a routerLink="/streams/foreign-exchange-international-finance">Foreign Exchange and International Finance <img src="assets/img/right.svg" alt=""></a> </p>
                <p><a routerLink="/streams/portfolio-management">Portfolio Management <img src="assets/img/right.svg" alt=""></a> </p>
            </div>
         </div>
      </div>
    </div>
</div>
<div class="getin-touch">
    <div class="container-fluid">
        <div class="row p-4">
            <div class="col-lg-5">
                <h5>Start your financial derivative education with Finstream today.</h5>
                <a class="secondary-btn" routerLink="/contact">Contact Us</a>
            </div>
        </div>
    </div>
</div>