import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-certificate-video',
  templateUrl: './certificate-video.component.html',
  styleUrls: ['./certificate-video.component.scss']
})
export class CertificateVideoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
