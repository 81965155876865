import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.scss']
})
export class VerifyAccountComponent implements OnInit {
  message = '';
  isLoading = false;
  isVerificationError = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _authService: AuthService,
    @Inject(PLATFORM_ID) private platform: object
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    if (isPlatformBrowser(this.platform)) {
      const key = this.route.snapshot.queryParamMap.get('key') || '';
      this._authService.verifyAccount(key).subscribe((res: any) => {
        this.message = res.message;
        this.isLoading = false;
      }, (err) => {
        console.log('verify_error', err);
        this.message = err.error.message;
        this.isVerificationError = true;
        this.isLoading = false;
      });
    }
  }
}
