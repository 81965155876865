import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../services/shared.service';
import { AuthService } from '../../../services/auth.service';
import { UserService } from '../../../services/user.service';
import { UserDetails } from '../../../model/user.model';
import { DialogModel } from '../../../model/dialog.model';
import { SuccessDialogComponent } from '../../../common/success-dialog/success-dialog.component';
import { Constants } from '../../../utilities/constants';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  userData = new UserDetails();
  activeTab = 'personal-info';
  userTypes = Constants.userTypes;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private authService: AuthService,
    private userService: UserService,
    private dialog: MatDialog) {
    route.queryParams.subscribe(params => {
      if (params.tab) {
        this.activeTab = params.tab;
      }
    });
  }

  ngOnInit(): void {
    const sub = this.sharedService.user.subscribe((res: any) => {
      this.userData = res;
      if (this.userData._id) {
        this.fetchUserDetails(this.userData._id);
      }
    });
    this.subscription.add(sub);
  }
  fetchUserDetails(id: string): void {
    this.userService.getDetails(id).subscribe((res: any) => {
      this.userData = res.body.data;
    });
  }
  edit(): void {
    this.router.navigate(['/account/profile/edit', this.userData._id]);
  }
  onAccountClose(): void {
    this.userService.closeAccount(this.userData._id).subscribe((res: any) => {
      this.showMessage('Uploaded !', res.message);
      this.router.navigate(['/logout']);
    }, (err) => {
      console.log('delete account error is', err);
    });
  }
  showMessage(heading: string, message: string): void {
    const dialogData = new DialogModel(heading, message);
    this.dialog.open(SuccessDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });
  }
  handleQueryParams() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        tab: this.activeTab
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
