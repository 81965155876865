<button id="btnDismissModal" type="button" class="close" data-dismiss="modal" style="display: none;">&times;</button>
<div [id]="viewName" class="group_list" [ngClass]="{'d_none':viewName==='create-group'}">
    <ng-container *ngIf="!this.groupModel._id || (this.groupModel._id && this.groupModel.createdBy===authUser._id)">
        <h4 id="lnkGroupBack" class="back_to_list"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                fill="#362A86" class="bi bi-arrow-left" viewBox="0 0 16 16" *ngIf="viewName==='create-group'">
                <path fill-rule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z">
                </path>
            </svg> <span *ngIf="!groupModel._id">Create Group</span>
            <span *ngIf="groupModel._id">Update Group</span>
            <span class="members-selection-count" *ngIf="groupModel.members.length===1">(1 member + including you)</span>
            <span class="members-selection-count" *ngIf="groupModel.members.length!==1">({{groupModel.members.length}} members + including you)</span>
        </h4>
        <input type="text" class="form-control group_list_inpt1" placeholder="Enter Group Name"
            [(ngModel)]="groupModel.groupName">
        <div class="form_inline">
          <div class="position-relative w-100 mr-2">
            <input type="text" class="form-control search_inpt no_icon" placeholder="Invite members" [formControl]="keywords">
            <button class="btn search_inpt" (click)="memberSearch()"></button>
          </div>

            <button [disabled]="btnDisabled" (click)="onSubmitGroup()">
                <span *ngIf="!this.groupModel._id">Create</span>
                <span *ngIf="this.groupModel._id">Update</span>
            </button>
        </div>
    </ng-container>
    <div class="group-info" *ngIf="this.groupModel._id && this.groupModel.createdBy!==authUser._id">
        <label>{{groupMessage.groupName}} <small>({{groupMessage.members.length}} members)</small></label>
        <span>Created By: <strong [innerHTML]="groupMessage.members | chatUserName: groupMessage.createdBy : authUser._id"></strong></span>
        <small> at: {{groupMessage.createdAt | localizeTime}}</small>
    </div>

    <ul class="item_wrp" [style.max-height]="viewName==='create-group' ? '40vh': '60vh'" (scroll)="onScroll($event)">
        <ng-container *ngIf="authUser.userType===userTypes.TUTOR">
            <li class="border-radius-0 pdng_1 border_bottom_1">
                <a href="javascript:void(0)" class="grp_list align-items-center">
                    <span class="member_icon" *ngIf="authUser.profilePicture && authUser.profilePicture.thumbPath">
                        <img [src]="authUser.profilePicture.thumbPath">
                    </span>
                    <span class="group_icon text-capitalize" [style.background-color]="authUser.firstName | randomColorPick "
                        *ngIf="!authUser.profilePicture?.thumbPath">{{
                            authUser.firstName | slice:0:1}}</span>
                    <div class="details_">
                        <h6>{{authUser.firstName}} {{authUser.lastName}}
                            <label *ngIf="authUser._id===authUser._id">(You)</label>
                        </h6>
                    </div>
                    <label class="container_list">
                        <input type="checkbox" checked>
                        <span class="checkmark"></span>
                    </label>
                </a>
            </li>
        </ng-container>
        <ng-container *ngFor="let item of ((!this.groupModel._id || (this.groupModel._id && this.groupModel.createdBy===authUser._id)) ? allMembers : groupMessage.members)">
            <li class="border-radius-0 pdng_1 border_bottom_1" *ngIf="item | chatOnlyConnectedUsers:this.groupModel.createdBy:authUser._id">
                <a href="javascript:void(0)" class="grp_list align-items-center">
                    <span class="member_icon" *ngIf="item.profilePicture?.thumbPath">
                        <img [src]="item.profilePicture.thumbPath">
                    </span>
                    <span class="group_icon text-capitalize" [style.background-color]="item.firstName | randomColorPick "
                        *ngIf="!item.profilePicture?.thumbPath">{{
                        item.firstName | slice:0:1}}</span>
                    <div class="details_">
                        <h6>{{item.firstName}} {{item.lastName}}
                            <label *ngIf="item._id===authUser._id">(You)</label>
                            <label *ngIf="item._id!==authUser._id && item.userType==='Tutor'">(Tutor)</label>
                        </h6>
                    </div>
                    <label class="container_list" *ngIf="!this.groupModel._id || (this.groupModel._id && this.groupModel.createdBy===authUser._id)">
                        <input type="checkbox" [(ngModel)]="newGroupCheckedIds[item._id]"
                            (ngModelChange)="onCheckedMember(newGroupCheckedIds[item._id],item._id)" [disabled]="item._id===authUser._id">
                        <span class="checkmark"></span>
                    </label>
                </a>
            </li>
        </ng-container>
    </ul>
</div>
