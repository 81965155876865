import { Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { ContactUsService } from '../../services/contact-us.service';
import { ContactUs } from '../../model/contact.model';
import { Animation } from '../../utilities/animation';
import { MatDialog } from '@angular/material/dialog';
import { SuccessDialogComponent } from '../../common/success-dialog/success-dialog.component';
import { DialogModel } from '../../model/dialog.model';
import { environment } from '../../../environments/environment';
import { ReCaptcha2Component } from 'ngx-captcha';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
  animations: [Animation.inOutAnimation()]
})
export class ContactUsComponent implements OnInit {
  @ViewChild('captchaElem', { static: false }) captchaElem!: ReCaptcha2Component;
  formGroup = new FormGroup({});
  btnDisabled = false;
  errorMessage = '';
  siteKeyData = environment.GOOGLE_CAPTCHA_SITE_KEY;
  isPlatformBrowser = isPlatformBrowser(this.platform);
  constructor(
    private contactUsService: ContactUsService,
    private dialog: MatDialog,
    @Inject(PLATFORM_ID) private platform: object
  ) { }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
      ])),
      name: new FormControl('', Validators.compose([
        Validators.required
      ])),
      phone: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('[0-9+]+')
      ])),
      message: new FormControl('', Validators.compose([
        Validators.required
      ])),
      reCaptcha: new FormControl('', Validators.compose([
        Validators.required
      ]))
    });
  }
  submitContact(formDirective: FormGroupDirective): void {
    const contactData: ContactUs = this.formGroup.value;
    if (this.formGroup.valid) {
      this.btnDisabled = true;
      this.contactUsService.contactUsCreate(contactData).subscribe((res: any) => {
        this.captchaElem.resetCaptcha();
        formDirective.resetForm();
        this.formGroup.reset();
        this.showMessage(res.message);
        this.btnDisabled = false;
      }, (err) => {
        console.log('error: ', err);
        this.btnDisabled = false;
        this.errorMessage = err.error.message;
      });
    }
  }
  showMessage(message: string): void {
    const dialogData = new DialogModel('Contact submitted', message);
    this.dialog.open(SuccessDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });
  }
}
