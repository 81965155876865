import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContactUs } from '../model/contact.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {
  constructor(public http: HttpClient) { }
  contactUsCreate(data: ContactUs) {
    return this.http.post<any>(environment.API_END_POINT + '/contact-us', data);
  }
}
