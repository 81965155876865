import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { SuccessDialogComponent } from '../../../common/success-dialog/success-dialog.component';
import { MessageAlert } from '../../../utilities/message.alert';
import { SubscriptionService } from '../../../services/subscription.service';
import { DialogModel } from '../../../model/dialog.model';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {
  orderId = '';
  isLoading = true;
  isPaymentCompleted = false;
  httpError: HttpErrorResponse | undefined;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private alert: MessageAlert,
    private _subscriptionService: SubscriptionService,
    @Inject(PLATFORM_ID) private platform: object
  ) { }

  ngOnInit(): void {
    // we need client side only
    if (isPlatformBrowser(this.platform)) {
      const sessionId = this.route.snapshot.queryParams.session_id;
      if (!sessionId) {
        this.router.navigate(['/404']);
      }
      this._subscriptionService.verifyPayment(sessionId).subscribe((res) => {
        this.isLoading = false;
        this.isPaymentCompleted = true;
      }, (err: HttpErrorResponse) => {
        this.isLoading = false;
        this.httpError = err;
      });
    }
  }
  showSuccessMessage() {
    const dialogData = new DialogModel('Payment Success', 'Success fully completed your payment');
    const dialogRef = this.dialog.open(SuccessDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      const result = dialogResult;
      if (result === true) {
        this.router.navigate(['/account/profile'], { queryParams: { tab: 'my-orders' } });
      }
    });
  }
}
