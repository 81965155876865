// tags
export class Tag {
    _id = '';
    title = '';
}
export class CustomTag {
    value = '';
    display = '';
}
export class TagSuccessData {
    message = '';
    status = '';
    data: Tag[] = [];
}
export class CustomTagSuccessData {
    message = '';
    status = '';
    data: CustomTag[] = [];
}
export interface TagErrorData {
    message: string;
    status: string;
    data: any;
}
