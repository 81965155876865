<ng-container *ngIf="messageType===messageTypes.STUDENT || messageType===messageTypes.INDIVIDUAL || messageType===messageTypes.TUTOR">
    <ng-container *ngIf="user.blockedData && user.blockedData._id">
        <div class="unblock-conv">
            <label>You blocked this conversation. </label>
        </div>
    </ng-container>
</ng-container>
<span class="vertical_menu">
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
        class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
        <path
            d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z">
        </path>
    </svg>
</span>
<div class="vertical_toggle" style="display: none;">
    <ng-container *ngIf="messageType===messageTypes.STUDENT || messageType===messageTypes.INDIVIDUAL || messageType===messageTypes.TUTOR">
        <span (click)="blockUser()" *ngIf="!user.blockedData">Block user</span>
        <span (click)="unBlockUser()" *ngIf="user.blockedData && user.blockedData._id">Unblock user</span>
    </ng-container>
    <ng-container *ngIf="messageType===messageTypes.GROUP && authUser._id !== groupMessage.createdBy">
        <span (click)="exitFromGroup()">Exit group</span>
    </ng-container>
    <ng-container *ngIf="messageType===messageTypes.GROUP && authUser._id === groupMessage.createdBy">
        <span (click)="deleteGroup()">Delete Group</span>
    </ng-container>
</div>
