import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable()
export class SeoService {

    constructor(
        private titleService: Title,
        private metaService: Meta,
    ) { }

    setTitle(title:string) {
        this.titleService.setTitle('eFin - ' + title);
    }
    updateTag(key: string, value: string) {
        this.metaService.updateTag({ name: key, content: value });
    }
}
