<section class="about_sec">
    <div class="container-fluid w_80">
      <div class="row">
        <div class="col-lg-12">
          <h3>Cookies</h3>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="container-fluid w_80 mb-5">
      <div class="row">
        <div class="col-lg-12">
          <div class="wrapper_white p-7">
            <h1>Our Cookie policy</h1>
            <div class="common-content mt-4 policies">
              <p>We use cookies and similar tracking technologies to track the activity on our Service and we hold
                certain information. If you visit our website to browse or download information, our web server will
                record the date and time of your visit to our site, the pages viewed and the information downloaded.
                We generally use this information for statistical purposes</p>
              <p>Cookies are files with a small amount of data which may include an anonymous unique identifier.
                Cookies are sent to your browser from a website and stored on your device. Other tracking technologies
                are also used such as beacons, tags and scripts to collect and track information and to improve and
                analyse our Service.</p>
              <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
                However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
                <p>Examples of Cookies we use:</p>
              <ol class="pl-start">
                <li> <p>Session Cookies: We use Session Cookies to operate our Service.</p> </li>
                <li><p>Preference Cookies: We use Preference Cookies to remember your preferences and various settings.</p>
                </li>
                <li><p>Security Cookies: We use Security Cookies for security purposes.</p></li>
                <li><p>Advertising Cookies: Advertising Cookies are used to serve you with advertisements that may be
                  relevant to you and your interests.</p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>