<div class="topsec h-auto">
    <div class="sub-banner banner2">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-7 col-xl-6">
                    <h2 class="f-40">Build your credentials with FinStream</h2>
                    <p>Challenge yourself to earn a unique and sought-after certification
                        recognised throughout the investment banking industry.</p>
                    <a class="btn-brdr" data-toggle="modal" data-target="#becomeCertified" href="javascript:void(0)">Become FinStream Certified</a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="midwrp">
    <div class="container">
        <div class="row p-4 p-sm-5 justify-content-center">
            <div class="col-lg-11">
                <h2 class="under-line">Why become a registered <br>FinStream Certified user?</h2>
                <p class="pt-2">A FinStream certification in finance will help you excel professionally. You’ll
                    build the knowledge and confidence needed to gain an edge in your industry. Whether you’re
                    looking to upskill your current position or add on to your existing finance education, a
                    FinStream certification helps open doors to new opportunities and secure a job that best suits
                    your aptitude and interests.</p>
                <p class="mt-4"> Certifications help students and professionals develop theoretical
                    and practical knowledge in addition to building a broader understanding of your field. FinStream
                    courses show that you have obtained the knowledge required to showcase your new skills in the
                    organisation.</p>
                <app-certificate-video></app-certificate-video>
            </div>
        </div>
    </div>
</div>
<div class="btm-wrp pb-4 pb-lg-5">
    <div class="certificate-wrp">
        <app-certificate-courses></app-certificate-courses>
        <div class="row p-4 p-sm-5 pt-0 justify-content-center">
            <div class="col-lg-12">
                <h2 class="under-line">How to build your credentials</h2>
                <p>To become a registered FinStream Certified user, you’ll need to enrol in any one of our
                    high-quality financial courses. Choose a course that is relevant to your current scope of work,
                    a topic you need more in-depth knowledge about or a concept that would help you transition into
                    a desired role. </p>
                <p class="mt-4">Once you’re enrolled in your FinStream financial course, learning materials and
                    interactive exams will help you obtain highly sought skills to advance your career. While
                    FinStream exams are in-depth and challenging, our online programs teach you everything you need
                    to know to succeed and unlock your certification.</p>

                <div class="icon-wrp">
                    <img src="assets/img/icon7.svg" alt="">
                    <h5>Work at your own pace.</h5>
                    <p clas="mt-2"> Our flexible course options allow you to defer the
                        certification and come back to complete it when the timing is right for you. Study and work
                        at a pace that fits your schedule.</p>
                </div>
                <div class="icon-wrp">
                    <img src="assets/img/icon13.svg" alt="">
                    <h5>Enhance your resume.</h5>
                    <p clas="mt-2"> Upgrade your resume with our course certification, which is
                        recognised in investment banking. Future employers will see the course completion as an
                        advancement in your own financial education.</p>
                </div>
                <div class="icon-wrp">
                    <img src="assets/img/icon14.svg" alt="">
                    <h5> Make yourself marketable.</h5>
                    <p clas="mt-2">Impress employers by demonstrating your practical and
                        theoretical proficiency in a particular topic, including:
                    </p>
                    <ul class="derivatives-items">
                        <li><a routerLink="/">Investment portfolio management.</a></li>
                        <li><a routerLink="/">Foreign exchange and international finance.</a></li>
                        <li><a routerLink="/">Financial derivatives.</a></li>
                    </ul>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- <div class="getin-touch">
    <div class="container-fluid">
        <div class="row p-4">
            <div class="col-lg-5">
                <h5>Start your financial derivative education with FinStream today.</h5>
                <a class="secondary-btn"routerLink="/contact">Contact Us</a>
            </div>
        </div>
    </div>
</div> -->
<div class="modal fade" id="becomeCertified" tabindex="-1" aria-labelledby="becomeCertifiedLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0">
            <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
          </div>
        <div class="modal-body p-5 pt-0 text-center">
            <img class="pb-4" src="assets/img/icon9.svg" alt="">
         <p>Several learnings streams are now offering exams with automatic certificate offerings. Others will be rolled out during 2023.</p>
         <p>Any current or past course stream users will be able to access and complete a new certification capability free of charge.</p>
        </div>
      </div>
    </div>
</div>