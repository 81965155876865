import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class FileUploadService {
    constructor(public http: HttpClient) { }

    create(data: FormData): Observable<any> {
        return this.http.post<any>(environment.API_END_POINT + '/file-upload/image', data, {
            observe: 'events' as 'body',
            reportProgress: true
        });
    }
    createMultiple(data: FormData): Observable<any> {
        return this.http.post<any>(environment.API_END_POINT + '/file-upload/multiple-files', data, {
            observe: 'events' as 'body',
            reportProgress: true
        });
    }
    uploadFiles(data: FormData): Observable<any> {
        return this.http.post<any>(environment.API_END_POINT + '/file-upload/images', data, {
            observe: 'events' as 'body',
            reportProgress: true
        });
    }
}
