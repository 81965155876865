<div class="topsec h-auto">
    <div class="sub-banner banner1">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-7 col-xl-5">
                    <p class="under-line-sub">Finstream offers crucial finance content for users in post-graduate studies or who are transitioning to the Investment Banking Industry
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<section>
    <div class="container py-4 py-lg-5">
        <div class="row justify-content-center px-4 px-lg-0">
            <div class="col-lg-10 py-2 py-lg-4">
                <p class="">
                    Our online learning streams serve as additional resources to help you obtain and broaden your degree. We offer more advanced bridging material that will make you stand out.  
                </p>
                <p class="pt-3">
                    Alternatively if you’re already working in the finance industry, our educational streams will let you quickly and continually expand your understanding of key foundational concepts, enabling faster learning which offers greater career flexibility.
                </p>
                <p class="pt-3 pb-5">
                    Thus our finance library BRIDGES the theoretical topics at MBA, CFA and Master’s levels but also provides additional practical industry-related topics within the finance sector.
                </p>
                <h2 class="">Our innovative approach, content and platform:</h2>
         
                <ul class="stream-lists mt-3">
                    <li>Focuses on the key concepts finance that will provide career flexibility</li>
                    <li>Integrates theory and examples via 'Excel' like embedded tools in the one place</li>
                    <li>Solves key conceptual problems with these tools that re-enforce the theory</li>
                    <li>Provides material for those who wish to access quality Masters level theory</li>
                    <li>Provides extra bridging material and long term content access</li>
                    <li>helps one to be more proficient in client facing roles. AI just cannot do that</li>
                </ul>
            </div>
        </div>
    </div>
</section>
<section class="certificate-wrp pt-0 pb-0">
    <div class="row p-4 p-sm-5 pt-0 pb-0 justify-content-center" style="padding-top: 0 !important; padding-bottom: 0 !important;">
        <div class="col-lg-12">
            <h2 class="under-line pb-0">The learning streams we currently offer include:</h2>
        </div>
    </div>
    <app-certificate-courses></app-certificate-courses>
</section>
<section>
    <div class="container pb-4">
        <div class="row justify-content-center pb-5">    
            <div class="">
                <a class="primaryButton cursor-pointer" (click)="exploreApp()">Explore our library - and register for free!</a>
            </div>
        </div>
    </div>
</section>


<div class="getin-touch">
    <div class="container-fluid">
        <div class="row p-4">
            <div class="col-lg-5">
                <h5 class="under-line">FinStream is here to bring enhanced Financial education to the finance industry.</h5>
                <p>Contact our team today to learn more about our practice-based financial education platform.</p>
                <a class="secondary-btn" routerLink="/contact">Contact Us</a>
            </div>
        </div>
    </div>
</div>