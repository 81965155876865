import { trigger, state, style, transition, animate } from '@angular/animations';

export const Animation = {
    inOutAnimation() {
        return trigger(
            'inOutAnimation',
            [
                transition(
                    ':enter',
                    [
                        style({ height: 0, opacity: 0 }),
                        animate('0.1s ease-out',
                            style({ height: 10, opacity: 1 }))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({ height: 10, opacity: 1 }),
                        animate('0.1s ease-in',
                            style({ height: 0, opacity: 0 }))
                    ]
                )
            ]
        );
    },
}

