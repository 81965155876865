
export class UserChatPreference {
    _id = '';
    type = '';
    status = '';
    fromUser = '';
    fromUserData = {
        _id: '',
        firstName: '',
        lastName: ''
    };
    toUser = '';
    users: string[] = [];
    createdAt = '';
    updatedAt = '';
};
export class User {
    _id = '';
    firstName = '';
    lastName = '';
    email = '';
    phone = '';
    // password = '';
    userType = '';
    // organization = '';
    // courseProvider = '';
    // isVerified = 0;
    // status = 0;
    // resources = [];
    profilePicture = {
        thumbPath: '',
        path: ''
    };
    // invitationKey = {};
    // forgotPasswordKey = {};
    // accountVerificationKey = {};
    invitationData = new UserChatPreference();
    blockedData = new UserChatPreference();
};
export class UserDetails {
    _id = '';
    firstName = '';
    lastName = '';
    email = '';
    phone = '';
    userType = '';
    organization = '';
    courseProvider = '';
    isVerified = 0;
    status = 0;
    resources = [];
    address = '';
    street = '';
    city = '';
    state = '';
    postcode = '';
    country = '';
    profilePicture = {
        filename: '',
        folder: '',
        originalName: '',
        path: '',
        thumbFilename: '',
        thumbFolder: '',
        thumbPath: ''
    };
};
export class AuthUser {
    _id = '';
    firstName = '';
    lastName = '';
    email = '';
    userType = '';
    organizationId = '';
    profilePicture: any;
}
export class UserSuccessData {
    message = '';
    status = '';
    data: User[] = [];
}
export interface UserErrorData {
    message: string;
    status: string;
    data: any;
};
