<a href="javascript:void(0)" class="grp_list" (click)="startNewConversation(user);">
    <span class="member_icon" *ngIf="user.profilePicture.thumbPath">
        <img [src]="user.profilePicture.thumbPath">
    </span>
    <span class="group_icon text-capitalize" [style.background-color]="user.firstName | randomColorPick " *ngIf="!user.profilePicture.thumbPath">{{
        user.firstName | slice:0:1}}</span>
    <div class="details_">
        <h6>{{user.firstName}} {{user.lastName}}</h6>
        <ng-container *ngIf="!user.blockedData">
            <div class="inv-container">
                <ng-container *ngIf="!user.invitationData">
                    <button class="btn btn-primary" (click)="sendInvitation(user)" [disabled]="btnDisabled">
                        Send Message Request
                        <i class="fa fa-user-plus"></i>
                    </button>
                </ng-container>
                <ng-container *ngIf="user.invitationData && user.invitationData.fromUser === authUser._id && user.invitationData.status==='Pending'">
                    <span class="inv-title">Invitation send at <span class="date">{{user.invitationData.updatedAt | localizeTime}}</span></span>
                    <button class="btn btn-primary" (click)="sendInvitation(user)" [disabled]="btnDisabled">
                    Resend <i class="fa fa-user-plus"></i>
                    </button>
                </ng-container>
                <ng-container *ngIf="user.invitationData && user.invitationData.toUser === authUser._id && user.invitationData.status==='Pending'">
                    <span class="inv-title">Invited to chat at <span class="date">{{user.invitationData.updatedAt | localizeTime}}</span></span>
                    <div class="button-container">
                        <button class="btn btn-success" [disabled]="btnDisabled" (click)="respondInvitation('Accepted')">Accept</button>
                        <button class="btn btn-danger" [disabled]="btnDisabled" (click)="respondInvitation('Rejected')">Reject</button>
                    </div>
                </ng-container>
            </div>
            <ng-container *ngIf="user.invitationData && user.invitationData.status==='Accepted'">
                <small><i>Say Hi to start conversation...</i></small>
            </ng-container>
        </ng-container>
        <!-- <ng-container *ngIf="user.blockedData && user.blockedData._id">
            <div class="unblock-conv">
                <label>You blocked this conversation. </label>
            </div>
        </ng-container> -->
    </div>
</a>
<app-inbox-actions [messageType]="messageTypes.INDIVIDUAL" [user]="user" [authUser]="authUser" (refreshList)="refreshList.emit(true)"></app-inbox-actions>
