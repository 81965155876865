<div class="">
    <div class="">
      <div class="">
        <div class="box_ bx_shadow_ bg-white">
          <div class="row mb-4 align-items-center">
            <div class="col-lg-6 col-md-6">
              <h3>My Bookmarks</h3>
              <span class="sml_ sub_sml"><span class="itemShow" *ngIf="listItems.length>0">Showing {{(limit*(page-1))+1}} -
                {{(limit*(page-1))+listItems.length}} of {{totalCount}} items</span></span>
            </div>
          </div>
          <!-- <div class="row mt-5">
            <div class="col-lg-12">
              <div class="inpt_wrp">
                <div class="col-lg-5 pl-0 col_6 mb-4 col-md-12 pr_sml_0 flex_sml_0 position-relative">
                  <input type="text" class="form-control flx_item" placeholder="Search" [formControl]="keywords">
                    <button class="search_btn" (click)="page=1;filterList()"><img src="assets/img/search.svg"></button>
                 </div>
              </div>
            </div>
          </div> -->
          <div class="row">
            <div class="col-lg-12">
              <div class="table_responsive">
                <table class="table srdr_dtls sortable-table custom_tbl">
                  <thead>
                    <tr>
                      <th (click)="onSortBy('sectionId')">Section</th>
                      <th (click)="onSortBy('chapterId')">Chapter</th>
                      <th (click)="onSortBy('resourceId')">Resource</th>
                      <th>Actions</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of listItems | paginate: { itemsPerPage: this.limit, currentPage: page,totalItems: totalCount } ; let i = index;">
                      <td class="pt-4">{{item.sectionTitle}}</td>
                      <td class="pt-4">{{item.chapterTitle}}</td>
                      <td class="pt-4">{{item.sectionTitle}}</td>
                      <td class="pt-4">
                        <a 
                        [routerLink]="'/resources/'+item.resourceSlug+'/'+item.chapterSlug"
                        [queryParams]="{'tab':'tab-theory','section':item.sectionSlug}">View</a>
                      </td>
                      <td class="pt-4">
                        <a href="javascript:void(0)" (click)="removeBookmark(item._id)">Remove</a>
                      </td>
                    </tr>
                  </tbody>
                  <tbody *ngIf="isLoading">
                      <tr *ngFor="let row of ' '.repeat(3).split('')">
                        <td><mat-progress-bar mode="buffer"></mat-progress-bar></td>
                        <td><mat-progress-bar mode="buffer"></mat-progress-bar></td>
                        <td><mat-progress-bar mode="buffer"></mat-progress-bar></td>
                        <td><mat-progress-bar mode="buffer"></mat-progress-bar></td>
                        <td><mat-progress-bar mode="buffer"></mat-progress-bar></td>
                      </tr>
                  </tbody>
                </table>
                <app-no-records-found [noResult]="noResult" [httpError]="httpError"></app-no-records-found>
              </div>
              <div class="pagination-container" *ngIf="!noResult">
                <pagination-controls (pageChange)="page = $event;pageChanged($event)" previousLabel="" nextLabel="">
                </pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
