/* eslint-disable no-eval */
import { Component, Input, OnInit, OnChanges, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Matrix, inverse } from 'ml-matrix';
import { Subscription } from 'rxjs';
import { SuccessDialogComponent } from '../../success-dialog/success-dialog.component';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { DialogModel } from '../../../model/dialog.model';
import { InteractiveExamination, SectionList } from '../../../model/section.model';
import { SharedService } from '../../../services/shared.service';
import { MessageAlert } from '../../../utilities/message.alert';
import { Utility as Helper } from '../../../utilities/utility';
import { InteractiveLib } from '../../../utilities/interactive-lib';
import { Constants } from '../../../utilities/constants';
import { ExamService } from '../../../services/exam.service';
import { CreateExamModel, StopExamModel } from '../../../model/exam.model';
import { Chapter } from '../../../model/chapter.model';
import { Resource } from '../../../model/resource.model';
import { AuthUser } from '../../../model/user.model';

@Component({
  selector: 'app-interactive-content',
  templateUrl: './interactive-content.component.html',
  styleUrls: ['./interactive-content.component.scss']
})
export class InteractiveContentComponent implements OnInit, OnChanges, OnDestroy {
  @Input() resource = new Resource();
  @Input() chapter = new Chapter();
  @Input() section = new SectionList();
  @Input() jsonData = '';
  sections: any = [];
  isValidJson = true;
  isRendering = false;
  httpError: any;
  hasChangeDetected = moment().valueOf().toString();
  defaultColWidth = Constants.defaultColWidth;
  infiniteLoopCell: any = {};
  cellsQueue: any = {};
  hasRenderChart = false;
  exam = new InteractiveExamination();
  examInProgress = new InteractiveExamination();
  examOptionsChecked: any = {};
  examBtnDisabled = false;
  examCanRetry = true;
  examResultCheck: any = [];
  examEnabledByOrganization = false;
  // examActiveQuestion = {
  //   id: '',
  //   index: 0
  // };
  authUser = new AuthUser();
  userTypes = Constants.userTypes;
  subscriptions = new Subscription();
  streamDurationStartTime = moment();
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private alert: MessageAlert,
    private dialog: MatDialog,
    private _examService: ExamService,
    private _sharedService: SharedService) {
  }

  ngOnInit(): void {
  }
  ngOnChanges(): void {
    this.authUser = this._sharedService.authUser();
    const sub = this.route.queryParams.subscribe(params => {
      if (params.section) {
        this.exam.section = params.section;
      }
    });
    const sub1 = this.route.params.subscribe((params) => {
      this.exam.resource = params.resource;
      this.exam.chapter = params.chapter;
    });
    const sub2 = this._sharedService.exam.subscribe((data: any) => {
      this.examInProgress = data;
      if (data) {
        // if (data.answers) {
        //   Object.keys(data.answers).forEach(key => {
        //     this.examOptionsChecked[key] = data.answers[key].answerId;
        //   });
        //   this.exam.isStarted = data.isStarted;
        //   this.exam.totalAttended = data.totalAttended;
        //   this.exam.totalQuestions = data.totalQuestions;
        // }
        // if (data.activeQuestion) {
        //   this.exam.activeQuestion = data.activeQuestion;
        // }
        // this.exam.marks = data.marks;
        // this.exam.isCompleted = data.isCompleted;
        // this.exam.id = data.id;
        const temp: any = this.exam;
        Object.keys(data).forEach(key => {
          if (key !== 'resource' && key !== 'chapter' && key !== 'section') {
            temp[key] = data[key];
          }
          if (key === 'answers') {
            Object.keys(data.answers).forEach(key1 => {
              this.examOptionsChecked[key1] = data.answers[key1].answerId;
            });
          }
        });
        this.exam = temp;
      }
    });
    this.subscriptions.add(sub);
    this.subscriptions.add(sub1);
    this.subscriptions.add(sub2);
    this.hasRenderChart = false;
    this.isRendering = true;
    this.questionnaireExamRetry();
    // check partner is offering certificate. else disable examination
    if (this.authUser.userType === this.userTypes.STUDENT) {
      const isOfferingCertificate = this.resource.certificateProviderAccess?.find(x => x.organizationId === this.authUser.organizationId);
      if (isOfferingCertificate && isOfferingCertificate.enabled) {
        this.examEnabledByOrganization = true;
      } else {
        this.examEnabledByOrganization = false;
      }
    } else {
      this.examEnabledByOrganization = true;
    }
    setTimeout(() => {
      try {
        this.sections = JSON.parse(this.jsonData) || [];
        this.isValidJson = true;
      } catch (error) {
        this.isValidJson = false;
        this.httpError = { status: 500, message: 'Invalid JSON input' };
      }
    }, 10);
    setTimeout(() => {
      this.isRendering = false;
    }, 1000);
  }
  clearOnChangeVal() {
    this.cellsQueue = {};
    // this.infiniteLoopCell = {};
    // setTimeout(() => {
    //   if (this.infiniteLoopCell.foundLoop !== undefined) {
    //     this.showMessage('Error', 'Unable to complete the process.');
    //   }
    // }, 1000);
  }
  validateInput(ctrl: any) {
    // validate min and max value for number field
    try {
      if (ctrl.type === 'number') {
        const value = Number(ctrl.value);
        if (ctrl.inputProperties?.minValue) {
          const min = Number(ctrl.inputProperties.minValue);
          if (value < min) {
            ctrl.value = '-';
            this.showMessage('Error', 'Value should be greater than or equal to ' + min);
            return;
          }
        }
        if (ctrl.inputProperties?.maxValue) {
          const max = Number(ctrl.inputProperties.maxValue);
          if (value > max) {
            ctrl.value = '-';
            this.showMessage('Error', 'Value should be less than or equal to ' + max);
            return;
          }
        }
      }
    } catch (error) {
      ctrl.value = '-';
      this.showMessage('Error', 'Unexpected value! ');
      return;
    }
    this.createCellsQue(ctrl);
  }
  changeValue(ctrl: any) {
    // ## ------------ block infinite loop -------------------
    // if (this.infiniteLoopCell[ctrl.id] !== undefined &&
    //   this.infiniteLoopCell[ctrl.id].count >= 10) {
    //   this.infiniteLoopCell[ctrl.id].count = this.infiniteLoopCell[ctrl.id].count + 1;
    //   this.infiniteLoopCell.foundLoop = ctrl.id;
    //   return;
    // }
    // if (this.infiniteLoopCell[ctrl.id] === undefined) {
    //   // first iteration
    //   this.infiniteLoopCell[ctrl.id] = { count: 0, cellRule: ctrl.cellRule };
    // } else {
    //   this.infiniteLoopCell[ctrl.id].count = this.infiniteLoopCell[ctrl.id].count + 1;
    // }
    // ## ----------------------------------------------------
    if (ctrl.rules) {
      try {
        const getValue = (item: any): any => {
          const values = this.sections[item.sectionIndex].value[item.row][item.col][0].data || [];
          for (const data of values) {
            const formula = data.format.split(/[==]/).toString();
            const onlyEq = formula.split(/[{}]/).filter((x: any) => x.includes('table-')).toString();
            const eq: any = [];
            onlyEq.split('-').forEach((element: any) => {
              if (element !== '' && !isNaN(element)) {
                eq.push(element);
              }
            });
            if (eq.length > 0) {
              const result = Helper.chunkArray(eq, 3);
              for (const c of result) {
                const tableIndex = c[0];
                const rowIndex = Number(c[1]);
                const colIndex = Number(c[2]);
                try {
                  data.value = this.sections[tableIndex].value[rowIndex - 1][colIndex - 1][0].value;
                } catch (error) {
                  // not required
                }
              }
            } else {
              data.value = data.format;
            }
          }
          let output = '';
          values.forEach((d: any) => {
            output += d.value;
          });
          return output;
        };
        for (const item of ctrl.rules) {
          if (item.rule === 'replace') {
            this.sections[Number(item.sectionIndex)].value[Number(item.row)][Number(item.col)][0].value = ctrl.value;
            this.changeRelatedValues(item.sectionIndex, Number(item.row), Number(item.col));
          } else if (item.rule === 'apply-equation') {
            const values = this.sections[item.sectionIndex].value[item.row][item.col][0].data || [];
            for (const data of values) {
              // let onlyEq = data.format.split(/[{}]/).filter((x: any) => x.startsWith('table-')).toString();
              let onlyEq = data.format.startsWith('table-') ? data.format : '';
              const eq: any = [];
              onlyEq.split('-').forEach((element: any) => {
                if (element !== '' && !isNaN(element)) {
                  eq.push(element);
                }
              });
              if (eq.length > 0) {
                const result = Helper.chunkArray(eq, 3);
                for (const c of result) {
                  const tableIndex = c[0];
                  const rowIndex = Number(c[1]);
                  const colIndex = Number(c[2]);
                  try {
                    data.value = this.sections[tableIndex].value[rowIndex - 1][colIndex - 1][0].value;
                  } catch (error) {
                    // not required
                  }
                }
              } else if (eq.length === 0) {
                // onlyEq = data.format.split(/[{}]/).filter((x: any) => x.startsWith('symbol-table-')).toString();
                onlyEq = data.format.startsWith('symbol-table-') ? data.format : '';
                onlyEq.split('-').forEach((element: any) => {
                  if (element !== '' && !isNaN(element)) {
                    eq.push(element);
                  }
                });
                if (eq.length > 0) {
                  const result = Helper.chunkArray(eq, 3);
                  for (const c of result) {
                    const tableIndex = c[0];
                    const rowIndex = Number(c[1]);
                    const colIndex = Number(c[2]);
                    try {
                      const value = this.sections[tableIndex].value[rowIndex - 1][colIndex - 1][0].value;
                      data.value = this.sections[tableIndex].value[rowIndex - 1][colIndex - 1][0].options.find((x: any) => x.value === value).symbol;
                    } catch (error) {
                      // not required
                    }
                  }
                } else {
                  data.value = data.format;
                }
              } else {
                data.value = data.format;
              }
            }
            let output = '';
            values.forEach((d: any) => {
              output += d.value;
            });
            this.sections[item.sectionIndex].value[item.row][item.col][0].value = output;
            this.changeRelatedValues(item.sectionIndex, Number(item.row), Number(item.col));
          } else if (item.rule === 'add-reference-to') {
            const newOptions: any = [];
            const data = this.sections[item.sectionIndex].value[item.row][item.col];
            if (data.length > 0) {
              for (const r of data[0].referenceFrom) {
                if (r.controlType === 'select') {
                  const dropdown = this.sections[r.sectionIndex].value[r.row][r.col][0];
                  const activeValue = dropdown.value;
                  if (activeValue) {
                    const o = dropdown.options.find((x: any) => x.value === activeValue);
                    if (o) {
                      const itemIndex = newOptions.findIndex((x: any) => x.value === o.value);
                      if (itemIndex === -1) {
                        newOptions.push(o);
                      }
                    }
                  }
                }
              }
            }
            if (newOptions.length > 0) {
              this.sections[item.sectionIndex].value[item.row][item.col][0].value = newOptions[0].value;
            }
            this.sections[item.sectionIndex].value[item.row][item.col][0].options = newOptions;
            this.changeRelatedValues(item.sectionIndex, Number(item.row), Number(item.col));
          } else if (item.rule === 'apply-formula') {
            const output = getValue(item);
            let formattedExpression = '';
            formattedExpression = output.substring(
              output.lastIndexOf('=(') + 1,
              output.lastIndexOf('=')
            );
            let result = eval(formattedExpression);
            try {
              if (/\d/.test(result)) {
                if (result.toString().split('.')[1].length > 4) {
                  result = result.toFixed('4');
                }
              }
            } catch (error) {
            }
            const value = output.replace(/=(.*=)/, result);
            this.sections[item.sectionIndex].value[item.row][item.col][0].value = value;
            this.changeRelatedValues(item.sectionIndex, Number(item.row), Number(item.col));
          } else if (item.rule === 'apply-if-else') {
            let output = getValue(item);
            output = output.split(/[()]/).filter((x: any) => x !== '' && x !== 'undefined');

            const firstVal = output[0];
            const operator = output[1];
            let thirdVal = output[2];
            const condition = output[3].match(/\d/g)[0];
            const temp = output[4].split(':');
            const displayOut = [];
            for (let item1 of temp) {
              if (item1.includes('eval')) {
                item1 = item1.replace('eval[', '').replace(']', '');
                item1 = eval(item1).toFixed('4');
                displayOut.push(item1);
              } else {
                displayOut.push(item1);
              }
            }
            let finalOut = '';
            if (thirdVal.includes('/')) {
              thirdVal = thirdVal.split('/')[Number(condition) - 2];
            }
            if (eval('"' + firstVal + '"' + operator + '"' + thirdVal + '"')) {
              finalOut = displayOut[0];
            } else {
              finalOut = displayOut[1];
            }
            this.sections[item.sectionIndex].value[item.row][item.col][0].value = finalOut;
            this.changeRelatedValues(item.sectionIndex, Number(item.row), Number(item.col));
          } else if (item.rule === 'eval') {
            const output = getValue(item);
            let result = eval(output);
            try {
              if (/\d/.test(result)) {
                if (result.toString().split('.')[1].length > 4) {
                  result = result.toFixed('4');
                }
              }
            } catch (error) {
            }
            this.sections[item.sectionIndex].value[item.row][item.col][0].value = result;
            this.changeRelatedValues(item.sectionIndex, Number(item.row), Number(item.col));
          } else if (item.rule === 'eval-string') {
            const output = getValue(item);
            let result: any = '';
            let substrings = [];
            substrings = output.includes('#') ? output.split('#') : [output];
            for (const s of substrings) {
              if (s.includes('eval(')) {
                const expressions = s.substring(
                  s.lastIndexOf('=eval(') + 1,
                  s.lastIndexOf(')=') + 1
                ).replace('eval', '');
                let op = eval(expressions);
                try {
                  if (/\d/.test(op)) {
                    if (op.toString().split('.')[1].length > 4) {
                      op = op.toFixed('4');
                    }
                  }
                } catch (error) {
                }
                result += s.replace('=eval' + expressions + '=', op);
              } else {
                result += s;
              }
            }
            if (result.substr(0, 2) === '~~') {
              const query = result.substr(2, result.length - 4);
              result = eval(query);
            }
            this.sections[item.sectionIndex].value[item.row][item.col][0].value = result;
            this.changeRelatedValues(item.sectionIndex, Number(item.row), Number(item.col));
          } else if (item.rule === 'exec-js') {
            const output = getValue(item);
            let result: any = eval(output);
            if (result === undefined) {
              result = '#N/A';
            }
            try {
              if (/\d/.test(result)) {
                if (result.toString().split('.')[1].length > 4) {
                  result = result.toFixed('4');
                }
              }
            } catch (error) {
            }
            this.sections[item.sectionIndex].value[item.row][item.col][0].value = result;
            this.changeRelatedValues(item.sectionIndex, Number(item.row), Number(item.col));
          }
          // check if matrix inverse have reference
          this.checkMatrixReference(item.sectionIndex);
        }
        this.hasChangeDetected = '';
        setTimeout(() => {
          this.hasChangeDetected = moment().valueOf().toString();
        }, 0);
      } catch (error) {
        this.alert.error('Invalid value!');
      }
    }
    if (ctrl.functionCallReference) {
      try {
        for (const item of ctrl.functionCallReference) {
          const data = this.sections[item.sectionIndex].value[item.row][item.col][0];
          // console.log('data', data);
          const argumentsArray: string[] = [];
          for (const param of data.functionCall.params) {
            const onlyEq = param.cell.split(/[{}]/).filter((x: any) => x.includes('table-')).toString();
            const eq: any = [];
            onlyEq.split('-').forEach((element: any) => {
              if (element !== '' && !isNaN(element)) {
                eq.push(element);
              }
            });
            if (eq.length > 0) {
              const result = Helper.chunkArray(eq, 3);
              for (const c of result) {
                const tableIndex = c[0];
                const rowIndex = Number(c[1]);
                const colIndex = Number(c[2]);
                try {
                  const val = this.sections[tableIndex].value[rowIndex - 1][colIndex - 1][0].value;
                  const e = param.cell.replace(`{table-${tableIndex}-cell-${rowIndex}-${colIndex}}`, val);
                  try {
                    argumentsArray.push(eval(e));
                  } catch (error) {
                    argumentsArray.push(e);
                  }
                } catch (error) {
                  argumentsArray.push('');
                }
              }
            } else {
              argumentsArray.push(param.cell);
            }
          }
          // dynamically pass arguments to function
          // eslint-disable-next-line no-inner-declarations
          function dispatch(this: any, fn: any, args: string[]) {
            fn = (typeof fn === 'function') ? fn : window[fn]; // Allow fn to be a function object or the name of a global function
            return fn.apply(this, args || []); // args is optional, use an empty array by default
          }
          const _lib: any = InteractiveLib;
          let result = dispatch(_lib[data.functionCall.name], argumentsArray);
          try {
            if (result.toString().split('.')[1].length > 4) {
              result = result.toFixed('4');
            }
          } catch (error) {
          }
          data.value = result;
          this.changeRelatedValues(item.sectionIndex, Number(item.row), Number(item.col));
        }
      } catch (error) {
        this.alert.error('Invalid value!');
      }
    }
  }
  changeRelatedValues(sectionIndex: number, rowIndex: number, colIndex: number) {
    // if (this.sections[sectionIndex].value[rowIndex][Number(colIndex)][0].rules.length > 0) {
    //   this.changeValue(this.sections[sectionIndex].value[rowIndex][Number(colIndex)][0]);
    // }
    // if (this.sections[sectionIndex].value[rowIndex][Number(colIndex)][0].functionCallReference && this.sections[sectionIndex].value[rowIndex][Number(colIndex)][0].functionCallReference.length > 0) {
    //   this.changeValue(this.sections[sectionIndex].value[rowIndex][Number(colIndex)][0]);
    // }
  }
  changeDataTable() {
    this.hasChangeDetected = '';
    setTimeout(() => {
      this.hasChangeDetected = moment().valueOf().toString();
    }, 0);
  }
  createCellsQue(ctrl: any) {
    const buildQue = (c: any) => {
      try {
        this.cellsQueue[c.id] = c;
        for (const item of c.rules) {
          buildQue(this.sections[item.sectionIndex].value[item.row][item.col][0]);
        }
        if (c.functionCallReference) {
          for (const item of c.functionCallReference) {
            buildQue(this.sections[item.sectionIndex].value[item.row][item.col][0]);
          }
        }
      } catch (error) {
      }
    };
    buildQue(ctrl);

    // run que items
    setTimeout(() => {
      Object.keys(this.cellsQueue).forEach(key => {
        this.changeValue(this.cellsQueue[key]);
      });
    }, 100);
  }

  // matrix inverse
  buildMatrixData(sourceTable: any, targetTable: any): any {
    return new Promise((resolve, reject) => {
      const d1: any = [];
      let arrayIndex = 0;
      for (let i = sourceTable.from.rowIndex; i <= sourceTable.to.rowIndex; i++) {
        d1.push([]);
        for (let j = sourceTable.from.colIndex; j <= sourceTable.to.colIndex; j++) {
          const val = Number(this.sections[sourceTable.from.sectionIndex].value[i][j][0].value);
          if (d1[arrayIndex].length === 0) {
            d1[arrayIndex] = [val];
          } else {
            d1[arrayIndex].push(val);
          }
        }
        arrayIndex++;
      }
      setTimeout(() => {
        try {
          const A = new Matrix(d1);
          const inverseA: any = inverse(A);
          let matrixRowIndex = 0;
          let matrixColIndex = 0;
          for (let i = targetTable.from.rowIndex; i <= targetTable.to.rowIndex; i++) {
            matrixColIndex = 0;
            for (let j = targetTable.from.colIndex; j <= targetTable.to.colIndex; j++) {
              if (this.sections[targetTable.from.sectionIndex].value[i][j].length > 0) {
                this.sections[targetTable.from.sectionIndex].value[i][j][0].value = inverseA.data[matrixRowIndex][matrixColIndex];
              }
              matrixColIndex++;
            }
            matrixRowIndex++;
          }
          resolve(true);
        } catch (error) {
          this.alert.error('Matrix parse error');
          reject(false);
        }
      }, 500);
    });
  }
  checkMatrixReference(sectionIndex: number) {
    const sourceSection = this.sections[sectionIndex];
    if (sourceSection.matrixInverseReference && sourceSection.matrixInverseReference.direction === 'from') {
      const targetIndex = this.sections.findIndex((x: any) => x.uuid === sourceSection.matrixInverseReference.targetTableUUID);
      sourceSection.matrixInverseReference.targetTable.from.sectionIndex = targetIndex;
      sourceSection.matrixInverseReference.targetTable.to.sectionIndex = targetIndex;

      const targetSection = this.sections[targetIndex];
      if (targetSection.matrixInverseReference && targetSection.matrixInverseReference.direction === 'to') {
        targetSection.matrixInverseReference.sourceTable.from.sectionIndex = sectionIndex;
        targetSection.matrixInverseReference.sourceTable.to.sectionIndex = sectionIndex;

        // build matrix inverse
        this.buildMatrixData(targetSection.matrixInverseReference.sourceTable, sourceSection.matrixInverseReference.targetTable);
      }
    }
  }

  // questionnaire
  questionnaireConfirmStartExam(section: any): void {
    const message = `Are you sure you want to start this examination`;
    const dialogData = new DialogModel('Confirmation', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      const result = dialogResult;
      if (result === true) {
        this.questionnaireStartExam(section);
      }
    });
  }
  async questionnaireStartExam(section: any) {
    this.questionnaireClearExam();
    this.exam.questions = section.value;
    this.exam.totalQuestions = section.value.length;
    this.exam.duration = section.examDuration;
    this.exam.examRetry = section.examRetry;
    this.exam.isStarted = true;
    this.exam.isCompleted = false;
    this.exam.marks = {
      examTotalMark: section.examTotalMark,
      examHighDistinction: section.examHighDistinction,
      examDistinction: section.examDistinction,
      examCredit: section.examCredit,
      examPassMark: section.examPassMark
    };
    for (const item of this.exam.questions) {
      this.exam.totalMark += item.score;
      if (!this.exam.activeQuestion.id) {
        this.exam.activeQuestion = { id: item.id, index: 0 };
      }
    }
    this.examBtnDisabled = true;
    const payload: CreateExamModel = {
      resourceId: this.resource._id,
      resourceName: this.resource.title,
      chapterId: this.chapter._id,
      chapterName: this.chapter.title,
      sectionId: this.section._id,
      sectionName: this.section.title,
      passMarkInPercentage: this.exam.marks.examPassMark,
      examDurationInMinutes: this.exam.duration,
      rawDataAnswers: '',
      rawDataQuestions: JSON.stringify(this.exam.questions),
      totalMark: this.exam.totalMark,
      result: {
        name: '',
        value: ''
      },
      examRetryAttempt: this.exam.examRetry,
      totalMarkGained: 0
    };
    let canStart = false;
    if (this.authUser.userType === this.userTypes.MASTER_ADMIN || this.authUser.userType === this.userTypes.ADMIN) {
      canStart = true;
    } else {
      canStart = await this.questionnaireCheckCanStartExam(payload.examRetryAttempt);
    }
    if (!canStart) {
      this.showMessage('Retry Attempt!', 'Exam retry limit reached. <br /> Your last attempt was the final result');
      this.examBtnDisabled = false;
      this.questionnaireClearExam();
    } else {
      this._examService.startExam(payload).subscribe((res) => {
        this.exam.id = res.data._id;
        this._sharedService.setExamData(this.exam);
        this.examBtnDisabled = false;
      }, (err) => {
        this.questionnaireClearExam();
        this.snackBar.open(err.error.message, 'DISMISS');
        this.examBtnDisabled = false;
      });
    }
  }
  questionnaireStopExam(): void {
    this.exam.isCompleted = true;
    this.exam.isStarted = false;
    this._sharedService.setExamData(this.exam);
    this.questionnaireCalculateScoreAndUpdate();
    this.questionnaireExamRetry();
    this.calculateStreamDuration();
  }
  questionnaireAnswer(question: any, selectedOption: any): void {
    // let message = '';
    // if (selectedOption.id === question.answer) {
    //   message = 'Correct!';
    // } else {
    //   message = 'Wrong answer. Try again';
    // }
    // const dialogData = new DialogModel('Message', message);
    // this.dialog.open(SuccessDialogComponent, {
    //   width: '400px',
    //   data: dialogData
    // });
    this.exam.answers[question.id] = {
      score: question.score,
      isCorrect: selectedOption.id === question.answer,
      answerId: selectedOption.id
    };
    this.exam.totalAttended = Object.keys(this.exam.answers).length;
    this.examOptionsChecked[question.id] = selectedOption.id;
    this._sharedService.setExamData(this.exam);
  }
  questionnaireCalculateScoreAndUpdate(): void {
    let totalMarkScored = 0;
    let percentage = 0;
    let grade = {
      name: '',
      value: ''
    };
    Object.keys(this.exam.answers).forEach(key => {
      if (this.exam.answers[key].isCorrect) {
        totalMarkScored += this.exam.answers[key].score;
      }
    });
    percentage = ((totalMarkScored / this.exam.totalMark) * 100);
    if (percentage >= this.exam.marks.examHighDistinction) {
      grade = {
        name: 'High Distinction',
        value: this.exam.marks.examHighDistinction + '%'
      };
    } else if (percentage >= this.exam.marks.examDistinction) {
      grade = {
        name: 'Distinction',
        value: this.exam.marks.examDistinction + '%'
      };
    } else if (percentage >= this.exam.marks.examCredit) {
      grade = {
        name: 'Credit',
        value: this.exam.marks.examCredit + '%'
      };
    } else if (percentage >= this.exam.marks.examPassMark) {
      grade = {
        name: 'Pass Mark',
        value: this.exam.marks.examPassMark + '%'
      };
    } else {
      grade = {
        name: 'Failed',
        value: 'Below ' + this.exam.marks.examPassMark + '%'
      };
    }
    const payload: StopExamModel = {
      rawDataAnswers: JSON.stringify(this.exam.answers),
      result: grade,
      status: 'Completed',
      totalMarkGained: totalMarkScored,
      totalMarkGainedInPercentage: percentage
    };
    this._examService.stopExam(this.exam.id, payload).subscribe((res) => {
      this.showMessage('Message', 'Examination has been successfully completed. Please visit <a href="/account/profile?tab=my-exams" target="_blank"> My Exams </a> page for view result');
      this.questionnaireClearExam();
      this.examOptionsChecked = {};
      this.examBtnDisabled = false;
      this._sharedService.setExamData(this.exam);
    }, (err) => {
      this.snackBar.open(err.error.message);
      this.examBtnDisabled = false;
    });
  }
  questionnaireCheckCanStartExam(examRetryAttempt: number): any {
    return new Promise((resolve, reject) => {
      this._examService.checkExamCanStart(this.section._id).subscribe((result) => {
        const foundExist = result.data;
        if (foundExist && foundExist.length >= examRetryAttempt) {
          resolve(false);
        } else {
          resolve(true);
        }
      });
    });
  }
  questionnaireClearExam(): void {
    this.exam = new InteractiveExamination();
    this.exam.resource = this.resource.slug;
    this.exam.chapter = this.chapter.slug;
    this.exam.section = this.section.slug;
    this.exam.activeQuestion = {
      id: '',
      index: 0
    };
  }
  questionnaireExamRetry(): void {
    // retry exam if previous exam is failed
    this._examService.checkExamCanStart(this.section._id).subscribe((result) => {
      const data = result.data;
      this.examResultCheck = data;
      // if (data.length === 0) {
      //   this.examCanRetry = false;
      // } else if (data.length > 0 && data[0].result.name === 'Failed') {
      //   this.examCanRetry = true;
      // } else {
      //   this.examCanRetry = false;
      // }
    });
  }
  questionnairePagination(action: string, questions: any): void {
    if (action === 'next') {
      if (!this.examOptionsChecked[this.exam.activeQuestion.id]) {
        this.snackBar.open('Please answer the question!', 'DISMISS');
        return;
      }
      this.exam.activeQuestion.index++;
    } else if (action === 'back') {
      this.exam.activeQuestion.index--;
    }
    this.exam.activeQuestion.id = questions[this.exam.activeQuestion.index].id;
  }
  calculateStreamDuration() {
    if (this.resource.streamDuration && this.resource.streamDuration.totalHours > 0) {
      const streamDurationEndTime = moment();
      const diff = streamDurationEndTime.diff(this.streamDurationStartTime, 'seconds');
      const socket = this._sharedService.webSocketGetInstance();
      socket.emit('customer.stream.duration', { userId: this.authUser._id, resourceId: this.resource._id, duration: diff });
      setTimeout(() => {
        this.streamDurationStartTime = moment();
      }, 2000);
    }
  }
  basicQuestionnaireAnswer(question: any, selectedOption: any): void {
    let message = '';
    if (selectedOption.id === question.answer) {
      message = 'Correct!';
    } else {
      message = 'Wrong answer. Try again';
    }
    const dialogData = new DialogModel('Message', message);
    this.dialog.open(SuccessDialogComponent, {
      width: '400px',
      data: dialogData
    });
  }
  reRenderChart(): void {
    if (!this.hasRenderChart) {
      this.hasChangeDetected = '';
      setTimeout(() => {
        this.hasChangeDetected = moment().valueOf().toString();
      }, 0);
    }
    this.hasRenderChart = true;
  }
  showMessage(title: string, content: string): void {
    const dialogData = new DialogModel(title, content);
    this.dialog.open(SuccessDialogComponent, {
      maxWidth: '600px',
      minWidth: '450px',
      data: dialogData
    });
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
