import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Resource, ResourceDetailsSuccessData, ResourceSuccessData } from '../model/resource.model';
import { environment } from '../../environments/environment';

@Injectable()
export class ResourceService {
    constructor(public http: HttpClient) { }

    filterList(
        keywords: string,
        status: string,
        organization: string,
        page: number,
        limit: number,
        sortBy: string,
        authStatus: string,
        isBrowsable: string,
        browsableTypes: string
    ): Observable<HttpResponse<ResourceSuccessData>> {
        return this.http.get<ResourceSuccessData>(environment.API_END_POINT + '/resource?title=' + keywords +
            '&organization=' + organization + '&status=' + status + '&isBrowsable=' + isBrowsable +
            '&browsableTypes=' + browsableTypes + '&page=' + page + '&limit=' + limit + '&sortBy=' + sortBy, {
            observe: 'response',
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'useractive': authStatus
            })
        });
    }
    getDetails(id: string): Observable<HttpResponse<ResourceDetailsSuccessData>> {
        return this.http.get<ResourceDetailsSuccessData>(environment.API_END_POINT + '/resource/' + id, { observe: 'response' });
    }
}
