<div class="topsec h-auto">
    <div class="sub-banner top-banner banner5">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-7 col-xl-5">
                   <h2>Contact us today</h2>
                   <p class="pt-2">If you are a single user or a corporate looking to take advantage of our powerful admin features, then contact us today.</p>
                   <div class="mail my-4 my-lg-5"><a href="mailto:info@finstream.com"><img src="assets/img/email.svg"> info@finstream.com</a></div>
                   <div class="address">
                    Address: Level 4, 75 Pitt Street<br> Sydney, 2000, New South Wales,<br> Australia
                   </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <section class="about_sec">
    <div class="container-fluid w_80">
        <div class="row">
            <div class="col-lg-12">
                <h3>Contact Us</h3>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container-fluid w_80 mb-5">
        <div class="row">
            <div class="col-lg-12">
                <div class="wrapper_white">
                    <div class="row">
                        <div class="col-lg-6 text-left">
                            <h1 class="ml-0 pb-3 w-100 text-left">Reach out to us!</h1>
                            <form [formGroup]="formGroup" #form="ngForm" (ngSubmit)="submitContact(form)" autocomplete="off" >
                                <div class="form-row contac_wrp pt-4">
                                    <div class="col-12">
                                        <input type="text" class="form-control" formControlName="name" placeholder="Name">
                                        <small [@inOutAnimation] class="form-text error"
                                            *ngIf="form.submitted && formGroup.controls['name'].hasError('required')">
                                            Please fill this field
                                         </small>
                                    </div>
                                    <div class="col-12">
                                        <input type="email" class="form-control" formControlName="email" placeholder="Email address">
                                        <small [@inOutAnimation] class="form-text error"
                                             *ngIf="form.submitted && formGroup.controls['email'].hasError('required')">
                                             Please fill this field
                                        </small>
                                        <small [@inOutAnimation] class="form-text error"
                                            *ngIf="form.submitted && formGroup.controls['email'].hasError('pattern')">
                                            Invalid email
                                        </small>
                                    </div>
                                    <div class="col-12">
                                        <input type="text" class="form-control" formControlName="phone" placeholder="Phone number">
                                        <small [@inOutAnimation] class="form-text error"
                                             *ngIf="form.submitted && formGroup.controls['phone'].hasError('required')">
                                             Please fill this field
                                        </small>
                                        <small [@inOutAnimation] class="form-text error"
                                            *ngIf="form.submitted && formGroup.controls['phone'].hasError('pattern')">
                                            Please enter a valid phone number
                                        </small>
                                    </div>
                                    <div class="col-12">
                                        <textarea type="text" rows="6" class="form-control" formControlName="message"
                                            placeholder="Message"></textarea>
                                            <small [@inOutAnimation] class="form-text error"
                                                *ngIf="form.submitted && formGroup.controls['message'].hasError('required')">
                                                Please fill this field
                                            </small>
                                            <small [@inOutAnimation] class="form-text error" *ngIf="errorMessage"
                                                [innerHTML]="errorMessage">
                                            </small>
                                    </div>
                                    <div class="col-12 pt-2" *ngIf="isPlatformBrowser">
                                        <ngx-recaptcha2 #captchaElem
                                            [siteKey]="siteKeyData"
                                            formControlName = "reCaptcha">
                                        </ngx-recaptcha2>
                                        <small [@inOutAnimation] class="form-text error"
                                            *ngIf="form.submitted && formGroup.controls['reCaptcha'].hasError('required')">
                                            reCaptcha is required
                                        </small>
                                    </div>
                                    <div class="col-12 text-right pt-4">
                                        <button type="submit" class="common_btn" [disabled]="btnDisabled"> Send 
                                            <mat-spinner class="btnLoading" [diameter]="20" *ngIf="btnDisabled">
                                            </mat-spinner>
                                        </button>
                                    </div>
                                </div>
                            </form>

                        </div>
                        <div class="col-lg-6">
                            <div class="w-100 pt-5">
                                <iframe width="100%" height="440" frameborder="0" scrolling="no" marginheight="0"
                                    marginwidth="0" id="gmap_canvas"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d37481.73834806173!2d151.18370538900345!3d-33.865207276478266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b129838f39a743f%3A0x3017d681632a850!2sSydney%20NSW%2C%20Australia!5e0!3m2!1sen!2sin!4v1630299196603!5m2!1sen!2sin"></iframe>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->