/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { Component, Input, OnInit, OnChanges, Inject, PLATFORM_ID, HostListener } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import * as d3 from 'd3';
import * as d3Scale from 'd3-scale';
import * as d3Shape from 'd3-shape';
import * as d3Array from 'd3-array';
import * as d3Axis from 'd3-axis';
let _this: any;
@Component({
  selector: 'app-d3-graph',
  templateUrl: './d3-graph.component.html',
  styleUrls: ['./d3-graph.component.scss']
})
export class D3GraphComponent implements OnInit, OnChanges {
  @Input() data: any = {};
  title = 'Multi Line Chart';
  width: number;
  height: number;
  private margin = { top: 20, right: 30, bottom: 30, left: 100 };
  private x: any;
  private y: any;
  private svg: any;
  private line: d3Shape.Line<[number, number]> | undefined; // this is line definition
  constructor(
    @Inject(PLATFORM_ID) private platform: Record<string, unknown>) {
    // configure margins and width/height of the graph
    this.width = 700 - this.margin.left - this.margin.right;
    this.height = 400 - this.margin.top - this.margin.bottom;
  }

  ngOnInit(): void {
    _this = this;
  }
  ngOnChanges() {
    this.onBuildD3();
  }
  onBuildD3() {
    if (isPlatformBrowser(this.platform)) {
      this.width = this.getResizedWidth();
      if (this.data && this.data.xAxisData && this.data.xAxisData.length > 0) {
        setTimeout(() => {
          this.buildSvg();
          this.addXandYAxis();
          this.addCoordsLabel();
          this.drawLineAndPath();
        }, 10);
      }
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.width = this.getResizedWidth();
    this.svg.attr('width', this.width);
  }
  getResizedWidth(): number {
    const innerWidth = window.innerWidth;
    const width = (innerWidth / 2.5) - this.margin.left - this.margin.right;
    return width;
  }
  private buildSvg() {
    this.svg = d3.select(`#${this.data.id}`)
      .append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');
  }
  private addXandYAxis() {
    // range of data configuring
    this.x = d3Scale.scaleLinear().range([0, this.width]);
    this.y = d3Scale.scaleLinear().range([this.height, 0]);
    this.x.domain(d3Array.extent(this.data.xAxisData));
    this.y.domain([d3Array.min(this.data.series, (d: any) => d3Array.min(d.values)), d3Array.max(this.data.series, (d: any) => d3Array.max(d.values))]).nice();

    // Configure the Y Axis
    this.svg.append('g')
      .attr('transform', 'translate(0,-9999999)') // just hide x asi here update at last (see below)
      .call(d3Axis.axisBottom(this.x));
    // Configure the Y Axis
    this.svg.append('g')
      .attr('class', 'axis axis--y')
      .call(d3Axis.axisLeft(this.y));

    const axisCoords: any = [];
    this.svg.selectAll('.tick').each(function (this: any, data: any) {
      const tick = d3.select(this);
      const translate = tick.attr('transform').substring(tick.attr('transform').indexOf('(') + 1, tick.attr('transform').indexOf(')')).split(',');
      let axis = '';
      if (translate[1] === '0') {
        axis = 'y';
      } else {
        axis = 'x';
      }
      axisCoords.push({
        axis,
        tick: data,
        translate
      });
    });
    const xCoords = axisCoords.filter((x: any) => x.axis === 'x');
    const lowestY = xCoords.reduce(function (prev: any, curr: any) {
      return prev.tick < curr.tick ? prev : curr;
    });
    const zeroThY = xCoords.find((x: any) => x.tick === 0);
    const diffHeight = Number(lowestY.translate[1]) - Number(zeroThY.translate[1]);

    this.svg.append('g')
      .attr('transform', 'translate(0,' + (this.height - diffHeight) + ')')
      .call(d3Axis.axisBottom(this.x));
  }

  private addCoordsLabel() {
    this.svg.append('text')
      .attr('class', 'x label')
      .attr('text-anchor', 'end')
      .attr('x', this.width / 1.5)
      .attr('y', this.height - 6)
      .text(this.data.x);

    this.svg.append('text')
      .attr('class', 'y label')
      .attr('text-anchor', 'end')
      .attr('y', 6)
      .attr('dy', '-80')
      .attr('dx', -(this.height / 3))
      .attr('transform', 'rotate(-90)')
      .text(this.data.y);
  }

  private drawLineAndPath() {
    const line: any = d3Shape.line()
      .x((d: any, i: any) => this.x(this.data.xAxisData[i]))
      .y((d: any) => this.y(d));
    // Configuring line path
    const _path: any = this.svg.append('g')
      .attr('fill', 'none')
      .attr('stroke-width', 1.5)
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .selectAll('path')
      .data(this.data.series)
      .join('path')
      .style('mix-blend-mode', 'multiply')
      .attr('d', (d: any) => line(d.values))
      .style('stroke-dasharray', function (d: any) { // Add the colours dynamically
        if (d.lineType === 'dashed') {
          return ('3, 3');
        } else {
          return ('0, 0');
        }
      })
      .style('stroke', function (d: any) { // Add the colours dynamically
        return d.lineColor;
      });

    this.svg.call(hover, _path);

    function hover(svg: any, path: any) {
      if ('ontouchstart' in document) {
        svg.style('-webkit-tap-highlight-color', 'transparent')
          .on('touchmove', moved)
          .on('touchstart', entered)
          .on('touchend', left);
      } else {
        svg
          .on('mousemove', moved)
          .on('mouseenter', entered)
          .on('mouseleave', left);
      }

      const dot = svg.append('g')
        .attr('display', 'none');

      dot.append('circle')
        .attr('r', 5.5);

      dot.append('text')
        .attr('font-family', 'sans-serif')
        .attr('font-size', 10)
        .attr('text-anchor', 'middle')
        .attr('y', -8);

      function moved(event: any) {
        event.preventDefault();
        const pointer = d3.pointer(event);
        const xm = _this.x.invert(pointer[0]);
        const ym = _this.y.invert(pointer[1]);
        const i = d3Array.bisectCenter(_this.data.xAxisData, xm);
        const s: any = d3Array.least(_this.data.series, (d: any) => Math.abs(d.values[i] - ym));
        path.attr('stroke', (d: any) => d === s ? null : '#ddd').filter((d: any) => d === s).raise();
        dot.attr('transform', `translate(${_this.x(_this.data.xAxisData[i])},${_this.y(s.values[i])})`);
        dot.select('text').text(`${s.name}: ${s.values[i]}`);
      }

      function entered() {
        path.style('mix-blend-mode', null).attr('stroke', '#ddd');
        dot.attr('display', null);
      }

      function left() {
        path.style('mix-blend-mode', 'multiply').attr('stroke', null);
        dot.attr('display', 'none');
      }
    }
  }
}
