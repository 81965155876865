import { Component, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Chapter } from '../../../model/chapter.model';
import { Resource } from '../../../model/resource.model';
import { Constants } from '../../../utilities/constants';
import { Section, SectionList } from '../../../model/section.model';
import { AuthUser } from '../../../model/user.model';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-def-theory',
  templateUrl: './def-theory.component.html',
  styleUrls: ['./def-theory.component.scss']
})
export class DefTheoryComponent implements OnInit, OnChanges {
  @Input() resource = new Resource();
  @Input() chapter = new Chapter();
  @Input() sections: SectionList[] = [];
  @Input() activeSection = '';
  @Output() emitSlug: EventEmitter<any> = new EventEmitter();
  section = new SectionList();
  authUser = new AuthUser();
  userTypes = Constants.userTypes;
  isLoading = true;
  isRendering = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _sharedService: SharedService) {
    route.queryParams.subscribe(params => {
      if (params.section) {
        this.activeSection = params.section;
      }
    });
  }

  ngOnInit(): void {
  }
  ngOnChanges(): void {
    this.isRendering = true;
    if (this.sections.length > 0) {
      if (this.activeSection) {
        this.section = this.sections.find(x => x.slug === this.activeSection) || this.sections[0];
      } else {
        this.section = this.sections[0];
      }
      this.activeSection = this.section.slug;
    }
    this.isLoading = false;
    this.authUser = this._sharedService.authUser();
    setTimeout(() => {
      this.isRendering = false;
    }, 1000);
  }
  selectSection(section: SectionList) {
    if (section.hasSectionAccess) {
      this.section = section;
      this.activeSection = this.section.slug;
      this.handleSection();
    }
  }
  handleSection() {
    this.emitSlug.emit(this.activeSection);
  }
}
