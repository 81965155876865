// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// const APIBaseUrl = 'http://localhost:3000';
const APIBaseUrl = 'https://api.develop.finstream.com.au';
// const APIBaseUrl = 'https://api.finstream.com';

export const environment = {
  production: false,
  encKey: 'yZZT78m7FFly62TaSFHWBa',
  view_id: '', // google analytics
  STRIPE_KEY: 'pk_test_51IpjBpDICbnogzaM9L8JjIRl8fKREel3NgvHj5EPOTlywMaYWOX2br9B4vIElZEerFUSBrd0WhR4LShqPzrjHiUS00p78CaN8t', // finstream test key
  // STRIPE_KEY: 'pk_test_51It67KSDGn0FlnvdiP7da9wF9cFJERMf0J6YQULiqcWdEWrZPQ1hJEd5DtNlgFDzeU3DJN9xKgWkqRlr7vUcSt4S00EDrJtHIb', // john test key
  API_BASE_URL: APIBaseUrl,
  API_END_POINT: APIBaseUrl + '/api',
  FACEBOOK_APP_ID: '1157875464617883',
  GOOGLE_APP_ID: '894966047162-4a0qjcglqkmlb7qemj55pcojpos8vk64.apps.googleusercontent.com',
  GA_TRACKING_ID: 'G-PM2D58JK6T',
  // GOOGLE_CAPTCHA_SITE_KEY: '6LcwMlYdAAAAAISI301OwwCACJRflrpDMS0o9Nbb'
  GOOGLE_CAPTCHA_SITE_KEY: '6LfRg38dAAAAAEIgn4W52UXpqYRKziX-qXoEDmR1'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
