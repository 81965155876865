import { Component, OnInit } from '@angular/core';
import { OrganizationDetails } from 'src/app/model/organization.model';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentYear: number = new Date().getFullYear();
  organization = new OrganizationDetails();
  constructor(
    private _sharedService: SharedService
    ) { }

  ngOnInit(): void {
    this._sharedService.organization.subscribe((res: any) => {
      this.organization = res;
    });
  }
}
