<ng-container *ngIf="!isValidJson">
    <div style="margin-top: 50px;">
        <app-no-records-found [httpError]="httpError"></app-no-records-found>
    </div>
</ng-container>
<div *ngIf="isValidJson" (mouseenter)="reRenderChart()">
    <h4>{{section.title}}</h4> 
    <div class="text-center w-100" *ngIf="isRendering">
        <mat-spinner class="btnLoading" [diameter]="50">
        </mat-spinner>
    </div>
    <ng-container *ngFor="let sec of sections; let secIndex = index;" >
        <label *ngIf="sec.type==='label'">{{sec.value}}</label>
        <p [innerHTML]="sec.value | safeHtml" *ngIf="sec.type==='text'" ></p>
        <mathjax [content]="sec.value | safeHtml" *ngIf="sec.type==='mathjax'" [style.display]="isRendering ? 'none': 'inline-block'"></mathjax>
        <ng-container *ngIf="sec.type === 'accordion'">
            <div class="accordion_wrp">
                <div class="accordion" id="accordion-group_{{secIndex}}">
                    <div class="card" *ngFor="let a of sec.value; let j = index">
                        <div class="card-header" [id]="j+'_headingOne_'+secIndex">
                            <span>
                                <button type="button" class="btn btn-link collapsed" data-toggle="collapse" [attr.data-target]="'#collapseOne_'+j+'_'+ secIndex"> 
                                    {{a.title}} <i class="fa fa-plus"></i>
                                </button>									
                            </span>
                        </div>
                        <div [id]="'collapseOne_'+j+'_'+secIndex" class="collapse" [attr.aria-labelledby]="j+'_headingOne_'+secIndex" [attr.data-parent]="'#accordion-group_'+secIndex">
                            <!-- <div class="card-body" [innerHTML]="a.content | safeHtml">
                            </div> -->
                            <div class="card-body">
                                <mathjax [content]="a.content | safeHtml" [style.display]="isRendering ? 'none': 'inline-block'"></mathjax>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="accordion-set" *ngIf="sec.type==='questionnaire-basic'">
            <div>
                <div class="questionnaire" *ngFor="let a of sec.value; let j = index;">
                    <ng-container>
                        <div class="title-wrapper">
                            <label class="qst-title">
                                <div>
                                    <mathjax class="qst-option" [content]="a.title | safeHtml" [style.display]="isRendering ? 'none': 'inline-block'"></mathjax>
                                </div>
                            </label>                         
                        </div>
                        <ng-container *ngFor="let o of a.options; let k = index">
                            <div class="qst-option-group">
                                <input type="radio" name="rdo_{{secIndex}}_b_{{j}}" [value]="o.id" (change)="basicQuestionnaireAnswer(a,o)">
                                <mathjax class="qst-option" [content]="o.title | safeHtml" [style.display]="isRendering ? 'none': 'inline-block'"></mathjax>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="accordion-set" *ngIf="sec.type==='questionnaire'">
            <div *ngIf="exam.isStarted">
                <div class="questions-count">
                   <span class="count">{{exam.activeQuestion.index+1}}/{{sec.value.length}}</span>
                </div>
                <div class="questionnaire" [ngClass]="{'exam-disabled': examInProgress.section && examInProgress.section!==exam.section}" *ngFor="let a of sec.value; let j = index;">
                    <ng-container *ngIf="exam.activeQuestion.id===a.id">
                        <div class="title-wrapper">
                            <label class="qst-title">
                                <div>
                                    <!-- <span>{{j+1}}.</span>   -->
                                    <mathjax class="qst-option" [content]="a.title | safeHtml" [style.display]="isRendering ? 'none': 'inline-block'"></mathjax>
                                </div>
                                <span class="strong">(Mark:{{a.score}})</span>
                            </label>                         
                        </div>
                        <ng-container *ngFor="let o of a.options; let k = index">
                            <div class="qst-option-group">
                                <input type="radio" name="rdo_{{secIndex}}_{{j}}" [(ngModel)]="examOptionsChecked[a.id]" [value]="o.id" (change)="questionnaireAnswer(a,o)">
                                <!-- <label class="qst-option">{{o.title}}</label> -->
                                <mathjax class="qst-option" [content]="o.title | safeHtml" [style.display]="isRendering ? 'none': 'inline-block'"></mathjax>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="questions-pagination mt-4 ml-3">
                    <button class="common_btn p-2 mt-2 mr-2" *ngIf="exam.activeQuestion.index>0" (click)="questionnairePagination('back', sec.value)">Back</button>
                    <button class="common_btn p-2 mt-2 mr-2" *ngIf="(exam.activeQuestion.index+1)<sec.value.length" (click)="questionnairePagination('next', sec.value)">Next</button>
                    <button class="common_btn p-2 mt-2 mr-2" 
                        (click)="questionnaireStopExam()" 
                        [disabled]="exam.totalAttended===0 || exam.totalAttended!==exam.totalQuestions"
                        *ngIf="examInProgress.section===exam.section && !exam.isCompleted && ((exam.activeQuestion.index+1)===sec.value.length)">Submit</button>
                </div>
                
            </div>
            <div class="col justify-center" *ngIf="!exam.isStarted">
                <div>
                    <table class="exam-table">
                        <tr>
                            <td>Exam Duration</td>
                            <td class="input-wrp">{{sec.examDuration}}Min</td>
                        </tr>
                        <tr>
                            <td>Total Questions</td>
                            <td class="input-wrp">{{sec.value.length}}</td>
                        </tr>
                        <tr>
                            <td>Total Mark</td>
                            <td class="input-wrp">{{sec.examTotalMark}}</td>
                        </tr>
                    </table>
                </div>
                <ng-container *ngIf="authUser.userType!==userTypes.MASTER_ADMIN && authUser.userType!==userTypes.ADMIN && examEnabledByOrganization">
                    <button class="common_btn p-2 mt-3 mr-2" (click)="questionnaireConfirmStartExam(sec)" *ngIf="this.examResultCheck.length===0"> Start Exam </button>
                    <button class="common_btn p-2 mt-3 mr-2" (click)="questionnaireConfirmStartExam(sec)" *ngIf="this.examResultCheck.length>0 &&  this.examResultCheck[0].result.name === 'Failed'">
                        Retry Exam
                    </button>
                    <span class="exam-comp-message" *ngIf="this.examResultCheck.length>0 &&  this.examResultCheck[0].result.name !== 'Failed'">This exam was already completed. Please check the result</span>
                </ng-container>
                <ng-container *ngIf="authUser.userType!==userTypes.MASTER_ADMIN && authUser.userType!==userTypes.ADMIN && !examEnabledByOrganization">
                    <span class="exam-comp-message">
                        Your organisation is not offering a certificate for this course however it
                        is available at <a href="https://finstream.com/" target="_blank">Finstream.com</a>
                    </span>
                </ng-container>
                <ng-container *ngIf="authUser.userType===userTypes.MASTER_ADMIN || authUser.userType===userTypes.ADMIN">
                    <button class="common_btn p-2 mt-3 mr-2" (click)="questionnaireConfirmStartExam(sec)"> Start Exam </button>
                </ng-container>
            </div>
        </div>
        <div class="table_responsive" *ngIf="sec.type==='table'">
            <ng-container *ngIf="!sec.settings?.tableSettings?.expandArea">
                <ng-container *ngTemplateOutlet="inner; context: {sec : sec}"></ng-container>
            </ng-container>            
            <div class="accordion_wrp" *ngIf="sec.settings?.tableSettings?.expandArea === true && sec.settings?.tableSettings?.showDataTable === true">
                <div class="accordion" id="table-group_{{secIndex}}">
                    <div class="card" [style.width]="sec | interactiveTableCollapsibleWidth">
                        <div class="card-header" [id]="'_tableCollapse_'+secIndex" [style.width]="sec | interactiveTableCollapsibleWidth">
                            <span>
                                <button type="button" class="btn btn-link collapsed" data-toggle="collapse" [attr.data-target]="'#tableCollapse_'+ secIndex"> 
                                    Data Table <i class="fa fa-plus"></i>
                                </button>									
                            </span>
                        </div>
                        <div [id]="'tableCollapse_'+secIndex" class="collapse" [attr.aria-labelledby]="'_tableCollapse_'+secIndex" [attr.data-parent]="'#table-group_'+secIndex">
                            <ng-container *ngTemplateOutlet="inner; context: {sec : sec}"></ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="sec.type==='graph'">
            <div class="col row drp-wrapper" style="text-align: left;margin-top: 15px;">
                <div class="drp-item" *ngIf="sec.value.dataTables && sec.value.dataTables.length>0">                               
                    <div class="form-group select">
                        <label>Data Table</label>
                        <ng-select class="single"
                            name="dtaTable{{secIndex}}"
                            [placeholder]="sec.value.selectedDataTable === ' ' ? 'Default Table' : '' "
                            [(ngModel)]="sec.value.selectedDataTable"
                            [bindLabel]="'title'"
                            [bindValue]="'tableUUID'"
                            [clearable]="false"
                            [searchable]="false"
                            (change)="changeDataTable()">
                            <ng-option value="">
                                <span>Default Table</span>
                            </ng-option>
                            <ng-option [value]="item.tableUUID" *ngFor="let item of sec.value.dataTables">
                                {{item.title}}
                            </ng-option>
                        </ng-select>
                    </div>                         
                </div>
                <div class="drp-item" *ngIf="sec.value.defaultColumnAsDataSet && hasChangeDetected!==''">
                    <div class="form-group select">
                        <label>Data Column</label>
                        <ng-select class="single"
                            name="dtaTableColumn{{secIndex}}"
                            [placeholder]="sec.value.selectedDataTable === ' ' ? '--Select Column--' : '' "
                            [(ngModel)]="sec.value.selectedDropdownColumn"
                            [clearable]="false"
                            [searchable]="false"
                            (change)="changeDataTable()">
                            <ng-option [value]="item.value" *ngFor="let item of sec | interactiveDataTableColumnPipe: sections;">
                                {{item.title}}
                            </ng-option>
                        </ng-select>
                    </div>
                </div>
            </div>
            <div style="width: 80%; text-align: center;margin-top: 15px;" *ngIf="sec.value.graphTitle">
                <span>{{sec.value.graphTitle}}</span>
            </div>
            <!-- <app-d3-graph [data]="sec | interactiveD3GraphData: sections" *ngIf="hasChangeDetected!==''"></app-d3-graph> -->
            <div style="width: 80%; height: 50%;">
                <app-line-chart [config]="sec | interactiveLineChartData: sections" *ngIf="sec.value.graphType==='line' && hasChangeDetected!==''"></app-line-chart>
                <app-bar-chart [config]="sec | interactiveBarChartData: sections" *ngIf="sec.value.graphType==='bar' && hasChangeDetected!==''"></app-bar-chart>
                <app-scatter-chart [config]="sec | interactiveScatterChartData: sections" *ngIf="sec.value.graphType==='scatter' && hasChangeDetected!==''"></app-scatter-chart>
            </div>
            <div style="width: 40%; height: 40%;">
                <app-pie-chart [config]="sec | interactivePieChartData: sections" *ngIf="sec.value.graphType==='pie' && hasChangeDetected!==''"></app-pie-chart>
            </div>
        </ng-container>
        <ng-container *ngIf="sec.type==='youtube-url'">                            
            <youtube-player [videoId]="sec.value" [style.width]="sec.settings?.width ? sec.settings?.width :'100%'" [style.height]="sec.settings?.height ? sec.settings?.height :'60vh'"></youtube-player>
        </ng-container>
        <ng-container *ngIf="sec.type==='video-url'">
            <video [style.width]="sec.settings?.width ? sec.settings?.width :'100%'" [style.height]="sec.settings?.height ? sec.settings?.height :'60vh'" controls>
                <source [src]="sec.value | safeResourceUrl">
                Your browser does not support HTML video.
            </video>
        </ng-container>
        <br>
    </ng-container>    
</div>

<ng-template #inner let-sec="sec">
  <table class="table table-borderless table-builder" [ngClass]="{'hide-Nth-border':sec.settings?.tableSettings?.expandArea}">
      <ng-container *ngFor="let r of sec.value; let i=index;"> 
            <tr *ngIf="(sec.settings | interactiveRowSettings: i)?.hideRow !== true">
                <ng-container *ngFor="let c of r; let j=index;" >
                    <td  
                        [style.border-width]="sec.settings?.tableSettings?.borderWidth || '1px'"
                        [style.border-color]="sec.settings?.tableSettings?.borderColor || '#c8c8c8'"
                        [style.background]="sec.settings?.cellSettings ? sec.settings?.cellSettings[i+'_'+j]?.backgroundColor : 'inherit'"
                        [style.color]="sec.settings?.cellSettings ? sec.settings?.cellSettings[i+'_'+j]?.color : 'inherit'"
                        [style.width]="sec.settings?.colSettings[j]?.width || 'inherit'"
                        [style.min-width]="!sec.settings?.colSettings[j]?.width ? defaultColWidth:'inherit'"
                        [style.border]="!c[0]?.value? 'none': (sec.settings?.tableSettings?.borderWidth && sec.settings?.tableSettings?.borderColor) ? ' '+sec.settings?.tableSettings?.borderWidth+'px solid '+sec.settings?.tableSettings?.borderColor+'' : '1px solid #d8d8d8'"
                        *ngIf="sec.settings?.colSettings[j]?.hideColumn !== true"
                    >
                        <div class="td-cell">
                            <ng-container *ngFor="let ctrl of c">
                                <ng-container *ngIf="ctrl.type==='label' || ctrl.type==='heading'">
                                    <label>{{ctrl.value}}</label>
                                </ng-container>
                                <ng-container *ngIf="ctrl.type==='image'">
                                    <img 
                                        [src]="ctrl.value" 
                                        [style.width]="ctrl.imageProperties.width || 'auto'"
                                        [style.height]="ctrl.imageProperties.height || 'auto'"
                                        [style.margin]="ctrl.imageProperties.margin || 'auto'"
                                    >
                                </ng-container>
                                <ng-container *ngIf="ctrl.type==='finstream-lib'">
                                    <label>{{ctrl.value}}</label>
                                </ng-container>
                                <ng-container *ngIf="ctrl.type==='output'">
                                    <label class="output">{{ctrl.value | customCommaSeparate}}</label>
                                </ng-container>
                                <ng-container *ngIf="ctrl.type==='editable-output'">
                                    <input [id]="ctrl.id" [name]="ctrl.id" [type]="ctrl.type" [(ngModel)]="ctrl.value"
                                        class="form-control txt-transparent" (change)="clearOnChangeVal(); createCellsQue(ctrl)" />
                                </ng-container>
                                <ng-container *ngIf="ctrl.type==='text'">
                                    <input [id]="ctrl.id" [name]="ctrl.id" type="text" [(ngModel)]="ctrl.value"
                                        class="form-control txt-transparent input-{{ctrl.type}}" (change)="clearOnChangeVal(); createCellsQue(ctrl)" />
                                </ng-container>
                                <ng-container *ngIf="ctrl.type==='number'">
                                    <input 
                                        type="text" 
                                        class="form-control txt-transparent input-{{ctrl.type}}"
                                        [id]="ctrl.id" 
                                        [name]="ctrl.id" 
                                        [min]="ctrl.inputProperties?.minValue"
                                        [max]="ctrl.inputProperties?.maxValue"
                                        [(ngModel)]="ctrl.value" 
                                        (change)="clearOnChangeVal(); validateInput(ctrl)" 
                                    />
                                </ng-container>
                                <ng-container *ngIf="ctrl.type==='select'">
                                    <ng-select class="single" required
                                        [name]="ctrl.id"
                                        [items]="ctrl.options"
                                        [(ngModel)]="ctrl.value"
                                        bindLabel="title" 
                                        bindValue="value" 
                                        [multiple]="false"
                                        [clearable]="false" 
                                        [searchable]="false"
                                        (change)="clearOnChangeVal(); createCellsQue(ctrl)">
                                    </ng-select>
                                </ng-container>
                            </ng-container>
                        </div>
                    </td>
                </ng-container>
            </tr>
        </ng-container>
    </table>
</ng-template>