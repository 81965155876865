<ng-container *ngIf="!section._id">
    <app-unauthorized></app-unauthorized>
</ng-container>
<ng-container *ngIf="section._id">
    <div class="row mt-3">
        <div class="col-lg-4 position-relative">
            <input type="text" class="form-control search_inpt no_icon" placeholder="search" [formControl]="keywords">
            <button class="btn_search"><img src="assets/img/search_grey.svg"></button>
        </div>
        <div class="col-lg-4">
            <div class="drp_dwn">
                <span>Tags</span>
                <input type="text" class="form-control tags_inpt" [value]="selectedTag.display" disabled>
            </div>
            <div class="drp_dwn_contnt d_none">
                <input type="text" class="form-control" placeholder="search" [formControl]="tagSearch">
                <h6><a href="javascript:void(0)" (click)="selectedTag = { display:'All', value:''}; filterList()">All</a>
                </h6>
                <ng-container *ngFor="let tag of tagFilterList">
                    <h6><a href="javascript:void(0)" (click)="selectedTag = tag; filterList()">{{tag.display}}</a></h6>
                </ng-container>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="add_note w-100 text-right">
                <a href="javascript:void(0)" data-toggle="modal" data-target="#Add_Note" (click)="addNew()">Add a note</a>
                <!-- Modal -->
                <div class="modal fade Add_Note" id="Add_Note" tabindex="-1" role="dialog"
                    aria-labelledby="Add_NoteCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <form name="form" class="col" autocomplete="off" (ngSubmit)="onSubmit(f);" #f="ngForm" novalidate>
                            <div class="modal-content">
                                <div class="modal-body">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label>Title</label>
                                                <input type="text" class="form-control" name="title"
                                                    [(ngModel)]="noteModel.title" required>
                                                <span [@inOutAnimation] class="error"
                                                    *ngIf="f.submitted && !noteModel.title">
                                                    This field is required
                                                </span>
                                            </div>
                                            <div class="form-group">
                                                <label>Tag optional</label>
                                                <tag-input class="form-control tag-input-custom" name="tag"
                                                    [(ngModel)]="noteModel.tags" placeholder="Add tag"
                                                    secondaryPlaceholder="Enter a new tag and hit Enter key">
                                                    <tag-input-dropdown [appendToBody]="false"
                                                        [autocompleteItems]="tagList">
                                                    </tag-input-dropdown>
                                                </tag-input>
                                            </div>
                                            <div class="form-group">
                                                <label>Take a note</label>
                                                <textarea type="text" rows="6" class="form-control" name="description"
                                                    [(ngModel)]="noteModel.description" required></textarea>
                                                <span [@inOutAnimation] class="error"
                                                    *ngIf="f.submitted && !noteModel.description">
                                                    This field is required
                                                </span>
                                                <span [@inOutAnimation] class="error" [innerHTML]="errorMessage">
                                                </span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer justify-content-start border-0">
                                    <button type="submit" class="btn btn-secondary" [disabled]="btnDisabled">Save
                                        <mat-spinner class="btnLoading" [diameter]="20" *ngIf="btnDisabled">
                                        </mat-spinner>
                                    </button>
                                    <button id="btnCancel" type="button" class="btn btn-secondary"
                                        data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row content-wrapper">    
        <div class="col-lg-6">
            <ng-container *ngFor="let item of listItems ; let odd=odd; let even=even;">
                <div class="random_sized_box" *ngIf="even">
                    <span class="vertical_menu">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                            class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                            <path
                                d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                        </svg>
                    </span>
                    <div class="vertical_toggle d_none">
                        <span data-toggle="modal" data-target="#Add_Note" (click)="edit(item)">Edit</span>
                        <span class="cursor-pointer" (click)="confirmDelete(item)">Delete</span>
                    </div>
                    <h6>{{item.title}}</h6>
                    <p>{{item.description}}
                        <span class="date_time">{{item.createdAt | localizeTime}}</span>
                        <span class="tags_" *ngFor="let t of item.tagsList">{{t.title}}</span>
                    </p>
                </div>
            </ng-container>
        </div>
        <div class="col-lg-6">
            <ng-container *ngFor="let item of listItems ; let odd=odd; let even=even;">
                <div class="random_sized_box" *ngIf="odd">
                    <span class="vertical_menu">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                            class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                            <path
                                d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                        </svg>
                    </span>
                    <div class="vertical_toggle d_none">
                        <span data-toggle="modal" data-target="#Add_Note" (click)="edit(item)">Edit</span>
                        <span class="cursor-pointer" (click)="confirmDelete(item)">Delete</span>
                    </div>
                    <h6>{{item.title}}</h6>
                    <p>{{item.description}}
                        <span class="date_time">{{item.createdAt | localizeTime}}</span>
                        <span class="tags_" *ngFor="let t of item.tagsList">{{t.title}}</span>
                    </p>
                </div>
            </ng-container>
        </div>
        <ng-container *ngIf="isLoading">
            <div class="col text-center">
                <mat-spinner class="btnLoading" [diameter]="50">
                </mat-spinner>
            </div>
            <!-- <div class="col-lg-6">
                <div class="random_sized_box" *ngFor="let row of ' '.repeat(6).split('')">
                    <h6>
                        <mat-progress-bar mode="buffer"></mat-progress-bar>
                    </h6>
                    <p>
                        <mat-progress-bar mode="buffer" *ngFor="let row of ' '.repeat(12).split('')"></mat-progress-bar>
                    </p>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="random_sized_box" *ngFor="let row of ' '.repeat(6).split('')">
                    <h6>
                        <mat-progress-bar mode="buffer"></mat-progress-bar>
                    </h6>
                    <p>
                        <mat-progress-bar mode="buffer" *ngFor="let row of ' '.repeat(12).split('')"></mat-progress-bar>
                    </p>
                </div>
            </div> -->
        </ng-container>
        <div class="col text-center view-more add_note" *ngIf="showViewMoreBtn">
            <a class="cursor-pointer" (click)="pagChanged()">View More</a>
            <mat-spinner class="btnLoading mt-2" [diameter]="20" *ngIf="isLoading">
            </mat-spinner>
        </div>
        <app-no-records-found class="col-lg-12" *ngIf="isError" [httpError]="httpError"></app-no-records-found>
        <app-no-records-found class="col-lg-12" *ngIf="noResult" [noResult]="noResult">
        </app-no-records-found>
    </div>
</ng-container>
