import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { CheckoutSessionSuccessData, CreateCheckoutSession, PaymentListSuccessData } from '../model/payment.model';

@Injectable()
export class PaymentService {
    constructor(public http: HttpClient) { }

    filterList(
        keywords: string,
        status: string,
        page: number,
        limit: number,
        sortBy: string
    ): Observable<HttpResponse<PaymentListSuccessData>> {
        return this.http.get<PaymentListSuccessData>(environment.API_END_POINT + '/payment?title=' + keywords +
        '&status=' + status + '&page=' + page + '&limit=' + limit +
        '&sortBy=' + sortBy, { observe: 'response' });
    }
    createCheckoutSession(data: CreateCheckoutSession): Observable<HttpResponse<CheckoutSessionSuccessData>> {
        return this.http.post<CheckoutSessionSuccessData>(environment.API_END_POINT + '/payment/create-checkout-session', data, { observe: 'response' });
    }
    paymentSuccess(orderId: string): Observable<HttpResponse<any>> {
        return this.http.post<any>(environment.API_END_POINT + '/payment/confirm-order', { orderId }, { observe: 'response' });
    }
    getPaymentDetails(orderId: string) {
        return this.http.get<any>(environment.API_END_POINT + '/payment/payment-status/' + orderId);
    }
}
