<section class="about_sec">
    <div class="container-fluid w_80">
        <div class="row">
            <div class="col-lg-12">
                <h3>{{page === 'reset-password' ? 'Reset Password' : 'Set password'}}</h3>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container-fluid w_80 mb-5">
        <div class="row">
            <div class="col-lg-12">
                <div class="wrapper_white">
                    <div class="row">
                        <div class="col-lg-6 text-left">
                            <form [formGroup]="formGroup" #form="ngForm" (ngSubmit)="onFormSubmit(form)" autocomplete="off">
                                <div class="form-row contac_wrp pt-4">
                                    <div class="col-12">
                                        <input type="password" class="form-control" formControlName="password" placeholder="New password">
                                        <small [@inOutAnimation] class="form-text error"
                                            *ngIf="form.submitted && formGroup.controls['password'].hasError('required')">
                                            Please fill this field
                                         </small>
                                         <small [@inOutAnimation] class="form-text error"
                                            *ngIf="form.submitted && formGroup.controls['password'].hasError('minlength')">
                                            Password should be minimum {{formGroup.controls['password'].errors?.minlength.requiredLength}} characters long.
                                        </small>
                                    </div>
                                    <div class="col-12">
                                        <input type="password" class="form-control" formControlName="confirmPassword" placeholder="Confirm password">
                                        <small [@inOutAnimation] class="form-text error"
                                             *ngIf="form.submitted && formGroup.controls['confirmPassword'].hasError('required')">
                                             Please fill this field
                                        </small>
                                        <small [@inOutAnimation] class="form-text error"
                                            *ngIf="form.submitted && formGroup.controls['confirmPassword'].hasError('minlength')">
                                            Confirm password should be minimum {{formGroup.controls['confirmPassword'].errors?.minlength.requiredLength}} characters long.
                                        </small>
                                        <small [@inOutAnimation] class="form-text error" *ngIf="errorMessage"
                                            [innerHTML]="errorMessage">
                                        </small>
                                    </div>
                                    <div class="col-12 text-right pt-4">
                                        <button type="submit" class="common_btn" [disabled]="btnDisabled"> Set Password 
                                            <mat-spinner class="btnLoading" [diameter]="20" *ngIf="btnDisabled">
                                            </mat-spinner>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>
