<div class="d-flex flex-wrap">
    <div class="form_wrpr">
        <div class="left_sec pr_adjest interactive-content styles_custom">
            <app-interactive-content 
                [resource]="resource" 
                [chapter]="chapter"
                [section]="section" 
                [jsonData]="section.contentInteractive"
            ></app-interactive-content>
        </div>
    </div>
    <div class="right_sec">
        <div class="absolutr_sec">
            <img class="menu_icon" src="assets/img/menu.svg">
            <div class="right_cntnt">
                <h4 class="pt-3">Sections ({{sectionList.length}})</h4>
                <ul class="item_wrp">
                    <li [ngClass]="{'active':section._id===item._id,'section-disabled':!item.hasSectionAccess }" *ngFor="let item of sectionList; let index = index"
                        (click)="selectSection(item)">
                        <a href="javascript:void(0)"><span class="pr-2">{{index+1}}.</span>
                            {{item.title}}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
